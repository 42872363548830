<!-- <div class="dialog-container"> -->
    <div class="dialog-container-header">
        <h2 mat-dialog-title> {{data.name}} </h2>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <!-- <br> -->
    <p>Note: Any lab values that you do not adjust will be applied at default levels.</p>
    <!-- <hr>  -->
    <mat-dialog-content>
        <mat-tab-group style="height:425px">
            <mat-tab label="CBC">
                <br>
                <div class="vitals-row">
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.whiteBloodCount"
                            name="whiteBloodCount"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.Hematocrit"
                            name="Hematocrit"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                </div>
                <div class="vitals-row">
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.Hemoglobin"
                            name="Hemoglobin"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.platelets"
                            name="platelets"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="BMP">
                <br>
                <div class="vitals-row">
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.Sodium"
                            name="Sodium"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.bloodUreaNitrogen"
                            name="bloodUreaNitrogen"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                </div>
                <div class="vitals-row">
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.potassium"
                            name="potassium"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.creatinine"
                            name="creatinine"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                </div>
                <div class="vitals-row">
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.chloride"
                            name="chloride"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.glucose"
                            name="glucose"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                </div>
                <div class="vitals-row">
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.bicarbonate"
                            name="bicarbonate"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.calcium"
                            name="calcium"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="FSG">
                <br>
                <div class="vitals-row">
                    <div class="cell">
                        <app-slider
                            [(data)]="data.clinical.FSG_glucose"
                            name="FSG_glucose"
                            [gender]="data.gender"
                            canEdit="true">
                        </app-slider>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="ABG/VBG">
                <br>
                <label>Draw Type</label>
                <br>
                <br>
                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="0" [checked]="data.clinical.drawType === 0" (click)="data.clinical.drawType = 0" style="margin-right: 50px;">ABG</mat-radio-button>
                    <mat-radio-button value="1" [checked]="data.clinical.drawType === 1" (click)="data.clinical.drawType = 1">VBG</mat-radio-button>
                </mat-radio-group>
                <br>
                <br>

                <!-- ABG VALUES -->

                <div *ngIf="data.clinical.drawType === 0">
                    <div class="vitals-row">
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.pH"
                                name="pH"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.bicarb"
                                name="bicarb"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                    </div>
                    <div class="vitals-row">
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.pa02"
                                name="pa02"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.bE"
                                name="bE"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                    </div>
                    <div class="vitals-row">
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.paCO2"
                                name="paCO2"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.lab_saO2"
                                name="lab_saO2"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                    </div>
                    <div class="vitals-row">
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.lactate"
                                name="lactate"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                    </div>
                </div>

                <!-- VBG VALUES -->

                <div *ngIf="data.clinical.drawType === 1">
                    <div class="vitals-row">
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.pH"
                                name="pH"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.bicarb"
                                name="bicarb"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                    </div>
                    <div class="vitals-row">
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.pa02"
                                name="pa02"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
        
                        </div>
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.bE"
                                name="bE"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                    </div>
                    <div class="vitals-row">
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.paCO2"
                                name="paCO2"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.lab_saO2"
                                name="lab_saO2"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                    </div>
                    <div class="vitals-row">
                        <div class="cell">
                            <app-slider
                                [(data)]="data.clinical.lactate"
                                name="lactate"
                                [gender]="data.gender"
                                canEdit="true">
                            </app-slider>
                        </div>
                    </div>

                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-button class="green-focus" (click)="onDialogClose()">Apply Labs</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
<!-- </div> -->