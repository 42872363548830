import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { EquipmentState } from '../dialog-treatment.component'

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {

  @Input() public equipment: string;
  @Input() public activeState: boolean;
  @Input() public index: number;
  @Output() activeStateChange = new EventEmitter<boolean>();

  constructor() { }

  public count: number = 0;

  /**
   * Construct a url path for this string.
   */
  public get imageUrl(): string {
    // need to strip out Left_, Right_, gender/ethnic from the names using pipes
    
    // const array = ['Left', 'Right', 'RightRight', 'Wht', 'White', 'Eth', 'Ethnic', 'Male', 'Female', 'Bby'];
    // const name = this.equipment
    //   .split("_")                           // split the array apart
    //   .filter(x=> array.indexOf(x) === -1 ) // remove any words that matches above
    //   .join('_')                            // reassemble back into string
    //   .trim();                              // remove any leading spaces if there are any.
    return `./assets/Images/equipment/${this.equipment.replace(/(Left_|Right_|RightRight_|Wht_|White_|Eth_|Ethnic_|Male_|Female_|Bby_)/g,'')}.png`
    //return `./assets/Images/equipment/${name.replace(/ /g, '_')}.png`
  }

  ngOnInit(): void {
  }

  onChangeState(): void{
    this.activeState = !this.activeState;
    this.activeStateChange.emit(this.activeState);
  }
}
