<!-- max-height:333px; -->
<div style="overflow-y: scroll; height:100%">
    <p class="label">Patient Name</p>
    <p class="content">{{patientControllerService.patient.patientName}}</p>
    <p class="label">Patient Goals or Details</p>
    <p class="content">{{patientControllerService.patient.goalsAndDetails}}</p>
    <p class="label">Patient Backstory</p>
    <p class="content">{{patientControllerService.patient.backstory}}</p>
    <div style="display: flex; flex-direction: row;">
        <div style="min-width: 50%;">
            <p class="label">Amputation</p>
            <p class="content">{{patientControllerService.amputationPart}}</p>
        </div>
        <div>
            <p class="label">Gender & Skin Tone</p>
            <p class="content">{{patientControllerService.GenderAndSkin}}</p>
        </div>
    </div>
    <div style="display: flex; flex-direction: row;">
        <div style="min-width: 50%;">
            <p class="label">Weight</p>
            <p class="content">{{patientControllerService.patient.weight}} kg</p> 
        </div>
        <div>
            <p class="label">Clothing State</p> 
            <p class="content">{{patientControllerService.patient.clothingState}}</p>
        </div>
    </div>
</div>