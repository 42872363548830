<div class="header">
  <h2 mat-dialog-title>Files</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Documents">
      <app-scenario-files
        style="height: 120px"
        Title="Document Files"
        (incomingFiles)="onDocumentFilesDropped($event)"
        icon="description"
        [acceptedExtension]="this.acceptedDocumentFileTypes"
      ></app-scenario-files>
      <div *ngFor="let file of this.uploadedDocumentFiles" class="listed-item">
        <span class="file-name">
          <p>
            <mat-icon style="transform: translateY(6px); margin-right: 10px"
              >content_copy</mat-icon
            >{{ file }}
          </p>
        </span>
        <span class="right">
          <button mat-icon-button>
            <mat-icon style="color: orangered" (click)="this.deleteDocumentItems(file)">close</mat-icon>
          </button>
        </span>
      </div>
    </mat-tab>

    <mat-tab label="Upload Audio">
      <app-scenario-files
        style="height: 120px"
        Title="Sound Files"
        (incomingFiles)="onSoundFilesDropped($event)"
        icon="volume_up"
        [acceptedExtension]="this.acceptedFileTypes"
      ></app-scenario-files>
      <app-audio-form
        *ngFor="let blob of this.fileArray"
        [blob]="blob"
        [fileName]="blob.name"
        (completed)="this.removeItemFromList(blob)"
      ></app-audio-form>
      <div *ngFor="let x of this.uploadedFiles" class="listed-item">
        <span class="file-name"
          ><p>
            <mat-icon style="transform: translateY(6px)">audiotrack</mat-icon
            >{{ x | ObtainFilename }}
          </p>
          <p></p
        ></span>
        <span class="right"
          ><p>{{ x.split("/")[0] }}</p>
          <p>{{ x.split("/")[1] }}</p>
          <button
            mat-icon-button
            (click)="this.deleteAudioItems(x)"
            style="background-color: transparent"
          >
            <mat-icon style="color: orangered">close</mat-icon>
          </button></span
        >
      </div>
    </mat-tab>

    <mat-tab label="Record Audio">
      <br/>
      <mat-form-field appearance="outline">
        <mat-label>Name*</mat-label>
        <input autocomplete="off"
        matInput
        [formControl]="recordNameControl"
        [errorStateMatcher]="matcher"
        [(ngModel)]="this.recordName">
        <mat-error *ngIf="recordNameControl.hasError('pattern') && !recordNameControl.hasError('required')">
          This name contains invalid character!
        </mat-error>
      </mat-form-field>
      <br/>
      <div>
      <mat-form-field appearance="outline">
        <mat-label>Source</mat-label>
        <mat-select [(value)]="recordSource"
        [disabled]="!recordNameControl.valid">
          <mat-option *ngFor="let source of this.audioFileSelection.getAudioKeys()"
            [value]="this.audioFileSelection.getAudioValue(source)"
            (click)="this.toggleDropdownSelections()">
            {{this.audioFileSelection.getAudioValue(source)}}
          </mat-option> 
        </mat-select>
      </mat-form-field>

      <div>
      <mat-form-field appearance="outline" *ngIf="this.showCharacterType">
        <mat-label> Character Type </mat-label>
        <mat-select [(value)]="recordCharacterType">
          <mat-option
            *ngFor="
              let characterType of this.audioFileSelection.getCharacterKeys()
            "
            value="{{ this.audioFileSelection.getCharacterValue(characterType) }}"
          >
            {{ this.audioFileSelection.getCharacterValue(characterType) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="outline" *ngIf="this.showAuscultationType">
        <mat-label> Organ </mat-label>
        <mat-select [(value)]="recordAuscultation">
          <mat-option
            *ngFor="let organ of this.audioFileSelection.getAuscultationKeys()"
            value="{{ this.audioFileSelection.getAuscultationValue(organ) }}"
          >
            {{ this.audioFileSelection.getAuscultationValue(organ) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      
    <br/>

    <div class="playback">
      <!-- dropdown to select one of the above items -->
      <p *ngIf="!recordUrl; else showAfterRecorded">Custom Recording</p>
      <ng-template #showAfterRecorded>
        <p>Click the button below to begin recording</p>
      </ng-template>

      <div *ngIf="!recordUrl; else playback">
        <button
          mat-fab
          color="primary"
          [ngStyle]="{ color: 'red' }"
          [disabled]="determineDisabled()"
          (click)="recordAudio()"
          *ngIf="!isRecording; else otherButton"
        >
          <mat-icon [ngStyle]="{ color: 'white' }">mic</mat-icon>
        </button>

        <ng-template #otherButton>
          <button
            mat-fab
            color="primary"
            [ngStyle]="{ background: 'red' }"
            *ngIf="isRecording; else playback"
            (click)="stopRecording()"
          >
            <mat-icon [ngStyle]="{ color: 'white' }">stop</mat-icon>
          </button>
        </ng-template>
      </div>

      <ng-template #playback>
        <!-- playback scrubber with time stamp -->
        <mat-slider
          style="width: 100%"
          [max]="recordSrc.duration"
          [(value)]="recordSrc.currentTime"
          async
        >
        </mat-slider>

        <!-- pause / play control -->
        <br />
        <div class="bottom">
          <button
            mat-fab
            color="primary"
            (click)="recordSrc.play()"
            *ngIf="recordSrc.paused"
          >
            <mat-icon [ngStyle]="{ color: 'white' }">play_arrow</mat-icon>
          </button>
          <button
            mat-fab
            color="primary"
            (click)="recordSrc.pause()"
            *ngIf="!recordSrc.paused"
          >
            <mat-icon [ngStyle]="{ color: 'white' }">pause</mat-icon>
          </button>
          <button mat-button  color="link" (click)="recordUrl = null">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
          <button mat-button color="primary" (click)="publish()">
            <mat-icon>backup</mat-icon>
            Publish
          </button>
          <p>
            {{ recordSrc.currentTime | CleanTimeline }} /
            {{ recordSrc.duration | CleanTimeline }}
          </p>
        </div>
      </ng-template>
    </div>
    

    </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-button class="green-focus" mat-dialog-close>Done</button>
</mat-dialog-actions>
