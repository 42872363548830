import { Component, Inject, OnInit } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { scenario } from 'src/app/models/scenario.model';

export interface DialogData {
  scenario: scenario;
}

@Component({
  selector: 'app-dialog-credits',
  templateUrl: './dialog-credits.component.html',
  styleUrls: ['./dialog-credits.component.scss']
})
export class DialogCreditsComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<DialogCreditsComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData ) { }

  ngOnInit(): void {
  }

}
