<div class="hover-button" (mouseleave)="showPopup = false">
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
        style="transform: translateX(-50%);" (mouseover)="showPopupF()" (click)="showAudioPopupF()">
        <mat-icon style="color: #1f7abe">     
            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19.8,18.4L14,10.67V6.5l1.35-1.69C15.61,4.48,15.38,4,14.96,4H9.04C8.62,4,8.39,4.48,8.65,4.81L10,6.5v4.17L4.2,18.4 C3.71,19.06,4.18,20,5,20h14C19.82,20,20.29,19.06,19.8,18.4z"/></g></svg>
        </mat-icon>
    </button>

    <div class="popup" *ngIf="showPopup">
        <div class="popup-notch">
        </div>
        <div class="transparent-top-part" (click)="showAudioPopupF()">
        </div>
        <div class="popup-content" *ngIf="!showAudioPopupContent">
            <div style="font-size: 11px">
                <strong>Labs</strong>
                <div style="display: flex; border-top: solid; border-color: #cccccc; border-width: 1px;">
                    <div style="border-right: solid; border-color: #cccccc; border-width: 1px;">
                        <div style="width: 120px; border-bottom: solid; border-color: #cccccc; border-width: 1px;">
                            <strong>CBC</strong>:
                            <div class="divider">
                                <div class="col">
                                    <app-slider
                                        [(data)]="clinical.whiteBloodCount"
                                        name="whiteBloodCount"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>WBC:{{clinical.whiteBloodCount}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.Hemoglobin"
                                        name="Hemoglobin"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>HGB: {{clinical.Hemoglobin}}</p> -->
                                </div>
                                <div class="col">
                                    <app-slider
                                        [(data)]="clinical.Hematocrit"
                                        name="Hematocrit"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>HCT: {{clinical.Hematocrit}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.platelets"
                                        name="platelets"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>Plt: {{clinical.platelets}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div>
                            <strong>ABG:</strong>
                            <div class="divider">
                                <div class="col">
                                    <app-slider
                                        [(data)]="clinical.pH"
                                        name="pH"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>pH: {{clinical.pH}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.pa02"
                                        name="pa02"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>PaO2: {{clinical.pa02}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.paCO2"
                                        name="paCO2"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>PaCO2: {{clinical.paCO2}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.lactate"
                                        name="lactate"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>Lactate: {{clinical.lactate}}</p> -->
                                </div>
                                <div class="col">
                                    <app-slider
                                        [(data)]="clinical.bicarb"
                                        name="bicarb"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>Bicarb: {{clinical.bicarb}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.bE"
                                        name="bE"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>BE: {{clinical.bE}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.lab_saO2"
                                        name="lab_saO2"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>SaO2: {{clinical.SaO2}}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style="width: 100px; border-bottom: solid; border-color: #cccccc; border-width: 1px;">
                            <strong>BMP:</strong>
                            <div class="divider">
                                <div class="col">
                                    <app-slider
                                        [(data)]="clinical.Sodium"
                                        name="Sodium"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>Na: {{clinical.Sodium}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.potassium"
                                        name="potassium"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>K: {{clinical.potassium}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.chloride"
                                        name="chloride"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>Cl: {{clinical.chloride}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.bicarbonate"
                                        name="bicarbonate"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>CO2: {{clinical.bicarbonate}}</p> -->
                                </div>
                                <div class="col">
                                    <app-slider
                                        [(data)]="clinical.bloodUreaNitrogen"
                                        name="bloodUreaNitrogen"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>BUN: {{clinical.bloodUreaNitrogen}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.creatinine"
                                        name="creatinine"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>Creat: {{clinical.creatinine}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.glucose"
                                        name="glucose"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>Glu: {{clinical.glucose}}</p> -->
                                    <app-slider
                                        [(data)]="clinical.calcium"
                                        name="calcium"
                                        [gender]="gender"
                                        [showShort]="true"
                                    ></app-slider>
                                    <!-- <p>Ca: {{clinical.calcium}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div>
                            <strong>FSG:</strong>
                            <app-slider
                                [(data)]="clinical.FSG_glucose"
                                name="FSG_glucose"
                                [gender]="gender"
                                [showShort]="true"
                            ></app-slider>
                            <!-- <p>Glu: {{clinical.FSG_glucose}}</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="popup-content" *ngIf="showAudioPopupContent">
            <button mat-flat-button style="color:#1f7abe" (click)="OnEditItem()"><mat-icon>create</mat-icon> Edit</button>
            <button mat-flat-button style="color:#1f7abe" (click)="onDeleteItem()"><mat-icon>delete</mat-icon> Delete</button>
        </div>
    </div>
</div>