import { Component, Input, OnInit } from '@angular/core';
import { PreviewAnimationService } from 'src/app/editor/services/preview-animation.service';
import { UnityService } from 'src/app/editor/simulation-preview/unity.service';
import { phase } from 'src/app/models/phase.model'
import { clinical } from 'src/app/models/statedata.model'

@Component({
  selector: 'app-phase-card-c',
  templateUrl: './phase-card-c.component.html',
  styleUrls: ['./phase-card-c.component.scss']
})
export class PhaseCardCComponent implements OnInit {
  
  @Input('phase') _phase:phase; //phase to pull the data from externally 
  public openCard:boolean = false;

  constructor(private unityService: UnityService, private previewAnimationService: PreviewAnimationService) { }

  ngOnInit(): void {
  }

  onPlayAnimation(symptom: clinical){
    if( this.previewAnimationService.IsMatchingSymptoms(symptom)){
      this.previewAnimationService.onStopAnimation();
    } else {
      this.previewAnimationService.onPlayAnimation(symptom);
    }
  };

}
