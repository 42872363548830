import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import appPackage from 'package.json';
import { TeamDownloadResult } from '../models/teamdownloadresult.model';

export interface TeamData {
  userId: string;
  scenarioId: string;
}

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  public get urlPath(): string {
    return appPackage.backendServerPath + '/teams';
  }

  constructor(private http: HttpClient) {}

  /**
   * This will provide you all of the simulation that was saved in the Teams file.
   */
  getList(): Observable<any> {
    return this.http.get(this.urlPath);
  }

  /**
   * Save the scenario ID that you wish to share with your team.
   * @param scenarioId Scenario ID object
   */
  addToSimulation(scenarioId: string,scenarioName:string) {
    // in this case here we'll either provide the string of the simulation we want to share with.
    return this.http.put(this.urlPath, { scenarioId,scenarioName });
  }

  /**
   * Remove the string from the shared team simulation.
   * @param scenarioId Scenario ID Object
   */
  removeFromSimulation(scenarioId: string) {
    return this.http.delete(this.urlPath + '/' + scenarioId);
  }

  /**
   * Immediately notify the server to create a copy of the scenario object and links to your personal folder
   * @param userId The original Author of the simulation you wish to download from
   * @param scenarioId The Simulation Id to download from
   */
   dowloadTeamSimulationToYourSimulation( userId: string, scenarioId: string ):Observable<TeamDownloadResult>{
        const data: TeamData = { userId, scenarioId };
    return this.http.post<TeamDownloadResult>( this.urlPath + '/importSimulation', data );
  }
}
