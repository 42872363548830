<div class="dialog-container-header">
    <h2 mat-dialog-title>Add/Remove Devices</h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="heading">
    <H3>Image</H3>
    <H3>Device Name</H3>
    <H3>Status</H3>
  </div>
  <hr>
  <div class="wrapper">
      <app-equipment
      *ngFor="let device of deviceStates; let i = index"
      [equipment]="device.deviceObject.kind"
      [activeState]="device.isActive"
      [index]="i"
      (activeStateChange)="updateActiveState($event, i)"
    ></app-equipment>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close>Cancel</button>
    <button
      mat-button
      class="green-focus"
      [mat-dialog-close]="this.selectedDevices"
    >
      Submit Changes
    </button>
  </mat-dialog-actions>