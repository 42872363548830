import { Component, Inject, Input } from '@angular/core';
import {   MatSnackBar,   MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface snackBarData {
  message : string
}

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  constructor( @Inject(MAT_SNACK_BAR_DATA) public data: snackBarData, public snackBar:MatSnackBar ) { }
}
