export class Simulation {
  constructor(
    public name: string,
    public creator: string,
    public date: Date,
    public isUpdated: boolean,
    public picture: string,
    public description: string,
    public isUpdating: boolean
  ) {}
}
