<div class="card" (click)="stateSwitch(scenario)">
  <div class="card-header-wrapper">
    <div class="card-header">
      <div class="more-content" *ngIf="this.shared && !this.scenario.isDownloaded" style="z-index: 1;">
        <mat-icon matTooltip="Get Scenario" (click)="onDownloadEvent($event)">get_app</mat-icon>
      </div>
      <div class="more-content" *ngIf="this.shared &&this.isOwnedScenario" style="z-index: 1;">
        <mat-icon matTooltip="Owned Scenario">check_circle</mat-icon>
      </div>
      <div *ngIf="!this.shared" class="more-content" style="z-index: 1;">
        <mat-icon id="scenario-card-menu" *ngIf="this.teamSimulation === false" matTooltip="View Options"
          [matMenuTriggerFor]="beforeMenu" (click)="onMoreEvent($event)">more_vert</mat-icon>

      </div>

      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item [routerLink]="['/scenario-editor']"
          [queryParams]="{ scenarioId: this.scenario.ScenarioId}">
          <mat-icon class="">edit</mat-icon>
          Edit Scenario</button>
        <button mat-menu-item (click)="onDuplicateSimulation()">
          <mat-icon>content_copy</mat-icon><span>Duplicate Scenario</span>
        </button>
        <button id="btn-team-share" mat-menu-item *ngIf="this.scenario?.isShared === false"
          (click)="onShareWithTeam(true)">
          <mat-icon>group</mat-icon><span>Share With Team</span>
        </button>
        <button class="btn-unshare" mat-menu-item id="btn-team-unshare" *ngIf="this.scenario?.isShared === true"
          (click)="onShareWithTeam(false)">
          <mat-icon class="btn-unshare">group</mat-icon><span>Remove From Team Share</span>
        </button>
        <button id="btn-community-share" *ngIf="!this.scenario?.isCommunityShared" mat-menu-item
          (click)="onShareWithCommunity(true)">
          <mat-icon>public</mat-icon><span>Share with Community</span>
        </button>
        <button id="btn-community-unshare" class="btn-unshare" *ngIf="this.scenario?.isCommunityShared" mat-menu-item
          (click)="onShareWithCommunity(false)">
          <mat-icon class="btn-unshare">public</mat-icon><span>Remove from Community</span>
        </button>
      </mat-menu>

    </div>
  </div>
  <div style="z-index: -1;">
    <div *ngIf="this.scenario?.patients?.length === 1 ||this.scenario?.patients?.length === 0" class="image">
      <mat-icon *ngIf="!this.scenario?.patients[0]?.imageUrl">wallpaper</mat-icon>

      <img class="scenario-card-image" *ngIf="this.scenario?.patients[0]?.imageUrl" loading="lazy"
        [src]="this.scenario?.patients[0]?.imageUrl" alt="{{ this.scenario?.patients[0]?.imageUrl }}" />
    </div>
    <div *ngIf="this.scenario?.patients?.length === 2" class="two-images">
      <div>
        <mat-icon *ngIf="!this.scenario?.patients[0]?.imageUrl" class="wallpaper">wallpaper</mat-icon>
        <img class="scenario-card-image" *ngIf="this.scenario?.patients[0]?.imageUrl" loading="lazy"
          [src]="this.scenario?.patients[0]?.imageUrl" alt="{{ this.scenario?.patients[0]?.imageUrl }}" />
      </div>
      <div>
        <mat-icon *ngIf="!this.scenario?.patients[1]?.imageUrl" class="wallpaper">wallpaper</mat-icon>
        <img class="scenario-card-image" *ngIf="this.scenario?.patients[1]?.imageUrl" loading="lazy"
          [src]="this.scenario?.patients[1]?.imageUrl" alt="{{ this.scenario?.patients[1]?.imageUrl }}" />
      </div>
    </div>
    <div *ngIf="this.scenario?.patients?.length === 3" class="three-images">
      <div>
        <mat-icon *ngIf="!this.scenario?.patients[0]?.imageUrl" class="wallpaper">wallpaper</mat-icon>
        <img class="scenario-card-image" *ngIf="this.scenario?.patients[0]?.imageUrl" loading="lazy"
          [src]="this.scenario?.patients[0]?.imageUrl" alt="{{ this.scenario?.patients[0]?.imageUrl }}" />
      </div>
      <div>
        <mat-icon *ngIf="!this.scenario?.patients[1]?.imageUrl" class="wallpaper">wallpaper</mat-icon>
        <img class="scenario-card-image" *ngIf="this.scenario?.patients[1]?.imageUrl" loading="lazy"
          [src]="this.scenario?.patients[1]?.imageUrl" alt="{{ this.scenario?.patients[1]?.imageUrl }}" />
      </div>
      <div>
        <mat-icon *ngIf="!this.scenario?.patients[2]?.imageUrl" class="wallpaper">wallpaper</mat-icon>
        <img class="scenario-card-image" *ngIf="this.scenario?.patients[2]?.imageUrl" loading="lazy"
          [src]="this.scenario?.patients[2]?.imageUrl" alt="{{ this.scenario?.patients[2]?.imageUrl }}" />
      </div>
    </div>
    <div *ngIf="this.scenario?.patients?.length === 4" class="four-images">
      <div class="row">
        <div>
          <mat-icon *ngIf="!this.scenario?.patients[0]?.imageUrl" class="wallpaper four">wallpaper</mat-icon>
          <img class="scenario-card-image" *ngIf="this.scenario?.patients[0]?.imageUrl" loading="lazy"
            [src]="this.scenario?.patients[0]?.imageUrl" alt="{{ this.scenario?.patients[0]?.imageUrl }}" />
        </div>
        <div>
          <mat-icon *ngIf="!this.scenario?.patients[1]?.imageUrl" class="wallpaper four">wallpaper</mat-icon>
          <img class="scenario-card-image" *ngIf="this.scenario?.patients[1]?.imageUrl" loading="lazy"
            [src]="this.scenario?.patients[1]?.imageUrl" alt="{{ this.scenario?.patients[1]?.imageUrl }}" />
        </div>
      </div>
      <div class="row">
        <div>
          <mat-icon *ngIf="!this.scenario?.patients[2]?.imageUrl" class="wallpaper four">wallpaper</mat-icon>
          <img class="scenario-card-image" *ngIf="this.scenario?.patients[2]?.imageUrl" loading="lazy"
            [src]="this.scenario?.patients[2]?.imageUrl" alt="{{ this.scenario?.patients[2]?.imageUrl }}" />
        </div>
        <div>
          <mat-icon *ngIf="!this.scenario?.patients[3]?.imageUrl" class="wallpaper four">wallpaper</mat-icon>
          <img class="scenario-card-image" *ngIf="this.scenario?.patients[3]?.imageUrl" loading="lazy"
            [src]="this.scenario?.patients[3]?.imageUrl" alt="{{ this.scenario?.patients[3]?.imageUrl }}" />
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div *ngIf="isLongName()">
    <h3  matTooltip="{{prettyScenarioName( scenario?.name,false)}}" >{{ prettyScenarioName( scenario?.name,true) }}</h3>
    </div>
    <div *ngIf="!isLongName()">
      <h3>{{ prettyScenarioName( scenario?.name,true) }}</h3>
    </div>
    <p style="width: 100%;" class="author" *ngIf="ServerOnline && scenario!=null && scenario.originalAuthor!=null">
      {{getInstitutionName()}}-{{ prettyName(scenario?.originalAuthor?._id) }}
    </p>
    <p class="author" *ngIf="ServerOffline">
      {{ this.aService.getFullName() }}
    </p>
    <div class="rating-updated">
      <mat-icon *ngIf="deterimineNewTag() && this.teamSimulation" class="new-icon">fiber_new</mat-icon>
      <p class="date">Updated: {{ this.prettyDate }} <span *ngIf="this.isDownloadCountEnabled && !this.isYourScenariosTab">Downloads:{{ this.downloadCount}}</span> </p>
      <div *ngIf="!this.shared" class="icon-container">
        <div *ngIf="this.scenario.isCommunityShared" matTooltip="shared with community">
          <mat-icon>public</mat-icon>
        </div>
        <div *ngIf="scenario?.isShared" matTooltip="shared with team">
          <mat-icon>group</mat-icon>
        </div>
      </div>
    </div>

    <div class="stars" *ngIf="this.isRatingEnabled && (this.iscommunity || this.teamSimulation)" >
      <mat-icon [ngStyle]="{ color: scenario?.rating >= 1 ? '#ffc300' : '#DDDDDD' }">grade</mat-icon>
      <mat-icon [ngStyle]="{ color: scenario?.rating >= 2 ? '#ffc300' : '#DDDDDD' }">grade</mat-icon>
      <mat-icon [ngStyle]="{ color: scenario?.rating >= 3 ? '#ffc300' : '#DDDDDD' }">grade</mat-icon>
      <mat-icon [ngStyle]="{ color: scenario?.rating >= 4 ? '#ffc300' : '#DDDDDD' }">grade</mat-icon>
      <mat-icon [ngStyle]="{ color: scenario?.rating >= 5 ? '#ffc300' : '#DDDDDD' }">grade</mat-icon>
      <span *ngIf="this.rateCount<=1" class="rate-count">
        Rating: {{this.rateCount}}
      </span>
      <span *ngIf="this.rateCount>1" class="rate-count">
        Ratings: {{this.rateCount}}
     </span>
    </div>


  </div>
  <!-- <button (click)="this.showThisScenario(scenario, $event)">show scenario</button> -->
</div>
