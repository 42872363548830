import { Component, OnInit, ɵConsole } from '@angular/core';
import {   MatDialogRef } from '@angular/material/dialog';
import { phase } from '../../../models/phase.model';
import { toolsservice } from '../../services/tools.service';
//import { DialogPhasesFormComponent } from './dialog-phases-form/dialog-phases-form.component';

@Component({
  selector: 'app-dialog-phases',
  templateUrl: './dialog-phases.component.html',
  styleUrls: ['./dialog-phases.component.scss']
})
export class DialogPhasesComponent implements OnInit {

  phases: phase[] = [];
  activePhase: phase;

  public get isValid() : boolean {
    var valid : boolean = false;
    for(var i = 0, n = this.phases.length; i< n; i++)
    {
      valid = (toolsservice.isNullUndefinedEmpty(this.phases[i].name) || toolsservice.isNullUndefinedEmpty(this.phases[i].description));
    }
    return valid;
  }

  constructor(public dialogRef: MatDialogRef<DialogPhasesComponent>) { }

  ngOnInit(): void {
    // let length:number;
    // if(this.phaseService.phases === undefined || this.phaseService.phases === null || this.phaseService.phases.length === 0){
    //   length = 0;
    // }else{
    //   length = this.phaseService.phases.length;
    // }
    this.activePhase = new phase(null,'','',0,[]);
    this.activePhase.edit = true;
    this.phases.push(this.activePhase);
  }

  onAddPhase(){
    this.phases.forEach(function (phase){
      phase.edit = false;
    })

    // phase.length
    // let length = this.phaseService.phases.length + 1;

    // let clinicalStates:statedata [] = [new statedata('','','',1,1,1)];
    this.activePhase = new phase(null,'','',0,[]);
    this.activePhase.edit = true;
    this.phases.push(this.activePhase);
  }

  onEditPhase(phase: phase){
    this.phases.forEach(function (phase){
      phase.edit = false;
    })
    phase.edit = true;
  }

  onPressedDelete(data_item:phase){
    this.phases = this.phases.filter(item => item !== data_item);
  }

  onPressCancel(){
    this.dialogRef.close();
  }

  onCloseDialog(){
    if( this.isValid ) return;
    this.dialogRef.close(this.phases);
  }
}
