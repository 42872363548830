<div class="dialog-container">
    <h2 mat-dialog-title style="font-family: OpenSansLight;"> Assign Archived Member's Published Simulations </h2>
    <hr>
    <mat-dialog-content>
        <p>{{data.name}} has published scenarios on the Team Simulations tab</p>
        <br>
        <p>
            Please assign these scenarios to an active member, so they can be updated in the future.
        </p>
        <br>
        <mat-label>Assign Published scenarios To*</mat-label>
        <!-- <mat-form-field appearance="outline" style="width: 100%;">
            <input autocomplete="off" matInput placeholder="USAF CCATT" [(ngModel)]="data.teamName" required>
            <mat-error *ngIf="data.name === ''">team name required</mat-error>
        </mat-form-field> -->
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select [(ngModel)]="selectedTeamMember">
              <mat-option *ngFor="let teammember of data.teammembers" [value]="selectedTeamMember">{{teammember.fName}} {{teammember.lName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <!-- <section>
            <mat-checkbox [(ngModel)]="checked" style="font-size: medium;">I understand this patient will be deleted forever</mat-checkbox>
        </section> -->
    </mat-dialog-content>
    <br>
    <mat-dialog-actions>
        <div class="buttons">
            <span>
                <button mat-raised-button [mat-dialog-close]="selectedTeamMember"
                    style="background-color: #1a844f; color: white;">Assign scenarios</button>
            </span>
        </div>
    </mat-dialog-actions>
</div>