import { SnackbarComponent } from '../../services/snackbar/snackbar.component';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { scenario } from 'src/app/models/scenario.model';
import { EventEmitter } from '@angular/core';
import { ScenariosService } from 'src/app/services/scenarios.service';
//import characterParams from '../../data/character-params.json';
import characterParams from '../../data/character-params.json';
import { FormControl } from '@angular/forms';

/****************************************************
 * Class: toolsservice
 * Purpose: app wide tools where helper functions are
 *          needed in more than one place
 ****************************************************/
export class toolsservice {
  // the current scenario we're editing
  public static currentScenario: scenario;

  // notification to force change the tab on scenario-header
  public static ForceTabChange = new EventEmitter();

  // receive notification to save scenario changes within Editor tab
  public static SaveScenarioChanges = new EventEmitter();

  public static Password_constraint = new RegExp("^(?=.*[a-zA-Z])(?=.*\\d)[A-Za-z\\d@$!%*?&_\\=\\-+]{8,}$");

  // generates a random 10 string long id for anything that needs it
  public static makeId(length: number = 10): string {
    let result: string = '';
    let characters: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i: number = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // convert object into hashable strings for comparison.
  public static createHash(data: any): string {
    // convert object into json
    var d = JSON.stringify(data);
    // variable declaration
    var hash = 0,
      chr = 0;
    // for each character length
    for (var i = 0, n = d.length; i < n; i++) {
      // get char index
      chr = d.charCodeAt(i);
      // multiply the has by 31 and append new char.
      hash = hash * 31 + chr;
      // force it to 32 bit char
      hash |= 0;
    }
    // return hex values of result.
    return hash.toString(16);
  }

  // QA: JB - Should this be relocated to the patient controller instead?
  public static patientClothesArray: string[] = [
    'no clothing',
    'underwear only',
    'pants only',
    'shirt only',
    'fully clothed',
  ];

  // QA: JB - should this be relocated to the patient controller instead?
  public static getPatientClothesIndex(clothesSelection: string): number {
    let index = 0;

    if (clothesSelection !== undefined) {
      index = toolsservice.patientClothesArray.indexOf(
        clothesSelection.toLowerCase()
      );
    }

    return index;
  }

  public static openSnackBar(
    snackBar: MatSnackBar,
    msg: string,
    goodBar: boolean,
    displayFor: number = 2000
  ): void {
    var cssStyle = goodBar
      ? 'mat-snack-bar-container-good'
      : 'mat-snack-bar-container-bad';
    snackBar.openFromComponent(SnackbarComponent, {
      data: { message: msg },
      duration: 2000,
      panelClass: [cssStyle],
    });
  }

  //obtains a list of character parameters based on the loaded character model string
  public obtainCharacterParameters(characterModelString: string): string[] {
    let caughtData = undefined;
    characterParams._models.forEach((model) => {
      for (let key in model) {
        if (key === characterModelString) {
          caughtData = model[key];
        }
      }
    });

    return caughtData;
  }

  /**
   * check if a string or data point is null or empty or filled with spaces
   * @param data the string that is being checked for breaking conditions
   */
  public static isNullUndefinedEmpty(data: any | string): boolean {
    if (data === undefined || data === null || data === '') {
      return true;
    } else if (this.isString(data) && !data.replace(/\s/g, '').length) {
      return true;
    }
    return false;
  }

  public static isString(x: any): x is string {
    return typeof x === "string";
  }

  private static StringIsNumber = value => isNaN(Number(value)) === false;

  public static ToArray( enumme ): string[] {
    return Object.keys(enumme)
    .filter(this.StringIsNumber)
    .map(key=>enumme[key]);
  }
}



//Enumerator for persim models
// QA: JB - should this be relocated to the patient controller instead?
export enum ModelType {
  Eth_Baby = 0,
  Wht_Baby = 1,
  Eth_N_Baby = 2,
  Wht_N_Baby = 3,
  Eth_Female_Arm = 4,
  Eth_Female_Arm_Leg = 5,
  Eth_Female_Body = 6,
  Eth_Female_Leg = 7,
  Eth_Male_Arm = 8,
  Eth_Male_Arm_Leg = 9,
  Eth_Male_Body = 10,
  Eth_Male_Leg = 11,
  Wht_Female_Arm = 12,
  Wht_Female_Arm_Leg = 13,
  Wht_Female_Body = 14,
  Wht_Female_Leg = 15,
  Wht_Male_Arm = 16,
  Wht_Male_Arm_Leg = 17,
  Wht_Male_Body = 18,
  Wht_Male_Leg = 19,
  G_Eth_Female_Arm = 20,
  G_Eth_Female_Arm_Leg = 21,
  G_Eth_Female_Body = 22,
  G_Eth_Female_Leg = 23,
  G_Eth_Male_Arm = 24,
  G_Eth_Male_Arm_Leg = 25,
  G_Eth_Male_Body = 26,
  G_Eth_Male_Leg = 27,
  G_Wht_Female_Arm = 28,
  G_Wht_Female_Arm_Leg = 29,
  G_Wht_Female_Body = 30,
  G_Wht_Female_Leg = 31,
  G_Wht_Male_Arm = 32,
  G_Wht_Male_Arm_Leg = 33,
  G_Wht_Male_Body = 34,
  G_Wht_Male_Leg = 35,
}

export enum ABGVBG_DrawType {
  ABG = 0,
  VBG = 1,
}

export enum RespiratoryWaveform {
  NORMAL_RESP = 0,
  HYPO = 1,
  HYPER = 2,
  CPR = 3,
  ROSC = 4,
  SUDDEN_LOSS = 5,
  DECREASING = 6,
  SEDATION = 7,
}

export enum CardiacRhythm {
  AFIB = 0,
  ASYSTOTLE = 1,
  AVB3 = 2,
  MOBITZ1 = 3,
  MOBITZ2 = 4,
  NORMAL = 5,
  SVT = 6,
  TORSADES = 7,
  VENTACHY_1 = 8, //(nonperfusing),
  VENTACHY_2 = 9, //(perfusing),
  VFIB = 10,
}

export enum Age {
  Baby = 0,
  Adult = 1,
  Geriatric = 2,
}
