<div class="header">
  <div style="background-color: whitesmoke">
    <div *ngIf="currentScenario; else LoadingBar">
      <div style="margin-left: auto; margin-right: auto; max-width: 1500px">
        <div style="padding-left: 10px; padding-top: 20px">
          <div class="header-navigation">
            <button
              mat-icon-button
              class="back-button"
              [routerLink]="['/dashboard']"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="back"
                style="color: steelblue"
                >arrow_back
              </mat-icon>
            </button>

            <div class="header-and-description">
              <div class="header-and-runtime">
                <h1 class="headerfont">{{ currentScenario.name }}</h1>
                <span class="header-buttons">
                  <button
                    mat-icon-button
                    [matTooltip]="
                      this.onDisplayChecklistItem(currentScenario.checklistLink)
                    "
                    matTooltipPosition="above"
                    [matMenuTriggerFor]="taskMenu"
                  >
                    <mat-icon class="header-icon">check_box</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="onFileUpload()"
                    matTooltip="Files"
                    matTooltipPosition="above"
                  >
                    <mat-icon>folder</mat-icon>
                  </button>
                  <!-- <button
                    mat-icon-button
                    [matTooltip]="currentScenario.tags.join(',')"
                    matTooltipPosition="above"
                    [matMenuTriggerFor]="tagMenu"
                  > -->
                  <button
                    mat-icon-button
                    matTooltip="Tags"
                    matTooltipPosition="above"
                    [matMenuTriggerFor]="tagMenu"
                  >
                    <mat-icon class="header-icon">local_offer</mat-icon>
                  </button>
                </span>
                <mat-menu #tagMenu="matMenu" (closed)="onContextMenuClosed()">
                  <app-advancesearchbox
                    title="Tag"
                    [(selectedItems)]="currentScenario.tags"
                    [canAcceptMultipleValues]="true"
                  ></app-advancesearchbox>
                </mat-menu>
                <mat-menu
                  #taskMenu="matMenu"
                  (click)="$event.stopPropagation()"
                  (closed)="onContextMenuClosed()"
                >
                  <app-advancesearchbox
                    title="Tasklist"
                    [(selectedItem)]="currentScenario.checklistLink"
                    [collectionItems]="taskList"
                    [canAcceptMultipleValues]="false"
                    (EditSelected)="onEditTaskList($event)"
                    (DeleteSelected)="onDeleteTaskList($event)"
                  ></app-advancesearchbox>
                </mat-menu>
                <div class="runtime" *ngIf="currentScenario.runtime > 0">
                  <p>
                    <mat-icon [inline]="true" class="icon"> schedule </mat-icon>
                    RUN TIME: {{ currentScenario.runtime }}m
                  </p>
                </div>
              </div>

              <div class="description">
                <p class="paragraphfont">
                  {{ currentScenario.simulationGoal }}
                </p>
              </div>
            </div>

            <div class="right-buttons">
              <button
                mat-raised-button
                *ngIf="ServerOnline"
                [ngClass]="{
                  'green-button': !this.scenarioControllerService.scenario
                    .isShared,
                  'red-button': this.scenarioControllerService.scenario.isShared
                }"
                (click)="onShareTeam()"
              >
                <p *ngIf="!this.scenarioControllerService.scenario.isShared">
                  Share With Team
                </p>
                <p *ngIf="this.scenarioControllerService.scenario.isShared">
                  Remove From Team Share
                </p>
              </button>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </div>

          <!--TODO: temporary button for showing tutorial dialog, remove later-->
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item style="color: #616161">
              <mat-icon style="color: #616161">tablet_mac</mat-icon>Export to
              Device
            </button> -->
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onEditTitleAndDescription()"
            >
              <mat-icon style="color: #616161">create</mat-icon>Edit Title &
              Description
            </button>
            <button 
              mat-menu-item 
              style="color: #616161"
              (click)="onDuplicateSimulation()">
              <mat-icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z"
                  />
                </svg>
              </mat-icon>
              Duplicate Scenario
            </button>
            <button 
              mat-menu-item 
              style="color: #616161"
              (click)="onFileUpload()">
              <!-- open the file dialog menu -->
              <mat-icon style="color: #616161">attach_file</mat-icon>Attach a
              File
            </button>
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onDeleteSimulation()"
            >
              <mat-icon style="color: #616161">delete</mat-icon>Delete
              Simulation
            </button>
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onOpenCredits()"
            >
              <mat-icon style="color: #616161">group</mat-icon>Credits
            </button>
          </mat-menu>
        </div>

        <div style="padding-left: 0px" *ngIf="currentScenario; else goback">
          <!-- PREVIEW WINDOW FOR WEBGL COMPONENT-->
          <!-- Needs to be in a iframe to avoid unity hook our keyboard events. -->
          <div #unityContainer>
            <p>Simulation Preview</p>
            <div>
              <app-simulation-preview 
                [width]="558"></app-simulation-preview>
            </div>
          </div>

          <mat-tab-group
            class="mat-tab-group-patients"
            id="headerTabs"
            animationDuration="0ms"
            [(selectedIndex)]="selectedTabIndex"
            (selectedIndexChange)="onPatientChanged($event)"
            style="width: auto"
          >
            <mat-tab
              label="{{ _patient.patientName }}"
              *ngFor="let _patient of currentScenario.patients; let i = index"
            >
              <app-scenarios-body
                [scenario]="scenarioControllerService.scenario"
                [patientModel]="_patient"
                [DisableDeleteButton]="DisableDeleteButton"
                (OnBodyNotifyEvent)="onPatientEventNotified($event, i)"
                [playerElement]="getUnityPlayer(i)"
                [Tags]="currentScenario.tags"
              >
              </app-scenarios-body>
            </mat-tab>
            <mat-tab disabled>
              <ng-template mat-tab-label>
                <button
                  mat-flat-button
                  [disabled]="currentScenario.patients.length > 3"
                  (click)="onAddPatient()"
                  class="tab-button"
                >
                  <mat-icon>add_box</mat-icon>
                  Add Patient
                </button>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <ng-template #goback>
          <p>Please go back and select the correct Simulation!</p>
        </ng-template>
      </div>
    </div>

    <ng-template #LoadingBar>
      <p>Loading Simulation model...</p>
      <mat-progress-bar mode="buffer"></mat-progress-bar>
    </ng-template>
  </div>
</div>
