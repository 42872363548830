import { Injectable, Input } from '@angular/core';
import { patient } from '../models/patient.model';
import { scenario } from '../models/scenario.model';
import {
  ModelType,
  toolsservice,
  ABGVBG_DrawType,
  Age,
} from '../editor/services/tools.service';
import patientNameData from '../data/names_US.json';
import { clinical } from '../models/statedata.model';

//BUG 2
// enum ModelType {
//   Eth_Baby = 0,
//   Eth_Female_Arm = 1,
//   Eth_Female_Arm_Leg = 2,
//   Eth_Female_Body = 3,
//   Eth_Female_Leg = 4,
//   Eth_Male_Arm = 5,
//   Eth_Male_Arm_Leg = 6,
//   Eth_Male_Body = 7,
//   Eth_Male_Leg = 8,
//   Wht_Baby = 9,
//   Wht_Female_Arm = 10,
//   Wht_Female_Arm_Leg = 11,
//   Wht_Female_Body = 12,
//   Wht_Female_Leg = 13,
//   Wht_Male_Arm = 14,
//   Wht_Male_Arm_Leg = 15,
//   Wht_Male_Body = 16,
//   Wht_Male_Leg = 17,
//   G_Eth_Female_Arm = 18,
//   G_Eth_Female_Arm_Leg = 19,
//   G_Eth_Female_Body = 20,
//   G_Eth_Female_Leg = 21,
//   G_Eth_Male_Arm = 22,
//   G_Eth_Male_Arm_Leg = 23,
//   G_Eth_Male_Body = 24,
//   G_Eth_Male_Leg = 25,
//   G_Wht_Female_Arm = 26,
//   G_Wht_Female_Arm_Leg = 27,
//   G_Wht_Female_Body = 28,
//   G_Wht_Female_Leg = 29,
//   G_Wht_Male_Arm = 30,
//   G_Wht_Male_Arm_Leg = 31,
//   G_Wht_Male_Body = 32,
//   G_Wht_Male_Leg = 33,
// }

@Injectable({
  providedIn: 'root',
})
export class PatientControllerService {
  @Input() patient: patient;

  constructor() {}

  public get gender(): string {
    if (this.patient.age.toLowerCase() === 'baby') return 'baby';
    else {
      return this.patient.genderEthnicity.split('_')[1].toLowerCase();
    }
  }

  /**
   * Function: get the string literal translation from modelType for age and skin
   */
  public get GenderAndSkin(): string {
    let sample: string = '';

    //for babies
    if (this.patient.age.toLowerCase() === 'baby') {
      sample = 'Baby, ';
      if (this.patient.genderEthnicity.toLowerCase() === 'wht') {
        sample += 'Caucasian';
      } else {
        sample += 'Person of Color';
      }
      return sample;
    }

    //CHECK IF OLD OR ADULT
    if (this.patient.age.toLowerCase() === 'geriatric') {
      sample = 'Geriatric, ';
    } else {
      sample = 'Adult, ';
    }

    if (this.patient.genderEthnicity.toLowerCase().split('_')[1] === 'male') {
      //IF THEY ARE MALE
      sample += 'Male, ';
    } else {
      //if they are female
      sample += 'Female, ';
    }

    if (this.patient.genderEthnicity.toLowerCase().split('_')[0] === 'wht') {
      sample += 'Caucasian';
    } else {
      sample += 'Person of Color';
    }
    return sample;
  }

  /**
   * Function: Get the string litearl translation from modeltype for amputation
   */

  public get amputationPart(): string {
    switch (this.patient.amputation.toLowerCase()) {
      case 'arm':
        return 'Arm Limb Missing';
      case 'arm_leg':
        return 'Arm & Leg Limb Missing';
      case 'body':
        return 'No Missing Limbs';
      case 'leg':
        return 'Leg Limb Missing';
      default:
        return undefined;
    }
  }

  /*************************************************************************************************
   * Function: Get FSG Draw Type
   * Purpose: obtains the fsg drawtype from the associated enum
   *
   * Parameters:
   *    I: drawtype:    string - string that represents the draw type
   *
   * Return:
   *    O: enumDrawType
   *************************************************************************************************/
  getABGVBG_DrawType(drawtype: string) {
    let enumDrawType = ABGVBG_DrawType[drawtype];
    return enumDrawType;
  }

  /*************************************************************************************************
   * Function: Set ABG/VBG Draw Type
   * Purpose: Set's the FSG draw type and based off that selection updates the default values
   *
   * Parameters:
   *    I: patient:    patient - patient that needs to be updated
   *************************************************************************************************/
  setABGVBG_DrawType_Data(patient: patient, drawType: number) {
    switch (drawType) {
      case 0:
        //ABG DEFAULT DATA
        this.updateABGVBGDrawtypeDataValues(patient, 7.4, 95, 40, 99);
        break;
      case 1:
        //VBG DEFAULT DATA
        this.updateABGVBGDrawtypeDataValues(patient, 7.36, 40, 44, 75);
        break;
      default:
        break;
    }
  }

  /*************************************************************************************************
   * Function: Update ABG/VBG Drawtype Data Values
   * Purpose: Helper function for setting FSG drawtype data
   *
   * Parameters:
   *    I: patient:    patient - patient that needs to be updated
   *    I: pH:         number  - number representing pH balance
   *    I: pa02:       number  - number representing pa02 patient value
   *    I: paCO2:      number  - number representing paCO2 patient value
   *    I: lab_saO2:   number  - number representing lab_sa02 patient value
   *************************************************************************************************/
  updateABGVBGDrawtypeDataValues(
    patient: patient,
    pH: number,
    pa02: number,
    paCO2: number,
    lab_saO2: number
  ) {
    patient.InitialClinicalData.pH = pH;
    patient.InitialClinicalData.pa02 = pa02;
    patient.InitialClinicalData.paCO2 = paCO2;
    patient.InitialClinicalData.lab_saO2 = lab_saO2;
  }

  /*************************************************************************************************
   * Function: Set Enum Value
   * Purpose: Set's the associated enum value for the persim model to be loaded
   *
   * Parameters:
   *    I: age: string - string representing the age of the model to be loaded
   *    I: patient: patient - patient that the changes are being made to
   *    I: genderEthnicty: string - string representing the gender and ethnicity fo the model
   *    I: amputation: string - represents the ampuation type, default 'body', aka no amputations
   *************************************************************************************************/
  setEnumValue(
    age: string,
    patient: patient,
    genderEthnicity: string,
    amputation: string = 'Body'
  ): void {
    let str: string = '';
    if (amputation === '') {
      str = genderEthnicity;
    } else {
      str = `${genderEthnicity}_${amputation}`;
    }

     

    //if old person append G_ to obtain proper number from ModelType
    if (Age[age] === 2) {
      str = `G_${str}`;
    } else if (Age[age] === 0) {
      //baby
      patient.clothingType = '0';
      str = str.split('_')[0];
      str += '_Baby';
    }

     
     
    patient.modelType = ModelType[str];
    //return ModelType[str];
  }

  /*************************************************************************************************
   * Function: Determine Starting Weight
   * Purpose: Set's the starting weight of the patient to the associted weight
   *
   * Parameters:
   *    I: genderEthnicity: string - string representing gender and ethnicity
   *    I: patient: patient - patient that the changes are being made to
   *************************************************************************************************/
  determineStartWeight(genderEthnicity: string, patient: patient) {
    if (genderEthnicity.toLowerCase() === 'eth') {
      //PERSON OF COLOR BABY
      patient.weight = 0;
      return;
    } else if (genderEthnicity.toLowerCase() === 'wht') {
      //CAUCASIAN BABY
      patient.weight = 0;
      return;
    }

    if (genderEthnicity.split('_')[1].toLowerCase() === 'male') {
      patient.weight = 80;
      return;
    } else if (genderEthnicity.split('_')[1].toLowerCase() === 'female') {
      patient.weight = 60;
      return;
    }
  }

  /*************************************************************************************************
   * Function: On Click Age
   * Purpose: When the user selects an age that information needs to immediately propagate up to
   *          the patient, the age will determine the enum value for the model to be loaded in persim
   *
   * Parameters:
   *   I: age - the string that represents the patient's age (Adult, Baby, Geriatric)
   *   I: patient - the patient that the changes are being applied to
   *************************************************************************************************/
  onClickAge(age: string, patient: patient) {
    //if the patients gender and ethnicity have not been set then do not update
    //if the patient is doing from baby to adult/geriatric or adult/geriatric to baby display warning

    patient.age = age;
    if (
      patient.genderEthnicity === undefined ||
      patient.genderEthnicity === null
    ) {
      return null;
    }

    //otherwise get a new enum value
    this.setEnumValue(
      patient.age,
      patient,
      patient.genderEthnicity,
      patient.amputation
    );
    return null;
  }

  /*************************************************************************************************
   * Function: On Clikc Gender Ethnicity
   * Purpose: When the user selects an gender and ethnicity that information needs to immediately
   *          propagate up to the patient, the gender and ethnicity will determine the enum value
   *          for the model to be loaded in persim
   *
   * Parameters:
   *    I: genderEthnicity: string - represents the patient's gender and ethnicity
   *    I: patient: patient - the patient that the changes are being applied to (may be unnecessary)
   *************************************************************************************************/
  //if the user selects the gender and ethnicity then that change needs to be reflected in the selected model string
  onClickGenderEthnicity(genderEthnicity: string, patient: patient) {
    //TODO: UPDATE to affect associated textures

    this.determineStartWeight(genderEthnicity, patient);
    this.setEnumValue(
      patient.age,
      patient,
      patient.genderEthnicity,
      patient.amputation
    );

    this.changePatientGender(genderEthnicity, patient);
  }

  //FUNCTION TO SWAP OUT MALE/FEMALE STRINGS FOR TEXTURES
  changePatientGender(genderEthnicity: string, patient: patient) {
    //there is no .contains equivalent in js. (will have to split accross delimiter)
    // if(genderEthnicity.toLowerCase() === "wht" || genderEthnicity.toLowerCase() === "eth"){
    //   //THE MODEL IS A BABY AND HAS NO GENDER, DOES NOT NEED TO BE USED
    //   return;
    // }
    if (
      patient.phases === undefined ||
      patient.phases[0] === undefined ||
      patient.phases[0].clinicalStates === undefined ||
      patient.phases[0].clinicalStates[0] === undefined ||
      patient.genderEthnicity === undefined
    ) {
      return;
    }
    for (let phase of patient.phases) {
      for (let clinicalState of phase.clinicalStates) {
        if (patient.genderEthnicity.split('_')[1] === 'Male') {
          (<clinical>clinicalState).Textures = this.manipulateTextures(
            (<clinical>clinicalState).Textures,
            'Female',
            patient.genderEthnicity.split('_')[1]
          );
        } else if (patient.genderEthnicity.split('_')[1] === 'Female') {
          (<clinical>clinicalState).Textures = this.manipulateTextures(
            (<clinical>clinicalState).Textures,
            'Male',
            patient.genderEthnicity.split('_')[1]
          );
        }

        if (patient.genderEthnicity.split('_')[0] === 'Eth') {
          (<clinical>clinicalState).Textures = this.manipulateTextures(
            (<clinical>clinicalState).Textures,
            'White',
            'Ethnic'
          );
        } else if (patient.genderEthnicity.split('_')[0] === 'Wht') {
          (<clinical>clinicalState).Textures = this.manipulateTextures(
            (<clinical>clinicalState).Textures,
            'Ethnic',
            'White'
          );
        }
      }
    }
    //if(genderEthnicity.toLowerCase().split("_")[1] === "")
    //this.manipulateTextures();
  }

  private testTexturesArray: string[] = [
    'Male_Texture_One',
    'Male_Texture_Two',
    'Male_Texture_Three',
  ];

  public manipulateTextures(
    stringArray: string[] = this.testTexturesArray,
    lookFor: string = 'Male',
    replaceWith: string = 'Female'
  ): string[] {
    for (let i = 0; i < stringArray.length; i++) {
      let stringCollection: string[] = stringArray[i].split('_');
      for (let j = 0; j < stringCollection.length; j++) {
        if (stringCollection[j] === lookFor) {
          //replace it
          stringCollection[j] = replaceWith;
        }
      }
      stringArray[i] = stringCollection.join('_');
    }

    return stringArray;
  }

  //

  /*************************************************************************************************
   * Function: On Click Amputation
   * Purpose: When the user selects an amputation type that information needs to immediately
   *          propagate up to the patient, the amputation type will determine the enum value
   *          for the model to be loaded in persim
   *
   * Parameters:
   *    I: amputation: string - represents the patient's amputation, if any.
   *    I: patient: patient - the patient that the changes are being applied to (may be unnecessary)
   *************************************************************************************************/
  onClickAmputation(patient: patient, amputation: string) {
    patient.amputation = amputation;
    this.setEnumValue(
      patient.age,
      patient,
      patient.genderEthnicity,
      patient.amputation
    );
  }

  /*************************************************************************************************
   * Function: On Headgear Changed
   * Purpose: When the user selects weather or not they want headgear to be applied this function
   *          sets the clothing state
   *
   * Parameters:
   *    I: patient: patient - the patient to be affected by this change
   *************************************************************************************************/
  onHeadgearChanged(patient: patient): void {
    patient.headgear = !patient.headgear;
    this.patient.isHeadgearChecked = !patient.headgear;
    this.setClothingType(
      patient,
      toolsservice.getPatientClothesIndex(patient.clothingState),
      patient.clothingOption,
      patient.accessory,
      patient.headgear
    );
  }

  /*************************************************************************************************
   * Function: On Clothing State Click
   * Purpose: when the user selects weather or not they want their persim model to have a certain
   *          set of clothing, then this function will set the clothing type accordingly
   *
   * Parameters:
   *    I: patient: patient - the patient to be affected by this change
   *    I: clothingState: string - represents the clothing state as a string
   *                               (no undewear, underweaer, fully clothed)
   *************************************************************************************************/
  onClothingStateClick(patient: patient, clothingState: string) {
    //dummy string array;
    patient.clothingState = clothingState;
    this.setClothingType(
      patient,
      toolsservice.getPatientClothesIndex(patient.clothingState),
      patient.clothingOption,
      patient.accessory,
      patient.headgear
    );
  }

  /*************************************************************************************************
   * Function: On Click Accessorie
   * Purpose: When the user selects an accessory from the accessorie list then that information
   *          must reflect accross the set clothing type
   *
   * Parameters:
   *    I: patient: patient - the patient to be affected by this change
   *    I: accessory: string - represents the selected accessory as a string
   *                               (sunglasses, goggles)
   *************************************************************************************************/
  onClickAccessorie(patient: patient, accessory: string) {
    patient.accessory = accessory;
    this.setClothingType(
      patient,
      toolsservice.getPatientClothesIndex(patient.clothingState),
      patient.clothingOption,
      patient.accessory,
      patient.headgear
    );
    return null;
  }

  /*************************************************************************************************
   * Function: On Clothing Option Click
   * Purpose: when the user selects a clothing option (civilian, army, marine, airforce), that
   *          change must reflect in the clothing type string controlled by the associated patient
   *
   * Parameters:
   *    I: patient: patient - the patient to be affected by this change
   *    I: accessory: string - represents the selected accessory as a string
   *                               (Civilian, Army, Marine, Airforce)
   *************************************************************************************************/
  onClothingOptionClick(patient: patient, clothingOption: string) {
    patient.clothingOption = clothingOption;
    if (patient.clothingState === undefined || patient.clothingState === null) {
      patient.clothingState = 'Underwear Only';
    }
    this.setClothingType(
      patient,
      toolsservice.getPatientClothesIndex(patient.clothingState),
      patient.clothingOption,
      patient.accessory,
      patient.headgear
    );
  }

  /*************************************************************************************************
   * Function: Set Clothing Type
   * Purpose: final stage in creating a string to represent the required clothing type. combines
   *          the 4 required items to generate the approriate string
   *
   *           example string production: "1-goggles-helmet-army"
   *
   * Parameters:
   *    I: patient: patient - the patient to be affected by this change
   *    I: clothingState: string - represents the enum value selected for the clothing state
   *    I: clothingOutfit: string - represents the types of clothes worn
   *    I: clothingAccessory: string - represents the selected accessory as a string
   *    I: clothingHat: boolean - represents weather or not the user has elected to have the patient
   *                              wear a hat
   *************************************************************************************************/
  setClothingType(
    patient: patient,
    clothingState: number = 0,
    clothingOutfit: string = '',
    clothingaccessory: string = '',
    clothingHat: boolean = false
  ): void {
    let clothingType: string = '';
    clothingType = `${clothingState}`;

    if (
      clothingHat !== null &&
      clothingHat !== undefined &&
      clothingHat !== false
    ) {
      clothingType += `-helmet`;
    }

    if (
      clothingaccessory !== null &&
      clothingaccessory !== undefined &&
      clothingaccessory !== 'none'
    ) {
      clothingType += `-${clothingaccessory}`;
    }

    if (patient.age === 'Baby') {
      patient.clothingType = '0';
      return;
    }

    if (
      clothingOutfit !== null &&
      clothingOutfit !== undefined &&
      clothingOutfit !== ''
    ) {
      clothingType += `-${clothingOutfit}`;
    }

    patient.clothingType = clothingType;
    return;
  }

  //#region PATIENT NAME AND GENDER RANDOMIZATION AND GENERATION

  /*************************************************************************
   * Function: Patient Name Generator
   * Purpose: controlling function to collect and manage stored names of
   *          automatically generated patient data
   *
   * Parameters:
   *    I: scenario:scenario    - the scenario that the patient will reside in
   *************************************************************************/
  public patientNameGenerator(scenario: scenario): void {
    let name: string;
    let gender: string; // TODO: for furture use to also pre-set gender
    let max: number = patientNameData['names'].length;

    do {
      let data: {} = patientNameData['names'][this.getRandomInt(max)];
      name = data['name'];
      gender = data['gender']; //TODO: discus with sohail about splitting gender and ethnicity.
      if (gender === 'unisex') {
        gender = this.getRandomGender();
      }
      gender = gender[0].toUpperCase() + gender.slice(1); //UPPERCASE THE FIRST CHAR
    } while (this.uniqueName(name, scenario));

    this.patient.patientName = name;
    this.patient.genderEthnicity = `${this.getRandomEthnicity()}_${gender}`;
    this.setEnumValue(
      this.patient.age,
      this.patient,
      this.patient.genderEthnicity,
      this.patient.amputation
    );
    this.determineStartWeight(this.patient.genderEthnicity, this.patient); // make sure the weight changes if female generated
  }

  /*************************************************************************
   * Function: Unique Name
   * Purpose: ensures that the name has not already been used in the data
   *          set of patients
   *
   * Parameters:
   *    I: name:string  - the name that was generated
   *    I: scenario:scenario - the scenario that contains patients to be checked
   *
   * Returns:
   *    O: boolean - true - if the name already exists,
   *                 false - if the name is good to use
   ***********************************************************************/
  public uniqueName(name: string, scenario: scenario): boolean {
    if (scenario === undefined) {
      return false;
    }
    if (scenario.patients === undefined) {
      return false;
    }
    for (let _patient of scenario.patients) {
      if (_patient.patientName === name) {
        return true;
      }
    }
    return false;
  }

  //Function for returning a random integer between 0 and max
  public getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
  }

  //Function for returning a random gender of either male or female
  public getRandomGender(): string {
    if (Math.floor(Math.random() * Math.floor(2))) {
      this.patient.weight = 80;
      return 'Male';
    } else {
      this.patient.weight = 60;
      return 'Female';
    }
  }

  //Function for returning a random ethnicity of either white or ethnic
  public getRandomEthnicity(): string {
    //JAVASCRIPT numbers are truthy falsy, with 0 being false, and 1 being true
    if (Math.floor(Math.random() * Math.floor(2))) {
      this.GenderAndSkin;
      return 'Wht';
    } else {
      return 'Eth';
    }
  }
  //#endregion
}
