import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhaseCardCComponent } from './phase-card-c/phase-card-c.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [PhaseCardCComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    PhaseCardCComponent
  ]
})
export class PhaseCardModule { }
