<div class="dialog-container-header">
  <h2 mat-dialog-title>Credits</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content style="max-height: 513px">
    <p>Here you will find everyone that has ever helped to add to this scenario, the original author is listed first, with
    every single subsequent author listed afterward.</p>
    <br>
    <ul>
        <li *ngFor="let author of this.data.scenario.authors; index as i">
            {{author.fName}} {{author.lName}} <em *ngIf="i === 0" style="opacity: 50%;">(Original Author)</em>
        </li>
    </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
</mat-dialog-actions>
