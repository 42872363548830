import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ObtainFilename' })
export class ObtainFilename implements PipeTransform {
  transform(value: string): string {
    const regex = /[\/|\\](.+[\/|\\])*(.+)$/;
    const match = value.match(regex);
    const newStr = match[2];
    return newStr;
  }
}

@Pipe({ name: 'CleanUpFileName' })
export class CleanUpFileName implements PipeTransform {
  transform(value: string): string {
    const regex = /[\/|\\](.+[\/|\\])*(.+)\.[^.]+$/;
    const match = value.match(regex);
    if (!match) {
      return 'none';
    }
    const newStr = match[2];
    return newStr;
  }
}
