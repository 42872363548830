import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogSoundsComponent } from '../dialogs/dialog-sounds/dialog-sounds.component';
import { DialogVitalsComponent } from '../dialogs/dialog-vitals/dialog-vitals.component';
import { DialogTexturesComponent } from '../dialogs/dialog-textures/dialog-textures.component';
import { DialogSymptomEditComponent } from '../dialogs/dialog-symptom-edit/dialog-symptom-edit.component';
import { DialogLabValuesComponent } from '../dialogs/dialog-lab-values/dialog-lab-values.component';
import { DialogAutomaticTransitionComponent } from '../dialogs/dialog-automatic-transition/dialog-automatic-transition.component';
import { DialogDeleteSymptomComponent } from '../dialogs/dialog-symptom/dialog-delete-symptom/dialog-delete-symptom.component';
import {   MatDialog } from '@angular/material/dialog';
import { clinical } from '../../models/statedata.model';
import { toolsservice } from '../services/tools.service';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { patient } from 'src/app/models/patient.model';
import { PatientControllerService } from 'src/app/controllers/patient-controller.service';
import { PreviewAnimationService } from '../services/preview-animation.service';
import { AnimationTransitionService } from '../services/animation-transition.service';

import { DialogEditAuscultationComponent } from '../dialogs/dialog-edit-auscultation/dialog-edit-auscultation.component';


@Component({
  selector: 'app-symptoms',
  templateUrl: './symptoms.component.html',
  styleUrls: ['./symptoms.component.scss'],
})
export class SymptomsComponent implements OnInit {
  @Input() clinical: clinical; // original clinical item from the patient defaults
  @Input() symptomIndex: number; // find a way to update clinical.position
  @Input() patient: patient;
  @Input() currentPhaseId: string;
  public prettyTransitionName = '';

  @Output() symptomsEvent = new EventEmitter<{
    action: string;
    clinical: any;
  }>();

  patientController: PatientControllerService = new PatientControllerService();

  /**
   * Check to see if there is a procedure data specific to this clinical state
   * TODO: determine the scale of this check required
   */
  get hasProcedure(): boolean {
    return this.isNullUndefinedEmpty(this.clinical.Auscultations);
  }

  /**
   * Check and see if this symptoms have any sounds assign
   */
  get hasAudio(): boolean {
    return (
      !this.isNullUndefinedEmpty(this.clinical.VoiceSound) ||
      !this.isNullUndefinedEmpty(this.clinical.LungSound) ||
      !this.isNullUndefinedEmpty(this.clinical.HeartSound)
    );
  }

  /**
   * Check and see if this symptoms have any texture layers
   */
  get hasLayers(): boolean {
    return (
      this.clinical.Textures.length > 0 || this.clinical.EyesOverlays.length > 0
    );
  }

  /**
   * Check and see if this symptoms have any transitional state
   */
  get hasTransition(): boolean {
    return this.clinical.transitionState !== '';
  }

  // public isPlaying: boolean = false;
  public get isPlaying(): boolean {
    return this.previewAnimationService.IsMatchingSymptoms(this.clinical);
  }

  public get Gender(): string {
    if (this.patient.genderEthnicity === 'Baby') return 'Baby';
    return this.patient.genderEthnicity.indexOf('Female') > -1
      ? 'Female'
      : 'Male';
  }

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    // private unityService: UnityService,
    private previewAnimationService: PreviewAnimationService,
    public animationTransitionService: AnimationTransitionService
  ) {}

  // do not know why but I am having the worst issue dealing with this?
  isNullUndefinedEmpty(val: any) {
    return val === undefined || val === null || val === '';
  }

  ngOnInit(): void {
    this.patientController.patient = this.patient;
    this.animationTransitionService.getSymptoms(
      this.patientController.patient,
      this.clinical.statedataId
    );
    // this.prettyTransitionName = this.clinical.transitionState;
    this.prettyTransitionName = this.animationTransitionService.transitionPrettyList[
      this.animationTransitionService.transitionData.indexOf(
        this.clinical.transitionState
      )
    ];
  }

  //#region Vital

  onVitalChanged(data) {
    // we're going to change this so that we can either disable or enable this.
    if (data === undefined || data === null) {
      return;
    }
    // data should come out as the following type
    // { action: string,data : clinical }
    switch (data.action) {
      case 'Add':
        break;
      case 'Update':
        this.onShowVitalSigns('Update');
        break;
      case 'Delete':
        this.onVitalDelete(data.data);
        break;
      default:
    }
    // find a way to bring back original vital information from the patient data
    this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
  }

  /**
   * Called when user selects to change the procedure data after it has been added to a clinical state
   * @param procedureActionData the event data string of 'Add' 'Update' or 'Delete' captured from the DOM
   */
  onProcedureChanged(procedureActionData): void {
    if (!procedureActionData) return;

    switch (procedureActionData.action) {
      case 'Add':
        break;
      case 'Update':
        this.onShowProcedures('Update');
        break;
      case 'Delete':
        this.onDeleteProcedure();
        break;
      default:
        break;
    }

    this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
  }

  /**
   * opens up the procedures dialog window for editing of this symptoms specific clinical state related to procedure data
   * @param action - optional parameter to determine update or add functionality, if none provided defaults to add.
   */
  onShowProcedures(data: string = 'Add') {
    let clinicalDataCopy: clinical = JSON.parse(JSON.stringify(this.clinical)); //deep copy of json data to avoid any pass by reference items in the JSON model

    const dialogRef = this.dialog.open(DialogEditAuscultationComponent, {
      width: '1000px',
      data: {
        clinical: clinicalDataCopy,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.saveProcedureDataToSymptomClinicalState(result);
    });
  }

  /**
   * saves the procedure data to the associated symptom's clinical state, retains the original clinical state ID
   * @param result new clinical state data
   */
  private saveProcedureDataToSymptomClinicalState(result: clinical) {

    result.statedataId = this.clinical.statedataId;
    this.clinical = result;

    toolsservice.openSnackBar(
      this.snackBar,
      'Procedure Data updated for patient',
      true
    );

    this.symptomsEvent.emit({
      action: 'Update',
      clinical: this.clinical,
    });
  }

  onDeleteProcedure() {
    this.clinical.Auscultations = this.patient.InitialClinicalData.Auscultations;
  }

  onShowVitalSigns(action: string) {
    // create a copy of the starting data for the patient
    const clinicalVital = Object.assign({}, this.clinical);
    console.log(clinicalVital);

    const dialogRef = this.dialog.open(DialogVitalsComponent, {
      // height: '700px',
      width: '850px',
      data: {
        name: `${action} Vitals`,
        clinical: clinicalVital,
        gender: this.patientController.gender,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '' && result !== undefined && result !== null) {
        // for some reason whenever we copy and paste the object, the Id gets changed. we'll copy the Id and paste it after we're done.
        const id = this.clinical.statedataId;
        // apply new values by user
        this.clinical = result;
        // ensure to display icon
        this.clinical.containsVital = true;
        this.clinical.statedataId = id;
        // show notification
        toolsservice.openSnackBar(this.snackBar, `Vitals ${action}`, true);
        // Save Master JSON file
        this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
      } else {
        // what was this suppose to do?
      }
      // TODO: figure out what to do after the dialog box closes
    });
  }

  onVitalDelete(symptoms: clinical) {
    // find a way to notify or somehow revert vital data back to default?
    this.clinical.containsVital = false;
  }

  //#endregion

  //#region Labs
  /**
   * Event handler for lab changes
   * @param data action, clincal
   */
  onLabChanged(data) {
    // expand this to see what other condition this can provide?
    if (data === undefined || data === null) {
      return;
    }
    // expeted data to come in as this format
    // { action : string, data : clinical }
    switch (data.action) {
      case 'Add':
        break;
      case 'Update':
        this.onShowLabValues('Update');
        break;
      case 'Delete':
        this.onDeleteLabValues();
        break;
      default:
    }

    // find a way to bring back original lab information from the patient data
    this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
  }

  /**
   * set's clinical states containslab value to false
   */
  onDeleteLabValues() {
    this.clinical.containsLab = false;
  }

  onShowLabValues(action: string = 'Add') {
    //TODO: below is a shallow copy of json object, deep copy should be used here.
    const clinicalCopyLab = Object.assign({}, this.clinical);
    const dialogRef = this.dialog.open(DialogLabValuesComponent, {
      width: '910px',
      data: {
        name: `${action} Lab Values`,
        clinical: clinicalCopyLab,
        gender: this.patientController.gender,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '' && result !== undefined) {
        // for some reason whenever we copy and paste the object, the Id gets changed. we'll copy the Id and paste it after we're done.
        const id = this.clinical.statedataId;
        // assign changes to the clinical data.
        this.clinical = result;
        // update clinical data to contains lab value
        this.clinical.containsLab = true;
        this.clinical.statedataId = id;
        // display pop up to notify user
        toolsservice.openSnackBar(this.snackBar, `Labs Values ${action}`, true);
        // Save Master JSON files with new changes
        this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
      }
    });
  }

  //#endregion

  //#region Sounds

  onSoundChanged(data) {
    if (data === undefined || data === null) {
      return;
    }
    // data should be in the following format
    // { action: string, data : clinical }

    switch (data.action) {
      case 'Add':
        break;
      case 'Update':
        this.onShowSound('Update');
        break;
      case 'Delete':
        this.onDeleteSound();
        break;
      default:
    }
    this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
  }

  onShowSound(action: string) {
    let genderAndAge = '';
    if (!this.patient || !this.patient.age) {
      return;
    }
    if (this.patient.age === 'Baby') {
      genderAndAge = 'Baby';
    } else {
      if (this.patient.genderEthnicity.indexOf('Male') > 0) {
        genderAndAge = `${this.patient.age}_Male`;
      } else {
        genderAndAge = `${this.patient.age}_Female`;
      }
    }

    const dialogRef = this.dialog.open(DialogSoundsComponent, {
      width: '500px',
      data: {
        name: `${action} Sound`,
        genderAndAge,
        initialVoice: this.clinical.VoiceSound,
        initialHeart: this.clinical.HeartSound,
        initialLung: this.clinical.LungSound,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '' && result !== undefined) {
        // result should have the following information { voice , lungAuscultation, heartAuscultation }
        this.clinical.LungSound = result.lungAuscultation;
        this.clinical.HeartSound = result.heartAuscultation;
        this.clinical.VoiceSound = result.voice;

        toolsservice.openSnackBar(this.snackBar, `Sounds ${action}`, true);
        // Save Master JSON file.
        this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
      }
    });
  }

  onDeleteSound() {
    this.clinical.HeartSound = '';
    this.clinical.LungSound = '';
    this.clinical.VoiceSound = '';
  }

  //#endregion

  //#region Texture

  onTextureChanged(data) {
    // at this point here we'll just update the symptoms?
    if (data === undefined || data === null) {
      return;
    }
    // data should be in this format { action: string, data : clinical }
    switch (data.action) {
      case 'Add':
        break;
      case 'Update':
        this.onShowInjuryTexture('Update');
        break;
      case 'Delete':
        this.onDeleteTexture();
        break;
      default:
    }
    this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
  }

  onShowInjuryTexture(action: string) {
    const clinicalTexture: clinical = Object.assign({}, this.clinical);
    const dialogRef = this.dialog.open(DialogTexturesComponent, {
      // height: '719px',
      width: '910px',
      data: {
        name: `${action} Injury Texture`,
        patient: this.patient,
        clinical: clinicalTexture,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '' && result !== undefined) {
        this.clinical = result;
        // I wonder if this will work?
        if (this.previewAnimationService.IsMatchingSymptoms(this.clinical)) {
          this.previewAnimationService.onPlayAnimation(this.clinical);
        }
        toolsservice.openSnackBar(
          this.snackBar,
          `Injury Textures ${action}`,
          true
        );
        // Save Master JSON file.
        this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
      }
    });
  }

  /**
   * Remove texture data
   */
  onDeleteTexture() {
    this.clinical.Textures = [];
    this.clinical.EyesOverlays = [];
    if (this.previewAnimationService.IsMatchingSymptoms(this.clinical)) {
      this.previewAnimationService.onPlayAnimation(this.clinical);
    }
  }

  //#endregion

  //#region Automatic Transition

  onTransitionChanged(data) {
    if (data === undefined || data === null) {
      return;
    }
    // should be in the following data format :
    // { action : string, data : clinical }
    switch (data.action) {
      case 'Add':
        break;
      case 'Update':
        this.onShowAutomaticTransition('Edit Automatic Transition');
        break;
      case 'Delete':
        this.onDeleteTransition();
        break;
      default:
    }

    this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
  }

  onShowAutomaticTransition(action: string = 'Add') {
    // TODO: create add automatic transition logic

    const dialogRef = this.dialog.open(DialogAutomaticTransitionComponent, {
      // height: '400px',
      width: '440px',
      data: {
        name: `${action} Automatic Transition`,
        state: this.clinical.transitionState,
        time: this.clinical.transitionTime,
        patient: this.patient,
        currentPhaseId: this.currentPhaseId,
        currentStateId: this.clinical.statedataId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '' && result !== undefined) {
        // assuming the data provided us is presented as clinical
        this.prettyTransitionName = result.prettyState;
        this.clinical.transitionState = result.state;
        this.clinical.transitionTime = result.time;

        toolsservice.openSnackBar(
          this.snackBar,
          `Auto Transition ${action}`,
          true
        );
        this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
      }
    });
  }

  showConsoleLog() {
    console.log(this.clinical);
  }

  /**
   * Remove transition data
   */
  onDeleteTransition() {
    this.clinical.transitionState = '';
    this.clinical.transitionTime = 0;
  }

  //#endregion

  /**
   * Send preview data to unity (Needs to follow up on this?)
   */
  onClickPreview() {
    if (this.previewAnimationService.IsMatchingSymptoms(this.clinical)) {
      this.previewAnimationService.onStopAnimation();
    } else {
      this.previewAnimationService.onPlayAnimation(this.clinical);
    }
  }

  /**
   * Delete this symptom card - Notify Phase.component.ts to remove this symptoms from current phase item.
   */
  onDeleteItem() {
    // TODO: remove this item from the overall list
    const dialogRef = this.dialog.open(DialogDeleteSymptomComponent, {
      // height: '320px',
      width: '447px',
      data: { name: this.clinical.name, description: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        // save the json files
        if (this.previewAnimationService.IsMatchingSymptoms(this.clinical)) {
          this.previewAnimationService.onStopAnimation();
        }
        this.symptomsEvent.emit({ action: 'Delete', clinical: this.clinical });
      }
    });
  }

  /**
   * Edit this symptoms information
   */
  onEditTitleAndDesc() {
    // TODO: create edit title and desc logic

    const dialogRef = this.dialog.open(DialogSymptomEditComponent, {
      data: {
        title: 'Add Custom Title & Description',
        name: this.clinical.name,
        description: this.clinical.description,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined || result === null || result === '') {
        return;
      }

      if (
        this.clinical.originalName === null ||
        this.clinical.originalName === undefined
      ) {
        this.clinical.originalName = this.clinical.name;
        this.clinical.originalDescript = this.clinical.description;
      }

      if (result.action === 'Revert') {
        this.clinical.name = this.clinical.originalName;
        this.clinical.description = this.clinical.originalDescript;

        this.clinical.originalName = null;
        this.clinical.originalDescript = null;

        toolsservice.openSnackBar(
          this.snackBar,
          'Title and Description Reverted',
          true
        );
        this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
      } else {
        this.clinical.name = result.name;
        this.clinical.description = result.description;

        toolsservice.openSnackBar(
          this.snackBar,
          'Title and Description Updated',
          true
        );
        this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
      }
    });
  }

  // Not sure what thisone is?
  onBundleAndPlay() {
    // TODO: create on bundle and play logic

    // so another dialog is needed for this?
    toolsservice.openSnackBar(this.snackBar, 'Bundle State Added', true);
    this.symptomsEvent.emit({ action: 'Update', clinical: this.clinical });
  }
}
