<!-- <div class="container" [ngStyle]="{backgroundColor: 'orange'}"> -->
<div class="container">
    <span class="symptomIndex">{{symptomIndex}}</span>
    <mat-icon style="margin-left: 16px; margin-right: 16px; margin-top: auto; margin-bottom: auto;">drag_handle
    </mat-icon>
    <div>
        {{instructornote.name}}
        <br>
        {{instructornote.description}}
    </div>
    <div>
        <div class="more">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onEditItem()" style="color: #616161;">
                    <mat-icon style="color: #616161;">create</mat-icon>Edit
                </button>
                <button mat-menu-item (click)="onDeleteItem()" style="color: #616161;">
                    <mat-icon style="color: #616161;">delete</mat-icon>Delete
                </button>
            </mat-menu>
        </div>
    </div>
</div>