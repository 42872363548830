import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import appPackage from 'package.json';
import jwt_decode from 'jwt-decode';
import { Permissions } from '../services/permissions.service';
import { ServerService } from '../services/Server.service';
import { User } from '../models/user.model';
import { LoginToken } from '../models/logintoken.model';
// import bcrypt from "bcryptjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    if (this.cookieService.check('token')) {
      this.setSession(JSON.parse(this.cookieService.get('token')));
    }
  }

  /**
   * Try and see if the credentials works, if it does then create a new session and return confirmation result.
   */
  login(username: string, password: string, cb: any) {
    // must use secure protocol to send plain text over the network
    // how do we remove the freakin ports????

    const url = (appPackage.backendServerPath + '/auth/login');
    return this.http.post(url, { username, password })
      .pipe(
        tap(
          res => this.setSession(res),
          err => cb(err)
        ),
        //  Prevent receiver of this observable from accidentally trigger multiple POST request due to multiple subscriptions.
        shareReplay(1)
      );
  }
  async startImpersonation(impersonationInfo: string, headerToken: string) {

    const url = appPackage.backendServerPath + '/impersonate/start/' + impersonationInfo;
    console.log(url);

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Accept': 'text/plain',
      'Authorization': "Bearer " + headerToken,
      'Access-Control-Allow-Origin': url
    });

    return this.http.get(url, { headers: httpHeaders, responseType: 'text' });
  }
  /**
   * Send reset email
   * @param email The email of the user who forgotten the password
   */
  sendResetEmail(email: string) {
    const data = { email };
    const url = appPackage.backendServerPath + '/auth/forgotpassword';
    return this.http.post(url, data);
  }

  /**
   * Set the current session based on the JWT we received
   */
  private setSession(authResult: any): void {
    // result is in form of jwt. need to decode this for reference use.
    const token = authResult.access_token;
    const data: any = jwt_decode(token);

    const expiresAt = Date.now() + data.exp;
    localStorage.setItem('id_token', token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    // sessionStorage.setItem('sub', data.sub);
    // sessionStorage.setItem('fName', data.fName);
    // sessionStorage.setItem('lName', data.lName);
    // sessionStorage.setItem('institutionId', data.institutionId);
    // sessionStorage.setItem('roleId', data.roleId);
    // sessionStorage.setItem('adminPermission', data.adminPermission);
    // sessionStorage.setItem('userPermission', data.userPermission);
    // check and see if the user account can show tutorial information.
    if (data.showTutorial)
      localStorage.setItem('showTutorial', data.showTutorial);
    else
      localStorage.setItem('showTutorial', 'true');

    //this.cookieService.set('token', JSON.stringify(authResult));
  }

 public isValidLoginToken(): boolean {
  const tokencall = localStorage.getItem('id_token');
  const token:any = jwt_decode(tokencall);
    const result:boolean= (
      'sub' in token &&
      'fName' in token &&
      'lName' in token &&
      'institutionId' in token &&
      'roleId' in token &&
      'isActive' in token &&
      'adminPermission' in token &&
      'userPermission' in token &&
      'canImpersonate' in token &&
      'isUserAdmin' in token &&
      'exp' in token

    );
    return result;
  }
  // if the data was forged from a third party, backend service will return an unauthorized error message for having forgery token.
  /**
   * return the decrypted token data to use and verify valid data integrity without user tempering.
   */
  private get getTokenData(): any {
    if(!this.isValidLoginToken())
    {
      return this.logout();
    }
    const token = localStorage.getItem('id_token');
    const tokenResult = jwt_decode(token);
    return tokenResult;
  }

  /**
   * Clear local storage of metadata from JWT
   */
  logout() {
    localStorage.clear();
  }

  /**
   * Check to see if the user is logged in
   */
  public isLoggedIn() {
    return this.hasExpired() || this.getToken() !== null;
  }

  /**
   * Check to see if the user is logged out
   */
  public isLoggedOut() {
    return !this.isLoggedIn();
  }

  /**
   * Check and see if the token has expired
   */
  hasExpired() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return Date.now() - expiresAt > 0;
  }

  /**
   * return the token received from the server in JWT format
   */
  public getToken(): string {
    try{
    return localStorage.getItem('id_token');
    }
    catch
    {
      return "";
    }
  }
  public decodeToken(userIdToken: string): any {
    return jwt_decode(userIdToken);
  }

  /**
   * return the token received from the server in JWT format
   */
  public getUserPermissions(): Permissions {
    const data = this.getTokenData;
    const permission = parseInt(data.userPermission);
    // const permission = parseInt(sessionStorage.getItem('userPermission'));
    return <Permissions>permission;
  }

  /**
   * return the admin permission for the logged in user
   */
  public getAdminPermission(): Permissions {
    const data = this.getTokenData;
    const permission = parseInt(data.adminPermission);
    // const permission = parseInt(sessionStorage.getItem('adminPermission'));
    return <Permissions>permission;
  }

  /**
   * Check and see if we are still authenticated
   */
  public isAuthenticated(): boolean {
    return !this.hasExpired();
  }

  /**
   * return USER
   */
  // public getUser(): string {
  //   return sessionStorage.getItem('');
  // }

  /**
   * return user ID
   */
  public getUserID(): string {
    try
    {
    const data = this.getTokenData;
    return data.sub;
    }
    catch
    {
      return " ";
    }
  }

  public getUserDetails(): User {
    const userDetails = this.getTokenData;
    return userDetails;
  }

  /**
   * return the first name of the user logged in
   */
  public getFirstName(): string {
    const data = this.getTokenData;
    return data.fName;
  }

  /**
   * return the last name of the user logged in
   */
  public getLastName(): string {
    const data = this.getTokenData;
    return data.lName;
  }

  /**
   * Return the full name from this login token
   */
  public getFullName(): string {
    if (this.getFirstName() === null) {
      return null;
    }
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  /**
   * return the role ID
   */
  public getRoleId(): string {
    const data = this.getTokenData;
    return data.roleId;
  }

  /**
   * return the user's institution Id
   */
  public getInstitutionId(): string {
    const data = this.getTokenData;
    return data.institutionId;
  }

  /**
   * Return whether we need to show the tutorial or not.
   */
  public getShowTutorial(): boolean {
    var tut: any = sessionStorage.getItem('showTutorial');
    return tut != 'undefined' ? tut as boolean : true;
  }

  /**
   * Update the showTutorial function
   */
  public setShowTutorial(canShow: boolean): void {
    sessionStorage.setItem('showTutorial', `${canShow}`);
  }
}
