<div class="dialog-container-header">
    <div class="header content">
        <h2 mat-dialog-title class="header-margin">
            <b>Upload Custom Audio</b>
        </h2>
    </div>
    <button id="btn_close" style="height: 40px; width: 40px;" mat-icon-button (click)="onDialogClose()">
        <mat-icon style="height: 36px; width: 36px; font-size: 36px; transform: translate(0px,6px);">close</mat-icon>
    </button>
</div>
<div class="spinnerHolder" *ngIf="isLoading">
    <mat-spinner [diameter]="50" [strokeWidth]="1"></mat-spinner>
</div>
<mat-dialog-content *ngIf="!isLoading">
    <div class="header-flex-box">
        <div class="playback">
            <audio #audioSrc></audio>
            <!-- playback scrubber with time stamp -->
            <mat-slider style="width: 100%" [(value)]="audioSrc.currentTime" [max]="audioSrc.duration"
                [step]="audioSrc.duration / 20" *ngIf="audioSrc.src" async>
            </mat-slider>
            <br />
            <div *ngIf="selectedAudioFile">
                <p>
                    {{selectedAudioFile}}
                </p>
            </div>
        </div>
    </div>
    <div id="audioPlayerContainer"></div>
    <div class="header-flex-box">

        <table *ngIf="(audioFiles.length>0)">
            <thead>
                <tr>
                    <th>Audio Type</th>
                    <th>File Name</th>
                    <th>Preview</th>
                    <th>Actions</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let audio of audioFiles">
                    <td>{{ audio.fileType }}</td>
                    <td>{{ audio.fileName }}</td>
                    <td>
                        <div class="button-spinner-container">
                            <mat-spinner *ngIf="audioLoading" [diameter]="25" [strokeWidth]="1"></mat-spinner>

                            <button *ngIf="!audioLoading" (click)="playAudioFile(audio)">
                                <mat-icon>play_arrow</mat-icon>
                            </button>
                        </div>
                    </td>
                    <td>



                        <button mat-button (click)="deleteAudioFile(audio)">
                            <mat-icon class="icon-size">delete</mat-icon> Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="header-flex-box">
        <div class="text-with-top-padding">
            <b> Audio File Type:</b>
        </div>
        <mat-form-field style="width: 200px">
            <mat-select [(value)]="selectedFileType">
                <mat-option *ngFor="let fileType of fileTypes" [value]="fileType">{{ fileType }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>


    <div class="progress">
        <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">
        </mat-progress-bar>
        <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>
    </div>
    <div class="header-flex-box">
        <div class="text-with-top-padding"> <b>Select Audio File:</b></div> <input type="file" class="file-input"
            accept=".mp3,.ogg" (change)="onFileSelected($event)" #fileupload>
        <div class="file-upload">
            {{fileName || "No file uploaded yet."}}
            <button mat-mini-fab color="primary" class="upload-btn" (click)="fileupload.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
        </div>
    </div>
    <div class="header-flex-box">
        <button mat-button class="btn-green" (click)="onUploadClicked()">
            <mat-icon class="icon-size">edit</mat-icon>Upload File</button>
    </div>
</mat-dialog-content>
<style>
    .custom-spinner {
        width: 24px;
        /* Set your desired width here */
        height: 24px;
        /* Set your desired height here */
    }

    /* Optionally, center the spinner and button within the container */
    .button-spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>