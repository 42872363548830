<div class="dialog-container">
    <h2 mat-dialog-title style="font-family: OpenSansLight;"> Team Name </h2>
    <hr>
    <mat-dialog-content>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Name</mat-label>
            <input autocomplete="off" matInput placeholder="USAF CCATT" [(ngModel)]="data.teamName" required>
            <mat-error *ngIf="data.teamName === ''">team name required</mat-error>
        </mat-form-field>
        <br>
        <!-- <section>
            <mat-checkbox [(ngModel)]="checked" style="font-size: medium;">I understand this patient will be deleted forever</mat-checkbox>
        </section> -->
    </mat-dialog-content>
    <br>
    <mat-dialog-actions>
        <div class="buttons">
            <span>
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button mat-dialog-close
                    [mat-dialog-close]="data.teamName" 
                    style="background-color: #1a844f; color: white;" 
                    [ngStyle]="{'background-color':data.teamName === '' ? 'grey' : '#1a844f'}" 
                    [disabled]="data.teamName === ''">Save</button>
            </span>
        </div>
    </mat-dialog-actions>
</div>