import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Symptom } from '../../models/draggable.model';
import { toolsservice } from '../services/tools.service';
import * as data from '../../data/clinical-states.json';
import { patient } from 'src/app/models/patient.model';
import { Input } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

export enum SymptomTypes {
  CHEST_PAIN = 'Chest Pain',
  CVA_SEIZURE = 'CVA Seizure',
  RESPIRATORY_DISTRESS = 'Respiratory Distress',
  TRAUMA = 'Trauma',
  TRAUMA_ABDOMINAL_PAIN = 'Trauma Abdominal Pain',
}

@Component({
  selector: 'app-symptoms-list',
  templateUrl: './symptoms-list.component.html',
  styleUrls: ['./symptoms-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SymptomsListComponent implements OnInit {
  public myControl: UntypedFormControl = new UntypedFormControl('');

  @Output() SymptomsModified: EventEmitter<void> = new EventEmitter();

  //TODO: probably would make more sense to use an enum and fill in.
  options: string[] = [
    'Chest Pain',
    'CVA Seizure',
    'Respiratory Distress',
    'Trauma',
    'Trauma Abdominal Pain',
  ];
  optionsControl = new UntypedFormControl();
  selectedOption: string = SymptomTypes.TRAUMA;
  filteredOptions: Observable<string[]>;
  isPrimaryState: boolean = true;

  private _patient: patient;

  @Input() set patient(pat: patient) {
    this._patient = pat;
    this.generateOptions();
     
    this.cd.detectChanges();
  }

  public symptoms: Symptom[] = [];

  /**
   * Switch the toggle view of the buttons
   */
  onSearchButtonPressed() {
    this.isPrimaryState = !this.isPrimaryState;
    //TODO: update symptoms list with ALL available symptoms - AGE SPECIFIC

    this.generateAllSymptoms(this._patient.age);
  }

  constructor(private cd: ChangeDetectorRef) {}

  public get getPhaseIDs(): string[] {
    var _list: string[] = [];
    toolsservice.currentScenario.patients.forEach((p) =>
      p.phases.forEach((x) => _list.push(x.phaseId))
    );
    return _list;
  }

  ngOnInit(): void {
    this.generateOptions();
  }

  /**
   * generate a list of symptoms specific to a selected category,
   * @param optionsKey The string representation of the selected category
   */
  public generateSymptoms(optionsKey: string) {
    this.symptoms = [];
    for (let key in data.CategoryLabels) {
      if (data.CategoryLabels[key] === optionsKey) {
        optionsKey = key;
      }
    }
    for (let set_key in data.Categories[optionsKey]) {
      this.symptoms.push(
        new Symptom(
          data.Categories[optionsKey][set_key],
          data.CategoryLabels[optionsKey],
          ''
        )
      );
    }
  }

  //TODO: Clean
  public generateAllSymptoms(patientAge: string) {
    this.symptoms = [];
    switch (patientAge.toLowerCase()) {
      case 'baby':
        for (let set_key in data.Categories['Baby']) {
          this.symptoms.push(
            new Symptom(
              data.Categories['Baby'][set_key],
              data.CategoryLabels['Baby'],
              ''
            )
          );
        }
        break;
      default:
        for (let key in data.CategoryLabels) {
          if (key.toLowerCase() !== 'baby') {
            for (let set_key in data.Categories[key]) {
              this.symptoms.push(
                new Symptom(
                  data.Categories[key][set_key],
                  data.CategoryLabels[key],
                  ''
                )
              );
            }
          }
          //adult or geriatric
        }
        break;
    }
  }

  /**
   * reads from an associated json file to fill in symptom information for searching/dragging and dropping, also categorizes
   * */
  public generateOptions(): void {
    this.options = [];
    this.symptoms = [];
    if (this._patient.age.toLowerCase() === 'baby') {
      this.options.push(data.CategoryLabels[this._patient.age]);
    } else {
      for (let key in data.CategoryLabels) {
        if (key.toLowerCase() !== 'baby') {
          this.options.push(data.CategoryLabels[key]);
        }
      }
    }

    //set the default selected option to the enumerated state set default in the selectedOptions variable
    this.optionsControl.setValue(this.selectedOption);
    this.generateSymptoms(this.selectedOption);
  }

  filterListByCategory(value: string): Symptom[] {
    return this.symptoms.filter((x) => x.category === value);
  }

  matchesFilter(fromVal: string, toVal: string): boolean {
    return fromVal?.toLowerCase().includes(toVal?.toLowerCase());
  }

  drop(event:CdkDragDrop<any[]>)
  {
    if(event.previousContainer !== event.container ){
      // this means that we're loading something onto this container, in any case we need to remove that item.
      event.previousContainer.data.splice(event.previousIndex, 1);
      // notify the controller to save the model after removing the symptoms.
      this.SymptomsModified.emit();
    }
  }
}
