<div class="channel-tab-content">
  <div class="channel-setup-data">
    <mat-label>Channel State</mat-label>
    <mat-form-field appearance="outline">
      <mat-select [(value)]="channel.State">
        <mat-option
          *ngFor="let state of ChannelState; let i = index"
          [value]="i"
        >
          {{ state }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-accordion>
      <!-- easy expansion panel -->
      <mat-expansion-panel
        [expanded]="!this.channel.CalcOn"
        (opened)="easyPanelOpenState = true"
        (closed)="easyPanelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Easy </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- primary content goes here -->
        <div style="margin-left: 118px" *ngIf="SetupLineOption === true">
          <mat-label>Setup Mode</mat-label>
          <mat-form-field
            appearance="outline"
            class="center"
            style="margin-left: 80px"
          >
            <mat-select [(value)]="channel.SetupMode">
              <mat-option *ngFor="let mode of SetupModes" [value]="mode">{{
                mode
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          style="margin-left: 118px; padding-top: 10px; padding-bottom: 10px"
        >
          <mat-label style="margin-right: 100px">Lines:</mat-label>
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button
              #primarySelection
              [checked]="this.determineTrueFalsePrimary()"
              value="1"
              (click)="offSecLine()"
              >Primary</mat-radio-button
            >
            <mat-radio-button
              style="margin-left: 10px"
              #primaryAndSecondary
              [checked]="this.determineTrueFalseSecondary()"
              value="2"
              (click)="onSecLine()"
              >Primary & Secondary</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div class="lines">
          <mat-label>Primary: </mat-label>
          <app-line
            [(line)]="this.channel.PrimaryLine"
            [Mode]="this.channel.SetupMode"
          >
          </app-line>
        </div>
        <div class="lines" *ngIf="primaryAndSecondary.checked === true">
          <mat-label>Secondary: </mat-label>
          <app-line
            [(line)]="this.channel.SecondaryLine"
            [Mode]="this.channel.SetupMode"
          ></app-line>
        </div>
        <div style="margin-left: 118px">
          <mat-label>Infusing Since:</mat-label>
          <mat-form-field appearance="outline" style="width: 80px">
            <input
              matInput
              type="number"
              [max]="24"
              [min]="0"
              [(ngModel)]="this.InfuseHour"
            />
          </mat-form-field>
          :
          <mat-form-field appearance="outline" style="width: 80px">
            <input
              matInput
              type="number"
              [max]="59"
              [min]="0"
              [(ngModel)]="this.InfuseMinute"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 140px">
            <input
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="this.InfuseDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <button mat-raised-button (click)="OnSetDateTimeNow()">Now</button>
          <button mat-raised-button (click)="ClearDateTime()">Clear</button>
        </div>
        <div class="row">
          <mat-label>Total Volume Infused:</mat-label>
          <app-custom-number-field
            class="center"
            [(value)]="channel.TotalVolumeInfused"
            [min]="0"
            [max]="999"
            [steps]="1"
          >
          </app-custom-number-field>
        </div>
      </mat-expansion-panel>
      <!-- advanced expansion panel -->
      <mat-expansion-panel
      [expanded]="this.channel.CalcOn"
        (opened)="advancedPanelOpenState = true; channel.CalcOn = true"
        (closed)="advancedPanelOpenState = false; channel.CalcOn = false"
        >
        <mat-expansion-panel-header>
          <mat-panel-title> Advanced </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- advanced primary content goes here-->
        <DIV>
          <div class="row">
            <mat-label>Patient Weight</mat-label>

            <app-custom-number-field
              class="center"
              [(value)]="channel.PatientWeight"
              [min]="0"
              [max]="999"
              [steps]="1"
              (valueChange)="calculateRate($event, true)"
            >
            </app-custom-number-field>
          </div>
          <div class="border">
            <p><strong>Concentration</strong></p>

            <app-custom-number-field (valueChange)="calculateRate($event, false, false, true)"
              class="center"
              [(value)]="channel.Concentration"
              [min]="0"
              [max]="999"
              [steps]="1"
            >
            </app-custom-number-field>

            <mat-form-field appearance="outline" class="center smallFormField">
              <mat-label>Unit of Measure</mat-label>
              <mat-select [(value)]="channel.ConcUOM">
                <mat-option *ngFor="let uom of concentrationUOMs;" [value]="this.UOMConverter.getUOMNumber(uom)" (click)="calculateRate($event)">
                  {{ uom }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <app-custom-number-field
              class="center"
              [(value)]="channel.ConcDivAmount"
              [min]="0"
              [max]="999"
              [steps]="1"
              label="Divided By Amount"
              (valueChange)="calculateRate($event, false, false, false, true)"
            >
            </app-custom-number-field>
          </div>

          <div class="border">
            <p><strong>Dose</strong></p>
            <app-custom-number-field (valueChange)="calculateRate($event, false, true)"
              class="center"
              [(value)]="channel.Dose"
              [min]="0"
              [max]="999"
              [steps]="1"
            >
            </app-custom-number-field>

            <mat-form-field appearance="outline" class="center smallFormField">
              <mat-label>Unit of Measure</mat-label>
              <mat-select [(value)]="channel.DoseUOM">
                <mat-option *ngFor="let uom of doesUOMs;" [value]="this.UOMConverter.getUOMNumber(uom)" (click)="calculateRate($event)">
                  {{ uom }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-label>per</mat-label>
            <mat-form-field appearance="outline" class="center smallFormField">
              <mat-select [(value)]="channel.DoseDivUOM">
                <mat-option *ngFor="let uom of doseDivideByUOMS;" [value]="this.UOMConverter.getUOMNumber(uom)" (click)="calculateRate($event)">
                  {{ uom }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-label>per</mat-label>
            <mat-form-field appearance="outline" class="center smallFormField">
              <mat-select [(value)]="channel.DoseDivTime">
                <mat-option
                  *ngFor="let time of doseDivTimeOptions"
                  [value]="time"
                  (click)="calculateRate($event)"
                >
                  {{ time }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="border">
            <p><strong>Rate</strong></p>
            <app-custom-number-field [disabled]="checkIfNumber(channel.DRCRate)" (valueChange)="calculateDose($event)"
              class="center"
              [(value)]="channel.DRCRate"
              [min]=".1"
              [max]="9999"
              [steps]=".1"
            >
            </app-custom-number-field>
            <p style="color: orangered" *ngIf="checkIfNumber(channel.DRCRate)"><strong>Invalid Formula:</strong> Either the unit of measures cannot be converted, or concentration and dose are both set to 0 </p>
          </div>
            
        </DIV>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="channel-alarms">
    <h3><strong>Alarms</strong></h3>
    <div class="alarm-list" *ngFor="let alarm of Alarms">
      <mat-checkbox
        [value]="alarm"
        (change)="OnAlarmChanged($event)"
        [checked]="IsAlarmSelected(alarm)"
        >{{ alarm }}</mat-checkbox
      >
    </div>
  </div>
</div>
