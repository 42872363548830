<div class="dialog-container-header">
  <div mat-dialog-title>
    <h2>Initial IV Pump Settings</h2>
    <h3>
      Adjust the settings for the IV Pump connected to patient
      <strong>{{ data.patientName }}</strong>
    </h3>
  </div>

  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<hr />

<p style="margin: 0; padding: 0">Device Settings</p>
<div style="display: flex">
  <mat-form-field
    appearance="outline"
    style="float: left; margin-right: 68px; width: 300px"
  >
    <mat-select [(value)]="ivpump.DeviceSettings">
      <mat-option
        *ngFor="let setting of DeviceSettings; let i = index"
        [value]="i"
        >{{ setting }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-checkbox
    [(ngModel)]="ivpump.SetupLine"
    style="margin: auto 0"
    >Setup Line Option</mat-checkbox
  >
</div>

<mat-tab-group animationDuration="0ms">
  <mat-tab label="Channel A">
    <app-channel
      [(channel)]="this.channels[0]"
      [SetupLineOption]="ivpump.SetupLine"
      (invalidChange)="errorInChannel[0] = $event"
    >
    </app-channel
  ></mat-tab>
  <mat-tab label="Channel B">
    <app-channel
      [(channel)]="this.channels[1]"
      [SetupLineOption]="ivpump.SetupLine"
      (invalidChange)="errorInChannel[1] = $event"
    >
    </app-channel
  ></mat-tab>
  <mat-tab label="Channel C">
    <app-channel
      [(channel)]="this.channels[2]"
      [SetupLineOption]="ivpump.SetupLine"
      (invalidChange)="errorInChannel[2] = $event"
    >
    </app-channel
  ></mat-tab>
</mat-tab-group>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>

  <button mat-button class="green-focus" [mat-dialog-close]="this.ivpump" [disabled]="errorInChannel[0] || errorInChannel[1] || errorInChannel[2]">
    Submit Changes
  </button>
</mat-dialog-actions>
