<!DOCTYPE html>
<html>
    <head>
        <meta charset="utf-8"/>
        <title>Password reset</title>
        <link href="/api/passwordreset.css" rel="stylesheet"/>
        <style>
            .hide {
                display:none;
            }
        </style>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/> 
    </head>
    <body>
        <form [formGroup]="form"  (ngSubmit)="resetPassword()" >
            <span *ngIf="!operationSuccess">
            <p>Please enter a new password here.</p>
            <input 
                type="password" 
                formControlName="newpassword" 
                id="newpassword" 
                required 
                
                pattern="(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d@$!%*?&_=\-\+]{8,}"
            />

            <p>Please retype your password again.</p>
            <input 
                type="password" 
                formControlName="retypepassword" 
                id="retypepassword" 
                required  minlength="8" 
                pattern="(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d@$!%*?&_=\-\+]{8,}"
            />
            <br>
            <input type="submit" value="Change password"/>
            <br><br>
            <div *ngIf="this.isErrorDisplayed" class="error-box">
                    <ul>
                        <li style="list-style:none;" ><strong>
                            Your password is missing one of the following criteria
                        </strong></li>
                        <li>Two field value must match</li>
                        <li>Must have a minimum of 8 Characters</li>
                        <li>Must have either lowercase or uppercase characters</li>
                        <li>Must have at least one number</li>
                    </ul>
              </div>
              <div *ngIf="passwordResult" [ngClass]="{ 'success-message':operationSuccess, 'error-message':!operationSuccess}">
                {{ passwordResult }}
              </div>
            </span>
              <div *ngIf="operationSuccess">
                <h2 style="color:green">
                    {{ passwordResult }}
                </h2>
                <br>
                <br>
                <input type="button" class="loginpage" (click)="goToLoginPage()" value="Go to login page"/>
              </div>

        </form>
    </body>  
</html>