import { Component, OnInit, ElementRef, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { TagSearchModule } from '../tag-search.module';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-tag-search-box',
  templateUrl: './tag-search-box.component.html',
  styleUrls: ['./tag-search-box.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class TagSearchBoxComponent implements OnInit {
  // display the editor mode whether the user can add tags or click on tags for filter.
  @Input() editorMode : boolean = false;
  // Selected Tags user either selected or added
  @Input() selectedTags: string[] = [];

  // variable to display tag list
  showSearchBox: Boolean = false;

  // collection of tags in the library presented to the user. Find out how this is generated and how we can define the rules behind this?
  tags: string[] = [
    'TEST',
    'TEST-TAG',
    'ANOTHER_TEST_TAG',
    'MORE.TEST.TAGS',
    'THIS-IS-A-TEST-TAG',
    'smallTag',
    'very-very.large_big-tag_thats_large',
  ]

  
  constructor(private _eref: ElementRef) { }

  ngOnInit(): void { }

  /** Function: Allows the user to expand the tag submenu in the searchbox.
   * 
   * @param event 
   */
  onClick(event){
    if (!this._eref.nativeElement.contains(event.target)){
      this.showSearchBox = false;
    }
  }

  /** Function: When user clicks on the Item, we'll either add or remove it from the selection list.
   * 
   * @param tag 
   * 
   */
  onSelectTag( tag : string ): void {
    var index = this.selectedTags.indexOf(tag);
    if( index >= 0 ) {
      this.selectedTags.splice( index, 1 );
    } else {
      this.selectedTags.push(tag.toUpperCase());
    }
  }

  /** Function: Allows user to add Tags into the list of selected Tags. - Editor mode only
   * 
   * @param tagName as new Tag Name
   */
  onAddTag( form: NgForm ) : void {
    // probably need to add a check to prevent duplicated name of tags?
    // display what the user key in
    if( !form.valid ) return;
    var tag = form.value.tagName.toUpperCase();
    if( this.selectedTags.indexOf(tag) === -1 )
      this.selectedTags.push(tag);
    // create a new tag and add it to the selected tags
    // push tag and notify event
    form.reset();  // clear the tag name for next tag input
  }

  /** Function: Allows user to remove tags from the selected tags - Editor mode only
   * 
   * @param tag 
   */

  onDeleteTag( tag : string ): void{
    // get the index array of the tags we wish to remove.
    var index = this.selectedTags.indexOf(tag);
    // splice the tags out
    this.selectedTags.splice(index, 1);
  }
}
