<div class="hover-button" (mouseleave)="showPopup = false">
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
        style="transform: translateX(-50%);" (mouseover)="showPopupF()" (click)="showAudioPopupF()">
        <mat-icon style="color: #1f7abe">forward</mat-icon>
        <span style="color: #1f7abe; font-weight:bold;">{{clinical.transitionTime}}s</span>
    </button>
    <div class="popup" *ngIf="showPopup">

        <div class="popup-notch">
        </div>

        <div class="transparent-top-part" (click)="showAudioPopupF()">
        </div>

        <div class="popup-content" *ngIf="!showPopupContent">
            <div style="font-size: small; min-width:200px;height:auto;">
                <p><strong>Automatic Transition</strong></p>
                <p>This state will automatically transition to {{ this.prettyState }} after {{clinical.transitionTime}} seconds</p>
            </div>
        </div>

        <div class="popup-content" *ngIf="showPopupContent">
            <button mat-flat-button style="color:#1f7abe" (click)="onEditItem()"><mat-icon>create</mat-icon> Edit</button>
            <button mat-flat-button style="color:#1f7abe" (click)="onDeleteItem()"><mat-icon>delete</mat-icon> Delete</button>
        </div>
    </div>
</div>