import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CleanUpFileName, ObtainFilename } from '../filename-obtainer.pipe';
import { ReplaceUnderscore } from '../remove-underscores.pipe';
import { CleanTimeline } from '../clean-timeline.pipe';
import { FirstLetterUppercase } from '../first-letter-uppercase.pipe';
import { RemoveGenderAndRace } from '../remove-gender-and-race.pipe'
// THIS IS A SHARED MODULE FOR CUSTOM PIPES

@NgModule({
  declarations: [
    CleanUpFileName,
    ObtainFilename,
    ReplaceUnderscore,
    CleanTimeline,
    FirstLetterUppercase,
    RemoveGenderAndRace,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CleanUpFileName,
    ObtainFilename,
    ReplaceUnderscore,
    CleanTimeline,
    FirstLetterUppercase,
    RemoveGenderAndRace,
  ]
})
export class ApplicationPipesModuleModule { }
