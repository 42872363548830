export class ShareMode
{
  public getTeamString()
  {
return `Team Share` ;
  }
  public getCommunityString()
  {

    return `Community Share`;
  }

}

export class LinkedScenarioData {
  constructor(
    public downloadedfrom:string,
    public userId : string,
    public institutionId:string,
    public  originalScenarioId:string,
    public copiedScenarioId:string
  )
  {

  }



}



