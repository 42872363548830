<div class="wrapper">
  <app-ngui-in-view>
    <img *ngIf [src]="imageUrl" alt="{{ this.equipment }}" />
  </app-ngui-in-view>
  <p>{{ this.equipment | ReplaceUnderscore | RemoveGenderAndRace }}</p>

  <div class="toggle-wrapper">
    <input
      type="checkbox"
      id="toggle-button-checkbox_{{ index }}"
      [checked]="this.activeState"
      (click)="onChangeState()"
    />
    <label class="toggle-button-switch" for="toggle-button-checkbox_{{ index }}"
      ><mat-icon class="disabled" *ngIf="this.activeState === false"
        >close</mat-icon
      >
      <mat-icon class="enabled" *ngIf="this.activeState === true">check</mat-icon></label
    >
    <div class="toggle-button-text">
      <div class="toggle-button-text-on"></div>
      <div class="toggle-button-text-off"></div>
    </div>
  </div>
</div>
