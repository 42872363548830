import { Component, EventEmitter, Inject, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { RolesService } from '../services/roles.service';

import {
  PermissionsService,
  Permissions,
} from '../services/permissions.service';
import { filter, finalize } from 'rxjs/operators';

import { DialogUploadFileComponent } from '../scenario-card/dialogs/dialog-upload-file/dialog-upload-file.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Feature, FeatureKeys, FeatureflagService } from '../services/featureflag.service';
import { ScenariosService } from '../services/scenarios.service';
import { HttpClient, HttpEventType, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { ServerService } from '../services/Server.service';
import { CookieService } from 'ngx-cookie-service';
import { toolsservice } from '../editor/services/tools.service';

import { Md5 } from 'ts-md5';
import { Subscription } from 'rxjs';
import { fileType } from '../services/upload.service';
import { FileType, customAudioModel } from '../models/CustomFile.model';
import * as packageInfo from 'package.json';


@Component({
  selector: 'app-persim-header',
  templateUrl: './persim-header.component.html',
  styleUrls: ['./persim-header.component.scss'],
  // host: {
  // onClick is not defined here. Commented out to suppress error message.
  // '(document:click)': 'onClick($event)',
  // }
})
export class PersimHeaderComponent implements OnInit {
  OpenPopUpMessageCalled: EventEmitter<string> = new EventEmitter<string>();
  public scenarioId: string;
  public static unityInstance: any;
  public showNavDropdown = false;
  public loggedInUserRole: string = '';
  public isInstructor = false;
  public isCustomAudioEnabled: boolean = false;
  currentRoute: string;
  private static MAX_FILE_SIZE_MB = 8;
  private static MAX_FILE_SIZE_BYTES = PersimHeaderComponent.MAX_FILE_SIZE_MB * 1024 * 1024;
  private isBrowserOpen: boolean = false;
  uploadSub: Subscription;
  private isUploadProcess: boolean = false;
  public get getLoginName(): string {
    return this.authService.getFullName();
  }

  public get getUserRole(): string {
    return this.authService.getRoleId();
  }
  public static featureList: Feature[] = [];
  private audioElement = new Audio();
  audioFiles: customAudioModel[];
  constructor(

    private msb: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private rolesService: RolesService,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private featureService: FeatureflagService,
    private readonly http: HttpClient,
    private scenarioService: ScenariosService,
  ) {
    document.addEventListener('customAudioRead', (event) => {
      const fileId = (event as CustomEvent).detail;
      this.customAudioDownload(fileId);
    });
    document.addEventListener('customAudioUpload', (event) => {
      const customAudioFileType = (event as CustomEvent).detail;
      this.uploadCustomAudio(customAudioFileType);
    });
    document.addEventListener('customaudioPlayStatusChanged', (event) => {
      const isplaying = (event as CustomEvent).detail;
      this.customAudioPlayStatusChanged(isplaying);
    });
  }

  ngOnInit() {

    this.featureService.getFeatureFlags().subscribe(
      (resultList) => {
        PersimHeaderComponent.featureList = resultList;
        this.isCustomAudioEnabled = resultList.find(x => x.featureName == FeatureKeys.CustomAudioUpload).isEnabled;
        const UserId = this.authService.getUserID();
        const userToken = this.authService.getToken();
        this.checkUserAdminPermissionLevel();
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
          this.currentRoute = this.router.url;
          if (this.currentRoute != undefined && this.currentRoute.includes('/scenario-editor', 0)) // if user is at sccenario editor page while loading
          {
            if (PersimHeaderComponent.unityInstance != null || PersimHeaderComponent.unityInstance != undefined) {
              try {
                PersimHeaderComponent.unityInstance.SendMessage("WebGLCommunication", "EnableWebGLKeyBoard");
              }
              catch (ex) {
                console.log("Error on EnableWebGLKeyBoard " + ex.toString());
              }

            }
          }
        });
        if (this.getLoginName === null) {
          this.router.navigateByUrl('/');
        }
        else {
          this.OpenWebGL();
          var canvas: HTMLElement = document.querySelector("#unity-container") || new HTMLElement();
          canvas.style.visibility = "hidden";
          canvas.style.height = "0px";
          canvas.style.width = "0%";
        }
      },

      (error) => {
        console.error(error.message);
      });
    this.getCurrentAudioFiles();
  }

  getCurrentAudioFiles() {

    this.scenarioService.getCustomAudioFiles().subscribe(
      (list) => {
        this.audioFiles = list.map(item => {
          const audioModel = new customAudioModel();
          audioModel.filePath = item.filePath;
          audioModel.fileType = item.fileType;
          audioModel.fileId = item.fileId;
          audioModel.fileName = item.fileName;
          audioModel.fileHash = item.fileHash;
          return audioModel;
        });
      },
      (err) => {
        console.error('Error on audioFiles' + err);
      });

  }
  /**
   * check the user's admin permission level and disable/enable admin button in hamburger menu
   */
  checkUserAdminPermissionLevel(): void {
    if (this.authService.getAdminPermission() & Permissions.Read) {
      this.permissionsService.isInstructor = false;
      this.isInstructor = false;
    } else {
      this.permissionsService.isInstructor = true;
      this.isInstructor = true;
    }
  }
  isValidExtension(filename): boolean {
    var parts = filename.name.split('.');
    var ext = parts[parts.length - 1];
    switch (ext.toLowerCase()) {
      case 'mp3':
      case 'ogg':
        return true;
    }
    return false;
  }
  async calculateMD5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        const data = new Uint8Array(buffer);
        const dataString = this.arrayBufferToString(data);
        const hash = Md5.hashStr(dataString);
        resolve(hash.toString());
      };
      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }
  arrayBufferToString(buffer: Uint8Array): string {
    let result = '';
    const length = buffer.length;
    for (let i = 0; i < length; i++) {
      result += String.fromCharCode(buffer[i]);
    }
    return result;
  }
  getGUID(): string {
    let d = new Date().getTime();
    const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return guid;
  }

  async OnFileUploaded(customAudioFileType: string, selectedFile: File) {
    const fileHash = await this.calculateMD5(selectedFile);
    let fileTypeenum = FileType.HEART_AUDIO;
    if (customAudioFileType == "HEART_AUDIO") {
      fileTypeenum = FileType.HEART_AUDIO;
    }
    else if (customAudioFileType == "LUNG_AUDIO") {
      fileTypeenum = FileType.LUNG_AUDIO;
    }
    if (!this.isValidExtension(selectedFile)) {
      toolsservice.openSnackBar(this.msb, 'File format is not Supported!', false);
      return;
    }
    if (this.audioFiles.length != 0 && this.audioFiles.filter(x => x.fileHash == fileHash).length > 0) {
      toolsservice.openSnackBar(this.msb, 'File ' + selectedFile.name + ' is already uploaded!', false);
      return;
    }


    //check if file size exceeds limits
    if (selectedFile.size > PersimHeaderComponent.MAX_FILE_SIZE_BYTES) {
      toolsservice.openSnackBar(this.msb, 'File is more than 8MB please select a smaller file!', false);
      return;
    }

    const fileId = this.getGUID();
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileId", fileId);
    formData.append("fileType", fileTypeenum);
    formData.append("fileHash", fileHash);
    const upload = this.http.post(ServerService.CustomAudio_URL(), formData);

    this.isUploadProcess = true;
    this.uploadSub = upload.subscribe(result => {

      const fileNameWithoutExtension = selectedFile.name.split('.').slice(0, -1).join('.');
      toolsservice.openSnackBar(this.msb, 'File ' + selectedFile.name + ' Successfully uploaded', true);
      PersimHeaderComponent.unityInstance.SendMessage("WebGLCommunication", "RefreshAudioList", fileNameWithoutExtension + '*' + fileTypeenum.toString());
      this.isUploadProcess = false;

      setTimeout(() => {
        const fileinfo = result as customAudioModel;
        this.customAudioDownload(fileinfo.fileId);
        this.getCurrentAudioFiles();
      }, 200);
    })
  }

  public async uploadCustomAudio(customAudioFileType: string) {
    if (this.isBrowserOpen) {
      return;
    }

    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    fileInput.accept = ".mp3,.ogg";
    document.body.appendChild(fileInput);
    fileInput.addEventListener("change", (event) => {
      const selectedFile = fileInput.files[0];
      if (selectedFile) {
        this.OnFileUploaded(customAudioFileType, selectedFile)
        this.isBrowserOpen = false;
      }
      document.body.removeChild(fileInput);
    });
    fileInput.click(); // Trigger the file input element
  }

  reset() {

    this.uploadSub = null;
  }

  logout() {
    this.CloseWebGL();
    setTimeout(() => {
      this.authService.logout();
      this.showNavDropdown = !this.showNavDropdown;
      this.router.navigateByUrl('/');
    }, 100);


  }

  OpenWebGL() {
    const UserId = this.authService.getUserID();
    const userToken = this.authService.getToken();
    var buildUrl = "https://persimstorage.blob.core.windows.net/persim-assets/ControllerWebGL";
    //var buildUrl = "assets/ControllerWebGL/Build";
    var config = {
      dataUrl: buildUrl + "/WebGL.data.unityweb",
      frameworkUrl: buildUrl + "/WebGL.framework.js.unityweb",
      codeUrl: buildUrl + "/WebGL.wasm.unityweb",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "MedCognition",
      productName: "ControllerWebGL",
      productVersion: "0.1",
      devicePixelRatio: 0
    };

    let container = document.querySelector("#unity-container") || new Element();
    var canvas: HTMLElement = document.querySelector("#unity-canvas") || new HTMLElement();
    var loadingBar: HTMLElement = document.querySelector("#unity-loading-bar") || new HTMLElement();
    var progressBar: HTMLElement = document.querySelector("#unity-progress-bar") || new HTMLElement();

    var progressBarFull: HTMLElement = document.querySelector("#unity-progress-bar-full") || new HTMLElement();
    var fullscreenButton: HTMLElement = document.querySelector("#unity-fullscreen-button") || new HTMLElement();
    var mobileWarning: HTMLElement = document.querySelector("#unity-mobile-warning") || new HTMLElement();
    this.route.queryParams.subscribe(params => {
      this.scenarioId = params['scenarioId'];
    });

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      container.className = "unity-mobile";
      config.devicePixelRatio = 1;
      mobileWarning.style.display = "block";
      setTimeout(() => {
        mobileWarning.style.display = "none";
      }, 5000);
    } else {
      //  canvas.style.width = "100%";
      //  canvas.style.height = "900px";
    }
    loadingBar.style.display = "block";

    createUnityInstance(canvas, config, (progress: any) => {
      //progressBarFull.style.width = 100 * progress + "%";
      progressBar.style.width = 100 * progress + "%";
    }).then((unityInstance: any) => {
      PersimHeaderComponent.unityInstance = unityInstance;
      loadingBar.style.display = "none";
      fullscreenButton.onclick = () => {
        unityInstance.SetFullscreen(1);
      };
      const userToken = this.authService.getToken();
      try {
        unityInstance.SendMessage("WebGLCommunication", "BackEndURLUpdate", packageInfo.backendServerPath);
        if (userToken != "" && this.scenarioId != null && this.scenarioId != undefined && this.scenarioId != '') {
          unityInstance.SendMessage("WebGLCommunication", "LoadUserScenario", userToken + "*" + this.scenarioId);
        }
        else if (userToken != "") {
          unityInstance.SendMessage("WebGLCommunication", "NewScenario", userToken);
        }
      }
      catch (exception) {
        console.log("Can't load webgl:"+exception)
      }

    }).catch((message: any) => {
      alert(message);
    });
  }

  CloseWebGL() {
    if (PersimHeaderComponent.unityInstance) {
      PersimHeaderComponent.unityInstance.Quit(() => {
      });
      PersimHeaderComponent.unityInstance = null;
    }
  }
  customAudioPlayStatusChanged(isPlaying: string) {

    this.audioElement.currentTime = 0;
    if (isPlaying == 'True') {
      this.audioElement.play();
    }
    else {
      this.audioElement.pause();
    }
    this.audioElement.addEventListener('timeupdate', () => {
      if (this.audioElement.currentTime >= this.audioElement.duration) {
        this.audioElement.currentTime = 0;
      }
    });
  }
  reloadDashBoard() {
    console.log("Reload DashBoard");
    this.router.navigateByUrl('/dashboard');
  }
  customAudioDownload(fileId: string) {

    const userToken = this.authService.getToken();
    const httpHeaders = new HttpHeaders({
      'Content-type': 'application/octet-stream',
      Accept: 'application/octet-stream',
      'Access-Control-Allow-Origin': ServerService.GetCustomAudioFile_URL(fileId),
      'Authorization': "Bearer " + userToken
    });
    this.audioElement.src = null;
    this.http.get(ServerService.GetCustomAudioFile_URL(fileId), { responseType: 'blob', headers: httpHeaders }).subscribe((fileData: Blob) => {
      const audioBlob = new Blob([fileData], { type: 'audio/wav' }); // Adjust the type based on the actual audio format
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log("Logg:" + audioUrl);
      this.audioElement.src = audioUrl;
      this.audioElement.controls = false;
      this.audioElement.addEventListener('loadedmetadata', () => {
        if (PersimHeaderComponent.unityInstance != null || PersimHeaderComponent.unityInstance != undefined) {
          PersimHeaderComponent.unityInstance.SendMessage("WebGLCommunication", "OnClipDurationChanged", this.audioElement.duration);
          this.audioElement.play();
        }

        this.audioElement.addEventListener('ended', () => {

          PersimHeaderComponent.unityInstance.SendMessage("WebGLCommunication", "FinishedAudioPlay");
        });
      });




    }, (error) => {
      console.error('Error downloading audio:', error);
    });
    return;
  }
}
