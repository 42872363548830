import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account/account/account.component';
import { AdminComponent } from './admin/admin/admin.component';
import { EditorComponentComponent } from './editor/editor-component/editor-component.component';
import { ListOfScenariosComponent } from './list-of-scenarios/list-of-scenarios/list-of-scenarios.component';
import { LoginComponent } from './login/login/login.component';
import { SettingsComponent } from './settings/settings/settings.component';
import { PagenotfoundComponent } from
    './pagenotfound/pagenotfound.component';
//services
import { PermissionsService } from './services/permissions.service'
import { AuthService } from './auth/auth.service';
//Guard Imports
import { AdminGuard } from './admin-guard.guard';
import { LoginProtectionGuard } from './login-protection.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ScenarioEditorComponent } from './scenario-editor/scenario-editor.component';
const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'editor', component: EditorComponentComponent, canActivate: [LoginProtectionGuard] },
  { path: 'editor/:id', component: EditorComponentComponent, canActivate: [LoginProtectionGuard] },
  { path: 'editor/:id/:tutorial', component: EditorComponentComponent, canActivate: [LoginProtectionGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard, LoginProtectionGuard]},
  // { path: 'devices', component: DevicesComponent },
  { path: 'dashboard', component: ListOfScenariosComponent, canActivate: [LoginProtectionGuard],pathMatch:'full' },
  { path: 'scenario-editor', component: ScenarioEditorComponent, canActivate: [LoginProtectionGuard],pathMatch:'full' },
  { path: 'account', component: AccountComponent, canActivate: [LoginProtectionGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [LoginProtectionGuard]},
  { path: 'reset-password/:id', component:ResetPasswordComponent},
  { path: '**',  canActivate: [LoginProtectionGuard], component: PagenotfoundComponent,pathMatch:'full'},
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [AuthService],
})
export class AppRoutingModule {}
