<p>Signs and Symptoms</p>
<div class="window">
  <!-- Search field -->
  <div class="search-field-window">
    <form>
      <!-- style="width: 100%; margin-top: auto; margin-bottom: auto;" -->
      <!-- mat-form-field -->
      <div
        class="search-field"
        *ngIf="isPrimaryState; else showAlternativeSearch"
      >
        <mat-select
          [formControl]="optionsControl"
          placeholder="Select Category" [(value)]="selectedOption"
        >
          <mat-option
            *ngFor="let option of options"
            [value]="option"
            (click)="generateSymptoms(option)"
            >{{ option }}
          </mat-option>
        </mat-select>

        <button
          mat-button
          (click)="onSearchButtonPressed()"
          class="actionButton"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>

      <ng-template #showAlternativeSearch>
        <div class="search-field">
          <mat-icon style="margin-top: auto; margin-bottom: auto"
            >search</mat-icon
          >
          <input
            autocomplete="off"
            type="text"
            placeholder="Type to search"
            aria-label="Number"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          </mat-autocomplete>

          <button
            mat-button
            (click)="onSearchButtonPressed()"
            class="actionButton"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </ng-template>

      <!-- Ok... so we need to get this button "inside" the auto complete? -->
      <!-- <button mat-icon-button (click)="onSearchButtonPressed()" style="margin-left:10px; margin-top: auto; margin-bottom: auto;">
        <mat-icon *ngIf="isPrimaryState; else showSecondaryState">search</mat-icon>
        <ng-template #showSecondaryState>
          <mat-icon>close</mat-icon>
        </ng-template>
      </button> -->
    </form>
  </div>
  <!-- (cdkDropListDropped)="drop($event)" -->
  <div
    cdkDropList
    [cdkDropListData]="symptoms"
    id="search-list"
    [cdkDropListConnectedTo]="getPhaseIDs"
    (cdkDropListDropped)="drop($event)"
    class="parent example-list">
    <!-- LIST OF SYMPTOMS STARTS HERE -->
    <span *ngIf="isPrimaryState; else showSecondarySearch">
      <div
        cdkDrag
        class="example-box"
        *ngFor="let symptom of filterListByCategory(optionsControl.value)">

        <mat-icon class="icon">drag_handle</mat-icon>
        <h3 style="margin-top: auto; margin-bottom: auto">
          {{ symptom.name }}
        </h3>

      </div>
    </span>
    
    <ng-template #showSecondarySearch>

      <span *ngFor="let symptom of symptoms" cdkDrag >
        <div class="example-box" *ngIf="matchesFilter(symptom.name, myControl.value)">
          
          <mat-icon class="icon">drag_handle</mat-icon>
          <div style="margin-top: auto; margin-bottom: auto">

            <h3 style="margin: 0px">{{ symptom.name }}</h3>
            <P style="margin: 0px; font-size: 12px; color: rgb(168, 168, 168)">
              <i>{{ symptom.category }}</i>
            </P>

          </div>
        </div>
      </span>
    </ng-template>
  </div>
</div>
