
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-custom-number-field',
  templateUrl: './custom-number-field.component.html',
  styleUrls: ['./custom-number-field.component.scss'],
})
export class CustomNumberFieldComponent implements OnInit {
  @Input() value: number;
  @Input() steps: number;
  @Input() min: number;
  @Input() max: number;
  @Input() disabled: boolean = false;
  @Input() label: string = null;

  @Output() valueChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * get the value of a change made by hand
   * */ 
  public get handChange(): number {
    return this.value;
  }

  /**
   * set the value of a change made by hand, limit it to between the min and max values
   * @param value - the value to change
   */
  public set handChange(value: number) {
    this.value = Math.max(Math.min(value, this.max), this.min);
    this.valueChange.emit(this.value);
  }

  /**
   * increase the value of a change made by clicking the + button
   */
  public increment() {
    if (this.max !== undefined && this.value < this.max) {
      this.value += this.steps;
      this.valueChange.emit(this.value);
    }
  }

  /**
   * decrease the value of a change made by clicking the - button
   */
  public decrement() {
    if (this.min !== undefined && this.value > this.min) {
      this.value -= this.steps;
      this.valueChange.emit(this.value);
    }
  }

  /**
   * helper function to lock the field to a number
   * @param value the value to change
   */
  public onClampValue(value: any) {
    value.value = this.handChange;
  }
}
