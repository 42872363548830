<section class="patient-basics">
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    "
  >
    <div style="width: 80%">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Patient Name</mat-label>
        <input
          autocomplete="off"
          matInput
          [(ngModel)]="patient.patientName"
          [formControl]="patientNameFormControl"
          [errorStateMatcher]="matcher"
          required
        />
        <mat-error *ngIf="patientNameFormControl.hasError('required')"
          >field required</mat-error
        >
        <mat-error *ngIf="!patientNameFormControl.hasError('required')"
          >patient with name <strong>{{ patient.patientName }}</strong> already
          exists</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field-left">
        <mat-label>Patient Goal or Details</mat-label>
        <input
          autocomplete="off"
          matInput
          placeholder="Placeholder"
          [(ngModel)]="patient.goalsAndDetails"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Patient Backstory</mat-label>
        <input
          autocomplete="off"
          matInput
          placeholder="Placeholder"
          [(ngModel)]="patient.backstory"
        />
      </mat-form-field>
    </div>
    <div *ngIf="!this.selectedImage" class="patient-image-area">
      <p>no image currently selected</p>
      <p> (optional) </p>
      <button mat-raised-button (click)="this.onSelectImageForPatient()">Select Image</button>
    </div>
    <div *ngIf="this.selectedImage" class="patient-image-area-occupied">
      <img class="patient-image" loading="lazy" [src]="this.selectedImage" alt="{{this.selectedImage}}">
      <button mat-raised-button (click)="this.onSelectImageForPatient()">Select New Image</button>
    </div>
  </div>
  <section class="dropdown selections">
    <!-- TODO: make dynamic? -->
    <mat-form-field
      appearance="outline"
      style="width: 25%; padding-right: 15px"
      (click)="tryMe(patient.age)"
    >
      <mat-label>Age</mat-label>
      <mat-select [value]="patient.age">
        <mat-option value="Baby" (click)="onClickAge('Baby', patient)"
          >Baby</mat-option
        >
        <mat-option value="Adult" (click)="onClickAge('Adult', patient)"
          >Adult</mat-option
        >
        <mat-option value="Geriatric" (click)="onClickAge('Geriatric', patient)"
          >Geriatric
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="patient.age !== 'Baby'"
      appearance="outline"
      style="width: 25%; padding-right: 15px; padding-left: 15px"
    >
      <mat-label>Gender & Skin Tone</mat-label>
      <mat-select
        [(ngModel)]="patient.genderEthnicity"
        [disabled]="patient.age === undefined"
      >
        <mat-option value="Wht_Male" (click)="updateGender('Wht_Male')">
          Caucasian Male</mat-option
        >
        <mat-option value="Wht_Female" (click)="updateGender('Wht_Female')"
          >Caucasian Female
        </mat-option>
        <mat-option value="Eth_Male" (click)="updateGender('Eth_Male')">
          Person of Color Male
        </mat-option>
        <mat-option value="Eth_Female" (click)="updateGender('Eth_Female')"
          >Person of Color Female
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="patient.age === 'Baby'"
      appearance="outline"
      style="width: 25%; padding-right: 15px; padding-left: 15px"
    >
      <mat-label> Skin Tone </mat-label>
      <mat-select [(ngModel)]="patient.genderEthnicity">
        <mat-option value="Eth" (click)="updateGender('Eth')"
          >Person of Color</mat-option
        >
        <mat-option value="Wht" (click)="updateGender('Wht')"
          >Caucasian
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      style="width: 20%; padding: 0 15px"
      *ngIf="patient.age !== 'Baby'"
    >
      <mat-label>Weight in kg</mat-label>
      <input
        autocomplete="off"
        matInput
        type="number"
        [disabled]="patient.genderEthnicity === undefined"
        [(ngModel)]="patient.weight"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      style="width: 30%; padding-left: 15px"
      *ngIf="patient.age !== 'Baby'"
    >
      <mat-label>Uniform Type</mat-label>
      <mat-select [(ngModel)]="patient.clothingOption">
        <mat-option value="Civ1" (click)="updateClothing('Civ1')"
          >Civilian 1
        </mat-option>
        <mat-option value="Civ2" (click)="updateClothing('Civ2')"
          >Civilian 2
        </mat-option>
        <mat-option value="Army" (click)="updateClothing('Army')"
          >Army
        </mat-option>
        <mat-option value="Marine" (click)="updateClothing('Marine')"
          >Marine
        </mat-option>
        <mat-option value="AF" (click)="updateClothing('AF')"
          >Air Force
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      style="width: 25%; padding-right: 15px"
      *ngIf="patient.age !== 'Baby'"
    >
      <mat-label>Amputations</mat-label>
      <mat-select
        [(ngModel)]="patient.amputation"
        [disabled]="
          patient.age === undefined || patient.genderEthnicity === undefined
        "
      >
        <mat-option value="Body" (click)="updateAmputation('Body')"
          >No Missing Limbs</mat-option
        >
        <mat-option value="Arm" (click)="updateAmputation('Arm')"
          >Arm Limb Missing
        </mat-option>
        <mat-option value="Arm_Leg" (click)="updateAmputation('Arm_Leg')"
          >Arm & Leg Limb Missing</mat-option
        >
        <mat-option value="Leg" (click)="updateAmputation('Leg')"
          >Leg Limb Missing
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      style="width: 25%; padding-left: 15px; padding-right: 15px"
      *ngIf="patient.age !== 'Baby'"
    >
      <mat-label>Clothing State</mat-label>
      <mat-select [(ngModel)]="patient.clothingState">
        <mat-option
          value="No Clothing"
          (click)="updateClothingState('No Clothing')"
          >No Clothing</mat-option
        >
        <mat-option
          value="Underwear Only"
          (click)="updateClothingState('Underwear Only')"
        >
          Underwear Only
        </mat-option>
        <mat-option
          value="Pants Only"
          (click)="updateClothingState('Pants Only')"
        >
          Pants Only</mat-option
        >
        <mat-option
          value="Shirt Only"
          (click)="updateClothingState('Shirt Only')"
        >
          Shirt Only</mat-option
        >
        <mat-option
          value="Fully Clothed"
          (click)="updateClothingState('Fully Clothed')"
          >Fully Clothed
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="patient.age.toLowerCase() !== 'baby'"
      appearance="outline"
      style="width: 25%; padding-left: 15px; padding-right: 15px"
    >
      <mat-label> Accessories </mat-label>
      <mat-select [(ngModel)]="patient.accessory">
        <mat-option
          *ngFor="let accessory of accessories"
          [value]="accessory"
          (click)="updateAccessory(accessory)"
        >
          {{ accessory }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox
      *ngIf="patient.age.toLowerCase() !== 'baby'"
      style="padding-left: 15px"
      [(ngModel)]="patient.isHeadgearChecked"
      (click)="updateHeadware()"
      >Helmet</mat-checkbox
    >
  </section>
</section>
<div *ngIf="showLabs">
  <button mat-icon-button (click)="presentVitals = !presentVitals">
    <mat-icon *ngIf="presentVitals === false">keyboard_arrow_up</mat-icon>
    <mat-icon *ngIf="presentVitals === true">keyboard_arrow_down</mat-icon>
  </button>
  Vitals/Labs
  <section class="starting-vitals" *ngIf="presentVitals">
    <mat-tab-group mat-stretch-tabs animationDuration="0ms">
      <mat-tab label="Vitals">
        <div class="vitals">
          <div class="vitals-col col-1">
            <div>
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label> Cardiac Rhythm </mat-label>
                <mat-select
                  [(ngModel)]="patient.InitialClinicalData.cardiacRhythm"
                >
                  <mat-option
                    *ngFor="
                      let crd of clinicalControllerService.getCardiacRhythmDescriptions
                    "
                    [value]="
                      clinicalControllerService.getCardiacRhythmValue(crd)
                    "
                    >{{ crd }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.HR"
                name="HR"
                [gender]="this.GetGenderOnly()"
                canEdit="true"
              ></app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.RR"
                name="RR"
                [gender]="this.GetGenderOnly()"
                canEdit="true"
              ></app-slider>
            </div>

            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.SaO2"
                name="SaO2"
                [gender]="this.GetGenderOnly()"
                canEdit="true"
              ></app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.ETCo2"
                name="ETCo2"
                [gender]="this.GetGenderOnly()"
                canEdit="true"
              ></app-slider>
            </div>
          </div>
          <div class="vitals-col col-2">
            <div>
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label> Capnography </mat-label>
                <mat-select
                  [(ngModel)]="patient.InitialClinicalData.respiratoryWaveform"
                >
                  <mat-option
                    *ngFor="
                      let wfrm of clinicalControllerService.getRespiratoryWaveformDescriptionArray
                    "
                    [value]="
                      clinicalControllerService.getRespiratoryWaveformValue(
                        wfrm
                      )
                    "
                    >{{ wfrm }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.sBP"
                name="SBP"
                [gender]="this.GetGenderOnly()"
                canEdit="true"
              ></app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.dBP"
                name="DBP"
                [gender]="this.GetGenderOnly()"
                canEdit="true"
              ></app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.temp"
                name="temp"
                [gender]="this.GetGenderOnly()"
                canEdit="true"
              ></app-slider>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="CBC">
        <div class="vitals">
          <div class="vitals-col col-1">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.whiteBloodCount"
                name="whiteBloodCount"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.Hematocrit"
                name="Hematocrit"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
          <div class="vitals-col col-2">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.Hemoglobin"
                name="Hemoglobin"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.platelets"
                name="platelets"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="BMP">
        <div class="vitals">
          <div class="vitals-col col-1">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.Sodium"
                name="Sodium"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.potassium"
                name="potassium"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.chloride"
                name="chloride"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.bicarbonate"
                name="bicarbonate"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
          <div class="vitals-col col-2">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.bloodUreaNitrogen"
                name="bloodUreaNitrogen"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.creatinine"
                name="creatinine"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.glucose"
                name="glucose"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.calcium"
                name="calcium"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="FSG">
        <div class="vitals">
          <div class="vitals-col col-1">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.FSG_glucose"
                name="FSG_glucose"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
          <div class="vitals-col col-2">
            <!-- empty column in ase any future values are added-->
          </div>
        </div>
      </mat-tab>
      <mat-tab label="ABG/VBG">
        <div style="padding: 16px">
          <div style="margin-bottom: 10px">Draw Type:</div>
          <br />
          <mat-radio-group
            aria-label="draw type"
            [(ngModel)]="patient.InitialClinicalData.drawType"
          >
            <mat-radio-button
              [value]="patientControllerService.getABGVBG_DrawType('ABG')"
              (click)="
                patientControllerService.setABGVBG_DrawType_Data(
                  patient,
                  patientControllerService.getABGVBG_DrawType('ABG')
                )
              "
              >ABG
            </mat-radio-button>
            <mat-radio-button
              [value]="patientControllerService.getABGVBG_DrawType('VBG')"
              (click)="
                patientControllerService.setABGVBG_DrawType_Data(
                  patient,
                  patientControllerService.getABGVBG_DrawType('VBG')
                )
              "
              style="margin-left: 16px"
            >
              VBG
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="vitals" *ngIf="patient.InitialClinicalData.drawType === 0">
          <div class="vitals-col col-1">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.pH"
                name="pH"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.pa02"
                name="pa02"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.paCO2"
                name="paCO2"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.lactate"
                name="lactate"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
          <div class="vitals-col col-2">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.bicarb"
                name="bicarb"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.bE"
                name="bE"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.lab_saO2"
                name="lab_saO2"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div></div>
          </div>
        </div>
        <div class="vitals" *ngIf="patient.InitialClinicalData.drawType === 1">
          <div class="vitals-col col-1">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.pH"
                name="pH"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.pa02"
                name="pa02"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.paCO2"
                name="paCO2"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.lactate"
                name="lactate"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
          <div class="vitals-col col-2">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.bicarb"
                name="bicarb"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.bE"
                name="bE"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.lab_saO2"
                name="lab_saO2"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div></div>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        class="Equipment"
        *ngIf="this.patient.age.toLocaleLowerCase() !== 'baby'"
        label="Equipment"
      >
        <h2>Ventilator Values</h2>
        <div class="vitals">
          <div class="vitals-col col-1">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.PIP"
                name="PIP"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.Vt"
                name="Vt"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
          <div class="vitals-col col-2">
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.PPLAT"
                name="PPLAT"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
            <div>
              <app-slider
                [(data)]="patient.InitialClinicalData.MAP"
                name="MAP"
                [gender]="patientControllerService.gender"
                canEdit="true"
              >
              </app-slider>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>
