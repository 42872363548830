import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'FirstLetterUppercase' })
export class FirstLetterUppercase implements PipeTransform {
  transform(value: string): string {
    const captureStrs = value.split(" "); 
    let newStr = "";
    captureStrs.forEach(capturedString => {
      newStr += capturedString.slice(0,1) + capturedString.slice(1, capturedString.length).toLocaleLowerCase() + " ";
    })

    newStr = newStr.slice(0,newStr.length-1);

    return newStr;
  }
}