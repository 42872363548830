<div class="card">
  <div class="card-header-wrapper">
    <div class="card-header-buttons">
      <mat-icon style="color:white;" (click)="onClickDownload($event)">get_app</mat-icon>
      <mat-icon style="color:white;" 
        [matMenuTriggerFor]="beforeMenu"  
      >more_vert</mat-icon>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <!-- <button mat-menu-item>
          <mat-icon>tablet_android</mat-icon><span>Export to Device</span>
        </button> -->
        <!-- <button mat-menu-item (click)="onRenameFile()">
          <mat-icon>content_copy</mat-icon><span>Rename</span>
        </button> -->
        <button mat-menu-item (click)="onDeleteFile()">
          <mat-icon>delete</mat-icon><span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="wrapper" *ngIf="this.audioIconIndex === 0">
    <mat-icon class="icon-styles">graphic_eq</mat-icon>
  </div>
  <div class="wrapper" *ngIf="this.audioIconIndex === 1">
    <mat-icon class="icon-styles">description</mat-icon>
  </div>
  <div class="wrapper" *ngIf="this.audioIconIndex === 2">
    <mat-icon class="icon-styles">picture_as_pdf</mat-icon>
  </div>

  <div class="bottom-wrapper">
    <div class="title">
      {{ this.fileName.split("/")[this.fileName.split("/").length - 1] }}
    </div>
    <div class="type" *ngIf="this.audioIconIndex === 0">Audio File</div>
    <div class="type" *ngIf="this.audioIconIndex === 1">Doc/Docx File</div>
    <div class="type" *ngIf="this.audioIconIndex === 2">PDF File</div>
  </div>
</div>