import { Injectable } from '@angular/core';
import { RolesService } from './roles.service';
import { Role } from '../models/role.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  public permissionLevel: Role = null;
  public isInstructor: boolean = false;

  constructor(private roleService: RolesService) { }
}

export enum Permissions {
  None = 0,
  Create = 1,
  Read = 2,
  Update = 4,
  Delete = 8,
}
