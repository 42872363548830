

<div class="mat-elevation-z8" style="height: 70px">
  <div
    style="
      font-family: OpenSansLight;
      font-size: xx-large;
      padding-left: 25px;
      padding-top: 27px;
      padding-bottom: auto;
    "
  >
    Dashboard
  </div>
</div>
<br />
<div style="padding-left: 25px; padding-right: 25px">
  <div class="dashboard-content">
    <mat-tab-group
      [selectedIndex]="selected.value"
      (selectedTabChange)="this.onShowButton($event)"
      animationDuration="0ms"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      mat-line
    >
      <mat-tab style="overflow: hidden;" id="your-scenarios-tab" label="Your Scenarios">
        <hr  style="margin-top: 0px;"/>
        <div class="tab-content">
          <div
            class="spinnerHolder"
            *ngIf="userSimulationLoading; else displayUserSim"
          >
            <mat-spinner [diameter]="500" [strokeWidth]="10"></mat-spinner>
          </div>
          <div >
            <app-search
              [searchArray]="this.scenariosSearchContainers"
              (searchArrayChange)="onSearchArrayChange($event)"
              [showTag]="true"
              [isYourScenarioTab]="true"
            ></app-search>
            <hr  style="margin-top: 0px;"/>
          </div>
          <ng-template  #displayUserSim>

            <mat-paginator
              class="paginator-container"
              #paginatoryourscenario
              [length]="this.scenarioSearchResult.length"
              [pageSize]="10"
              [pageSizeOptions]="[10, 15, 20]"
              (page)="OnPageChange($event)"
            ></mat-paginator>
            <div
              class="card-list"
              *ngIf="this.scenariosSearchContainers.length !== 0"
            >
              <div *ngFor="let searchResult of this.searchResultSlice">
                <app-scenario-card
                  [teamSimulation]="false"
                  [scenario]="searchResult.object"
                  (itemRemoved)="updateScenarioList()"
                  (teamSimulationAdded)="updateTeamList()"
                  [iscommunity]="false"
                  [shared]="false"
                >
                </app-scenario-card>
              </div>
            </div>
          </ng-template>
        </div>

        <a [routerLink]="['/scenario-editor']" class="btn-add-scenario">
          <mat-icon style="padding: 0px 7px 0px 0px"> note_add</mat-icon>New
          Scenario</a
        >
      </mat-tab>

      <mat-tab id="team-scenarios-tab" *ngIf="ServerOnline">
        <ng-template mat-tab-label>
          <span> Team Scenarios</span>
        </ng-template>
        <hr  style="margin-top: 0px;"/>

        <div class="tab-content">
          <div
            class="spinnerHolder"
            *ngIf="remoteSimulationLoading; else displayRemoteSimulation"
          >
            <mat-spinner [diameter]="500" [strokeWidth]="10"></mat-spinner>
          </div>
          <ng-template #displayRemoteSimulation>
            <div
              *ngIf="this.remoteSearchContainers?.length === 0"
              class="center-circle"
            >
              <div class="wrapper">
                <div class="content">
                  <p>You or your team have not shared any scenario yet.</p>
                </div>
              </div>
            </div>
            <app-search
              [searchArray]="remoteSearchContainers"
              (searchArrayChange)="onSearchTeamArrayChange($event)"
              [showTag]="true"
              [authors]="this.teamAuthors"
              [isYourScenarioTab]="false"
            ></app-search>
            <mat-paginator
              class="paginator-container"
              #paginatorteamscenario
              [length]="this.remoteSearchResult.length"
              [pageSize]="10"
              [pageSizeOptions]="[10, 15, 20]"
              (page)="OnTeamPageChange($event)"
            ></mat-paginator>
            <div class="card-list" *ngIf="remoteSearchResult.length !== 0">
              <div *ngFor="let searchResult of teamsearchResultSlice">
                <app-scenario-card
                  [teamSimulation]="true"
                  [iscommunity]="false"
                  [shared]="true"
                  [scenario]="searchResult.object"
                  (itemRemoved)="updateScenarioList()"
                >
                </app-scenario-card>
              </div>
            </div>
          </ng-template>
        </div>
      </mat-tab>

      <mat-tab id="community-scenario-tab" *ngIf="ServerOnline">
        <ng-template mat-tab-label>
          <span> Community Scenarios</span>
        </ng-template>
        <hr  style="margin-top: 0px;"/>
        <div class="tab-content">
          <div
            class="spinnerHolder"
            *ngIf="communitySimulationLoading; else displaypublicSimulation"
          >
            <mat-spinner [diameter]="500" [strokeWidth]="10"></mat-spinner>
          </div>
          <ng-template #displaypublicSimulation>
            <div
              *ngIf="communitySearchResult?.length === 0"
              class="center-circle"
            >
              <div class="wrapper">
                <div class="content">
                  <p>There is no Public scenario to Show</p>
                </div>
              </div>
            </div>
            <app-search
              [searchArray]="communityScenarioSearchContainers"
              (searchArrayChange)="onSearchCommunityArrayChange($event)"
              [showTag]="true"
              [authors]="this.communityAuthors"
              [isYourScenarioTab]="false"
            ></app-search>
            <mat-paginator style="background-color: none;"
              class="paginator-container"
              #paginatorcommunityscenario
              [length]="this.communitySearchResult.length"
              [pageSize]="10"
              [pageSizeOptions]="[10, 15, 20]"
              (page)="OnCommunityPageChange($event)"
            ></mat-paginator>
            <div class="card-list" *ngIf="communitySearchResult.length !== 0">
              <div *ngFor="let searchResult of communitySearchResultSlice">
                <app-scenario-card
                  [teamSimulation]="false"
                  [shared]="true"
                  [iscommunity]="true"
                  [scenario]="searchResult.object"
                  (itemRemoved)="updateScenarioList()"
                >
                </app-scenario-card>
              </div>
            </div>
          </ng-template>
        </div>
      </mat-tab>

      <!-- <mat-tab label="Files">
      <div class="tab-content">

        <div class="spinnerHolder" *ngIf="userFilesLoading; else showUserFiles">
          <mat-spinner [diameter]="500" [strokeWidth]="10"></mat-spinner>
        </div>
        <ng-template #showUserFiles>

          <div *ngIf="fileSearchContainers.length === 0" class="center-circle">
            <div class="wrapper">
              <div class="content">
                <p>You haven’t upload any custom files yet.</p>
              </div>
            </div>
          </div>
          <app-search
            [searchArray]="fileSearchContainers"
            (searchArrayChange)="fileSearchResult = $event"
            [showTag]='false'
          ></app-search>
          <div class="card-list" *ngIf="fileSearchContainers.length !== 0">
            <div *ngFor="let searchResult of this.fileSearchResult">
              <app-file-card [file]="searchResult.object" (RequestChangeNotification)="refreshFileList()"></app-file-card>
            </div>
          </div>

        </ng-template>
      </div>
    </mat-tab> -->
    </mat-tab-group>

    <hr />
  </div>
</div>
