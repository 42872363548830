<div class="dialog-container-header">
  <div mat-dialog-title>
    <h2>Auscultation Settings</h2>
    <h3>Adjust the settings for the Auscultation procedure</h3>
  </div>

  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div style="display: flex; flex-direction: row; width: 100%;">
<div style="width: 70%;"><img style="width: 100%" src="../../../../assets/Images/AuscultationLocations.png" loading="lazy" alt="Image of Auscultation Points"/></div>
<div style="display: flex; flex-direction: column; height: 500px; overflow-y: auto; width: 30%;">
  <app-auscultation-point
    [label]="'Point A'"
    [(auscultation)]="this.data.clinical.Auscultations.PointA"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point B'"
    [(auscultation)]="this.data.clinical.Auscultations.PointB"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point C'"
    [(auscultation)]="this.data.clinical.Auscultations.PointC"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point D'"
    [(auscultation)]="this.data.clinical.Auscultations.PointD"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point E'"
    [(auscultation)]="this.data.clinical.Auscultations.PointE"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point F'"
    [(auscultation)]="this.data.clinical.Auscultations.PointF"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point G'"
    [(auscultation)]="this.data.clinical.Auscultations.PointG"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point H'"
    [(auscultation)]="this.data.clinical.Auscultations.PointH"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point I'"
    [(auscultation)]="this.data.clinical.Auscultations.PointI"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point J'"
    [(auscultation)]="this.data.clinical.Auscultations.PointJ"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point K'"
    [(auscultation)]="this.data.clinical.Auscultations.PointK"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
  <app-auscultation-point
    [label]="'Point L'"
    [(auscultation)]="this.data.clinical.Auscultations.PointL"
    [options]="this.auscultationFiles"
  ></app-auscultation-point>
</div>
</div>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>

  <button
    mat-button
    class="green-focus"
    [mat-dialog-close]="this.data.clinical"
  >
    Submit Changes
  </button>
</mat-dialog-actions>
