<!-- <div class="dialog-container"> -->
    <h2 mat-dialog-title>Phase Title and Goal</h2>
    <!-- <hr> -->
    <mat-dialog-content style="height:500px">
        <div *ngFor="let phase of phases" style="padding: 10px 0">
            <div *ngIf="phase.edit; else showReady">
                <mat-form-field appearance="outline">
                    <mat-label>Title</mat-label>
                    <input autocomplete="off" matInput placeholder="Phase Title" [(ngModel)]="phase.name" required>
                    <mat-error *ngIf="phase.name.length <= 0 || phase.name.length >= 300 ">Required Field</mat-error>
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline">
                    <mat-label>Goals</mat-label>
                    <textarea rows="10" matInput placeholder="Phase Goals" [(ngModel)]="phase.description" required></textarea>
                    <mat-error *ngIf="phase.description.length == 0">Required Field</mat-error>
                </mat-form-field>
            </div>

            <!-- class="dialog-container-row" -->
            <ng-template #showReady>
                <div class="ready">
                    <div>
                        <h3><B>{{phase.name}}</B></h3>
                        <p>{{phase.description}}</p>
                    </div>
                    <div class="edit-buttons">
                        <button mat-icon-button (click)="onEditPhase(phase)">
                            <mat-icon>create</mat-icon>
                        </button>
                        <button mat-icon-button (click)="onPressedDelete(phase)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="buttons">
            <button mat-button (click)="onAddPhase()" style="background-color: white; color: #1a844f; border: solid; border-color: #1a844f; border-radius: 0px; border: none;">Add Another Phase</button>
            <span>
                <button mat-button color="primary" mat-dialog-close (click)="onPressCancel()">Cancel</button>
                <button mat-button [disabled]="isValid" (click)="onCloseDialog()"
                 class="green-focus" >Done</button>
                <!-- <mat-error *ngIf="!isValid()">Please fill out all </mat-error> -->
            </span>
        </div>
    </mat-dialog-actions>
<!-- </div> -->