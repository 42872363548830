<div class="phase-card">
  <div style="padding: 7px 0px 7px 7px">
    <button mat-icon-button (click)="this.openCard = !this.openCard">
      <mat-icon *ngIf="!this.openCard" style="color: #1f7abe">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="this.openCard" style="color: #1f7abe">keyboard_arrow_up</mat-icon>
    </button>
  </div>
  <div class="info">
    <h3>{{ _phase.name }}</h3>
    <p>{{ _phase.description }}</p>
    <div *ngIf="this.openCard" class="symptoms-list">
        <div *ngFor="let symptom of this._phase.clinicalStates; let i = index">
            <div [ngClass]="{
                'symptom': i !== (this._phase.clinicalStates.length - 1),
                'symptom-last': i === (this._phase.clinicalStates.length - 1)
            }">
            <div class="number" (click)="onPlayAnimation(symptom)">{{i + 1}}</div>
            <h3>{{symptom.name}}</h3>
            <p>{{symptom.description}}</p>
        </div>

        </div>
        <div *ngIf="this._phase.clinicalStates.length === 0" style="color: #cccccc; text-align: center; vertical-align: middle; margin-top: 25px; width: 100%;">no clinical states</div>
    </div>
  </div>



</div>
