
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import info from '../../../../package.json';
import { loadFull } from "tsparticles";

import type { Container, Engine, ISourceOptions } from "tsparticles-engine";
import { JsonPipe } from '@angular/common';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  resetform: UntypedFormGroup;

  public isImpersonationenabled = false;

  public versionNumber: string = info.version;


  public resetPassword = false;
  public forgotPassword = false;
  public hide = true;

  public isErrorDisplayed = false;
  public isAccountDeactivated = false;
  public isAccountLocked = false;

  public isLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      impersonateUserInfo: ['']
    });

    this.resetform = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    if (info.impersonate == "1") {
      this.isImpersonationenabled = true;
    }
    else {
      this.isImpersonationenabled = false;
    }
    if (this.authService.isAuthenticated()) {
      this.router.navigateByUrl('/dashboard');
    }
    this.resetPassword = false;
    this.forgotPassword = false;
    this.hide = true;
  }
  async particlesInit(engine: Engine): Promise<void> {
    console.log("init", engine);

    await loadFull(engine);
  }

  public particlesLoaded(container: Container): void {
    console.log("loaded", container);
  }


  /**
   * Display the error message for valid or invalid email entry
   */
  getErrorMessage() {
    const emailFormField = this.resetform.controls.email;
    if (emailFormField.hasError('required')) {
      return `You must enter a value`;
    }
    return emailFormField.hasError('email') ? 'Not a valid email' : '';
  }

  /**
   * resets the login form
   */
  resetForm(): void {
    this.resetPassword = false;
    this.forgotPassword = false;
  }

  /**
   * gate to check for valid login credentials
   */
  login() {
    const val = this.form.value;
    this.isLoading = true;

    if (val.email && val.password) {
      this.authService
        .login(val.email, val.password, (err) => {
          this.isLoading = false;
          this.displayErrorMessage(err);
        })
        .subscribe(async (result) => {
          this.isLoading = false;
          var token:any=result;
          if (this.isImpersonationenabled) {
            if (val.impersonateUserInfo == undefined) {
              this.displayErrorMessage("please enter impersonation info");
              this.authService.logout();
            }
            else {
              (await this.authService.startImpersonation(val.impersonateUserInfo,token.access_token)).subscribe((result: string) => {
                if(result.includes('User not Found'))
                {
                  this.displayErrorMessage("Impersonation user does not exists");
                  this.authService.logout();
                  return;
                }
                this.authService.logout();
                this.authService.login(val.email, val.password, (err) => {
                  this.isLoading = false;
                  this.displayErrorMessage(err);
                }).subscribe(() => {
                  this.isLoading = false;
                  this.router.navigateByUrl('/dashboard');
                  if(!this.authService.isValidLoginToken())
                  {
                    this.displayErrorMessage("Database Error");
                    this.authService.logout();
                    this.router.navigateByUrl('/login');
                  }
                });

              }, (err) => {

                this.displayErrorMessage("Impersonation user does not exists");
                this.authService.logout();

              });

            }
          }
          else // normal successful login
          {
            this.router.navigateByUrl('/dashboard');
            if(!this.authService.isValidLoginToken())
            {
              this.displayErrorMessage("Database Error");
              this.authService.logout();
              this.router.navigateByUrl('/login');
            }
          }
        });
    } else {
      this.isLoading = false;
    }
  }

  /**
   * determines what error message to display back to the user, if 403, display account deactivated, else display incorrect user credentials
   * @param err the type of error that is being passed back to the user
   */
  displayErrorMessage(err: any) {
    this.isErrorDisplayed = false;
    this.isAccountDeactivated = false;
    this.isAccountLocked = false;

    switch (err.status) {
      case 403:
        this.isAccountDeactivated = true;
        break;
      case 429:
        this.isAccountLocked = true;
        break;
      default:
        this.isErrorDisplayed = true;
        break;
    }
  }

  /**
   * Checks weather or not to send reset password email by checking if form is valid
   */
  sendResetPassword(): void {
    if (!this.resetform.valid) {
      return;
    }
    this.authService
      .sendResetEmail(this.resetform.controls.email.value)
      .subscribe();
    this.resetPassword = !this.resetPassword;
  }

  //#region tsParticles settings
  id: 'tsparticles';
  particlesOptions = {
    background: {
      color: {
        value: 'transparent',
      },
    },
    fpsLimit: 60,
    interactivity: {
      detectsOn: 'canvas',
      events: {
        onClick: {
          enable: false,
          mode: 'push',
        },
        onHover: {
          enable: false,
          mode: 'repulse',
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 40,
        },
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: '#ffffff',
      },
      links: {
        color: '#ffffff',
        distance: 150,
        enable: true,
        opacity: 1,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        direction: 'none',
        enable: true,
        outMode: 'bounce',
        random: false,
        speed: 0.5,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          value_area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 1,
      },
      shape: {
        type: 'circle',
      },
      size: {
        random: true,
        value: 3,
      },
    },
    detectRetina: true,
  };
  //#endregion
}
