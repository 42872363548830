import { Injectable, Input, Output, EventEmitter } from '@angular/core';
import { scenario } from '../models/scenario.model';
import { patient } from '../models/patient.model';
import { FormControl } from '@angular/forms';
import { toolsservice } from '../editor/services/tools.service';

@Injectable({
  providedIn: 'root'
})
export class ScenarioControllerService {

  @Input() scenario: scenario;
  // @Output() ForceTabChange = new EventEmitter<string>();

  onAddPatient(newPatient: patient = null): void {
    if (newPatient === null) {
      newPatient = new patient(toolsservice.makeId(10), '', '', 0, '', 0, '', [], '');
    }
    this.scenario.patients.push(newPatient);
  }

  onRemovePatient(patient: patient): void {
    if (this.scenario.patients.length === 1) return;
    var index = this.scenario.patients.map(x => x.patientId).indexOf(patient.patientId);
    this.scenario.patients.splice(index, 1);
     
    // this.scenario.patients.splice(this.scenario.patients.indexOf(patient),1);
    toolsservice.ForceTabChange.emit("Delete");
  }

  get disableAddPatient(): boolean {
    return this.scenario.patients.length > 3;
  }

  constructor() { }
}
