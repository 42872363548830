import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { clinical } from 'src/app/models/statedata.model';

@Component({
  selector: 'app-procedures-hover-button',
  templateUrl: './procedures-hover-button.component.html',
  styleUrls: ['./procedures-hover-button.component.css']
})
export class ProceduresHoverButtonComponent implements OnInit {

  @Input() clinical: clinical;
  @Output() procedureEvent = new EventEmitter<{action:string, data: clinical}>();

  showPopupContent: boolean = false;
  showPopup: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showProceduresPopup(): void {
    this.showPopupContent = true;
  }

  showPopupF(): void{
    this.showPopupContent = false;
    this.showPopup = true;
  }

  onDeleteItem(): void {
    this.procedureEvent.emit({action:"Delete", data: this.clinical});
  }

  onEditItem(): void {
    this.procedureEvent.emit({action:"Update", data: this.clinical});
  }

}
