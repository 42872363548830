import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Simulation } from '../../../models/simulation.model';

export interface DialogData {
  name: string;
  description: string;
}

@Component({
  selector: 'app-dialog-new-simulations',
  templateUrl: './dialog-new-simulations.component.html',
  styleUrls: ['./dialog-new-simulations.component.scss']
})
export class DialogNewSimulationsComponent implements OnInit {

  simulations: Simulation[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogNewSimulationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    // TEMPORARY FILL FOR DESIGN
    // TODO: replace with real scenarios later
    for (let i = 0; i < 30; i++) {
      this.simulations.push(new Simulation(`fake_simulation_${i}`, `Gene`, new Date(), true, 'PICTURE PLACEHOLDER', '', false));
    }
  }

  onClickSimulation(): void {

  }

}
