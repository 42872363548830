import { Component, OnInit } from '@angular/core';
import { AudioFileSelection, audioType } from 'src/app/models/AudioFileSelections.model';
import { fileType, UploadService } from 'src/app/services/upload.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from '../dialog-sounds/dialog-sounds.component';
import { toolsservice } from '../../services/tools.service';
import {   MatSnackBar } from '@angular/material/snack-bar';

declare var MediaRecorder: any;
const approvedMimeType = [
  "audio/ogg",
  "audio/webm",
  "audio/mp3",
  "audio/wav",
 ];

@Component({
  selector: 'app-dialog-upload-files',
  templateUrl: './dialog-upload-files.component.html',
  styleUrls: ['./dialog-upload-files.component.scss'],
})
export class DialogUploadFilesComponent implements OnInit {
  public fileArray: Array<File> = [];
  public documentFileArray: Array<File> = [];

  public uploadedFiles: string[] = [];
  public uploadedDocumentFiles: string[] = [];

  public acceptedFileTypes: string[] = [
    '.ogg',
    '.mp3',
    '.wav',
    '.m4a'
  ];
  public acceptedDocumentFileTypes: string[] = [
    '.pdf',
    '.docx',
    '.doc',
    '.txt',
  ];

  public recordBlob: any;

  public audioFileSelection: AudioFileSelection = new AudioFileSelection();

  //#region audio variables
  public recordName = '';
  public recordSource = '';
  public recordCharacterType: string;
  public recordAuscultation: string;
  public recordNameControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^[\\w \\-.\\(\\)]+$'),
  ]);
  public matcher = new MyErrorStateMatcher();
  public showCharacterType = false;
  public showAuscultationType = false;
  public isRecording = false;
  public recordSrc = new Audio();
  public recordUrl: any;

  private record: any;
  private recordMimeType: string;
  //#endregion

  constructor(
    private uploadService: UploadService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getAudioUploadFiles();
    this.getDocumentUploadFiles();
  }

  public onDocumentFilesDropped(files: Array<File>) {
    files.forEach((element) => {
      this.uploadService.upload(element, fileType.File, null).subscribe({
        error: (err) => this.getDocumentUploadFiles(),
        complete: () => this.getDocumentUploadFiles(),
      });
    });
  }

  public onSoundFilesDropped(files: Array<File>) {
    // in this case we'll upload them to the server. We'll default this to Voice,
    // and then later let the user change to other category if needed.
    files.forEach((x) => {
      this.fileArray.push(x);
    });
    // this.uploadToServer(this.newSoundName, this.selectedSoundType, blob);
  }

  public getDocumentUploadFiles() {
    this.uploadedDocumentFiles = [];
    this.uploadService.getFileNames().subscribe((object) => {
      this.uploadedDocumentFiles = object;
    });
  }

  public getAudioUploadFiles() {
    this.uploadedFiles = [];
    this.uploadService.getCustomSounds().subscribe((object) => {
      for (const x of Object.keys(object)) {
        for (const y of Object.keys(object[x])) {
          for (const z of Object.keys(object[x][y])) {
            this.uploadedFiles.push(object[x][y][z]);
          }
        }
      }
    });
  }

  public removeItemFromList(file: File) {
    this.getAudioUploadFiles();
    this.fileArray.splice(this.fileArray.indexOf(file), 1);
  }

  public deleteDocumentItems(path: string) {
    this.uploadService.deleteFile(path).subscribe(() => {
      this.getDocumentUploadFiles();
    });
  }

  public deleteAudioItems(path: string) {
    this.uploadService.deleteUploadedAudio(path).subscribe(() => {
      this.getAudioUploadFiles();
    });
  }

  //#region Record Audio code

  /**
   * sets up the starting values for the drop down items
   * should be set to None for all
   */
  // private setUpStartingDropdownValues() {
  //   this.recordSource = this.audioFileSelection.getAudioValue(this.audioFileSelection.getAudioKeys()[0]);
  //   this.recordCharacterType = this.audioFileSelection.getCharacterValue(this.audioFileSelection.getCharacterKeys()[0]);
  //   this.recordAuscultation = this.audioFileSelection.getAuscultationValue(this.audioFileSelection.getCharacterKeys()[0]);
  // }

  public determineDisabled(): boolean {
    return (this.recordAuscultation === 'None' && this.recordCharacterType === 'None');
  }

  public toggleDropdownSelections() {
    switch (this.recordSource){
      case audioType.NONE:
        this.showCharacterType = false;
        this.showAuscultationType = false;
        this.recordCharacterType = audioType.NONE;
        this.recordAuscultation = audioType.NONE;
        break;
      case audioType.VOICE:
        this.showCharacterType = true;
        this.showAuscultationType = false;
        this.recordAuscultation = audioType.NONE;
        break;
      case audioType.AUSCULTATION:
        this.showCharacterType = false;
        this.showAuscultationType = true;
        this.recordCharacterType = audioType.NONE;
        break;
      default:
        this.showCharacterType = false;
        this.showAuscultationType = false;
        this.recordCharacterType = audioType.NONE;
        this.recordAuscultation = audioType.NONE;
    }
  }

  recordAudio(){
    const mediaConstraints = { video: false, audio: true };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then( stream => {



      let mimeType: string = null;
      for (var i in approvedMimeType) {
        if( MediaRecorder.isTypeSupported(approvedMimeType[i]) ){
          mimeType = approvedMimeType[i];
          break;
        }
      }

      if( !mimeType ){
        console.error("Sorry, your web browser does not support approved mimeType! Please try using different browser!");
        return;
      }

      const data: any = [];
      const options = {
        mimeType
      }
      const recorder = new MediaRecorder(stream, options);
      this.record = recorder;

      recorder.addEventListener('start', () => {
        data.length = 0;
      });

      recorder.addEventListener('dataavailable', event => {
        data.push(event.data);
      });

      recorder.addEventListener('stop', () => {
        this.isRecording = false;
        stream.getTracks().forEach((track) => track.stop());
        this.recordMimeType = this.record.mimeType;
        const blob = new Blob(data, {type: this.recordMimeType });
        this.processRecording(blob);
      });
      this.isRecording = true;
      recorder.start();
      console.log(this.record);
    });
  }

  /**
   * Stop recording
   */
  stopRecording() {
    this.record?.stop();
  }

  /**
   *
   * @param blob Audio data
   */
  processRecording(blob) {
    this.recordUrl = URL.createObjectURL(blob);
    this.recordSrc.src = this.recordUrl;
    this.recordBlob = blob;
  }

  publish(){
    // in this case here we'll push the changes to the server
    const ext = this.ext(this.recordMimeType);
    if( !ext ) {
      return;
    }
    const FileName = `${this.recordName}.${ext}`;
    const file = new File([this.recordBlob], FileName, { type: this.recordMimeType});
    const destination = this.showAuscultationType ?
                        this.recordAuscultation :
                        this.recordCharacterType;

    this.uploadService.uploadAudio(file, this.recordSource, destination )
    .subscribe(
      {
        error: (err) => {
          console.log(err);
          toolsservice.openSnackBar(this.matSnackBar, `Success! ${this.recordName} have been uploaded to the server!`, true);
          this.clearRecordSound();
          this.getAudioUploadFiles();
        },
        complete: () => {
          // clean up the fie stuff.
          toolsservice.openSnackBar(this.matSnackBar, `Success! ${this.recordName} have been uploaded to the server!`, true);
          this.clearRecordSound();
          this.getAudioUploadFiles();
        }
      }
    );
  }

  // for this sole purpose right now, we'll have it remapped here.
  // this will be used just for audio only!
  private ext( mimeType: string ): string{
    let key = '';
    if ( mimeType.indexOf(';') > -1 ) {
      key = mimeType.match(/\/(.*?)\;/)[1];
    } else {
      key = mimeType.match(/\/(.*)/)[1];
    }
    switch ( key ) {
      case 'ogg': return 'ogg';
      case 'mpeg': return 'mp3';
      case 'wav': return 'wav';
      case 'm4a': return 'm4a';
      case 'webm': return 'weba';
      default: return undefined;
    }
  }

  // private lookup(filename: string ): string{
  //   const list = filename.split('.');
  //   let ext = '';
  //   if ( list.length <= 1 ) { ext = filename.toLowerCase(); }
  //   else { ext = list[list.length - 1].toLowerCase(); }

  //   switch ( ext ) {
  //     case 'json': return 'application/json';
  //     case 'pdf': return 'application/pdf';
  //     case 'doc': return 'application/msword';
  //     case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  //     case 'ogg': return 'audio/ogg';
  //     case 'mp3': return 'audio/mpeg';
  //     case 'mp4': return 'video/mp4';
  //     case 'txt': return 'text/plain';
  //     case 'wav': return 'audio/wav';
  //     case 'm4a': return 'audio/m4a';
  //     case 'weba': return 'audio/webm';
  //     case 'webm': return 'video/webm';
  //     default: return undefined;
  //   }
  // }

  private clearRecordSound(){
    this.recordName = '';
    this.recordSource = '';
    this.recordAuscultation = 'None';
    this.recordCharacterType = 'None';
    this.recordBlob = null;
    this.record = null;
    this.recordUrl = null;
  }

  //#endregion
}

// TODO Bugs:
/**
 * Record button still shows available even after open dialog with blank state information
 * publish does not hide the character/organs
 */
