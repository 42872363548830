<div class="dialog-container-header">
  <h1 mat-dialog-title>Create New Scenario</h1>
  <button mat-icon-button alt="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content *ngIf="scenarioControllerService.scenario">
  <div class="body-item">
    <p>
      First, give your scenario a name and goal, then create up to 4
      scenario patients below.
    </p>
    <div>
      <div class="form-field">
        <mat-form-field appearance="outline" class="left">
          <mat-label>Scenario Name</mat-label>
          <input
            autocomplete="off"
            matInput
            placeholder="Placeholder"
            [(ngModel)]="scenarioControllerService.scenario.name"
            required
          />
        </mat-form-field>
      </div>

      <div class="form-field">
        <mat-form-field appearance="outline" class="right">
          <mat-label>Scenario Goal</mat-label>
          <input
            autocomplete="off"
            matInput
            placeholder="Placeholder"
            [(ngModel)]="scenarioControllerService.scenario.simulationGoal"
          />
        </mat-form-field>
      </div>
    </div>
    <mat-label>Run Time (In Minutes)</mat-label>
    <br>
    <app-custom-number-field [(value)]="scenarioControllerService.scenario.runtime" [steps]="1" [min]="0" [max]="999"></app-custom-number-field>
  </div>

  <mat-tab-group [(selectedIndex)]="selectedTabsIndex">
    <!-- Initial 1 patient must exist -->
    <mat-tab
      *ngFor="let patient of patients; let index = index"
      label="Patient {{ index + 1 }}"
    >
      <ng-template mat-tab-label>
        Patient {{ index + 1 }}
        <button
          mat-icon-button
          [disabled]="scenario.patients.length === 1"
          (click)="onRemovePatient(patient)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
      <app-add-patient
        [patient]="patient"
        [(namesArray)]="patientNames"
        [scenario]="scenarioControllerService.scenario"
      ></app-add-patient>
    </mat-tab>

    <mat-tab disabled>
      <ng-template mat-tab-label>
        <button
          mat-flat-button
          (click)="onAddPatient()"
          [disabled]="scenarioControllerService.disableAddPatient"
        >
          <mat-icon>add_box</mat-icon> Add Patient
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="scenarioControllerService.scenario">
  <button mat-button cdkFocusInitial class="cancel" mat-dialog-close>
    Cancel
  </button>
  <button
    mat-button
    [mat-dialog-close]="this.scenario"
    class="green-focus"
    alt="Create Simulation Button"
    [disabled]="isSubmitButtonDisabled()"
  >
    Create Scenario
  </button>
</mat-dialog-actions>
