import { Component, OnInit,  Inject } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface DialogData {
  teamName: string;
}

@Component({
  selector: 'app-dialog-edit-team-name',
  templateUrl: './dialog-edit-team-name.component.html',
  styleUrls: ['./dialog-edit-team-name.component.scss']
})
export class DialogEditTeamNameComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogEditTeamNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {


  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
