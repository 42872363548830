import { Component, Inject, OnInit } from '@angular/core';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Prompt {
  title: string;
  message: string;
  approve: string;
  reject: string;
}

@Component({
  selector: 'app-dialog-prompt',
  templateUrl: './dialog-prompt.component.html',
  styleUrls: ['./dialog-prompt.component.scss']
})
export class DialogPromptComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public promptData: Prompt ) {
    // if this is null, assign value by default
    if ( !promptData.approve ) {
      promptData.approve = 'Accept';
    }
    // if this is null, assign value by default
    if ( !promptData.reject ) {
      promptData.reject = 'Cancel';
    }
  }

  ngOnInit(): void { }
}
