<label>{{this.label}}</label>
<mat-form-field appearance="fill">
  <mat-label
    >Auscultation Sound for {{ this.label }}
  </mat-label>
  <mat-select [(value)]="this.auscultation" (valueChange)="this.emitChange()">
    <mat-option
      [value]="option"
      *ngFor="let option of options"
      >{{ option | CleanUpFileName }}</mat-option
    >
  </mat-select>
</mat-form-field>
