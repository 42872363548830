import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagSearchBoxComponent } from './tag-search-box/tag-search-box.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    TagSearchBoxComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    TagSearchBoxComponent,
  ],
})
export class TagSearchModule { }
