import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScenarioCardComponent } from './scenario-card/scenario-card.component';
import { SharedModule } from '../shared/shared.module';
import { DialogPreviewWindowComponent } from './dialogs/dialog-preview-window/dialog-preview-window.component';
import { PhaseCardModule } from 'src/app/phase-card/phase-card.module';
import { ObtainFilename } from 'src/app/custom-pipes/filename-obtainer.pipe';

import { EditorModule } from 'src/app/editor/editor.module';
import { FileCardComponent } from './file-card/file-card.component';
import { ApplicationPipesModuleModule } from '../custom-pipes/application-pipes-module/application-pipes-module.module';
import { RouterLink, RouterModule } from '@angular/router';
import { RenameScenarioComponent } from './dialogs/rename-scenario/rename-scenario.component';
import { DialogUploadFileComponent } from './dialogs/dialog-upload-file/dialog-upload-file.component';
import { DialogDeleteConfirmComponent } from './dialogs/dialog-delete-confirm/dialog-delete-confirm.component';



@NgModule({
  declarations: [ScenarioCardComponent, DialogPreviewWindowComponent, RenameScenarioComponent, FileCardComponent, DialogUploadFileComponent, DialogDeleteConfirmComponent],
  imports: [
    CommonModule,
    SharedModule,
    PhaseCardModule,
    EditorModule,
    ApplicationPipesModuleModule,
    RouterModule
  ],
  exports: [ScenarioCardComponent, FileCardComponent],
})

export class ScenarioCardModule { }
