<div class="hover-button" (mouseleave)="showPopup = false" (mouseover)="showPopup = true">
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
        style="transform: translateX(-50%);" (mouseover)="showPopupF()" (click)="showAudioPopupF()">
        <mat-icon style="color: #1f7abe">volume_up</mat-icon>
        <span style="color: #1f7abe; font-weight:bold">{{audioCount}}</span>
    </button>

    <div class="popup" *ngIf="showPopup">
        <div class="popup-notch">
        </div>
        <div class="transparent-top-part" (click)="showAudioPopupF()">
        </div>
        <div class="popup-content" *ngIf="!showPopupContent">
            <div style="font-size: small; font-weight: 500;">
                Sounds
                <div style="display: flex; margin-top: 6px;">
                    <audio #audioSrc></audio>
                    <div style="width: 155px;">
                        <strong>Auscultation</strong>
                        <div style="background-color: transparent; height: 100px;">

                            <div *ngIf="this.clinical.LungSound">
                                <!-- Playback function audioSrc.src=this.clinical.LungSound; audioSrc.play();-->
                                <mat-icon *ngIf="!isPlaying(audioSrc, this.clinical.LungSound)"
                                    (click)="onPlayAudio(this.clinical.LungSound, audioSrc)">
                                    play_circle_filled
                                </mat-icon>

                                <mat-icon *ngIf="isPlaying(audioSrc, this.clinical.LungSound)"
                                    (click)="audioSrc.pause()">
                                    pause_circle_filled
                                </mat-icon>

                                {{displayTitle(this.clinical.LungSound)}}
                            </div>

                            <div *ngIf="this.clinical.HeartSound">
                                <!-- Playback function -->
                                <mat-icon *ngIf="!isPlaying(audioSrc, this.clinical.HeartSound)"
                                    (click)="onPlayAudio(this.clinical.HeartSound, audioSrc)">
                                    play_circle_filled
                                </mat-icon>

                                <mat-icon *ngIf="isPlaying(audioSrc, this.clinical.HeartSound)"
                                    (click)="audioSrc.pause()">
                                    pause_circle_filled
                                </mat-icon>

                                {{displayTitle(this.clinical.HeartSound)}}
                            </div>

                        </div>
                    </div>
                    <div style="width: 155px">
                        <strong>Voices</strong>
                        <div *ngIf="this.clinical.VoiceSound">

                            <mat-icon *ngIf="!isPlaying(audioSrc,this.clinical.VoiceSound)"
                                (click)="onPlayAudio(this.clinical.VoiceSound, audioSrc)">
                                play_circle_filled
                            </mat-icon>

                            <mat-icon *ngIf="isPlaying(audioSrc,this.clinical.VoiceSound)" mat-fob color="primary"
                                (click)="audioSrc.pause()">
                                pause_circle_filled
                            </mat-icon>

                            {{displayTitle(this.clinical.VoiceSound)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup-content" *ngIf="showPopupContent">
            <button mat-flat-button style="color:#1f7abe" (click)="onEditSound()">
                <mat-icon>create</mat-icon>
                Edit
            </button>
            <button mat-flat-button style="color:#1f7abe" (click)="onDeleteItem()">
                <mat-icon>delete</mat-icon>
                Delete
            </button>
        </div>
    </div>
</div>