import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { patient } from 'src/app/models/patient.model';
import { scenario } from 'src/app/models/scenario.model';
import { PatientControllerService } from 'src/app/controllers/patient-controller.service';
import { ClinicalControllerService } from 'src/app/controllers/clinical-controller.service';
import {
  AbstractControl,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {   MatDialog } from '@angular/material/dialog';
import { toolsservice } from 'src/app/editor/services/tools.service';
import { DialogBabyWarningComponent } from 'src/app/editor/dialogs/dialog-baby-warning/dialog-baby-warning.component';
import { Alarms } from 'src/app/models/alarms.model';
import { DialogSelectImageComponent } from '../../dialogs/dialog-select-image/dialog-select-image.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss'],
})
export class AddPatientComponent implements OnInit {
  @Input() isEditMode = false;
  public cleanPhase = false;

  public doesPatientHaveImage = false;

  @Input() patient: patient;
  @Output() patientChange: EventEmitter<patient> = new EventEmitter();
  @Input() showLabs = true;

  @Input() scenario: scenario;
  public loggedPatientNames: string[] = [];
  @Output() public patientExist = false;

  @Input() namesArray: string[] = [];
  @Output() namesArrayChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  @Output() onPatientNotifyEvent = new EventEmitter<{
    action: string;
    patient: patient;
  }>();

  public clinicalControllerService: ClinicalControllerService = new ClinicalControllerService();
  public patientControllerService: PatientControllerService = new PatientControllerService();

  public alarms: Alarms = new Alarms();

  matcher = new MyErrorStateMatcher();
  patientNameFormControl: UntypedFormControl;
  public selectedImage: string = '';

  public accessories: string[] = ['none', 'goggles', 'sunglasses'];

  constructor(
    // private _scs: ScenarioControllerService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {}

  public presentVitals: boolean;

  ngOnInit(): void {
    this.presentVitals = false;
    this.patientControllerService.patient = this.patient;
    this.clinicalControllerService.clinical = this.patient.InitialClinicalData;
    this.selectedImage = this.patient.imageUrl;

    // check if the patient is in edit mode, (in the editor), if they are do not generate new information
    // fixes the bug where when editing an existing patient it auto generated new content.
    if (!this.isEditMode) {
      this.patientControllerService.patientNameGenerator(this.scenario);
    }

    if (this.scenario !== undefined) {
      if (this.scenario.patients !== undefined) {
        for (let patient of this.scenario.patients) {
          this.loggedPatientNames.push(patient.patientName);
        }
      }
    } else {
    }

    this.patientNameFormControl = new UntypedFormControl('', [
      Validators.required,
      this.alreadyExists(),
    ]);
  }

  // need either baby, female, or male to assign app-slider properly. If you know if there's a better way to do this, then be my guest. For now I'll check for string.
  public GetGenderOnly(): string {
    if( this.patient.age === 'Baby' )
      return this.patient.age.toLowerCase();
    else
      return this.patient.genderEthnicity.indexOf('Female') > -1 ? 'female' : 'male';
  }

  doesPatientNameAlreadyExist(newPatientName: string): void {
    this.patientExist = this.loggedPatientNames.includes(newPatientName);
  }

  public alreadyExists(): ValidatorFn {
    this.namesArray = [];

    if (this.scenario.patients !== undefined) {
      for (let patient of this.scenario.patients) {
        this.namesArray.push(patient.patientName.toLowerCase());
      }

      this.namesArray.splice(
        this.namesArray.indexOf(this.patient.patientName.toLowerCase()),
        1
      );

      this.namesArrayChange.emit(this.namesArray);
    }
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alreadyExists = this.namesArray.includes(
        (control.value as string).toLowerCase()
      );
      return alreadyExists ? { existsName: { value: control.value } } : null;
    };
  }

  public openBabyWarningPopup(age: string, patient: patient): void {
    const dialogRef = this.dialog.open(DialogBabyWarningComponent, {
      disableClose: true,
      // height: '447px',
      width: '447px',
      data: {
        name: 'testing baby',
        age: age,
      },
    });

    // TODO: result currently = true
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // understood, change to baby

        toolsservice.openSnackBar(
          this.snackbar,
          'Changed To Baby OR adult',
          true
        );
        this.patientControllerService.patient.phases = [];
        this.onPatientNotifyEvent.emit({
          action: 'Update',
          patient: this.patientControllerService.patient,
        });
        this.cleanPhase = true;
      } else {
        // NOT UNDERSTOOD, make sure it it still adult/geriatric

        this.patientControllerService.patient.age = 'Adult';
        this.patientControllerService.onClickAge(
          this.patientControllerService.patient.age,
          this.patientControllerService.patient
        );
        this.patientControllerService.patient.genderEthnicity = 'Wht_Female';
        this.patientControllerService.onClickGenderEthnicity(
          this.patientControllerService.patient.genderEthnicity,
          this.patientControllerService.patient
        );
        this.patientControllerService.patient.clothingState = 'Underwear Only';
        this.patientControllerService.onClothingStateClick(
          this.patientControllerService.patient,
          this.patientControllerService.patient.clothingState
        );
      }
    });
  }

  public tempAge = '';

  tryMe(age: string): void {
    this.tempAge = age;
  }

  private previousAge = '';

  // Determines what happens when the user selects the age option.
  public onClickAge(age: string, patient: patient): void {
    if (age.toLowerCase() === 'baby') {
      this.patientControllerService.patient.genderEthnicity = 'Wht';
      patient.age = age;
      this.patientControllerService.patient.age = age;
      this.patientControllerService.onClickGenderEthnicity(
        'Wht',
        this.patientControllerService.patient
      );
      this.cd.detectChanges();
      this.previousAge = 'baby';
    } else if (
      (age.toLowerCase() === 'adult' || age.toLowerCase() === 'geriatric') &&
      patient.age.toLowerCase() === 'baby'
    ) {
      patient.age = age;
      this.patientControllerService.patient.age = age;
      (patient.genderEthnicity = 'Wht_Female'),
        this.patientControllerService.onClickGenderEthnicity(
          this.patientControllerService.patient.genderEthnicity,
          this.patientControllerService.patient
        );
      this.patientControllerService.patient.clothingState = 'Underwear Only';
      this.patientControllerService.onClothingStateClick(
        this.patientControllerService.patient,
        this.patientControllerService.patient.clothingState
      );
      this.previousAge = '';
    } else if (age.toLowerCase() === 'adult') {
      this.patientControllerService.patient.age = age;
      patient.age = age;
    } else if (age.toLowerCase() === 'geriatric') {
      this.patientControllerService.patient.age = age;
      patient.age = age;
    }

    // check if need to open warning box. otherwise proceed as normal.
    if (this.isEditMode) {
      if (
        ((this.tempAge.toLowerCase() === 'adult' ||
          this.tempAge.toLowerCase() === 'geriatric') &&
          age.toLowerCase() === 'baby') ||
        (this.tempAge.toLowerCase() === 'baby' &&
          (age.toLowerCase() === 'adult' || age.toLowerCase() === 'geriatric'))
      ) {
        this.openBabyWarningPopup(age, patient);
        this.patientChange.emit(this.patient);
        return null;
      } else {
        this.patientControllerService.onClickAge(age, patient);
        this.patientChange.emit(this.patient);
      }
    } else {
      this.patientControllerService.onClickAge(age, patient);
      this.patientChange.emit(this.patient);
    }
    this.patientChange.emit(this.patient);
    return;
  }

  /**
   * Added functionality to work with two ways binding system, update gender and then emit changes
   */
  public updateGender(gender: string): void {
    this.patientControllerService.onClickGenderEthnicity(gender, this.patient);
    this.patientChange.emit(this.patient);
  }

  /**
   * Added functionality to work with two ways binding system, update clothing kind and then emit changes
   */
  public updateClothing(clothingType: string): void {
    this.patientControllerService.onClothingOptionClick(
      this.patient,
      clothingType
    );
    this.patientChange.emit(this.patient);
  }

  /**
   * Added functionality to work with two ways binding system, update amputation and then emit changes
   */
  public updateAmputation(limb: string): void {
    this.patientControllerService.onClickAmputation(this.patient, limb);
    this.patientChange.emit(this.patient);
  }

  /**
   * Added functionality to work with two ways binding system, update clothing state and then emit changes
   */
  public updateClothingState(state: string): void {
    this.patientControllerService.onClothingStateClick(this.patient, state);
    this.patientChange.emit(this.patient);
  }

  /**
   * Added functionality to work with two ways binding system, update accessory and then emit changes
   */
  public updateAccessory(misc: string): void {
    this.patientControllerService.onClickAccessorie(this.patient, misc);
    this.patientChange.emit(this.patient);
  }

  /**
   * Added functionality to work with two ways binding system, update Headware and then emit changes
   */
  public updateHeadware(): void {
    this.patientControllerService.onHeadgearChanged(this.patient);
    this.patientChange.emit(this.patient);
  }

  //#region patient image



  /**
   * call the dialog box popup for selecting an image for a patient
   */
  public onSelectImageForPatient(): void {
    const dialogRef = this.dialog.open(DialogSelectImageComponent, {
      data: {
        selectedImage: this.selectedImage
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if(!result){
        return;
      }

      this.selectedImage = result;
      this.doesPatientHaveImage = true;
      this.patient.imageUrl = this.selectedImage;
      this.patientControllerService.patient.imageUrl = this.patient.imageUrl;
      this.patientChange.emit(this.patient);
    });
  }

  //#endregion
}
