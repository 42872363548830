import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account/account.component';
import { SharedModule } from '../shared/shared.module';
import { DialogEditAccountInformationComponent } from './dialogs/dialog-edit-account-information/dialog-edit-account-information.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminModule } from '../admin/admin.module';

@NgModule({
  declarations: [AccountComponent, DialogEditAccountInformationComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule,
  ],
  exports: [AccountComponent],
})
export class AccountModule {}
