import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import * as configValue from '../../../assets/configs/ValueConfigs.json';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent implements OnInit {

  constructor() { }

  // required field
  @Input() data : number;
  @Output() dataChange : EventEmitter<number> = new EventEmitter<number>();
  
  // required variable name
  private _name = '';
  @Input() set name(value: string) {
    this._name = value;
    this.LoadConfigValues();
  }

  get name(): string {
    return this._name;
  }
  
  // optional : show unit of measure
  @Input() showUoM : boolean = true;
  // optional : can edit this value / show slider
  @Input() canEdit : boolean = false;
  // Show short version
  @Input() showShort : boolean = false;
  // inclusive Max allowed
  @Input() inclusiveMax : boolean = false;
  // inclusive Min allowed
  @Input() inclusiveMin : boolean = false;

  private _gender = '';
  // required gender of patient
  @Input() set gender(value: string ) {
    this._gender = value;
    // in this case here we have updated our gender list, we need to reload the data.
    this.LoadConfigValues();
  };

  get gender() : string {
    return this._gender;
  }
  
  // holds the unit of measure values
  uom : string = "";

  min : number = 0;

  max : number = 100;

  step : number = 1;

  defaultValue : number = 0;

  displayName : string;

  shortDisplayName : string;

  numberControl: UntypedFormControl = new UntypedFormControl("", [Validators.max(this.max), Validators.min(this.min)]);

  //#region Property

  // private variable for Min Normal
  private _minNormal : number;
  
  /**
   * MinNormal (Getter)
   */
  get minNormal() : number{
      return this._minNormal;
  }

  /**
   * MinNormal (Setter)
   * Contains rules to prevent out of bound ranges.
   */
  set minNormal(min : number ){
      this._minNormal = this.clamp( this.min, this.max, min);
  }

  // private variable for Max Normal
  private _maxNormal : number;

  /**
   * Max Normal ( Getter )
   */
  get maxNormal() : number{
      return this._maxNormal;
  }

  /**
   * Max Normal ( Setter )
   * Contains rules to prevent out of bound ranges.
   */
  set maxNormal(max : number ) {
      this._maxNormal = this.clamp( this.min, this.max, max);
  }

  //#endregion

  //#region implementation

  /**
   * Return the value based on the min/max value stored in the data model.
   */
  get value() : number { 
    return this.clamp( this.min, this.max, this.data ); 
  }  


  /**
   * Set the value restricted via the min/max model
   */
  set value( v : number ) { 
    this.data = this.clamp( this.min, this.max, v);
    this.dataChange.emit(this.data);
  }

  //#endregion

  clamp(min: number, max: number, value: number){
    return Math.min( Math.max( value, min ), max );
  }

  ngOnInit(): void {
    this.checkRequiredFields(this.gender);
    this.checkRequiredFields(this.name);
    this.LoadConfigValues();
  }

  private LoadConfigValues() {
    var valueList = null;
    switch (this.gender) {
      case "male": valueList = configValue.male; break;
      case "female": valueList = configValue.female; break;
      case "baby": valueList = configValue.baby; break;
      default:
        if( this.gender !== '' )
          console.error(`Unhandled case exception : ${this.gender}`);
        return;
    }

    var data = valueList.filter(x => x.name === this.name);
    if (data.length === 0) {
      console.error(`Unable to retrieve value information for this variable name ${this.name} - using default values instead!`);
      // automatically assign the min Normal range
      this.minNormal = this.min;

      // automatically assign the max Normal range
      this.maxNormal = this.max;
    }

    else {
      this.min = data[0].min;
      this.max = data[0].max;
      this.uom = data[0].uom;
      this.step = data[0].tick;
      this.minNormal = data[0].minNormal;
      this.maxNormal = data[0].maxNormal;
      this.displayName = data[0].displayName;
      this.shortDisplayName = data[0].shortDisplayName;
    }

    // enforce rule to ensure proper data is loaded for sanitation reason.
    if (this.min > this.max) {
      const temp = this.min;
      this.min = this.max;
      this.max = temp;
    }

    // in any cases we can set our default values to revert values back to.
    this.defaultValue = this.data;

    // if the display name is null, we will fill in the variable name instead to properly display the title
    if (this.displayName === undefined || this.displayName === null || this.displayName === "")
      this.displayName = this.name;
    if (this.shortDisplayName === undefined || this.shortDisplayName === null || this.shortDisplayName === "")
      this.shortDisplayName = this.displayName ?? this.name;
  }

  onClampValue(target){
    // if it goes out of the range, go ahead and update itself to clamp itself down.
    target.value = this.value;
  } 

  checkRequiredFields(input){
    if( input === null )
      throw new Error(`Attribute ${Object.keys(input)[0]} is required!`);
  }

  
  // John and Kevin have revised this task once more, the max needs to be exlusive. 
  // especially with saO2 max normal. 
  // made two variables to allow this value be adjustible by designer instead.
  isNormal(): boolean {
    const max = this.inclusiveMax ? this.value < this.maxNormal : this.value <= this.maxNormal;
    const min = this.inclusiveMin ? this.value > this.minNormal : this.value >= this.minNormal; 
    return max && min;
  }

  public reset(): void{
      this.data = this.defaultValue;
  }
}
