export class Draggable{
    constructor(public name: string, public category: string, public type: string){
    }
}

export class Symptom extends Draggable {

}

export class InstructorNote extends Draggable {

}

export class TreatmentPoint extends Draggable {

}