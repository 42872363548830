import { Component, OnInit, Inject, Input, Injector } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { toolsservice } from 'src/app/editor/services/tools.service';
import { phase } from 'src/app/models/phase.model';

export interface PhaseInfo {
  name: string;
  description: string;
}

@Component({
  selector: 'app-dialog-edit-phase',
  templateUrl: './dialog-edit-phase.component.html',
  styleUrls: ['./dialog-edit-phase.component.scss'],
})
export class DialogEditPhaseComponent implements OnInit {
  private originalPhase: phase;

  constructor(
    public dialogRef: MatDialogRef<DialogEditPhaseComponent>,
    @Inject(MAT_DIALOG_DATA) public editPhase: PhaseInfo | any
  ) {}

  // initialized - copy current phase as a backup to revert back to.
  ngOnInit(): void {
    // hmm ok so if I click cancel it needs to return the original value back...
    this.originalPhase = Object.create(this.editPhase);
  }

  // check and see if user have actually clear the phase name out. if they did this then we'll either prevent the user from doing so, or simply delete the phase (Would like to ask Gene/Sohail on intentional design behind this.)
  onDisableCheck(): boolean {
    return (
      toolsservice.isNullUndefinedEmpty(this.editPhase.name) || toolsservice.isNullUndefinedEmpty( this.editPhase.description )
    );
  }

  onAcceptClick() {
    // make sure that we're not allowing user to submit blank phases.
    // if( !this.onDisableCheck() )
    this.dialogRef.close(this.editPhase);
  }
}
