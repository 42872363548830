import { Device, DeviceType } from './device.model';
import { Channel } from './channel.model';

export enum DeviceMode {
    "General Purpose" = 0, 
    "Neonatal" = 1, 
    "Controller Pressure" = 2, 
    "Operating Room" = 3,
    "General Purpose II" = 4, 
    "Operating Room II" = 5
}

export class IVPump extends Device {
    public DeviceSettings : DeviceMode = 4
    public SetupLine = false;
    public ChannelA : Channel = new Channel();
    public ChannelB : Channel = new Channel();
    public ChannelC : Channel = new Channel();

    constructor(){
        super();
        this.kind = DeviceType.IVPump;
    }
}