import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CleanTimeline' })
export class CleanTimeline implements PipeTransform{
    transform(value: number): string {
        // minutes                 adding additional 0    seconds
        return `${Math.floor(value / 60)}:${value < 10 ? '0' : ''}${Math.floor(
            value % 60
        )}`;
    }
}