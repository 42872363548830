
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AudioFileSelection, audioType, characterType, auscultationType } from 'src/app/models/AudioFileSelections.model';
import { UploadService, fileType } from 'src/app/services/upload.service';

@Component({
  selector: 'app-audio-form',
  templateUrl: './audio-form.component.html',
  styleUrls: ['./audio-form.component.scss']
})
export class AudioFormComponent implements OnInit {

  @Input() blob: Blob;
  @Input() fileName: string;
  private file: File;
  public audioFileSelection: AudioFileSelection = new AudioFileSelection();

  @Output() completed: EventEmitter<string> = new EventEmitter();

  public selectedSource: string;
  public selectedCharacterType: string;
  public selectedAuscultation: string;

  public showCharacterType = false;
  public showAuscultationType = false;

  constructor(private uploadService: UploadService) {}

  ngOnInit(): void {
    this.file = new File([this.blob], this.fileName, { type: 'audio/ogg' });
    this.setUpStartingDropdownValues();
  }

  /**
   * sets up the starting values for the drop down items
   * should be set to None for all
   */
  private setUpStartingDropdownValues() {
    this.selectedSource = this.audioFileSelection.getAudioValue(this.audioFileSelection.getAudioKeys()[0]);
    this.selectedCharacterType = this.audioFileSelection.getCharacterValue(this.audioFileSelection.getCharacterKeys()[0]);
    this.selectedAuscultation = this.audioFileSelection.getAuscultationValue(this.audioFileSelection.getCharacterKeys()[0]);
  }

  public determineDisabled(): boolean { 
    return (this.selectedAuscultation === 'None' && this.selectedCharacterType === 'None');
  }

  public uploadToServer() {
    let destination = '';
    if(this.showAuscultationType){
      destination = this.selectedAuscultation;
    }else{
      destination = this.selectedCharacterType;
    }
    this.uploadService.uploadAudio(this.file, this.selectedSource, destination)
      .subscribe( 
        { 
          error: (err) => {

            this.completed.emit();
          },
          complete: () => {
              this.completed.emit()
          }
         }
      );
  }

  

  public toggleDropdownSelections() {
    switch(this.selectedSource){
      case audioType.NONE:
        this.showCharacterType = false;
        this.showAuscultationType = false;
        this.selectedCharacterType = audioType.NONE;
        this.selectedAuscultation = audioType.NONE;
        break;
      case audioType.VOICE:
        this.showCharacterType = true;
        this.showAuscultationType = false;
        this.selectedAuscultation = audioType.NONE;
        break;
      case audioType.AUSCULTATION:
        this.showCharacterType = false;
        this.showAuscultationType = true;
        this.selectedCharacterType = audioType.NONE;
        break;
      default:
        this.showCharacterType = false;
        this.showAuscultationType = false;
        this.selectedCharacterType = audioType.NONE;
        this.selectedAuscultation = audioType.NONE;
    }
  }
}
