<div class="grid-container">
  <!-- <div class="item1"></div> -->
  <!-- This is where unity player should place hold. -->
  <div #container>
    <!-- Append unity player here. -->
  </div>
  <app-scenarios-information
    class="item2"
    [(patientModel)]="patientModel"
    [isDeleteButtonDisabled]="DisableDeleteButton"
    (onPatientNotifyEvent)="OnPatientNotified($event)"
    [(scenario)]="scenario"
    [Tags]="Tags"
    style="width: 558px;"
  >
  </app-scenarios-information>
  <app-simulation-timeline class="item3"
  [(patient)]="patientModel"
  (phaseChanged)="onPhaseChanged($event)"
  style="
    height: 100%;
    min-width: 441px;
    margin-left: auto;
    width: 100%;
    margin-right: 30px;
  "
></app-simulation-timeline>
<app-symptoms-list class="item4"
  [(patient)]="patientModel"
  (SymptomsModified)="onSymptomsChanged()"
  style="
    height: 100%;
    min-width: 323px;
    margin-left: 10px;
  "
></app-symptoms-list>
</div>


