<div class="split">
    <div class="row">
        <p>Rate <em>( mL / h )</em></p>
        <app-custom-number-field 
            [value]="line.Rate"
            (valueChange)="updateTime($event)"
            [min]="0"
            [max]="999"
            [steps]="1"
            [disabled]="!IsVRandRate(this.Mode)"
        >
        </app-custom-number-field>
    </div>

    <div class="row">
        <p>Time Remaining <em>( Min )</em></p>
        <app-custom-number-field 
            [value]="line.TimeRemaining"
            (valueChange)="updateRate($event)"
            [min]="0"
            [max]="999"
            [steps]="1"
            [disabled]="IsVRandRate(this.Mode)"
        >
        </app-custom-number-field>
    </div>

    <div class="row">
        <p>Volume Remaining <em>( mL )</em></p>
        <app-custom-number-field 
            [value]="line.VolumeRemaining"
            (valueChange)="UpdateValue($event)"
            [min]="0"
            [max]="999"
            [steps]="1"
        >
        </app-custom-number-field>
    </div>

    <div class="row">
        <p>Volume Infused</p>
        <app-custom-number-field 
            [(value)]="line.VolumeInfused"
            [min]="0"
            [max]="999"
            [steps]="1"
        >
        </app-custom-number-field>
    </div>
</div>

<mat-form-field appearance="outline" class="center">
    <mat-label>Drug Label</mat-label>
    <input matInput [(ngModel)]="line.DrugLabel">
</mat-form-field>