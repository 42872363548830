import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toolsservice } from '../../services/tools.service';

export interface DialogData{
  name: string,
  description: string,
  time: number,
}

@Component({
  selector: 'app-dialog-scenario-edit',
  templateUrl: './dialog-scenario-edit.component.html',
  styleUrls: ['./dialog-scenario-edit.component.scss']
})
export class DialogScenarioEditComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogScenarioEditComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  isSubmitButtonDisabled(): boolean {
    return(toolsservice.isNullUndefinedEmpty(this.data.name));
  }

}
