import { Injectable } from '@angular/core';

import Baby from './Character-Parameters/Baby.json'
import Eth_Baby from './Character-Parameters/Eth_Baby.json'
import Eth_Female_Arm_Leg from './Character-Parameters/Eth_Female_Arm_Leg.json'
import Eth_Female_Arm from './Character-Parameters/Eth_Female_Arm.json'
import Eth_Female_Body from './Character-Parameters/Eth_Female_Body.json'
import Eth_Female_Leg from './Character-Parameters/Eth_Female_Leg.json'
import Eth_Male_Arm_Leg from './Character-Parameters/Eth_Male_Arm_Leg.json'
import Eth_Male_Arm from './Character-Parameters/Eth_Male_Arm.json'
import Eth_Male_Body from './Character-Parameters/Eth_Male_Body.json'
import Eth_Male_Leg from './Character-Parameters/Eth_Male_Leg.json'
import Eth_N_Baby from './Character-Parameters/Eth_N_Baby.json'
import Wht_Baby from './Character-Parameters/Wht_Baby.json'
import Wht_Female_Arm_Leg from './Character-Parameters/Wht_Female_Arm_Leg.json'
import Wht_Female_Arm from './Character-Parameters/Wht_Female_Arm.json'
import Wht_Female_Body from './Character-Parameters/Wht_Female_Body.json'
import Wht_Female_Leg from './Character-Parameters/Wht_Female_Leg.json'
import Wht_Male_Arm_Leg from './Character-Parameters/Wht_Male_Arm_Leg.json'
import Wht_Male_Arm from './Character-Parameters/Wht_Male_Arm.json'
import Wht_Male_Body from './Character-Parameters/Wht_Male_Body.json'
import Wht_Male_Leg from './Character-Parameters/Wht_Male_Leg.json'
import Wht_N_Baby from './Character-Parameters/Wht_N_Baby.json'
import { characterType } from '../models/AudioFileSelections.model';

export enum IDENTITY_KEY{
  Baby,
  Eth_Baby,
  Eth_Female_Arm_Leg,
  Eth_Female_Arm,
  Eth_Female_Body,
  Eth_Female_Leg,
  Eth_Male_Arm_Leg,
  Eth_Male_Arm,
  Eth_Male_Body,
  Eth_Male_Leg,
  Eth_N_Baby,
  Wht_Baby,
  Wht_Female_Arm_Leg,
  Wht_Female_Arm,
  Wht_Female_Body,
  Wht_Female_Leg,
  Wht_Male_Arm_Leg,
  Wht_Male_Arm,
  Wht_Male_Body,
  Wht_Male_Leg,
  Wht_N_Baby,
}

export enum IDENTITY_KEY_STRING{
  Baby = "Baby",
  Eth_Baby = "Eth_Baby",
  Eth_Female_Arm_Leg = "Eth_Female_Arm_Leg",
  Eth_Female_Arm = "Eth_Female_Arm",
  Eth_Female_Body = "Eth_Female_Body",
  Eth_Female_Leg = "Eth_Female_Leg",
  Eth_Male_Arm_Leg = "Eth_Male_Arm_Leg",
  Eth_Male_Arm = "Eth_Male_Arm",
  Eth_Male_Body = "Eth_Male_Body",
  Eth_Male_Leg = "Eth_Male_Leg",
  Eth_N_Baby = "Eth_N_Baby",
  Wht_Baby = "Wht_Baby",
  Wht_Female_Arm_Leg = "Wht_Female_Arm_Leg",
  Wht_Female_Arm = "Wht_Female_Arm",
  Wht_Female_Body = "Wht_Female_Body",
  Wht_Female_Leg = "Wht_Female_Leg",
  Wht_Male_Arm_Leg = "Wht_Male_Arm_Leg",
  Wht_Male_Arm = "Wht_Male_Arm",
  Wht_Male_Body = "Wht_Male_Body",
  Wht_Male_Leg = "Wht_Male_Leg",
  Wht_N_Baby = "Wht_N_Baby",
}


@Injectable({
  providedIn: 'root'
})
export class CharacterParamsManagerService {

  //list of character parameters to use and search through
  private CharacterParams: any [] = [];

  constructor() { 
    this.generateCharacterParameters();
  }

  /**
   * Finds a single entry in the collection associated with a character parameters data set.
   * @param name the name of the entry the user is searching for i.e. Baby, Eth_Female_Arm
   */
  public findEntryByName(name: string): any{
    return this.CharacterParams[IDENTITY_KEY[name]];
  }

  /**
   * Returns the list of available textures for the associated character parameters
   * @param name the name of the entry the user is searching for i.e Baby, Eth_Female_Arm
   */
  public getTexturesByEntry(name: string): string [] {
    const entry: any = this.findEntryByName(name);
     
    return entry["MonoBehaviour"]["_textureOptions"];
  }

  public getAnimOverlayTexturesByEntry(name: string): string [] {
    const entry: any = this.findEntryByName(name);
    return entry["MonoBehaviour"]["_textureOptions"]["_animOverlayTextures"];
  }

  public getBodyOverlayTexturesByEntry(name: string): string [] {
    const entry: any = this.findEntryByName(name);
    return entry["MonoBehaviour"]["_textureOptions"]["_bodyOverlayTextures"];
  }

  public getBodyTreatmentTexturesByEntry(name: string): string [] {
    const entry: any = this.findEntryByName(name);
    return entry["MonoBehaviour"]["_textureOptions"]["_bodyTreatmentTextures"];
  }

  public getEyeOverlayTexturesByEntry(name: string): string [] {
    const entry: any = this.findEntryByName(name);
    return entry["MonoBehaviour"]["_textureOptions"]["_eyeOverlayTextures"];
  }

  /**
   * Returns the list of available equipment for the associated character parameters
   * @param name the name of the entry the user is searching for i.e. Baby, Eth_Female_Arm
   */
  public getEquipmentByEntry(name: string): string [] {
    const entry: any = this.findEntryByName(name);
    return entry["MonoBehaviour"]["_itemOptions"]["equipments"]
  }

  /**
   * return pictures of all available equipment, does not store duplicate equipment
   */
  public getAllEquipment(): string []{
    let entries: string [] = [];
    let temp: string [] = [];
    this.CharacterParams.forEach(characterParam => {
      temp = this.getEquipmentByEntry(characterParam["MonoBehaviour"]["m_Name"])
      temp = temp.filter(tmp => !entries.find(entry => tmp === entry));
      entries.push(...temp);
    });

    return entries;
  }

  /**
   * return pictures of all available textures, does not store duplicate textures
   */
  public getAllTextures(): string []{
    let entries: string [] = []
    let temp: string [] = [];
    this.CharacterParams.forEach(characterParam => {
      this.getAnimOverlayTexturesByEntry(characterParam["MonoBehaviour"]["m_Name"]).forEach(animTexture => {
        temp.push(`_animOverlayTextures/${animTexture}`);
      });
      this.getBodyOverlayTexturesByEntry(characterParam["MonoBehaviour"]["m_Name"]).forEach(bodyTexture => {
        temp.push(`_bodyOverlayTextures/${bodyTexture}`);
      });
      this.getBodyTreatmentTexturesByEntry(characterParam["MonoBehaviour"]["m_Name"]).forEach(bodyTreatment => {
        temp.push(`_bodyTreatmentTextures/${bodyTreatment}`);
      });
      this.getEyeOverlayTexturesByEntry(characterParam["MonoBehaviour"]["m_Name"]).forEach(eyeTexture => {
        temp.push(`_eyeOverlayTextures/${eyeTexture}`);
      });
      temp = temp.filter(tmp => !entries.find(entry => entry === tmp));
      entries.push(...temp)
    });
    return entries;
  }

  /**
   * collect url for images of textures and equipments
   */
  public getAllTexturesAndEquipmentURLS(): string []{
    let entries: string [] = [];
    this.getAllEquipment().forEach(equipment => {
      entries.push(`./assets/Images/equipment/${equipment.replace(/(Left_|Right_|RightRight_|Wht_|White_|Eth_|Ethnic_|Male_|Female_|Bby_)/g,'')}.png`);
    });
    this.getAllTextures().forEach(texture => {
      entries.push(`./assets/Images/Textures/${texture.replace(/(Left_|Right_|RightRight_|Wht_|White_|Eth_|Ethnic_|Male_|Female_|Bby_)/g,'')}.PNG`);
    });
    return entries;
  }

  /**
   * return all textures and equipment 
   */
  public getAllTexturesAndEquipment(): string []{
    let entries: string [] = [];
    entries.push(...this.getAllEquipment());
    entries.push(...this.getAllTextures());
    return entries;
  }

  /**
   * Build a list of available character parameter data sets.
   */
  private generateCharacterParameters(): void{
    this.CharacterParams.push(Baby);
    this.CharacterParams.push(Eth_Baby);
    this.CharacterParams.push(Eth_Female_Arm_Leg);
    this.CharacterParams.push(Eth_Female_Arm);
    this.CharacterParams.push(Eth_Female_Body);
    this.CharacterParams.push(Eth_Female_Leg);
    this.CharacterParams.push(Eth_Male_Arm_Leg);
    this.CharacterParams.push(Eth_Male_Arm);
    this.CharacterParams.push(Eth_Male_Body);
    this.CharacterParams.push(Eth_Male_Leg);
    this.CharacterParams.push(Eth_N_Baby);
    this.CharacterParams.push(Wht_Baby);
    this.CharacterParams.push(Wht_Female_Arm_Leg);
    this.CharacterParams.push(Wht_Female_Arm);
    this.CharacterParams.push(Wht_Female_Body);
    this.CharacterParams.push(Wht_Female_Leg);
    this.CharacterParams.push(Wht_Male_Arm_Leg);
    this.CharacterParams.push(Wht_Male_Arm);
    this.CharacterParams.push(Wht_Male_Body);
    this.CharacterParams.push(Wht_Male_Leg);
    this.CharacterParams.push(Wht_N_Baby);
  }

}
