<p>Scenario Timeline</p>
<div class="window">
    <div *ngIf="phases?.length > 0; else addNewPhase">
        <app-phase 
            *ngFor="let phase of phases" 
            [patient]="patient" 
            [phase]="phase"
            (phaseEvent)="onPhaseEvent($event)"
            (symptomEvent)="onSymptomEvent($event)">
        </app-phase>
    </div>

    <ng-template #addNewPhase style="width:100%;height:100%">
        <!-- Can't this just be a giant button instead? -->
        <div class="btnNewPhase" (click)="onOpenDialog()">
            <div class="center-circle">+</div>
            <div class="centerText">Add a Phase to Begin</div>
        </div>
    </ng-template>

</div>
<div style="position: relative;">
    <button mat-raised-button class="round-corners" (click)="onOpenDialog()">Add Phase</button>
</div>