<!-- <div class="dialog-container"> -->
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h2 mat-dialog-title style="font-family: OpenSansLight; font-size: large;"> {{data.name}} </h2>
        <button mat-icon-button style="margin-left: auto; transform: translate(6px,-6px)" mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    <!-- <br> -->
    <p style="font-size: small; font-family: OpenSans;"> {{data.description}} </p>
    
    <div>
        <h2 style="font-family: OpenSansLight">Drop Down / Search / Tag Filter</h2>
    </div>

    <mat-dialog-content style="font-family: OpenSans">
    
        <div id="devicesScrollArea" style="overflow-y: scroll; max-height: 500px; background-color: transparent; margin-left: 27px; display: flex; flex-direction: row; flex-wrap: wrap; cursor: pointer;" (click)="onClickSimulation()">
                <div id="row" 
                     *ngFor="let simulation of simulations">
                    <div style="width: 325px; height: 336px; background-color: white; margin-left: 27px; margin-bottom: 37px;">
                        <div style="display: flex; flex-direction: row; justify-content: space-between; height: 138px; overflow: hidden; background-image: url(../../../assets/Images/helpers-six-month-medical-exam-HERO-2-768x569.jpg); background-position: center;">
                            <!-- <img src="../../../assets/Images/helpers-six-month-medical-exam-HERO-2-768x569.jpg" style="transform: translate(-200px, -400px);"> -->
                            <p></p>
                            <div style="margin-left: auto; margin-right: 4px; margin-top: 4px; height: 32px; width: 32px; background-color: white; opacity: 0.5; border: solid; border-color: transparent; border-width: 0px; border-radius: 100px; z-index: 2; text-align: right;">
                                <!-- <button mat-icon-button style="transform: translate(-4px, -4px);" [matMenuTriggerFor]="menu"><mat-icon style="color: black;">more_vert</mat-icon></button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item style="color: #616161;" (click)="onDownloadToAccount()"><mat-icon style="color: #616161;">get_app</mat-icon>Download to My Account</button>
                                    <button mat-menu-item style="color: #616161;" (click)="onRemoveSimulation(simulation)"><mat-icon style="color: #616161;">delete</mat-icon>Remove Simulation</button>
                                  </mat-menu> -->
                            </div>
                        </div>
                        <div style="background-color: white; border: solid; border-top: none; border-color: silver; border-width: 1px; min-height: 198px; max-height: 198px; padding: 16px; padding-bottom: 0px;font-family: OpenSans; overflow: hidden;">
                            <p style="font-family: OpenSansBold; color: #616161;">{{simulation.name}}</p>
                            <p style="font-family: OpenSansLight; font-size: small;">{{simulation.creator}}</p>
                            <div style="display: flex; flex-direction: row; justify-content: space-between; height: 40px; line-height: 40px;">
                                <div>
                                    <p>
                                        <mat-icon style="color: #ffc300;">grade</mat-icon>
                                        <mat-icon style="color: #ffc300;">grade</mat-icon>
                                        <mat-icon style="color: #ffc300;">grade</mat-icon>
                                        <mat-icon style="color: #ffc300;">grade</mat-icon>
                                        <mat-icon style="color: #eeeeee;">grade</mat-icon>
                                    
                                    </p>
                                </div>
                                <div style="font-size: small;">
                                    <i style="margin-top: auto;">Updated: MM/DD/YY</i>
                                </div>
                            </div>
                            <br>
                            <p style="font-size: small;">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    </mat-dialog-content>
    <!-- <br> -->
    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button mat-dialog-close [mat-dialog-close]="true">Upload Simulation(s)</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
<!-- </div> -->