import { statedata } from './statedata.model';
import { toolsservice } from '../editor/services/tools.service';

export class phase
{
    // variable only used in the dialog.... 
    edit : boolean = false; 
    // This checklist is invalid, it needs to be scenario globally.
    // public checklist : string[] = [];

    constructor(
        public phaseId : string,
        public name : string,
        public description : string,
        public index : number,
        public clinicalStates : statedata[]
    ){ 
        if( phaseId == null )
            this.phaseId = toolsservice.makeId();
    }
}