import { Injectable } from '@angular/core';
import { patient } from 'src/app/models/patient.model';

interface PhaseSymptomRelation {
  phaseName: string;
  clinicalName: string;
  clinicalAnimationName: string;
  clinicalID: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnimationTransitionService {
  private transitionPrettyList$: string[] = [];
  private transitionData$: string[] = [];

  /**
   * Pretty name shown the the end user format is: PhaseName - ClinicalName
   */
  public get transitionPrettyList(): string[] {
    return this.transitionPrettyList$;
  }

  /**
   * raw data saved to the controller format is: clinicalAnimationName_ClinicalStateID
   */
  public get transitionData(): string[] {
    return this.transitionData$;
  }

  constructor() {}

  /**
   * fills in a list of symptoms to be collected and maintained by the AnimationTransitionService
   * @param selectedPatient the patient that has the associated phases
   * @param currentStateId the associated stateID to be excluded from transition selection
   */
  getSymptoms(selectedPatient: patient, currentStateId: string) {
    this.clearLists();
    selectedPatient?.phases.forEach((p) =>
      p.clinicalStates
        .filter((s) => s.statedataId !== currentStateId)
        .filter((s) => s.name !== 'Treatment Point')
        .filter((s) => s.name !== 'Instructor Note')
        .forEach((s) => {
          const phaseSymptomRelation: PhaseSymptomRelation = {
            phaseName: p.name,
            clinicalName: s.name,
            clinicalID: s.statedataId,
            clinicalAnimationName: s.animation,
          };
          this.transitionPrettyList$.push(
            `${phaseSymptomRelation.phaseName} - ${phaseSymptomRelation.clinicalName}`
          );
          this.transitionData$.push(
            `${phaseSymptomRelation.clinicalAnimationName}_${phaseSymptomRelation.clinicalID}`
          );
        })
    );
  }

  /**
   * Clear the list of data
   */
  private clearLists() {
    this.transitionPrettyList$ = [];
    this.transitionData$ = [];
  }
}
