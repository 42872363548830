<h2 mat-dialog-title>Edit Patient</h2>
<mat-dialog-content>
  <app-add-patient
    [isEditMode]="true"
    [(patient)]="data.patient"
    [scenario]="data.scenario"
    [showLabs]="false"
  ></app-add-patient>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button
    mat-button
    class="green-focus"
    [mat-dialog-close]="data.patient"
    [disabled]="isSubmitButtonDisabled()"
  >
    Submit Changes
  </button>
</mat-dialog-actions>