<!-- <div class="dialog-container"> -->
    <h2 mat-dialog-title> Edit Scenario Title and Description/Goal? </h2>
    <!-- <hr> -->
    <mat-dialog-content>
        <div class="wrapper">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Scenario Name</mat-label>
                <input autocomplete="off" matInput [(ngModel)]="data.name" required>
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Scenario Goal / Description</mat-label>
                <textarea matInput [(ngModel)]="data.description"></textarea>
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <!-- <br> -->
    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-button class="green-focus" [mat-dialog-close]="data" [disabled]="isSubmitButtonDisabled()">Submit
                    Changes</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
<!-- </div> -->


<!--
  name: string;
  description: string;
  time: number;
-->