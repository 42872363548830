<div class="dialog-container">
    <h2 mat-dialog-title style="font-family: OpenSansLight;"> Invite New Team Member </h2>
    <!-- <hr> -->
    <mat-dialog-content>
      <form [formGroup]="frmInvite">
        <mat-label *ngIf="institutionName"> Institution Name:<b>  {{institutionName}}</b></mat-label>

        <br>
        <br>

        <!-- FIRST NAME: required -->
        <mat-label>First Name</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
            <input autocomplete="off" matInput placeholder="Lt. Col. Shane Runyon"  id="fname" formControlName="fname" [(ngModel)]="teamMember.fName"
           required>
            <mat-error *ngIf="frmInvite.controls.fname.invalid">member name required</mat-error>
        </mat-form-field>
        <!-- LAST NAME : required -->
        <mat-label>Last Name</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
            <input autocomplete="off" matInput placeholder="Lt. Col. Shane Runyon" id="lname" formControlName="lname"
                 required>
            <mat-error *ngIf="frmInvite.controls.lname.invalid">member name required</mat-error>
        </mat-form-field>

        <!-- EMAIL: required -->
        <mat-label>Email</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
            <input autocomplete="off" matInput placeholder="first.last@usgov.mil"  id="txtemail" formControlName ="txtemail"
                type="email"  required email>
            <mat-error *ngIf="frmInvite.controls.txtemail.invalid">valid email required</mat-error>
        </mat-form-field>
        <!-- USERNAME : required -->
        <mat-label>Username</mat-label>
        <mat-form-field appearance="outline" style="width: 100%">
            <input autocomplete="off" matInput placeholder="username" id="username" formControlName ="username" required>
            <mat-error *ngIf="frmInvite.controls.username.invalid">username required</mat-error>
        </mat-form-field>
        <!-- ROLE -->
        <mat-label>Role</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">

            <mat-select formControlName="roleId" >
                <mat-option   *ngFor="let r of roles" [value]="r._id" >{{ r.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <p style="font-size: small;">A password valid for 48 hours will be automatically generated and sent to the new
            team member inside the invite email.
            If the team member doesn’t sign in within 48 hours, you can reinvite them using the menu on that team
            member’s table row.</p>
        <br>
        <!-- <section>
            <mat-checkbox [(ngModel)]="checked" style="font-size: medium;">I understand this patient will be deleted forever</mat-checkbox>
        </section> -->
      </form>
    </mat-dialog-content>
    <br>
    <mat-dialog-actions>
        <div class="buttons">
            <span>
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button mat-dialog-close [mat-dialog-close]="teamMember"
                    style="background-color: #1a844f; color: white;"
                    [ngStyle]="{'background-color':isDisabled() ? 'grey' : '#1a844f'}"
                    [disabled]="isDisabled()"
                    (click)="saveValues()" >Save</button>
            </span>
        </div>
    </mat-dialog-actions>
</div>
