import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, async } from 'rxjs';
import { User } from '../models/user.model';
import { ServerService } from '../services/Server.service';
import { AuthService } from '../auth/auth.service';

export interface prettyName {
  userId:string,
  fullName:string
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public prettyNamesArray: prettyName [] = [];

  constructor(private http: HttpClient,private authService:AuthService ) {

  }

  /**
   * Refresh userlist
   */
  refreshUserList()
  {
    this.prettyNamesArray = [];
    this.prettyNames().subscribe(obj => {
      if ( obj ) {
        obj.forEach( (element: any) => {
          this.prettyNamesArray.push(element);
        });
      }
    });
  }

  /**
   * Get all user based on your institution Id
   * Returns User[] Observable are all async operands.
   */
  getAll() : Observable<any> {
    return this.http.get(ServerService.User_URL);
  }
  getAllNoFilter() : Observable<any> {
    return this.http.get(ServerService.User_All_URL);
  }
  /**
   * create a new user
   * Requires the following data: fullname, email, roleId
   */
  create( data : User ) : Observable<any>{
    return this.http.post(ServerService.User_URL, {
        fName : data.fName,
        lName : data.lName,
        email : data.email,
        roleId : data.roleId,
        userName : data.userName,
        institutionId:data.institutionId,
      });
  }

  /**
   * Return the user you search
   * @param id User._id
   *
   * Returns User model
   */
  get( id : string ) : Observable<any> {
    return this.http.get(`${ServerService.User_URL}/${id}`);
  }

  /**
   * update selected user / Archive user here as well
   * Return nulls
   */
  update( data : User ): Observable<any> {
    return this.http.put(`${ServerService.User_URL}/${data._id}`, data);
  }
  resetPassword(newPass:string,token:string):Observable<any>
  {
    var data= {newPassword:newPass};
    return this.http.post(ServerService.ResetPass_URL(token), data,{ responseType: 'text' });
  }
  reInvite(data:User): Observable<any>
  {
    const idToken = this.authService.getToken();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : ServerService.User_ReInviteURL,
      'Authorization':"Bearer " + idToken
    });

    return this.http.post(ServerService.User_ReInviteURL,data,{ headers: httpHeaders });
  }

  /**
   * delete current user.
   * Return nulls
   */
  delete( id : string ) : Observable<any> {
    return this.http.delete(`${ServerService.User_URL}/${id}`);
  }

  /**
   *
   * @param previousUser The ID of the previous user whom we're copy files from
   * @param targetUser The ID of the destinated user that we're transfering files to.
   */
  transferUserFiles( previousUser : string, targetUser : string ) : Observable<any>
  {
    var data = {
      oldUser : previousUser,
      newUser : targetUser
    }
    return this.http.post(`${ServerService.User_URL}/transfer`, data );
  }

  /**
   * Return a list of userID and fullName that reassemble the individual account under your institution.
   */
  prettyNames(): Observable<any>{
    return this.http.get(`${ServerService.User_URL}/pretty`);
  }

  UpdateUserTutorialPreference(showTutorial: boolean) {
    return this.http.post(`${ServerService.User_URL}/tutorial`, showTutorial);
  }

}
