import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  CharacterParamsManagerService,
  IDENTITY_KEY_STRING,
} from 'src/app/data/character-params-manager.service';

export interface DialogData {
  selectedImage: string;
}

@Component({
  selector: 'app-dialog-select-image',
  templateUrl: './dialog-select-image.component.html',
  styleUrls: ['./dialog-select-image.component.scss'],
})
export class DialogSelectImageComponent implements OnInit {
  constructor(
    public characterParamsManagerService: CharacterParamsManagerService,
    public dialogRef: MatDialogRef<DialogSelectImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public itemizedImages: string[] = [];
  public urlImages: string[] = [];

  public selectedImage: string = null;

  ngOnInit(): void {
    //collect list of images to allow the user to choose from
    this.selectedImage = this.data.selectedImage;
    this.urlImages = this.characterParamsManagerService.getAllTexturesAndEquipmentURLS();
  }

  /**
   * loads an image selected by a user
   * @param url the url path to the image
   */
  onSelectImage(url: string): void {
    this.selectedImage = url;
  }
}
