<div
  class="container"
  [ngStyle]="{
    'background-image': isPlaying
      ? 'linear-gradient(rgb(168, 251, 255),rgb(255, 255, 255))'
      : 'linear-gradient(rgb(255, 255, 255),rgb(255, 255, 255))',
    transition: '0.2s'
  }"
>
  <span class="symptomIndex">{{ symptomIndex }}</span>
  <mat-icon style="margin-left: 16px; margin-right: 16px; color: gray"
    >drag_handle</mat-icon
  >
  <div style="width: 100%">
    <div
      *ngIf="
        clinical.originalName === null || clinical.originalName === undefined;
        else customSymptom
      "
    >
      <p style="margin-top: 15px; margin-bottom: 15px">{{ clinical.name }}</p>
    </div>

    <ng-template #customSymptom>
      <p style="margin-top: 15px; margin-bottom: 0">{{ clinical.name }}</p>
      <p style="margin-bottom: 15px">
        <em style="color: rgb(172, 172, 172)">({{ clinical.originalName }})</em>
      </p>
      <p>{{ clinical.description }}</p>
    </ng-template>
    <div class="dropdown-container">
      <app-audio-hover-button
        *ngIf="hasAudio"
        [clinical]="clinical"
        [age]="patient.age"
        [gender]="patient.genderEthnicity"
        (soundEvent)="onSoundChanged($event)"
      ></app-audio-hover-button>
      <app-layer-hover-button
        *ngIf="hasLayers"
        [clinical]="clinical"
        (textureEvent)="onTextureChanged($event)"
      ></app-layer-hover-button>
      <app-vitals-hover-button
        *ngIf="clinical.containsVital"
        [clinical]="clinical"
        [gender]="this.Gender"
        (VitalEvent)="onVitalChanged($event)"
      ></app-vitals-hover-button>
      <app-labs-hover-button
        *ngIf="clinical.containsLab"
        [clinical]="clinical"
        [gender]="patientController.gender"
        (labEvent)="onLabChanged($event)"
      ></app-labs-hover-button>
      <app-procedures-hover-button
        *ngIf="hasProcedure"
        [clinical]="clinical"
        (procedureEvent)="onProcedureChanged($event)"
      ></app-procedures-hover-button>
      <app-transition-hover-button
        *ngIf="hasTransition"
        [clinical]="clinical"
        [prettyState]="this.prettyTransitionName"
        (transitionEvent)="onTransitionChanged($event)"
      >
      </app-transition-hover-button>
    </div>
  </div>
  <div style="width: 50px">
    <div class="more">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button
          mat-menu-item
          (click)="showConsoleLog();"
          class="mat-menu-custom">
        Ouput Clinical Data</button> -->

        <button
          mat-menu-item
          (click)="onEditTitleAndDesc()"
          class="mat-menu-custom"
        >
          <mat-icon>subject</mat-icon>Add Custom Title & Description
        </button>
        <button
          mat-menu-item
          (click)="onShowSound('Add')"
          class="mat-menu-custom"
          [disabled]="hasAudio === true"
        >
          <mat-icon>volume_up</mat-icon>Add Sounds
        </button>
        <button
          mat-menu-item
          (click)="onShowInjuryTexture('Add')"
          class="mat-menu-custom"
          [disabled]="hasLayers"
        >
          <mat-icon>layers</mat-icon>Add Injury Textures
        </button>
        <button
          mat-menu-item
          (click)="onShowVitalSigns('Add')"
          class="mat-menu-custom"
          [disabled]="clinical.containsVital"
        >
          <mat-icon>favorite</mat-icon>Add Vital Signs
        </button>
        <button
          mat-menu-item
          (click)="onShowLabValues('Add')"
          class="mat-menu-custom"
          [disabled]="clinical.containsLab"
        >
          <mat-icon class="icon-color">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <g><rect fill="none" height="24" width="24" /></g>
              <g>
                <path
                  d="M19.8,18.4L14,10.67V6.5l1.35-1.69C15.61,4.48,15.38,4,14.96,4H9.04C8.62,4,8.39,4.48,8.65,4.81L10,6.5v4.17L4.2,18.4 C3.71,19.06,4.18,20,5,20h14C19.82,20,20.29,19.06,19.8,18.4z"
                />
              </g>
            </svg> </mat-icon
          >Add Lab Values
        </button>
        <button
          mat-menu-item
          (click)="onShowProcedures()"
          class="mat-menu-custom"
          [disabled]="hasProcedure"
          >
          <mat-icon><img style="width: 24px; height: 24px;" loading="lazy" alt="procedures icon" src="../../../assets/SVGIcons/medical_services-black-18dp.svg"/></mat-icon>Procedures
        </button>
        <button
          mat-menu-item
          (click)="onShowAutomaticTransition('Add')"
          class="mat-menu-custom"
          [disabled]="hasTransition"
        >
          <mat-icon>forward</mat-icon>Add Automatic Transition
        </button>
        <button mat-menu-item (click)="onDeleteItem()" class="mat-menu-custom">
          <mat-icon>delete</mat-icon>Delete
        </button>
        <button
          mat-menu-item
          (click)="onClickPreview()"
          class="mat-menu-custom"
        >
          <mat-icon>play_circle_filled</mat-icon>Preview
        </button>
      </mat-menu>
    </div>
  </div>
  <ng-template #showBlank>
    <div></div>
  </ng-template>
</div>
