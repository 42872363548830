<!-- <div class="dialog-container"> -->
    <div class="dialog-container-header">
        <h2 mat-dialog-title> {{data.name}} </h2>
        <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    <!-- <hr> -->
    <mat-dialog-content>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Message</mat-label>
            <textarea matInput #input maxlength=100 style="resize: none; height: 100px; width: 100%;" [(ngModel)]="formText" required></textarea>
            <mat-hint align="end">{{input.value?.length || 0}}/100</mat-hint>
            <mat-error *ngIf="formText.length == 0">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button 
                        color="primary" 
                        mat-dialog-close>Cancel</button>
                <button mat-raised-button 
                        class="green-focus"
                        mat-dialog-close 
                        (click)="onPressedDone()"
                        [disabled]="isDisabled()" [mat-dialog-close]="formText">Done</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
<!-- </div> -->