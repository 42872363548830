import { Component, OnInit, Inject } from '@angular/core';
// import { Teammember, Role, MemberState } from '../../../models/teammember.model';
import { Role } from '../../../models/role.model';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../../models/user.model';
import { RolesService } from '../../../services/roles.service';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-invite-team-member',
  templateUrl: './dialog-invite-team-member.component.html',
  styleUrls: ['./dialog-invite-team-member.component.scss']
})
export class DialogInviteTeamMemberComponent implements OnInit {

  public teamMember: User = new User();
  public roles: Role[] = [];
  public institutionName: string = '';
frmInvite: FormGroup;
  constructor(public dialogRef: MatDialogRef<DialogInviteTeamMemberComponent>,
    private roleService: RolesService,
    private formBuilder: FormBuilder
  ) { }
  ngOnInit(): void {

    this.frmInvite = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      txtemail: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      username: ['', Validators.required],
      roleId: ['',Validators.required]
  });
    this.roleService.getAssignmentRoles().subscribe(roleList => {
      if (roleList) {
        this.roles = roleList;
      }
      if (this.roles.length > 0) {
        this.teamMember.roleId = this.roles[0]._id;
        this.frmInvite.get('roleId').setValue(this.roles[0]._id);
      }
    });

  }
  setValues(institutionName: string) {
    this.institutionName = institutionName
  }
  saveValues()
  {
    if(this.frmInvite.invalid)
    {

      return;
    }

    this.teamMember.fName= this.frmInvite.get('fname').value
    this.teamMember.lName=this.frmInvite.get('lname').value
    this.teamMember.email= this.frmInvite.get('txtemail').value
    this.teamMember.userName=this.frmInvite.get('username').value


  }
  isDisabled(): boolean {
   return this.frmInvite.invalid;
    // return (this.teamMember.fName.trim() === '' ||
    //   this.teamMember.lName.trim() === '' ||
    //   this.teamMember.email.trim() === '' ||
    //   this.teamMember.userName.trim() === '' ||
    //   this.teamMember.roleId === '');
  }
}
