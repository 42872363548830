import { UnitOfMeasure } from './unit-of-measure.enum';
import { UnitOfTime } from './unit-of-time.enum';
import { Line } from './line.model';

export enum ChannelMode {
    Stopped = 0, 
    Standby = 1,
    Infusing = 2, 
    KVO = 3,
    Alarm = 4
}

export enum ChannelAlarm {
    "Check Air Sensor", 
    "Low Battery", 
    "Air In Line", 
    "Air In Lower Tubing", 
    "Battery Depleted", 
    "Check Fluid Side", 
    "Fluid-Side Occluded", 
    "Patient-Side Occluded"
}

export enum SetupMode {
    "VR & Rate",
    "VR & Time"
}

export class Channel{
    public SetupMode : string = SetupMode[0];
    public State = ChannelMode.Stopped;
    public CalcOn = false;
    public Alarms : string[] = ['None'];
    public TotalVolumeInfused = 0;
    public InfusingStart = '';  // MUST BE IN THE FOLLOWING FORMAT {HH}:{MM}{A/P} {DD} {MMM} {YY}
    public DRCRate = 0;
    public PatientWeight = 70;
    public Concentration = 0;
    public ConcUOM = UnitOfMeasure.mg;
    public ConcDivAmount = 0;
    public Dose = 0;
    public DoseUOM = UnitOfMeasure.mcg;
    public DoseDivUOM = UnitOfMeasure.kg;
    public DoseDivTime: string = "min"
    public PrimaryLine = new Line();
    public SecondaryLine: Line = null;
}