// TO BE USED IN A DROPDOWN MENU SELECTION FOR IVPUMP ALARMS
export enum IVPumpAlarmsStates {
  NONE = 'None',
  CHECK_AIR_SENSOR = 'Check Air Sensor',
  LOW_BATTERY = 'Low Battery',
  AIR_IN_LINE = 'Air In Line',
  AIR_IN_LOWER_TUBING = 'Air In Lower Tubing',
  BATTERY_DEPLETED = 'Battery Depleted',
  CHECK_FLUID_SIDE = 'Check Fluid Side',
  FLUID_SIDE_OCCLUDED = 'Fluid-Side Occluded',
  PATIENT_SIDE_OCCLUDED = 'Patient-Side Occluded',
}

export enum IVPumpAlarmStatesNumber {
  NONE = 0,
  CHECK_AIR_SENSOR = 1,
  LOW_BATTERY = 2,
  AIR_IN_LINE = 3,
  AIR_IN_LOWER_TUBING = 4,
  BATTERY_DEPLETED = 5,
  CHECK_FLUID_SIDE = 6,
  FLUID_SIDE_OCCLUDED = 7,
  PATIENT_SIDE_OCCLUDED = 8,
}

// TO BE USED IN A DROPDOWN MENU SELECTION FOR VENT ALARMS
export enum VentilatorAlarmsStates {
  NONE = 'None',
  TUBING_COMPLIANCE_FAULT = 'Tubing Compliance Fault',
  GAS_INTAKE_FAULT = 'Gas Intake Fault',
  SELF_CHECK_FAILURE = 'Self Check Failure',
  PEEP_LEAK = 'PEEP Leak',
}

export enum VentilatorAlarmsStatesNumber {
  NONE = 0,
  TUBING_COMPLIANCE_FAULT = 1,
  GAS_INTAKE_FAULT = 2,
  SELF_CHECK_FAILURE = 3,
  PEEP_LEAK = 4,
}


export class Alarms {
  private IV_PUMP_ALARMS_STATES = IVPumpAlarmsStates;
  private IV_PUMP_ALARMS_STATES_NUMBER = IVPumpAlarmStatesNumber;
  private VENTILATOR_ALARM_STATES = VentilatorAlarmsStates;
  private VENTILATOR_ALARM_STATES_NUMBER = VentilatorAlarmsStatesNumber;
  private VentilatorAlarmKeys: string[] = [];
  private IVPumpAlarmKeys: string[] = [];

  constructor() {
    this.VentilatorAlarmKeys = Object.keys(this.VENTILATOR_ALARM_STATES);
    this.IVPumpAlarmKeys = Object.keys(this.IV_PUMP_ALARMS_STATES);
  }

  public getVentilatorAlarmKeys(): string[] {
    return this.VentilatorAlarmKeys;
  }

  public getIVPumpAlarmKeys(): string[] {
    return this.IVPumpAlarmKeys;
  }

  public getVentAlarmString(key: string): string {
    return this.VENTILATOR_ALARM_STATES[key];
  }
  public getVentAlarmNumber(key: string): number {
    return this.VENTILATOR_ALARM_STATES_NUMBER[key] as number;
  }

  public getIVPumpAlarmString(key: string): string {
    return this.IV_PUMP_ALARMS_STATES[key];
  }
  public getIVPumpAlarmNumber(key: string): number {
    return this.IV_PUMP_ALARMS_STATES_NUMBER[key] as number;
  }
}
