import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData{
  name: string;
  description: string;
}

@Component({
  selector: 'app-dialog-delete-treatment-option',
  templateUrl: './dialog-delete-treatment-option.component.html',
  styleUrls: ['./dialog-delete-treatment-option.component.scss']
})
export class DialogDeleteTreatmentOptionComponent implements OnInit {

  checked:boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogDeleteTreatmentOptionComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.checked = false;
  }
}
