import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { audioType, characterType, auscultationType } from 'src/app/models/AudioFileSelections.model';
import { ServerService } from '../services/Server.service';

export enum fileType {
  File = 0,
  Voice = 1,
  Auscultation = 2
}

interface AudioData {
  type: string;
  kind: string;
  name: string;
}

interface AudioChangeRequest {
  before: AudioData;
  after: AudioData;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  // at this point here, we probably don't need to use the userID yet.. but it would
  // be nice to be able to separate user files and everyone else.
  // how does it know what we're sending???
  // public upload (data, userId ){
  //   let uploadURL = `${this.Server_URL}/auth/${userId}/files`;
  // this function seems reduntant. removed.
  // read( urlPath: string ){
  //   return this.httpClient.get(urlPath);
  // }

  audioKind( type: fileType, kind?: string ){
    switch ( type ) {
      case fileType.File :          return 'upload';
      case fileType.Voice :         return `voices/${kind}`;
      case fileType.Auscultation :  return `auscultations/${kind}`;
      default :
        throw new Error(`Unhandle exception raised ${type}`);
    }
  }

  /**
   * Upload a file to the server
   * @param fileToUpload File to push to azure server
   * @param type What type is it. (Voices / Auscultations )
   * @param kind File kind ( Hearts, Lung, Male)
   */
  upload( fileToUpload: File, type: fileType, kind: string ): Observable<any> {
    // get the urlpath of this file and then communicate it with underlying struct
    const urlPath = `${ServerService.File_URL}/${this.audioKind(type, kind)}`;
    // create a new form data to pass file to server
    const formData: FormData = new FormData();
    // add file to form data
    formData.append('file', fileToUpload );
    // try to post to the server
    return this.post(urlPath, formData);
  }
  /**
   * Upload a file to the server
   * @param fileToUpload File to push to azure server
   * @param type What type is it. (Voices / Auscultations )
   * @param kind File kind ( Hearts, Lung, Male)
   */
  uploadAudio( fileToUpload: File, type: string, kind: string ): Observable<any> {
    // get the urlpath of this file and then communicate it with underlying struct
    const urlPath = `${ServerService.File_URL}/${type.toLowerCase()}s/${kind}`;
    // create a new form data to pass file to server
    const formData: FormData = new FormData();
    // add file to form data
    formData.append('file', fileToUpload );
    // try to post to the server
    return this.post(urlPath, formData);
  }

  /**
   * Delete the audio file from the server.
   * @param fileName File name to remove from the server
   * @param type File type (Voices/Auscultations)
   * @param kind File Kind (Male/Female/Baby - Hearts/Lung)
   */
  deleteAudio( fileName: string, type: fileType, kind: string ): Observable<any> {
    // generate url code to execute server commands
    const urlPath = `${ServerService.File_URL}/${this.audioKind(type, kind)}/${fileName}`;
    // return observable view from deleting file..
    return this.httpClient.delete(urlPath);
  }

  /**
   * Delete a user uploaded audio file
   * @param path the path for the file. i.e. Voices/Male/FileName.ogg
   */
  deleteUploadedAudio( path: string ): Observable<any> {
    const urlPath = `${ServerService.File_URL}/${path}`;
    return this.httpClient.delete(urlPath);
  }

  /**
   * Function : Rename a file
   * Purpose : Send a ticket to the server to rename the file.
   */
  renameFile( filePath: string, newName: string ): Observable<any>{
    const urlPath = `${ServerService.File_URL}/change`;
    const file = filePath.indexOf('\\') > -1 ? filePath.split('\\') : filePath.split('/');
    const ticket: AudioChangeRequest = {
      before : {
        type : file[file.length - 3],
        kind : file[file.length - 2],
        name : file[file.length - 1]
      },
      after : {
        type : file[file.length - 3],
        kind : file[file.length - 2],
        name : newName
      }
    };
    return this.httpClient.put(urlPath, { audioChangeRequestTicket : ticket } );
  }

  /**
   * Function : Update file category
   * Purpose : Send the server a ticket to relocate the file per user request
   */
  updateCategory( filePath: string, newCategory: string, newKind: string ): Observable<any> {
    const urlPath = `${ServerService.File_URL}/change`;
    const dir = filePath.indexOf('\\') > -1 ? filePath.split('\\') : filePath.split('/');

    return this.httpClient.put(urlPath, {
      before : {
        type : dir[dir.length - 3],
        kind : dir[dir.length - 2],
        name : dir[dir.length - 1]
      },
      after : {
        type : newCategory,
        kind : newKind,
        name : dir[dir.length - 1]
      }
    });
  }

  /**
   * Function : delete the file from the server
   * Purpose : To remove the file from the server per user request.
   */
  deleteFile( fileToDelete: string ): Observable<any>{
    const urlPath = `${ServerService.Document_URL}/${fileToDelete}`;
    return this.httpClient.delete(urlPath);
  }

  private post( url, data ): Observable<any>{
    return this.httpClient.post(url, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch ( event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total );
          return { status : 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default :
          return `Unhandled event: ${event.type}`;
      }
    }));
  }

  getFileNames(): Observable<any> {
    // so in this case here I need to list of all url path to whatever files I have saved on the server?
    return this.httpClient.get(ServerService.Document_URL);
  }

  // expected to call out and get a special datatype that goes like this
  /**
  * {
  *    "Voice" : [
  *      "Baby" : {
  *          "path1",
  *          "path2",
  *          ...
  *        },
  *        ...
  *    ],
  *    "Auscultation" : [
  *      "Heart" : {
  *          "path1",
  *          "path2",
  *          ...
  *        },
  *        ...
  *    ]
  * }
  */
  getCustomSounds(): Observable<any> {
    const urlPath = `${ServerService.File_URL}/soundlist`;
    return this.httpClient.get<any>(urlPath);
  }

  /**
   * Must be either Baby, Male, or Female - Case sensitive!
   */
  // getCustomVoice(gender : string ) : Observable<string[]>{
  //   const url = this.Server_URL+'/Voices/'+gender;
  //   return this.httpClient.get<string[]>(url);
  // }

  // /**
  //  * Must be either Hearts or Lung - Case sensitive!
  //  */
  // getCustomAuscultation(body : string ) : Observable<string[]>{
  //   const url = this.Server_URL+'/Auscultation/'+body;
  //   return this.httpClient.get<string[]>(url);
  // }

  constructor( private httpClient: HttpClient ) { }
}
