<button
  mat-raised-button
  style="
    background-color: #eeeeee;
    padding: 0px 6px;
    min-width: 44px;
    max-width: 44px;
    width: 44px;
    height: 44px;
    transform: translateY(-4px);
    margin-right: 5px;
  "
  (click)="this.decrement()"
  [disabled]="this.disabled"
>
  <mat-icon>remove</mat-icon>
</button>
<input
  type="number"
  [step]="this.steps"
  [(ngModel)]="handChange"
  [min]="this.min"
  [max]="this.max"
  [disabled]="this.disabled"
  (focusout)="onClampValue($event.target)"
  [ngClass]="{'custom-input-field':this.disabled === false, 'custom-input-field-disabled':this.disabled === true}"
/>
<button
  mat-raised-button
  style="
    background-color: #eeeeee;
    padding: 0px 6px;
    min-width: 44px;
    max-width: 44px;
    width: 44px;
    height: 44px;
    transform: translateY(-4px);
    margin-left: 5px;
  "
  (click)="this.increment()"
  [disabled]="this.disabled"
>
  <mat-icon>add</mat-icon>
</button>
