

<div style="display: flex; flex-direction: row; width:100%" *ngIf="!editorMode else editorMenu">
    <button mat-flat-button (click)="showSearchBox = !showSearchBox" style="min-height: 40px; max-height: 40px;"> Filter By Tags </button>
    <div style="display: flex; flex-direction: row; width: 800px; overflow-x: hidden;">
        <div *ngFor="let selectedTag of selectedTags" class="selectedTag">
            {{selectedTag.toUpperCase()}}
    </div>
    </div>
</div>
<div *ngIf="showSearchBox" class="searchfilter" style="position: absolute; transform: translateY(-40px);">
    <div class="tagSearch">
        <p style="margin-left: 10px; margin-top: 10px;">Filter By Tags</p>
        <p>
            <button mat-icon-button (click)="showSearchBox = !showSearchBox"><mat-icon>close</mat-icon></button>
        </p>
    </div>
    <div class="tagArea">
        <div *ngFor="let tag of tags" (click)="onSelectTag(tag)" [ngClass]="( selectedTags.indexOf(tag) >= 0 ) ?'selectedTag':'tag'">
            {{tag.toUpperCase()}}
        </div>
    </div>
</div>

<ng-template #editorMenu>
    <div style="width:100%;height:100%">
        <!-- Allow user to add Custom Tags, also bring the dialog box just in case.-->
        <div style="max-height:380px;padding: 0 10px; margin-top: auto; margin-bottom:auto; border-bottom: 1px solid rgb(189,189,189); height: 67px;">
            <div class="searchField">
                <form #f="ngForm" (ngSubmit)="onAddTag(f)" novalidate style="width:100%; height:100%">
                    <mat-form-field appearance="outline" style="width:100%; margin-top:auto; margin-bottom:auto;">
                        <mat-label>Add a custom tags</mat-label>
                        <input autocomplete="off" matInput placeholder="Insert custom tags here" name="tagName" maxlength="25" ngModel required pattern="([a-zA-Z0-9-\._]){2,}"/>
                    </mat-form-field>
                    <button mat-button class="addTagButton">Add Tag</button>
                </form>
            </div>
        </div>

        <div class="tagAreaAdded">
            <div *ngFor="let tag of selectedTags" Class="selectedTagEntry">
                {{tag.toUpperCase()}} <span style="margin-left:10px;" (click)="onDeleteTag(tag)">X</span>
            </div>
        </div>
    </div>
</ng-template>