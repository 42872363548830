
import { customAudioModel } from 'src/app/models/CustomFile.model';
import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {

  audioFile:customAudioModel

}
@Component({
  selector: 'app-dialog-delete-confirm',
  templateUrl: './dialog-delete-confirm.component.html',
  styleUrls: ['./dialog-delete-confirm.component.scss']
})

export class DialogDeleteConfirmComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogDeleteConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data:DialogData
  ) { }

  ngOnInit(): void {
  }

}
