import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogDeleteSimulationComponent } from '../dialogs/dialog-simulation/dialog-delete-simulation/dialog-delete-simulation.component';
import { DialogTutorialComponent } from '../dialogs/dialog-tutorial/dialog-tutorial.component';
import { DialogAddPatientComponent } from '../dialogs/dialog-add-patient/dialog-add-patient.component';
import {  MatDialog } from '@angular/material/dialog';
import { scenario } from '../../models/scenario.model';
import { ScenariosService } from 'src/app/services/scenarios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { patient } from 'src/app/models/patient.model';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { toolsservice } from '../services/tools.service';
import { DialogScenarioEditComponent } from '../dialogs/dialog-scenario-edit/dialog-scenario-edit.component';
import { UnityService } from '../simulation-preview/unity.service';
import { ScenarioControllerService } from '../../controllers/scenario-controller.service';
import { PreviewAnimationService } from '../services/preview-animation.service';
import { ChecklistComponent } from '../checklist/checklist.component';
import { HttpClient } from '@angular/common/http';
// import { UploadService } from 'src/app/services/upload.service';
import { TeamsService } from '../../services/teams.service';
import { DialogCreditsComponent } from '../dialogs/dialog-credits/dialog-credits.component';
import { DialogUploadFilesComponent } from '../dialogs/dialog-upload-files/dialog-upload-files.component';
import { ServerService } from 'src/app/services/Server.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/admin/user.service';
import { ɵINTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic';
import { SimulationPreviewComponent } from '../simulation-preview/simulation-preview.component';

@Component({
  selector: 'app-scenarios-header',
  templateUrl: './scenarios-header.component.html',
  styleUrls: ['./scenarios-header.component.scss'],
})
export class ScenariosHeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  scenarioControllerService: ScenarioControllerService = new ScenarioControllerService();
  taskList: string[] = [];
  @ViewChild('innerDiv') div: ElementRef;
  @ViewChild('unityContainer') unityContainer : ElementRef;

  get currentScenario(): scenario {
    return this.scenarioControllerService.scenario;
  }

  set currentScenario(inputScenario: scenario) {
    this.scenarioControllerService.scenario = inputScenario;
  }

  get ServerOnline(): boolean {
    return ServerService.ServerOnline;
  }

  // disable the delete button if we've reached 4 or more
  public get DisableDeleteButton(): boolean {
    return this.scenarioControllerService.scenario.patients.length === 1;
  }

  public selectedTabIndex = 0;

  constructor(
    private previewAnimationService: PreviewAnimationService,
    private scenarioServices: ScenariosService,
    private UnityServices: UnityService,
    private teamService: TeamsService,
    private authService: AuthService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    public elementRef: ElementRef,
  ) {}

    private calledOnce = false;

  ngAfterViewChecked(): void {
    if( this.unityContainer && !this.calledOnce )
    {
      this.calledOnce = true;
      this.onPatientChanged(0);
      // find a way to push this guy into the patient tabs.
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      // retrieve the simulation ID from url parameter
      const scenarioId = params.get('id');

      // check and see if this is a brand new simulation in the url tag
      const showTutorial = params.get('tutorial');

      // Check and see if user has opt out viewing tutorial
      const userShowTutorial = this.authService.getShowTutorial();

      // check and see if we need to display the tutorial to the user.
      if (showTutorial && userShowTutorial) {
        this.onShowTutorial();
      }

      this.scenarioServices.readScenario(scenarioId).subscribe(
        (data) => {
          // so when we're offline, this guy is returning undefined?


          this.currentScenario = data as scenario;
          toolsservice.currentScenario = this.scenarioControllerService.scenario;

          const firstPatient = this.scenarioControllerService.scenario
            .patients[0];
          this.UnityServices.loadCharacter(
            firstPatient.modelType,
            firstPatient.patientName,
            firstPatient.clothingType
          );
          this.getList();
        },
        (err) => {}
      );
    });

    // is this being subscribed correctly???
    toolsservice.ForceTabChange.subscribe((data) => {
      switch (data) {
        case 'Add':
          this.selectedTabIndex = this.currentScenario.patients.length - 1;
          break;
        case 'Delete':
          if (this.selectedTabIndex > 0) {
            this.selectedTabIndex--;
          }
          break;
        case 'Load':
          this.selectedTabIndex = 0;
          break;
        default:
          console.log(
            `Unhandled event handled: ${data} in ScenarioControllerService.ForceTabChange`
          );
      }
    });

    toolsservice.SaveScenarioChanges.subscribe(() => {
      this.onSaveData();
    });

    // I need to get the taskList items from the fileserver... hmm
    // taskItem =

    //this.getDomReferences();
  }


  // provide a full list of combo box of checklist items.
  getList() {
    if (ServerService.ServerOnline) {
      this.http.get<string[]>(ServerService.Task_URL).subscribe((x) => {
        const temp = this.currentScenario.checklistLink;
        this.taskList = x.map((y) => y.replace('.json', ''));
        this.currentScenario.checklistLink = temp;
      });
    }
  }

  ngOnDestroy(): void {
    toolsservice.ForceTabChange.unsubscribe();
    toolsservice.SaveScenarioChanges.unsubscribe();
  }

  /**
   * When called will present the dialog box for uploading new files
   */
  onFileUpload() {
    const dialogRef = this.dialog.open(DialogUploadFilesComponent, {
      width: '900px',
    });
  }

  onDeleteSimulation() {
    const dialogRef = this.dialog.open(DialogDeleteSimulationComponent, {
      // height: '375px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.scenarioServices
          .deleteScenario(this.scenarioControllerService.scenario.ScenarioId)
          .subscribe(() => {
            this.router.navigate(['/dashboard']);
            toolsservice.openSnackBar(
              this.snackBar,
              'simulation deleted',
              false
            );
          });
      }
    });
  }

  // display a popup window showing the users that have created this scenario
  onOpenCredits() {
    const dialogRef = this.dialog.open(DialogCreditsComponent, {
      width: '300px',
      data: {
        scenario: this.currentScenario,
      },
    });
  }

  onShowTutorial() {
    const dialogRef = this.dialog.open(DialogTutorialComponent, {
      width: '910px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        // this means if it's checked then we do not want to show the result again.
        this.userService.UpdateUserTutorialPreference(!result);
        this.authService.setShowTutorial(!result);
      }
      // TODO: do not show this again boolean reference should be stored somewhere
    });
  }

  onShareTeam() {
    console.log(this.scenarioControllerService.scenario.isShared);
    if (!this.scenarioControllerService.scenario.isShared) {
      this.teamService
        .addToSimulation(this.scenarioControllerService.scenario.ScenarioId,this.scenarioControllerService.scenario.name)
        .subscribe(() => {
          this.scenarioControllerService.scenario.isShared = !this
            .scenarioControllerService.scenario.isShared;
          toolsservice.openSnackBar(
            this.snackBar,
            'Simulation Shared with Team',
            true
          );
        });
    } else {
      this.teamService
        .removeFromSimulation(
          this.scenarioControllerService.scenario.ScenarioId
        )
        .subscribe(() => {
          this.scenarioControllerService.scenario.isShared = !this
            .scenarioControllerService.scenario.isShared;
          toolsservice.openSnackBar(
            this.snackBar,
            'Simulation No Longer Shared With Team',
            false
          );
        });
    }
  }

  onAddPatient() {
    const dialogRef = this.dialog.open(DialogAddPatientComponent, {
      // height: '713px',
      width: '910px',
      data: { scenario: this.scenarioControllerService.scenario },
    });

    dialogRef.afterClosed().subscribe((newPatient) => {
      // TODO: what to do with data after the dialog box closes
      // assuming that the user did fill out patient model then we simply push the patient model to the scenario file?

      if (newPatient instanceof patient) {
        // this.currentScenario.patients.push(newPatient);
        this.scenarioControllerService.onAddPatient(newPatient);
        this.scenarioServices.updateScenario(this.currentScenario).subscribe(
          (res) => {
            if (res != null) {
              console.log(res);
            }
          },
          (err) => console.log(err)
        );
        toolsservice.ForceTabChange.emit('Add');
        toolsservice.openSnackBar(this.snackBar, 'New patient added!', true);
        // this.currentScenario.onAddPatient(newPatient);
        this.selectedTabIndex = this.currentScenario.patients.length - 1;
      }
    });
  }

  onEditTaskList(taskName) {
    const dialogRef = this.dialog.open(ChecklistComponent, {
      width: '910px',
      data: { tasklink: taskName },
    });

    dialogRef.afterClosed().subscribe((newTaskLink) => {
      if (
        newTaskLink !== null &&
        newTaskLink !== undefined &&
        newTaskLink.trim().length !== 0
      ) {
        this.getList();
        toolsservice.openSnackBar(this.snackBar, 'Task list Updated!', true);
      }
    });
  }

  onDeleteTaskList(taskName) {
    // this is probably where we'll send out a url path to tell the server to remove this task...
    if (ServerService.OfflineMode) {
      // return new Observable<any>((observer)=> {
      //   observer.next(true);
      //   observer.complete;
      // });
    } else {
      // once it's done deleting the task list, refresh the list anyway.
      return this.http
        .delete(`${ServerService.Task_URL}/${taskName}`)
        .subscribe(() => this.getList());
    }

    this.scenarioControllerService.scenario.checklistLink = null;
  }

  public onDisplayChecklistItem(tagName: string): string {
    if (tagName) {
      return `Checklist - ${tagName}`;
    }
    return 'Checklist';
  }

  openTaskListDialog() {
    const dialogRef = this.dialog.open(ChecklistComponent, {
      width: '910px',
      data: { tasklink: this.currentScenario.checklistLink },
    });
    // why are these not showing up anymore?

    dialogRef.afterClosed().subscribe((newTaskLink) => {
      if (
        newTaskLink !== null &&
        newTaskLink !== undefined &&
        newTaskLink.trim().length !== 0
      ) {
        this.currentScenario.checklistLink = newTaskLink; // hmm?
        this.getList();
        this.scenarioServices.updateScenario(this.currentScenario).subscribe(
          (res) => {
            if (res != null) {
              console.log(res);
            }
          },
          (err) => console.log(err)
        );
        toolsservice.openSnackBar(this.snackBar, 'Task list Updated!', true);
      }
    });
  }

  /****************************************************************
   * Function: On immediate save data
   * Purpose : Immediately save the data to JSON file to either the backend or the frontend (Offline Mode)
   ****************************************************************/
  private onSaveData() {
    this.scenarioServices
      .updateScenario(this.scenarioControllerService.scenario)
      .subscribe(
        (res) => {
          if (res != null) {
            console.log(res);
          }
        },
        (err) => console.log(err)
      );
  }

  /****************************************************************
   * Function : On Patient Notify Event
   * Purpose : To handle events notification from the child components
   ****************************************************************/
  onPatientEventNotified(data: any, index: number) {
    if (data === null) {
      return;
    }
    switch (data.action) {
      case 'Add':
        break;
      // Update to explict update the current scenario model to use the latest patient model...
      case 'Update':
        this.scenarioControllerService.scenario.patients[index] = data.patient;
        break;
      case 'Delete':
        this.scenarioControllerService.onRemovePatient(data.patient);
        this.onPatientChanged(this.selectedTabIndex);
        break;
      default:
        console.log(
          `Unhandled exception notified: ${data.action}`,
          data.patient
        );
    }
    this.onSaveData();
  }

  private activePatientIndex : number = -1;
  /****************************************************************
   * Function: On Patient Changed Notification
   * Purpose : To handle notification event from child component
   *            regarding of patient status.
   ****************************************************************/
  onPatientChanged(event) {
    if (this.previewAnimationService.isPlaying()) {
      this.previewAnimationService.onStopAnimation();
    }
    const obtainedPatient = this.currentScenario.patients[event];
    this.activePatientIndex = event;  // update our curretn active patient index window?
    this.UnityServices.loadCharacter(
      obtainedPatient.modelType,
      obtainedPatient.patientName,
      obtainedPatient.clothingType
    );
  }

  getUnityPlayer(i : number ) : any
  {
    if( i === this.activePatientIndex )
      return this.unityContainer;
    else
      return null;
  }

  /****************************************************************
   * Function: On context menu closed
   * Purpose: Handle event when the context is closed in this case,
   *          immediately save the Tag system to the scenario object.
   ****************************************************************/
  onContextMenuClosed() {
    // no matter what, we'll save the data even if the tag system did not update accordingly.

    this.onSaveData();
  }

  /****************************************************************
   * Function: On Edit Title and Description
   * Purpose: to display a dialog box after the user presses
   *          the button for editing the title and description of
   *          a scenario from the dropdown nav menu
   *
   *          after the dialog is closed with a confirmed submission
   *          the changes are applied and a snackbar is displayed
   *          to the user indicating a change was made
   *****************************************************************/
  public onEditTitleAndDescription(): void {
    const dialogRef = this.dialog.open(DialogScenarioEditComponent, {
      data: {
        name: this.currentScenario.name,
        time: this.currentScenario.runtime,
        description: this.currentScenario.simulationGoal,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '' && result !== undefined) {
        this.currentScenario.name = result.name;
        this.currentScenario.runtime = result.time;
        this.currentScenario.simulationGoal = result.description;
        toolsservice.openSnackBar(
          this.snackBar,
          'Simualtion information Updated',
          true
        );
        this.scenarioServices.updateScenario(this.currentScenario).subscribe(
          (res) => {
            if (res !== null) {
              console.log(res);
            }
          },
          (err) => console.log(err)
        );
      }
    });
  }

  /****************************************************************
   * Function: On Duplicate Simulation
   * PurposeS: Duplicate this simulation over.
   */
  public onDuplicateSimulation(): void {
    const copySim : scenario = Object.assign({}, this.currentScenario);
    // change the ID to make a new simulation out of this one.
    copySim.ScenarioId = toolsservice.makeId();
    this.scenarioServices.createScenario(copySim).subscribe( (data)=> {
      if( data ) {
        toolsservice.openSnackBar(this.snackBar, "Simulation duplicated!", true);
      }
    }),
    (err) => {
      console.log(err);
    };
  }

  public onCheckListWindow(): void {
    const dialogRef = this.dialog.open(DialogScenarioEditComponent, {
      data: {
        name: this.currentScenario.name,
        time: this.currentScenario.runtime,
        description: this.currentScenario.simulationGoal,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '' && result !== undefined) {
        this.currentScenario.name = result.name;
        this.currentScenario.runtime = result.time;
        this.currentScenario.simulationGoal = result.description;
        toolsservice.openSnackBar(
          this.snackBar,
          'Simualtion information Updated',
          true
        );
        this.scenarioServices.updateScenario(this.currentScenario).subscribe(
          (res) => {
            if (res !== null) {
              console.log(res);
            }
          },
          (err) => console.log(err)
        );
      }
    });
  }
}
