import { Component, Inject, OnInit } from '@angular/core';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Ventilator,
  VentilatorAdjunctMode,
  VentilatorAlarms,
  VentilatorMode,
} from 'src/app/models/ventilator.model';
import { toolsservice } from '../../services/tools.service';

export interface DialogData {
  device: Ventilator;
  patientName: string;
}

@Component({
  selector: 'app-dialog-edit-ventilator',
  templateUrl: './dialog-edit-ventilator.component.html',
  styleUrls: ['./dialog-edit-ventilator.component.scss'],
})
export class DialogEditVentilatorComponent implements OnInit {
  public ventilator: Ventilator;

  public get VentilatorMode(): string[] {
    return toolsservice.ToArray(VentilatorMode);
  }

  public get VentilatorAdjunctMode(): string[] {
    return toolsservice.ToArray(VentilatorAdjunctMode);
  }

  public get VentilatorAlarms(): string[] {
    return toolsservice.ToArray(VentilatorAlarms);
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    this.ventilator = this.data.device;
    // definitely need to find a way to update the checkboxes... hmm how do I go around that?
  }

  OnAlarmChange(event: any, alarm: string): void {
    // in this case here we'll just go ahead and check all alarms and add/remove from the list.
    const index = this.ventilator.Alarms.indexOf(alarm);
    if (index > -1) {
      this.ventilator.Alarms.splice(index, 1);
    } else {
      this.ventilator.Alarms.push(alarm);
    }

    console.log(this.ventilator.Alarms);
  }

  IsActiveAlarm(alarm: string): boolean {
    const index = this.ventilator.Alarms.indexOf(alarm);
    return index !== -1;
  }
}
