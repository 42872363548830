import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DialogDeletePatientComponent } from '../dialogs/dialog-delete-patient/dialog-delete-patient.component';
import {  MatDialog } from '@angular/material/dialog';
import { patient } from 'src/app/models/patient.model';
import { toolsservice } from '../services/tools.service';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { DialogEditPatientComponent } from '../dialogs/dialog-edit-patient/dialog-edit-patient.component';
import { clinical } from 'src/app/models/statedata.model';
import { ClinicalControllerService } from '../../controllers/clinical-controller.service';
import { PatientControllerService } from '../../controllers/patient-controller.service';
import { scenario } from 'src/app/models/scenario.model';
import { UnityService } from '../simulation-preview/unity.service';
import { UploadService } from 'src/app/services/upload.service';
import { fileType } from '../../services/upload.service';
import * as DocTypes  from 'src/assets/configs/DocumentExtension.json';
import _ from 'underscore';
import { HttpClient } from '@angular/common/http';
import { AudioFileSelection } from 'src/app/models/AudioFileSelections.model';
import { ServerService } from 'src/app/services/Server.service';
import { DialogTreatmentComponent } from '../dialogs/dialog-treatment/dialog-treatment.component';
import { Device, DeviceType } from 'src/app/models/device.model';
import { DialogListDeviceComponent } from '../dialogs/dialog-list-device/dialog-list-device.component';
import { DialogEditVentilatorComponent } from '../dialogs/dialog-edit-ventilator/dialog-edit-ventilator.component';
import { DialogEditIVPumpComponent } from '../dialogs/dialog-edit-iv-pump/dialog-edit-iv-pump.component';
import { DialogVitalsComponent } from '../dialogs/dialog-vitals/dialog-vitals.component';
import { PreviewAnimationService } from '../services/preview-animation.service';
import { DialogLabValuesComponent } from '../dialogs/dialog-lab-values/dialog-lab-values.component';
import { DialogEditAuscultationComponent } from '../dialogs/dialog-edit-auscultation/dialog-edit-auscultation.component';

enum EditMode {
  None = 0,
  Vitals,
  CBC,
  BMP,
  FSG,
  BG,
  VentilatorVitals,
}

@Component({
  selector: 'app-scenarios-information',
  templateUrl: './scenarios-information.component.html',
  styleUrls: ['./scenarios-information.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ScenariosInformationComponent implements OnInit {
  // find a way to provide this patientModel into the clinicalControllerService...
  @Input() patientModel: patient;
  @Input() isDeleteButtonDisabled: boolean;
  @Input() Tags: string[];
  @Input() scenario: scenario;
  // TODO: TSLint complains that this should not have on prefix, however, removing it also breaks a lot of script.
  // Will have to do this after Dec 6th release or whatever free time we have to redo this.
  @Output() onPatientNotifyEvent = new EventEmitter<{
    action: string;
    patient: patient;
  }>();

  get clinicalData(): clinical {
    return this.patientModel.InitialClinicalData;
  }

  set clinicalData(data: clinical) {
    this.patientModel.InitialClinicalData = data;
  }

  // patientController: PatientControllerService = new PatientControllerService();

  public clinicalControllerService: ClinicalControllerService = new ClinicalControllerService();
  public patientControllerService: PatientControllerService = new PatientControllerService();

  public currentMode: EditMode = EditMode.None;
  public EditModeType = EditMode;

  // used to compare against the values to see if anything was changed.
  private orgHash: string;

  public audioFileSelection: AudioFileSelection = new AudioFileSelection();

  //#region Angular Lifecycle hooks

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private unityService: UnityService,
    private uploadService: UploadService,
    private previewAnimationService : PreviewAnimationService,
    // private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.clinicalControllerService.clinical = this.patientModel.InitialClinicalData;
    this.patientControllerService.patient = this.patientModel;
  }

  //#endregion

  displayTitle(filePath: string): string {
    return filePath.match(/[^/]+(?=\/$|$)/)[0];
  }

  deleteFile(filePath: string) {
    // delete the file from the server and pop it out
    const file =
      filePath.indexOf('\\') >= 0 ? filePath.split('\\') : filePath.split('/');
    if (
      DocTypes.SupportedAudioExtensionType.some((x) => file[file.length - 1].endsWith(x))
    ) {
      const isVoice = filePath.indexOf('voices') > -1;
      this.uploadService
        .deleteAudio(
          file[file.length - 1],
          isVoice ? fileType.Voice : fileType.Auscultation,
          file[file.length - 2]
        )
        .subscribe(
          (data) => {
            if (data != null) {
              console.log(data);
            }
          },
          (err) => console.log(err)
        );
    } else {
      this.uploadService.deleteFile(file[file.length - 1]).subscribe(
        (data) => {
          if (data != null) {
            console.log(data);
          }
        },
        (err) => console.log(err)
      ); // we want to get the file name not the whole url path.
    }
    const index = this.patientModel.customfileLinks.indexOf(filePath);
    this.patientModel.customfileLinks.splice(index, 1);
    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });
  }

  fileType(filePath: string): string {
    // Document should use description
    // Music should use audiotrack
    const isDoc = DocTypes.SupportedDocumentExtensionType.some((x) =>
      filePath.endsWith(x)
    );
    const isAudio = DocTypes.SupportedAudioExtensionType.some((x) =>
      filePath.endsWith(x)
    );
    return isDoc ? 'description' : isAudio ? 'audiotrack' : 'help';
  }

  /**
   * Deletes the active patient
   */
  onDeletePatient(): void {
    const dialogRef = this.dialog.open(DialogDeletePatientComponent, {
      width: '447px',
      data: { name: this.patientModel.patientName, description: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '' && result !== undefined) {
        this.onPatientNotifyEvent.emit({
          action: 'Delete',
          patient: this.patientModel,
        });
        toolsservice.openSnackBar(
          this.snackBar,
          `Patient: ${this.patientModel.patientName} Removed`,
          false
        );
      }
    });
  }

  /**
   * Enters the edit mode to edit Starting Lab values.
   *
   * @param mode Type mode
   */
  EnterEditMode(mode: EditMode): void {
    this.currentMode = mode;
    this.orgHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );
  }

  // whenever a checklist was changed or update, we'll save the scenario automatically
  onCheckListChanged(data: any): void {
    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });
  }

  /**
   * opens up the auscultation edit window
   */
  public onEditAuscultation(){

    let clinicalDataCopy: clinical = JSON.parse(JSON.stringify(this.patientModel.InitialClinicalData));

    const dialogRef = this.dialog.open(DialogEditAuscultationComponent, {
      width: '1000px',
      data: {
        clinical: clinicalDataCopy,
      }
    });

    dialogRef.afterClosed().subscribe(newClinicalData => {

      if(!newClinicalData){
        return;
      }

      this.patientModel.InitialClinicalData = newClinicalData;
      toolsservice.openSnackBar(this.snackBar, "Ascultation Default Data updated for patient", true);

      this.onPatientNotifyEvent.emit({
        action: 'Update',
        patient: this.patientModel,
      });
    });
  }

  /**
   * opens the treatment dialog window
   */
  public onEditTreatment(): void {
    //conditional edge checking for model types
    let amputation = '';
    if (this.patientModel.age.toLocaleLowerCase() === 'baby') {
      amputation = 'Baby';
    } else {
      amputation = this.patientModel.amputation;
    }

    //open the dialog box
    const dialogRef = this.dialog.open(DialogTreatmentComponent, {
      width: '800px',
      data: {
        characterParam: this.patientModel.genderEthnicity + '_' + amputation,
        patientActiveEquipment: this.patientModel.treatments,
      },
    });

    //close the dialog box
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.patientModel.treatments = result;

      this.onPatientNotifyEvent.emit({
        action: 'Update',
        patient: this.patientModel,
      });
    });
  }

  /**
   * Open Device list
   */
  public onAddRemoveDevices(): void {
    const dialogRef = this.dialog.open(DialogListDeviceComponent, {
      width: '800px',
      data: {
        activeDevices: this.patientModel.devices,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      // assign the ventilator devices
      this.patientModel.devices = result;

      // update the model to the server
      this.onPatientNotifyEvent.emit({
        action: 'Update',
        patient: this.patientModel,
      });
    });
  }

  /**
   * Open a dialog box dependent on the type of device that was selected for edit
   */
  public onEditDeviceValues(device: Device): void {
    let dialogRef;
    let data = {
      width: '1200px',
      data: { device, patientName: this.patientModel.patientName },
    };

    switch (device.kind) {
      case DeviceType.IVPump:
        dialogRef = this.dialog.open(DialogEditIVPumpComponent, data);
        break;
      case DeviceType.Ventilator:
        dialogRef = this.dialog.open(DialogEditVentilatorComponent, data);
        break;
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      const index = this.patientModel.devices.indexOf(device);
      console.log(index);
      this.patientModel.devices[index] = result;
      this.onPatientNotifyEvent.emit({
        action: 'Update',
        patient: this.patientModel,
      });
    });
  }

  /**
   * pop up a dialog box and manage data after the user selects Edit Vitals
   */
  public onEditDefaultVitals(): void {
    const action = `Update`;
    // create a copy of the starting data for the patient
    const clinicalVital = Object.assign({}, this.patientModel.InitialClinicalData);
    console.log(clinicalVital);

    const dialogRef = this.dialog.open(DialogVitalsComponent, {
      // height: '700px',
      width: '850px',
      data: {
        name: `${action} Vitals`,
        clinical: clinicalVital,
        gender: this.patientControllerService.gender,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.patientModel.InitialClinicalData = result;
      this.ExitEditMode();
    });
  }

  /**
   * pop up a dialog box and manage data after the user selects edit vitals
   */
  public onEditDefaultLabs(): void {
    const action = `Update`
    const clinicalLabs = Object.assign({}, this.patientModel.InitialClinicalData);
    console.log(clinicalLabs);

    const dialogRef = this.dialog.open(DialogLabValuesComponent, {
      // height: '795px',
      width: '910px',
      data: {
        name: `${action} Lab Values`,
        clinical: clinicalLabs,
        gender: this.patientControllerService.gender,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(!result){
        return;
      }

      this.patientModel.InitialClinicalData = result;
      this.ExitEditMode();
    })

  }


  /*************************************************************************
   * Function: OnEditPatient
   * Purpose: when the user selects to edit a patient in the scenario editor
   *          pop up a dialog box, and update the patient with new information
   * ***********************************************************************/
  onEditPatient(): void {
    // problem with above script doesn't retain data after leaving function.
    const tempPatient: patient = Object.assign(
      {},
      this.patientControllerService.patient
    );

    const dialogRef = this.dialog.open(DialogEditPatientComponent, {
      width: '800px',
      data: {
        name: this.patientModel.patientName,
        description: '',
        patient: tempPatient,
        scenario: this.scenario,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      } else {
        const newPatient: patient = result as patient;
        // if the patient didnt change at all, then do nothing
        if( _.isEqual(this.patientControllerService.patient, newPatient)) {
          return;
        }
        this.patientControllerService.patient = Object.assign({}, newPatient);
        const patientObj = this.patientControllerService.patient;
        this.unityService.loadCharacter(
          patientObj.modelType,
          patientObj.patientName,
          patientObj.clothingType
        );
        this.patientModel = newPatient;
        // go ahead and reset any activate preview animation it may have been set.
        this.previewAnimationService.onStopAnimation();

        toolsservice.openSnackBar(this.snackBar, `Patient Updated`, true); // inform the user
        this.onPatientNotifyEvent.emit({
          action: 'Update',
          patient: this.patientModel,
        });
      }
    });
  }

  /**
   * Revert Vital result back to default value.
   */
  RevertVitalData() {
    const temp = new clinical(null, '', '', 0, 0, 0);
    this.patientModel.InitialClinicalData.cardiacRhythm = temp.cardiacRhythm;
    this.patientModel.InitialClinicalData.HR = temp.HR;
    this.patientModel.InitialClinicalData.sBP = temp.sBP;
    this.patientModel.InitialClinicalData.dBP = temp.dBP;
    this.patientModel.InitialClinicalData.temp = temp.temp;
    this.patientModel.InitialClinicalData.RR = temp.RR;
    this.patientModel.InitialClinicalData.SaO2 = temp.SaO2;
    this.patientModel.InitialClinicalData.ETCo2 = temp.ETCo2;

    const newHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );
    if (newHash !== this.orgHash) {
      this.updateNonEditSymptoms();
    }

    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });
  }

  /**
   * Revert CBC data back to default value.
   */
  RevertCBCData() {
    const temp = new clinical(null, '', '', 0, 0, 0);

    this.patientModel.InitialClinicalData.whiteBloodCount =
      temp.whiteBloodCount;
    this.patientModel.InitialClinicalData.Hemoglobin = temp.Hemoglobin;
    this.patientModel.InitialClinicalData.Hematocrit = temp.Hematocrit;
    this.patientModel.InitialClinicalData.platelets = temp.platelets;

    const newHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );
    if (newHash !== this.orgHash) {
      this.updateNonEditSymptoms();
    }

    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });
  }

  /**
   * Revert Ventilator Vitals Values back to their default value.
   */
  public RevertVentialtorVitalData(): void {
    const temp: clinical = new clinical(null, '', '', 0, 0, 0);

    this.patientModel.InitialClinicalData.PIP = temp.PIP;
    this.patientModel.InitialClinicalData.Vt = temp.Vt;
    this.patientModel.InitialClinicalData.PPLAT = temp.PPLAT;
    this.patientModel.InitialClinicalData.MAP = temp.MAP;

    const newHash: string = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );

    if (newHash !== this.orgHash) {
      this.updateNonEditSymptoms();
    }

    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });
  }

  /**
   * Revert BMP data back to default value.
   */
  RevertBMPData() {
    const temp = new clinical(null, '', '', 0, 0, 0);
    const orgHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );

    this.patientModel.InitialClinicalData.Sodium = temp.Sodium;
    this.patientModel.InitialClinicalData.potassium = temp.potassium;
    this.patientModel.InitialClinicalData.chloride = temp.chloride;
    this.patientModel.InitialClinicalData.bicarbonate = temp.bicarbonate;
    this.patientModel.InitialClinicalData.bloodUreaNitrogen =
      temp.bloodUreaNitrogen;
    this.patientModel.InitialClinicalData.creatinine = temp.creatinine;
    this.patientModel.InitialClinicalData.glucose = temp.glucose;
    this.patientModel.InitialClinicalData.calcium = temp.calcium;

    const newHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );
    if (newHash !== this.orgHash) {
      this.updateNonEditSymptoms();
    }

    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });
  }

  /**
   * revert FSG data
   */
  RevertFSGData() {
    const temp = new clinical(null, '', '', 0, 0, 0);
    const orgHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );

    this.patientModel.InitialClinicalData.FSG_glucose = temp.FSG_glucose;

    const newHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );
    if (newHash !== this.orgHash) {
      this.updateNonEditSymptoms();
    }

    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });
  }

  /**
   * Revert ABG and VBG Data
   */
  RevertABGVBGData() {
    const temp = new clinical(null, '', '', 0, 0, 0);
    const orgHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );

    this.patientModel.InitialClinicalData.pH = temp.pH;
    this.patientModel.InitialClinicalData.pa02 = temp.pa02;
    this.patientModel.InitialClinicalData.paCO2 = temp.paCO2;
    this.patientModel.InitialClinicalData.lactate = temp.lactate;
    this.patientModel.InitialClinicalData.bicarb = temp.bicarb;
    this.patientModel.InitialClinicalData.bE = temp.bE;
    this.patientModel.InitialClinicalData.SaO2 = temp.SaO2;

    const newHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );
    if (newHash !== this.orgHash) {
      this.updateNonEditSymptoms();
    }

    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });
  }

  /**
   * Exit the starting lab edit mode.
   */
  ExitEditMode(): void {
    // set the mode back to none
    this.currentMode = EditMode.None;
    // create a hash to compare to
    const newHash = toolsservice.createHash(
      this.patientModel.InitialClinicalData
    );
    // if the hash are not identical, it means that something
    // in this model has been changed hence to ensure to update the phase default values
    if (newHash !== this.orgHash) {
      this.updateNonEditSymptoms();
    }
    // notify the event anyway
    this.onPatientNotifyEvent.emit({
      action: 'Update',
      patient: this.patientModel,
    });

    toolsservice.openSnackBar(this.snackBar, `Updates Saved`, true);
  }

  /**
   * Update all of the symptoms on this patient that does not
   * have any user defined value. This will help keep them consist with the current data we provide.
   */
  private updateNonEditSymptoms(): void {
    this.patientModel.phases.forEach((p) => {
      p.clinicalStates
        .filter((i) => !(i as clinical).containsVital)
        .forEach((d) => {
          const target = d as clinical;
          const source = this.patientModel.InitialClinicalData;

          target.cardiacRhythm = source.cardiacRhythm;
          target.HR = source.HR;
          target.RR = source.RR;
          target.SaO2 = source.SaO2;
          target.respiratoryWaveform = source.respiratoryWaveform;
          target.lungPressureWaveform = source.lungPressureWaveform;
          target.sBP = source.sBP;
          target.dBP = source.dBP;
          target.temp = source.temp;
        });
      p.clinicalStates
        .filter((i) => !(i as clinical).containsLab)
        .forEach((d) => {
          const target = d as clinical;
          const source = this.patientModel.InitialClinicalData;

          target.whiteBloodCount = source.whiteBloodCount;
          target.Hematocrit = source.Hematocrit;
          target.Hemoglobin = source.Hemoglobin;
          target.platelets = source.platelets;
          target.Sodium = source.Sodium;
          target.bloodUreaNitrogen = source.bloodUreaNitrogen;
          target.potassium = source.potassium;
          target.creatinine = source.creatinine;
          target.chloride = source.chloride;
          target.glucose = source.glucose;
          target.bicarbonate = source.bicarbonate;
          target.calcium = source.calcium;
          target.FSG_glucose = source.FSG_glucose;
          target.drawType = source.drawType;
          target.pH = source.pH;
          target.bicarb = source.bicarb;
          target.pa02 = source.pa02;
          target.bE = source.bE;
          target.paCO2 = source.paCO2;
          target.lab_saO2 = source.lab_saO2;
          target.lactate = source.lactate;
        });
    });
  }

  public GetGenderOnly(): string {
    if (this.patientModel.genderEthnicity === 'Baby') return 'Baby';
    else
      return this.patientModel.genderEthnicity.indexOf('Female') > -1
        ? 'Female'
        : 'Male';
  }

  // why is the file not updating scenario object?
  // onFilesDropped(files: any) {
  //   // this is the event that should handle incoming files dropped from the drag and drop window.
  //   // Make sure to handle the difference between audio files (Default to Voices only) and the doc files (pdf, doc, docx, txt)
  //   // For document files, this should be maintain and kept a record within the patient information.
  //   // for music files, we'll shovel them all under voices anyway.

  //   // need to get the gender..
  //   const gender = this.GetGenderOnly();

  //   files
  //     .filter((x) =>
  //       SupportedAudioExtensionType.some((y) => x.name.endsWith(y))
  //     )
  //     .forEach((x) => {
  //       this.uploadService.upload(x, fileType.Voice, gender).subscribe(
  //         (data) => {
  //           if (data != null) {
  //             console.log(data);
  //           }
  //         },
  //         (err) => console.log(err)
  //       );
  //       this.patientModel.customfileLinks.push(
  //         `${ServerService.Voice_URL}/${gender}/${x.name}`
  //       );
  //       this.onPatientNotifyEvent.emit({
  //         action: 'Update',
  //         patient: this.patientModel,
  //       });
  //     });

  //   files
  //     .filter((x) =>
  //       SupportedDocumentExtensionType.some((y) => x.name.endsWith(y))
  //     )
  //     .forEach((x) => {
  //       // pretty sure I wanted this to return as a string..
  //       this.uploadService.upload(x, fileType.File, null).subscribe(
  //         (data) => {
  //           if (data != null) {
  //             console.log(data);
  //           }
  //         },
  //         (err) => console.log(err)
  //       );
  //       this.patientModel.customfileLinks.push(
  //         `${ServerService.Document_URL}/${x.name}`
  //       );
  //       this.onPatientNotifyEvent.emit({
  //         action: 'Update',
  //         patient: this.patientModel,
  //       });
  //     });
  // }

  /**
   * When user downloads the file from the scenario file list.
   */
  // downloadFile(urlPath: string): void {
  //   // hmm how can we prompt the user to download the file anyway?
  //   this.http.get(urlPath, { responseType: 'blob' }).subscribe((blob) => {
  //     const a = document.createElement('a');
  //     const objectUrl = URL.createObjectURL(blob);
  //     a.href = objectUrl;
  //     a.download = this.displayTitle(urlPath);
  //     a.click();
  //     URL.revokeObjectURL(objectUrl);
  //   });
  // }
}
