
<div class="dialog-container-header">
  <h2 mat-dialog-title>{{ data.name }}</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-tab-group style="height: 500px" animationDuration="0ms">
    <mat-tab label="Auscultation">
      <br />

      <mat-label>Lung Auscultation</mat-label>
      <mat-form-field appearance="outline">
        <mat-select (selectionChange)="onAuscultationSelectionChanged($event, 'Lung', audioSrc)" [value]="this.selectedLungAuscultation">
          <mat-option value="none" selected>None</mat-option>
          <mat-option
            *ngFor="let lungOption of lungOptions"
            [value]="lungOption"
            >{{ lungOption | CleanUpFileName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- LABEL: Heart Auscultations DROPDOWN: unk items, at least Normal Heart Sound DEFAULT: none selected -->
      <br />
      <mat-label>Heart Auscultations</mat-label>
      <mat-form-field appearance="outline">
        <mat-select (selectionChange)="onAuscultationSelectionChanged($event, 'Heart', audioSrc)" [value]="this.selectedHeartAuscultation">
          <mat-option value="none" selected>None</mat-option>
          <mat-option
            *ngFor="let heartOption of heartOptions"
            [value]="heartOption">
            {{ heartOption | CleanUpFileName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- DIV that separates playback -->
      <br />
      <div class="playback">
        <audio #audioSrc></audio>

        <!-- dropdown to select one of the above items -->
        <mat-form-field appearance="outline">
          <mat-label>Select Audio To Preview</mat-label>
          <mat-select
            #playbackSelection
            (selectionChange)="onStatementChanged($event, audioSrc)"
          >
            <mat-option
              *ngIf="selectedLungAuscultation && selectedLungAuscultation !== 'none'"
              value="{{ selectedLungAuscultation }}"
              >Lung Auscultation -
              {{ selectedLungAuscultation | CleanUpFileName }}</mat-option
            >
            <mat-option
              *ngIf="selectedHeartAuscultation && selectedHeartAuscultation !== 'none'"
              value="{{ selectedHeartAuscultation }}"
              >Heart Auscultation -
              {{ selectedHeartAuscultation | CleanUpFileName }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <!-- playback scrubber with time stamp -->
        <mat-slider
          style="width: 100%"
          [(value)]="audioSrc.currentTime"
          [max]="audioSrc.duration"
          [step]="audioSrc.duration / 20"
          *ngIf="audioSrc.src"
          async
        >
        </mat-slider>

        <!-- pause / play control -->
        <br />
        <div class="bottom" *ngIf="playbackSelection.value">
          <button
            mat-fab
            color="primary"
            aria-label="Example icon button with a delete icon"
            *ngIf="audioSrc.paused; else paused"
            (click)="audioSrc.play()"
            [disabled]="!playbackSelection.value"
          >
            <mat-icon [ngStyle]="{ color: 'white' }">play_arrow</mat-icon>
          </button>
          <ng-template #paused>
            <button
              mat-fab
              color="primary"
              aria-label="Paused"
              (click)="audioSrc.pause()"
            >
              <mat-icon [ngStyle]="{ color: 'white' }">pause</mat-icon>
            </button>
          </ng-template>

          <p *ngIf="audioSrc.src !== null">
            {{ audioSrc.currentTime | CleanTimeline }} /
            {{ audioSrc.duration | CleanTimeline }}
          </p>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Voice">
      <br />
      <mat-label>Statement</mat-label>
      <mat-form-field appearance="outline">
        <mat-select
          (selectionChange)="onUpdateVoiceSelection($event, voiceSrc)" [value]="this.selectedVoice"
        >
        <mat-option value="none">None</mat-option>
          <mat-option
            *ngFor="let statement of statements"
            [value]="statement"
            >{{ statement | CleanUpFileName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <div class="playback">
        <audio #voiceSrc></audio>
        <!-- dropdown to select one of the above items -->

        <!-- playback scrubber with time stamp -->
        <mat-slider
          style="width: 100%"
          [max]="voiceSrc.duration"

          async
        >
        </mat-slider>
        <!-- pause / play control -->
        <br />
        <div class="bottom">
          <button
            mat-fab
            color="primary"
            *ngIf="voiceSrc.paused"
            (click)="voiceSrc.play()"
          >
            <mat-icon [ngStyle]="{ color: 'white' }">play_arrow</mat-icon>
          </button>
          <button
            mat-fab
            color="primary"
            *ngIf="!voiceSrc.paused"
            (click)="voiceSrc.pause()"
          >
            <mat-icon [ngStyle]="{ color: 'white' }">pause</mat-icon>
          </button>
          <p>
            {{ voiceSrc.currentTime | CleanTimeline }} /
            {{ voiceSrc.duration | CleanTimeline }}
          </p>
        </div>
      </div>
    </mat-tab>

    <!-- <mat-tab label="Record">
      <audio #preview></audio>

      <br />
      <mat-label>Sound Name*</mat-label>
      <mat-form-field appearance="outline">
        <input
          autocomplete="off"
          matInput
          [formControl]="soundNameControl"
          [errorStateMatcher]="matcher"
          [(ngModel)]="newSoundName"
        />
        <mat-error
          *ngIf="
            soundNameControl.hasError('pattern') &&
            !soundNameControl.hasError('required')
          "
        >
          This name contains invalid character!
        </mat-error>
        <mat-error *ngIf="soundNameControl.hasError('required')">
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <br />
      <mat-label>Sound Type*</mat-label>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="selectedSoundType">
          <mat-option *ngFor="let soundType of soundTypes" [value]="soundType"
            >{{ soundType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      <div class="playback"> -->
        <!-- dropdown to select one of the above items -->
        <!--
        <p *ngIf="!recordUrl; else showAfterRecorded">Custom Recording</p>
        <ng-template #showAfterRecorded>
          <p>Click the button below to begin recording</p>
        </ng-template>

        <div *ngIf="!recordUrl; else playback">
          <button
            mat-fab
            color="primary"
            [ngStyle]="{ color: 'red' }"
            [disabled]="newSoundName === '' || selectedSoundType === ''"
            (click)="recordAudio()"
            *ngIf="!isRecording; else otherButton"
          >
            <mat-icon [ngStyle]="{ color: 'white' }">mic</mat-icon>
          </button>

          <ng-template #otherButton>
            <button
              mat-fab
              color="primary"
              [ngStyle]="{ background: 'red' }"
              *ngIf="isRecording; else playback"
              (click)="stopRecording()"
            >
              <mat-icon [ngStyle]="{ color: 'white' }">stop</mat-icon>
            </button>
          </ng-template>
        </div>

        <ng-template #playback>
          -->
          <!-- playback scrubber with time stamp -->
          <!--
          <mat-slider
            style="width: 100%"
            [max]="recordSrc.duration"
            [(value)]="recordSrc.currentTime"
            async
          >
          </mat-slider>-->

          <!-- pause / play control -->
          <!--
          <br />
          <div class="bottom">
            <button
              mat-fab
              color="primary"
              (click)="recordSrc.play()"
              *ngIf="recordSrc.paused"
            >
              <mat-icon [ngStyle]="{ color: 'white' }">play_arrow</mat-icon>
            </button>
            <button
              mat-fab
              color="primary"
              (click)="recordSrc.pause()"
              *ngIf="!recordSrc.paused"
            >
              <mat-icon [ngStyle]="{ color: 'white' }">pause</mat-icon>
            </button>
            <button color="link" (click)="recordUrl = null">
              Delete & Try Again
            </button>
            <p>
              {{ displayTime(recordSrc.currentTime) }} /
              {{ displayTime(recordSrc.duration) }}
            </p>
          </div>
        </ng-template>
      </div>

      <span (click)="OnAddSound()" *ngIf="recordUrl" style="color: #1f7abe"
        >Add Another Recording</span
      >
    </mat-tab>

    <mat-tab label="Upload" style="height: unset !important">
      -->
      <!--Drag and drop component... May need to provide some invoking options here.-->
      <!--
      <app-scenario-files
        style="height: 120px"
        Title="Sound Files"
        (incomingFiles)="onFilesDropped($event)"
        [Uploading]="isUploading"
        [barProgress]="uploadingProgress"
        icon="volume_up"
        [acceptedExtension]="acceptExtension"
      >
      </app-scenario-files>

      -->
      <!-- Display list of custom files uploaded to the server -->
      <!--
      <div *ngFor="let item of customList">
        <input type="checkbox" style="display: none" #canEdit />
        <input type="text" style="display: none" #newName />
        <div *ngIf="!canEdit.isChecked; else showEdit">
          <div class="listed-item">
            <span class="list-item-name">{{
              item.audioPath | CleanUpFileName
            }}</span>
            <button
              mat-icon-button
              style="margin-top: auto; margin-bottom: auto"
              (click)="
                canEdit.isChecked = true;
                newName.value = displayTitle(item.audioPath);
              "
            >
              <mat-icon class="iconPointer blue" style="color: #757575"
                >create</mat-icon
              >
            </button>
            <mat-form-field appearance="standard" style="width: 100px">
              <mat-select [(value)]="item.category">
                <mat-option
                  *ngFor="let sound of soundTypes"
                  (click)="updateCategory(item, sound)"
                  value="{{ sound }}"
                  >{{ sound }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <button
              mat-icon-button
              (click)="deleteSound(item)"
              style="margin-top: auto; margin-bottom: auto"
            >
              <mat-icon
                class="blue iconPointer deleteIcon"
                style="color: #757575"
                >delete</mat-icon
              >
            </button>
          </div>
        </div>
        <ng-template #showEdit>
          <mat-form-field style="width: 60%">
            <input matInput [(ngModel)]="newName.value" />
          </mat-form-field>

          <button mat-icon-button (click)="canEdit.isChecked = false">
            <mat-icon style="color: #757575">clear</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="canEdit.isChecked = false; RenameFile(item, newName.value)"
          >
            <mat-icon>done</mat-icon>
          </button>
        </ng-template>
        <mat-divider></mat-divider>
      </div>
    </mat-tab> -->
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-button (click)="onDialogClose()" class="green-focus">
    Add Sound
  </button>
</mat-dialog-actions>
