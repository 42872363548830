import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData{
  name: string;
}

@Component({
  selector: 'app-dialog-archive-member',
  templateUrl: './dialog-archive-member.component.html',
  styleUrls: ['./dialog-archive-member.component.scss']
})
export class DialogArchiveMemberComponent implements OnInit {

  public checked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogArchiveMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.checked = false;
  }

}
