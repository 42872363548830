import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {  MatTable,  MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogInviteTeamMemberComponent } from '../dialogs/dialog-invite-team-member/dialog-invite-team-member.component';
import { DialogDeleteNewMemberComponent } from '../dialogs/dialog-delete-new-member/dialog-delete-new-member.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogArchiveMemberComponent } from '../dialogs/dialog-archive-member/dialog-archive-member.component';
import { DialogTransferPublishedSimulationsComponent } from '../dialogs/dialog-transfer-published-simulations/dialog-transfer-published-simulations.component';
import { DialogReinstateAccountComponent } from '../dialogs/dialog-reinstate-account/dialog-reinstate-account.component';
import { DialogDeleteArchivedAccountComponent } from '../dialogs/dialog-delete-archived-account/dialog-delete-archived-account.component';
import { DialogChangeMemberPasswordComponent } from '../dialogs/dialog-change-member-password/dialog-change-member-password.component';

import { User } from '../../models/user.model';
import { hash, hashSync } from 'bcryptjs';
import { UserService } from '../user.service';
import { toolsservice } from 'src/app/editor/services/tools.service';
import { MatSort } from '@angular/material/sort';
import { Role } from 'src/app/models/role.model';
import { RolesService } from 'src/app/services/roles.service';
import { AuthService } from 'src/app/auth/auth.service';
import { catchError } from 'rxjs/operators';
import { DialogResendInvitationComponent } from '../dialogs/dialog-resend-invitation/dialog-resend-invitation.component';
import { Institution } from 'src/app/models/institution.model';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit {

  dataIsReversed = false;
  archiveDataIsReversed = false;

  @ViewChild('activeTable') activeSort: MatSort;
  @ViewChild('archivedTable') archiveSort: MatSort;

  rawUserArray: User[] = [];
  dataSource: User[] = [];
  archivedDataSource: User[] = [];
  public currentInstitution: Institution



  ds = new MatTableDataSource(this.dataSource);
  archiveDs = new MatTableDataSource(this.archivedDataSource);

  roles: Role[] = [];
  allowableRoles: Role[] = [];

  displayedColumns: string[] = [
    'fName',
    'email',
    'role',
    'more',
  ];

  archivedDisplayedColumns: string[] = [
    'fName',
    'email',
    'sims',
    'more',
  ];

  roleName(value: string): string {
    return this.roles.filter(x => x._id === value)[0]?.name ?? 'Unknown';
  }

  public get currentUser(): string {
    return this.authService.getUserID();
  }

  constructor(
    private roleService: RolesService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private userService: UserService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.currentInstitution = new Institution();
    //this.refreshUserList();
    this.roleService.getRoles().subscribe(roleList => {
      if (roleList) {
        this.roles = roleList;
        // roleList.forEach( role => this.roles.push(role));
      }
    });
    this.roleService.getAssignmentRoles().subscribe(roleList => {
      if (roleList) {
        this.allowableRoles = roleList;
      }
    })
  }

  refreshUserListData(user: User[], institution: Institution): void {
    if (institution != null) {
      this.currentInstitution = institution;
    }

    if (user == null) {
      return;
    }
    this.rawUserArray = user;
    this.dataSource.splice(0, this.dataSource.length);
    this.archivedDataSource.splice(0, this.archivedDataSource.length);
    if (user.length > 0) {
      this.ds.data = user.filter(x => x.isActive);
      this.archiveDs.data = user.filter(x => !x.isActive);
      user.forEach(item => {
        if (item != null) {


          if (item.isActive) {
            this.dataSource.push(item as User);
          }
          else {
            this.archivedDataSource.push(item as User);
          }

          this.ds.sort = this.activeSort;
          this.archiveDs.sort = this.archiveSort;

        }
      });


    }
    else {
      this.ds = new MatTableDataSource(this.dataSource);;
      this.archiveDs = new MatTableDataSource(this.archivedDataSource);;

    }
  }


  addCreatedUser(user: User): void {
    let currentdata = this.ds.data;
    currentdata.push(user as User);
    this.ds.data = currentdata;
    this.ds.sort = this.activeSort;
    this.archiveDs.sort = this.archiveSort;

  }

  onCopySimsToAccount(): void {
    toolsservice.openSnackBar(this.snackBar, `All Simulations Copied to Your Account`, true);
  }
  onReinviteUser(invitedUser: User): void {
    const dialogRef = this.dialog.open(DialogResendInvitationComponent, {
      // height: '304px',
      width: '447px',
      data: { name: `${invitedUser.fName} ${invitedUser.lName}` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.userService.reInvite(invitedUser).subscribe();
      }
    });
  }


  onReinstateAccount(archivedMember: User): void {
    const dialogRef = this.dialog.open(DialogReinstateAccountComponent, {
      // height: '304px',
      width: '447px',
      data: { name: `${archivedMember.fName} ${archivedMember.lName}` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // update the user service
        archivedMember.isActive = true;
        this.userService.update(archivedMember).subscribe();

        // Remove from the archive list.
        const index = this.archivedDataSource.indexOf(archivedMember, 0);
        if (index > -1) {
          this.archivedDataSource.splice(index, 1);
        }
        this.archiveDs._updateChangeSubscription();

        // Add to the active user list.
        this.dataSource.push(archivedMember);
        this.ds._updateChangeSubscription();
        toolsservice.openSnackBar(this.snackBar, `${archivedMember.fName} ${archivedMember.lName} Reactivated`, true);
      }
    });
  }

  onChangePassword(userToUpdate: User): void {
    const dialogRef = this.dialog.open(DialogChangeMemberPasswordComponent, {
      width: '440px',
      data: { username: userToUpdate.userName, password: null }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        var newPass = hashSync(result, 10);
        userToUpdate.password = newPass;

        //this is not within scope
        this.userService.update(userToUpdate).subscribe();

        toolsservice.openSnackBar(this.snackBar, `Password reset for ${userToUpdate.fName} ${userToUpdate.lName}`, true);
      }
    });
  }

  UpdateUserRole(user: User, roleId: string) {
    user.roleId = roleId;
    this.userService.update(user).subscribe();
  }

  onClickAddTeamMember(): void {
    const institutiontoAdd = this.currentInstitution as Institution
    const dialogRef = this.dialog.open(DialogInviteTeamMemberComponent, {
      width: '440px',
    });
    dialogRef.componentInstance.setValues(institutiontoAdd.institutionName);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result !== null && result !== '') {
        const data = result as User;
        const user = new User();
        user.institutionId = institutiontoAdd._id;
        user.fName = data.fName;
        user.lName = data.lName;
        user.email = data.email;
        user.roleId = data.roleId;
        user.userName = data.userName;

        // notify the server to create new user.
        try {
          this.userService.create(user)
            .pipe(
              catchError(err => {
                toolsservice.openSnackBar(this.snackBar, `Error: ${err.error.message}`, false);
                return [];
              })
            )
            .subscribe(
              newUser => {
                if (newUser) {
                  this.addCreatedUser(user);
                  //need to refresh list  with new User
                  toolsservice.openSnackBar(this.snackBar, `Invitation Sent!`, true);
                } else {
                  /*  just in case if something goes wrong with the backend service,
                      more likely the user did not provide all of the necessary information to save into database.
                      The following information is required and cannot be null or undefined
                      RoleID
                      Username
                      First name
                      Last name
                  */
                  toolsservice.openSnackBar(this.snackBar, `Error: Unable to create user! Please contact technical support!`, false);
                }
              }
            );
        } catch (ex) {
          console.log(ex);
        }
      }
    });
  }

  onDeleteArchivedAccount(archivedMember: User): void {
    const dialogRef = this.dialog.open(DialogDeleteArchivedAccountComponent, {
      // height: '392px',
      width: '447px',
      data: { name: `${archivedMember.fName} ${archivedMember.fName}` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // delete user from the service
        this.userService.delete(archivedMember._id).subscribe();
        const index = this.rawUserArray.indexOf(archivedMember, 0);
        if (index > -1) {
          this.rawUserArray.splice(index, 1);
        }

        this.archiveDs._updateChangeSubscription();
        this.refreshUserListData(this.rawUserArray, this.currentInstitution);
        toolsservice.openSnackBar(this.snackBar, `${archivedMember.fName} ${archivedMember.fName} Deleted Permanently`, false);
      }
    });
  }

  onDeleteNewMember(teamMember: User): void {
    const dialogRef = this.dialog.open(DialogDeleteNewMemberComponent, {
      // height: '232px',
      width: '447px',
      data: { name: `${teamMember.fName} ${teamMember.lName}` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // permanently delete the user from the server
        this.userService.delete(teamMember._id).subscribe();

        const index = this.rawUserArray.indexOf(teamMember, 0);
        if (index > -1) {
          this.rawUserArray.splice(index, 1);
        }

        this.refreshUserListData(this.rawUserArray, this.currentInstitution);
        this.ds._updateChangeSubscription();
        toolsservice.openSnackBar(this.snackBar, `${teamMember.fName} ${teamMember.lName} deleted`, false);
      }
    });
  }

  onArchiveMember(teamMember: User): void {
    const dialogRef = this.dialog.open(DialogArchiveMemberComponent, {
      // height: '368px',
      width: '447px',
      data: { name: `${teamMember.fName} ${teamMember.lName}` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // Update the status of  the member, then send server a update notification.
        teamMember.isActive = false;
        this.userService.update(teamMember).subscribe();

        // remove the entry from datasource
        const index = this.dataSource.indexOf(teamMember);
        if (index > -1) {
          this.dataSource.splice(index, 1);
        }
        this.ds._updateChangeSubscription();

        // append the entry into archive datasource
        this.archivedDataSource.push(teamMember);
        this.archiveDs._updateChangeSubscription();

        // show the snackbar
        toolsservice.openSnackBar(this.snackBar, `${teamMember.fName} ${teamMember.lName} archived`, false);

        // Ask the user if they wish to transfer published simulation to another account
        const dialogRef2 = this.dialog.open(DialogTransferPublishedSimulationsComponent, {
          width: '447px',
          disableClose: true,
          data: { name: `${teamMember.fName} ${teamMember.lName}`, teammembers: this.dataSource }
        });

        dialogRef2.afterClosed().subscribe(targetUser => {
          if (targetUser) {
            // result is in the datatype of User
            this.userService.transferUserFiles(teamMember._id, targetUser._id).subscribe();
            toolsservice.openSnackBar(this.snackBar, `Publish Simulations Assigned`, true);
          }
        });
      }
    });
  }
}
