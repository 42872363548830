import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../admin/user.service';
import { PersimHeaderComponent } from '../persim-header/persim-header.component';

@Component({
  selector: 'app-scenario-editor',
  templateUrl: './scenario-editor.component.html',
  styleUrls: ['./scenario-editor.component.css'],
  encapsulation: ViewEncapsulation.None

})

export class ScenarioEditorComponent implements OnInit {

  public scenarioId:string;
  private unityInstance: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute

  ) { }
  async ngOnInit() {
    var canvas : HTMLElement = document.querySelector("#unity-container") || new HTMLElement();

    canvas.style.visibility="visible";
    canvas.style.height = "900px";
    canvas.style.width = "100%";
    this.route.queryParams.subscribe(params => {
      this.scenarioId = params['scenarioId'];
    });
    const UserId=  this.authService.getUserID();
    const userToken=this.authService.getToken();
    let array:string[] = [UserId,userToken];
    
      try
      {
        if(PersimHeaderComponent.unityInstance!=undefined)
        {
        PersimHeaderComponent.unityInstance.SendMessage("WebGLCommunication", "EnableWebGLKeyBoard");
        PersimHeaderComponent.unityInstance.SendMessage("WebGLCommunication", "NewScenario",userToken);
        }
      }
      catch (ex)
      {
        console.log(`Scenario-editor exception: ${ex}`);
        return;
      }
      if (this.scenarioId!=null && this.scenarioId!=undefined&& this.scenarioId!='') {
        PersimHeaderComponent.unityInstance.SendMessage("WebGLCommunication", "LoadUserScenario",userToken+"*"+this.scenarioId);
      }   

  }
    ngOnDestroy() {
      var canvas : HTMLElement = document.querySelector("#unity-container") || new HTMLElement();
      canvas.style.visibility="hidden";
      canvas.style.height = "0px";
      canvas.style.width = "0%";
      PersimHeaderComponent. unityInstance.SendMessage("WebGLCommunication", "DisableWebGLKeyBoard");
      }


}













