import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import appPackage from 'package.json';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  get baseUrl() : string {
    return appPackage.backendServerPath + "/institutions";
  }
  get listUrl():string
  {
    return appPackage.backendServerPath + "/institutions/list";
  }

  constructor(private http : HttpClient) { }

  get() : Observable<any>{
    return this.http.get(`${this.baseUrl}`);
  }
  getAllList() : Observable<any>{
    return this.http.get(`${this.listUrl}`);
  }
}
