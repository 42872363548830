import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { SharedModule } from '../shared/shared.module';
import {   MatSelectModule } from '@angular/material/select';
import {   MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatSelectModule,
    MatFormFieldModule, MatSelectModule, FormsModule,ReactiveFormsModule],
  exports: [
    SearchComponent
  ]
})



export class SearchModule { }
