import { Component, OnInit } from '@angular/core';

import {   MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line max-len
import { DialogEditAccountInformationComponent } from '../dialogs/dialog-edit-account-information/dialog-edit-account-information.component';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/admin/user.service';
import { User } from 'src/app/models/user.model';
import { InstitutionService } from '../../services/institution.service';
import { toolsservice } from 'src/app/editor/services/tools.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  // TODO: temporary account placeholder
  // account: Account = new Account('Lt. Col. Shane Runyon','shane.s.runyon.mil@mail.mil','USAF CCATT','password');
  account: User = new User();
  institutionName = '';

  constructor(
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private authService: AuthService,
    private userService: UserService,
    private institutionService: InstitutionService,
  ) { }

  onEditInfo(): void {
    const dialogRef = this.dialog.open(DialogEditAccountInformationComponent, {
      width: '440px',
      data: { account: this.account },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (typeof (result) === 'boolean') {
        if (result === true) {
          toolsservice.openSnackBar( this.snackbar, `Password Updated`, true );
        }
      } else {
        if (result !== undefined && result !== null && result !== '') {
          toolsservice.openSnackBar(this.snackbar,  `Account Updated`, true );
        }
      }
    });
  }

  ngOnInit(): void {
    this.userService.get(this.authService.getUserID()).subscribe( userData => {
        if (userData) {
          this.account = userData;
        }
      },
      err => console.log(err)
    );

    this.institutionService.get().subscribe(data=> {
      if( data ) {
        this.institutionName = data.institutionName;
      }
    });
  }

}
