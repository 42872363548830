import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { patient } from 'src/app/models/patient.model';
import { scenario } from 'src/app/models/scenario.model';
import { toolsservice } from '../services/tools.service';
import { SimulationPreviewComponent } from '../simulation-preview/simulation-preview.component';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
  component: InnerHTML;
}

@Component({
  selector: 'app-scenarios-body',
  templateUrl: './scenarios-body.component.html',
  styleUrls: ['./scenarios-body.component.scss']
})
export class ScenariosBodyComponent implements OnInit, AfterViewChecked {
  @Input() scenario: scenario;
  /** Update to include two way binding system */
  @Input() patientModel : patient;
  @Output() patientModelChange : EventEmitter<patient> = new EventEmitter();

  @Input() DisableDeleteButton : boolean;
  @Input() Tags : string[];
  @Output() OnBodyNotifyEvent = new EventEmitter<{action:string, patient:patient}>();

  @ViewChild('container') container : ElementRef;

  private placeHolder : any;
  // A setter event to invoke other action. 
  @Input() set playerElement(value : ElementRef){
    if( value ){
      if( this.container )
        this.container.nativeElement.appendChild(value.nativeElement);
      else
        this.placeHolder = value;
    }
  }

  // not sure why this is needed?
  GetPatient(): patient{
    return this.patientModel;
  }

  private calledOnce = false;

  constructor() { }
  ngAfterViewChecked(): void {
    if( this.container && !this.calledOnce){
      this.calledOnce = true;
      this.playerElement = this.placeHolder;
    }
  }

  ngOnInit(): void {
  }

  // When a phase gets notification, pass it on to the body notification
  onPhaseChanged( data: string): void{
    this.OnBodyNotifyEvent.emit({action: 'Update', patient: this.patientModel});
  }

  onSymptomsChanged(): void {
    this.OnBodyNotifyEvent.emit({action:'Update', patient: this.patientModel});
  }

  // When the patient gets notification, pass it on to the body notification
  OnPatientNotified( data: any){
    if ( data !== null || data !== undefined){
      this.patientModel = data.patient;
      this.patientModelChange.emit(this.patientModel);
    }
    this.OnBodyNotifyEvent.emit(data);
  }
}
