import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-scenario-files',
  templateUrl: './scenario-files.component.html',
  styleUrls: ['./scenario-files.component.scss']
})
export class ScenarioFilesComponent {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  // Default extension to accept, which can be alter.
  // TODO: mp3, wav, m4a were removed and should later be added back and converted from them to ogg upon upload.
  @Input() acceptedExtension: string[] = [
    '.pdf',
    '.doc',
    '.docx',
    '.ogg',
  ];

  // Title to display
  @Input() Title = 'Files';
  // Event emitter to indicate user have upload files
  @Output() incomingFiles: EventEmitter<File[]> = new EventEmitter();
  // Show uploading window
  @Input() Uploading = false;
  // the current bar progress of uploading
  @Input() barProgress = 0;
  // whether it should be minimized or not
  @Input() minimized = false;
  // Icon to display
  @Input() icon = 'note_add';

  /**
   * on file drop handler
   */
  onFileDropped( files: File[]){
    this.onFileHandle(files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler( files: File[]){
    this.onFileHandle(files);
  }

  /**
   * Handle file event.
   * First it checks the collection and ensure we do not have any non-acceptable files extension type.
   * Then invoke emitter of those incoming accepted files
   */
  onFileHandle( files: File[]){
    const list = Array.from(files);
    const acceptFiles = list.filter( f => this.acceptedExtension.some( x => f.name.endsWith(x)));
    if ( acceptFiles.length > 0 ) {
      this.incomingFiles.emit( acceptFiles );
    }
  }
}
