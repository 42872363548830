
import { Inject, Injectable } from '@angular/core';
import { scenario } from '../models/scenario.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServerService } from '../services/Server.service';
import {scenarioIdModel} from '../models/scenarioId.model';

@Injectable({
  providedIn: 'root'
})


export class CommunityService {

  constructor( private http: HttpClient ) { }
  public scenarios: scenario[] = [];
  // Create new
  public addScenarioToCommunity( _scenarioId: string,scenarioName:string ): Observable<string> 
  {  
    const scenariomodel=new scenarioIdModel(_scenarioId) 
    const httpHeaders = new HttpHeaders({
        'Content-type' : 'application/json',
        'Access-Control-Allow-Origin' : ServerService.PublicScenario_URL
      });
        return this.http.post<string>(ServerService.PublicScenario_URL, {scenarioId:_scenarioId,scenarioName:scenarioName});
    }

  public getCommunityScenarios(): Observable<any[]> {
    
    return this.http.get<any[]>(`${ServerService.PublicScenario_URL}`);

    
  }

  public removeScenarioFromCommunity( scenarioId: string ):Observable<any> {
    

    const httpOptions : Object = {
      headers: new HttpHeaders({
        'Accept': 'text/html',
        'Content-Type': 'text/plain; charset=utf-8'
      }),
      responseType: 'text'
    };

    return this.http.delete<string>( `${ServerService.PublicScenario_URL}/${scenarioId}`,httpOptions );
  }

  
}
