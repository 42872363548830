import { Component, NgModule, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../admin/user.service';
import { AppComponent } from '../app.component';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  public isErrorDisplayed = false;
  public operationSuccess:boolean=false;
  private userIdToken:string
  private  userId:string;
  public passwordResult:string;
  constructor(
    private fb: UntypedFormBuilder,
    private appComponent:AppComponent,
    private route: ActivatedRoute,
    private auth:AuthService,
    private userService:UserService,
    private router: Router
    
  ) { 
    this.form = this.fb.group({
      newpassword: ['', Validators.required],
      retypepassword: ['', Validators.required],
    });
      this.appComponent.isErrorPage=true; // to hide header
    }
    

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
  
      this.userIdToken = params.get('id');
    let userData=  this.auth.decodeToken(this.userIdToken);
    this.userId=userData.userId;
    });
  }
  
goToLoginPage()
{
  this.router.navigate(['/']);
}

  resetPassword()
  {
    if(!this.form.valid)
    {

this.isErrorDisplayed=true;
return;
    }
    const val = this.form.value;    
    if (val.newpassword != val.retypepassword) { // values not mactiing
      this.isErrorDisplayed=true;
      return;
    } 
    this.isErrorDisplayed=false;
    this.userService.resetPassword(val.newpassword, this.userIdToken).subscribe(
      () => {
        this.passwordResult = 'Your password has been changed successfully.';
        this.operationSuccess=true;
      },
      (error) => {
        console.error(error);
        this.operationSuccess=false;
        this.passwordResult = 'There was an error changing your password.  Pasword already changed';
        
      }
    );
    
  }

}
