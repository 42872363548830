<!-- button for inviting a new team member, opens a dialog box-->
<button
  mat-button
  style="color: #616161; position: absolute; right: 30px; z-index: 3"
  (click)="onClickAddTeamMember()"
>
  <mat-icon style="color: #616161">add_box</mat-icon>
  Invite New Team Member
</button>

<div
  *ngIf="dataSource.length === 0"
  style="
    position: absolute;
    z-index: 10px;
    text-align: center;
    width: calc(100% - 100px);
    transform: translateY(164px);
  "
>
  <p>
    <b>{{ this.currentInstitution?.institutionName }}</b> does not have any team
    members yet.
  </p>
  <p style="color: #616161">
    Click "Invite New Team Member" to add team members
  </p>
</div>

<!-- mat tab group that lists active and archived members within a team!-->
<mat-tab-group>
  <mat-tab label="Active Members ({{ dataSource.length }})" style="width: 100%">
    <table
      mat-table
      #activeTable="matSort"
      [dataSource]="ds"
      matSort
      style="width: 100%"
    >
      <ng-container matColumnDef="fName" style="background-color: cadetblue">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="background-color: #eeeeee"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          <i *ngIf="!element.emailvertified; else showNormal">
            {{ element.fName }} {{ element.lName }} (Invited)
          </i>
          <ng-template #showNormal>
            {{ element.fName }} {{ element.lName }}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="background-color: #eeeeee"
        >
          Email
        </th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef style="background-color: #eeeeee">
          Role
        </th>
        <td mat-cell *matCellDef="let element">

          <button style="width: auto;"
            *ngIf="element._id !== currentUser"
            mat-icon-button
            [matMenuTriggerFor]="menu"
          >
            {{ roleName(element.roleId) }}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <span *ngIf="element._id === currentUser">
             {{ roleName(element.roleId) }}
          </span>

          <mat-menu #menu="matMenu">
            <button
              *ngFor="let r of allowableRoles"
              mat-menu-item
              style="text-align: right; color: #616161"
              (click)="UpdateUserRole(element, r._id)"
            >
              <mat-icon *ngIf="element.roleId === r._id" style="color: #616161"
                >check</mat-icon
              >{{ r.name }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="more">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="background-color: #eeeeee; text-align: right"
        >
          Options
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: right">
          <button
            *ngIf="!element.emailvertified"
            mat-icon-button
            [matMenuTriggerFor]="menu_other"
          >
            <mat-icon> more_vert </mat-icon>
          </button>
          <mat-menu #menu_other="matMenu">
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onReinviteUser(element)"
            >
              <mat-icon style="color: #616161">email</mat-icon>Resend Invite
            </button>
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onDeleteNewMember(element)"
            >
              <mat-icon style="color: #616161">delete</mat-icon>Delete Member
            </button>
          </mat-menu>
          <button
            *ngIf="element.emailvertified"
            mat-icon-button
            [matMenuTriggerFor]="menu_no_inv"
          >
            <mat-icon> more_vert </mat-icon>
          </button>
          <mat-menu #menu_no_inv="matMenu">
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onArchiveMember(element)"
            >
              <mat-icon style="color: #616161">archive</mat-icon>Archive Member
            </button>
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onChangePassword(element)"
            >
              <mat-icon style="color: #616161">lock</mat-icon>Change Password
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        style="height: 72px"
      ></tr>
    </table>
  </mat-tab>
  <mat-tab label="Archived Members ({{ archivedDataSource.length }})">
    <table
      mat-table
      #archivedTable="matSort"
      matSort
      [dataSource]="archiveDs"
      style="width: 100%"
    >
      <ng-container matColumnDef="fName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="background-color: #eeeeee"
        >
          Team Member
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fName }} {{ element.lName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="background-color: #eeeeee"
        >
          Email
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sims">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="background-color: #eeeeee"
        ></th>
        <td mat-cell *matCellDef="let element">
          <a href="#" style="text-decoration: none; color: #616161"
            >View scenarios</a
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="more">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="background-color: #eeeeee; text-align: right"
        ></th>
        <td mat-cell *matCellDef="let element" style="text-align: right">
          <button mat-icon-button [matMenuTriggerFor]="menu_archived">
            <mat-icon> more_vert </mat-icon>
          </button>
          <mat-menu #menu_archived="matMenu">
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onCopySimsToAccount()"
            >
              <mat-icon style="color: #616161"> file_copy </mat-icon>
              Copy All Member's Simulations to My Account
            </button>
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onReinstateAccount(element)"
            >
              <mat-icon style="color: #616161"> restore </mat-icon>
              Reinstate Account
            </button>
            <button
              mat-menu-item
              style="color: #616161"
              (click)="onDeleteArchivedAccount(element)"
            >
              <mat-icon style="color: #616161"> delete </mat-icon>
              Delete Account Forever
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="archivedDisplayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: archivedDisplayedColumns"
        style="height: 72px"
      ></tr>
    </table>
  </mat-tab>
</mat-tab-group>
