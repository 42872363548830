import { BooleanInput } from '@angular/cdk/coercion';
import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toolsservice } from '../../services/tools.service';

export interface DialogData {
  name: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-dialog-treatment-option',
  templateUrl: './dialog-treatment-option.component.html',
  styleUrls: ['./dialog-treatment-option.component.scss']
})
export class DialogTreatmentOptionComponent implements OnInit {

  formText:string = '';

  constructor(public dialogRef: MatDialogRef<DialogTreatmentOptionComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.formText = this.data.description;
  }

  isDisabled(): boolean {
    return(toolsservice.isNullUndefinedEmpty(this.formText))
  }

  // onPressedDone(){
  // }

  getErrorMessage() {
    return 'field must not be left blank';
  }
}
