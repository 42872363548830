import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { clinical } from 'src/app/models/statedata.model';

@Component({
  selector: 'app-layer-hover-button',
  templateUrl: './layer-hover-button.component.html',
  styleUrls: ['./layer-hover-button.component.scss']
})
export class LayerHoverButtonComponent implements OnInit {

  @Input() clinical: clinical;

  @Output() textureEvent = new EventEmitter<{action:string, data: clinical}>();

  showPopupContent: boolean = false;
  showPopup: boolean = false;

  get layerCount() : number { 
    return this.clinical.Textures.length + this.clinical.EyesOverlays.length;
  }

  showAudioPopupF(): void {
    this.showPopupContent = true;
  }

  showPopupF(): void{
    this.showPopupContent = false;
    this.showPopup = true;  // what's this?
  }

  constructor() { }

  ngOnInit(): void { }

  onDeleteItem(): void {
    this.textureEvent.emit({action:"Delete", data: this.clinical});
  }

  onEditItem(): void {
    this.textureEvent.emit({action:"Update", data: this.clinical});
  }

}
