import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SetupMode } from 'src/app/models/channel.model';
import { Line } from 'src/app/models/line.model';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineComponent implements OnInit {

  @Input() line : Line;
  @Output() lineChange : EventEmitter<Line> = new EventEmitter<Line>(); 

  @Input() Mode : SetupMode

  IsVRandRate(mode : any){
    const VRandRate = SetupMode[SetupMode["VR & Rate"]];
    return mode === VRandRate;
  }

  constructor() { }

  ngOnInit(): void {
  }

  updateTime(value){
    // need equation here.
    this.line.Rate = value;
    const rate = this.line.Rate;
    const vr = this.line.VolumeRemaining;

    //TR = VR / (rate /60)
    this.line.TimeRemaining = vr / ( rate / 60 );
  }

  updateRate(value){
    // need equation here.
    this.line.TimeRemaining = value;
    const tr = this.line.TimeRemaining;
    const vr = this.line.VolumeRemaining;
    //Rate = ( vr / tr (in minutes) ) * 60
    this.line.Rate = ( vr / tr ) * 60;
  }

  UpdateValue(value){
    this.line.VolumeRemaining = value;
    if( this.IsVRandRate(this.Mode)){
      this.updateTime(this.line.Rate);
    } else {
      this.updateRate(this.line.TimeRemaining);
    }
  }

  // based on the Mode, certain field will be locked and will be auto calculated by the system based on fewer entry entered.
  // Asking sarah on this one to see and if I can understand more mechanics on this field. 
  // when user selects VR & Rate - Time is locked - Time is automatically calculated based on VR and rate. ml * r? 
  // when user selects VR & Time - Rate is locked - Rate is automatically calculated based on VR and Time ml/h
}
