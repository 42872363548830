import { Component, OnInit, ViewChild } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstitutionService } from 'src/app/services/institution.service';
import { DialogEditTeamNameComponent } from '../dialogs/dialog-edit-team-name/dialog-edit-team-name.component'
import { UserService } from '../user.service';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Institution } from 'src/app/models/institution.model';
import { Observable, map, startWith } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/models/user.model';
import { MemberListComponent } from '../member-list/member-list.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  currentInstitutionName: string = "";
  searchInstitutionControl = new UntypedFormControl('');

  institutions: Institution[] = [];
  public hideSearchBox: boolean;
  public institutionObservable: Observable<Institution[]>;
  public selectedInstitution: Institution;
  public institutionUsers: User[];
  private currentUser: User;
  @ViewChild('appmemberlist') appmemberList: MemberListComponent;
  //TODO: Team name should more than likely be provided by some sort of service
  public teamName: string = "Loading..."; //"USAF CCATT"
  public isLoading = false;
  constructor(private institutionService: InstitutionService,
    public dialog: MatDialog,
    private authService: AuthService,
    private userService: UserService
  ) {


  }

  ngOnInit() {
    this.hideSearchBox = true;
    this.isLoading = true;
    this.currentUser = this.authService.getUserDetails();
    // if user is not admin only get  institution
    if (this.currentUser.isUserAdmin == undefined || !this.currentUser.isUserAdmin) {
      this.institutionService.get().subscribe(x => {
        if (x) {
          this.teamName = x.institutionName;
          this.currentInstitutionName = x.institutionName;
        }
      });
      this.filterByInstitutionId(this.authService.getUserDetails().institutionId);
    }
    else {
      this.hideSearchBox = false;
      this.institutionService.getAllList().subscribe(x => {
        this.selectedInstitution = x.find(inst => inst._id == this.currentUser.institutionId);
        this.currentInstitutionName = this.selectedInstitution.institutionName;
        if (x) {
          this.institutions = x.sort((a, b) => a.institutionName.localeCompare(b.institutionName));

          this.institutionObservable = this.searchInstitutionControl.valueChanges.pipe(
            startWith(''),
            map(x => (x ? this._filterInstitutions(x) : this.institutions.slice())),
          );

          this.filterByInstitutionId(this.authService.getUserDetails().institutionId);
        }
      });

    }


  }

  private _filterInstitutions(value: string): Institution[] {
    const filterValue = value.toLowerCase();
    var result = this.institutions.filter(x => x?.institutionName.toLowerCase().includes(filterValue));
    return result.sort((a, b) => a.institutionName.localeCompare(b.institutionName));

  }
  onInstitutionChange(event: MatAutocompleteSelectedEvent) {

    this.selectedInstitution = this.institutions.find(
      intstit => intstit.institutionName === event.option.value
    );
    if (this.selectedInstitution != null) {
      this.filterByInstitutionId(this.selectedInstitution._id);
    }

  }
  filterByInstitutionId(instId: string) {
    this.isLoading = true;
    if (this.currentUser.isUserAdmin) {
      this.userService?.getAllNoFilter().subscribe(userlist => {
        this.institutionUsers = userlist.filter(x => x.institutionId === instId);
        this.isLoading = false;
        this.appmemberList.refreshUserListData(this.institutionUsers, this.selectedInstitution);
      });
    }
    else {
      this.userService?.getAll().subscribe(userlist => {
        this.institutionUsers = userlist.filter(x => x.institutionId === instId);
        this.isLoading = false;
        this.appmemberList.refreshUserListData(this.institutionUsers, this.selectedInstitution);
      });

    }
  }
  revertIfNoSelection(event)
  {

    setTimeout(() => {
      if( this.selectedInstitution!=null)
      {
        this.currentInstitutionName=this.selectedInstitution.institutionName;
    }
    }, 100);


  }
  //When the user edits the team name display a dialog box
  onClickEdit(): void {
    const dialogRef = this.dialog.open(DialogEditTeamNameComponent, {
      width: '440px',
      height: '284px',
      data: { teamName: this.teamName }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== '' && result !== undefined) this.teamName = result;
    })
  }
}
