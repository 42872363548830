<div class="dialog-container">
    <h2 mat-dialog-title style="font-family: OpenSansLight;"> Resend invitation Mail? </h2>
    <hr>
    <mat-dialog-content>
        <p>Are you sure you want to resend invitation mail to {{data.name}}?</p>
        <br>
    </mat-dialog-content>
    <br>
    <mat-dialog-actions>
        <div class="buttons">
            <span>
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button mat-dialog-close
                    [mat-dialog-close]="true" 
                    style="background-color: #1a844f; color: white;" >Resend</button>
            </span>
        </div>
    </mat-dialog-actions>
</div>