<div class="dialog-container-header">
  <h2 mat-dialog-title>{{ data.name }}</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content height="600px">
  <div class="vitals-row">
    <mat-form-field
      appearance="outline"
      style="width: 49%; padding-right: 20px"
    >
      <mat-label>Cardiac Rhythm</mat-label>
      <mat-select [(value)]="data.clinical.cardiacRhythm">
        <mat-option
          *ngFor="
            let cardiacRhythmOption of clinicalControllerService.getCardiacRhythmDescriptions
          "
          [value]="
            clinicalControllerService.getCardiacRhythmValue(cardiacRhythmOption)
          "
        >
          {{ cardiacRhythmOption }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 49%">
      <mat-label>Capnography</mat-label>
      <mat-select [(value)]="data.clinical.respiratoryWaveform">
        <mat-option
          *ngFor="
            let wfrm of clinicalControllerService.getRespiratoryWaveformDescriptionArray
          "
          [value]="clinicalControllerService.getRespiratoryWaveformValue(wfrm)"
        >
          {{ wfrm }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="vitals-row">
    <div>
      <app-slider
            [(data)]="data.clinical.ETCo2"
            name="ETCo2"
            [gender]="data.gender"
            canEdit="true"></app-slider>
    </div>
    <div>
      <app-slider
            [(data)]="data.clinical.HR"
            name="HR"
            [gender]="data.gender"
            canEdit="true"></app-slider>
    </div>
  </div>
  <div class="vitals-row">
    <div>
      <app-slider
            [(data)]="data.clinical.sBP"
            name="SBP"
            [gender]="data.gender"
            canEdit="true"></app-slider>
    </div>
    <div>
      <app-slider
            [(data)]="data.clinical.RR"
            name="RR"
            [gender]="data.gender"
            canEdit="true"></app-slider>
    </div>
  </div>
  <div class="vitals-row">
    <div style="width: 100%">
      <app-slider
            [(data)]="data.clinical.dBP"
            name="DBP"
            [gender]="data.gender"
            canEdit="true"></app-slider>
    </div>
    <div style="width: 100%">
      <app-slider
            [(data)]="data.clinical.SaO2"
            name="SaO2"
            [gender]="data.gender"
            canEdit="true"></app-slider>
    </div>
  </div>
  <div class="vitals-row">
    <div style="width: 50%">
      <app-slider
            [(data)]="data.clinical.temp"
            name="temp"
            [gender]="data.gender"
            canEdit="true"></app-slider>
    </div>
  </div>
  <h3>Ventilator Specific Vitals</h3>
  <div class="vitals-row">
    <div class="cell">
      <app-slider
        [(data)]="data.clinical.PIP"
        name="PIP"
        [gender]="data.gender"
        canEdit="true"
      >
      </app-slider>
    </div>
    <div class="cell">
      <app-slider
        [(data)]="data.clinical.Vt"
        name="Vt"
        [gender]="data.gender"
        canEdit="true"
      ></app-slider>
    </div>
  </div>
  <div class="vitals-row">
    <div class="cell">
      <app-slider
        [(data)]="data.clinical.PPLAT"
        name="PPLAT"
        [gender]="data.gender"
        canEdit="true"
      >
      </app-slider>
    </div>
    <div class="cell">
      <app-slider
        [(data)]="data.clinical.MAP"
        name="MAP"
        [gender]="data.gender"
        canEdit="true"
      ></app-slider>
    </div>
  </div>
  <div class="vitals-row">
    <div class="cell">
      <mat-form-field appearance="outline">
        <mat-label>Ventilator Alarms</mat-label>
        <mat-select [(value)]="data.clinical.VentilatorAlarm">
          <mat-option
            *ngFor="let alarm of alarms.getVentilatorAlarmKeys()"
            [value]="alarms.getVentAlarmNumber(alarm)"
            >{{ alarms.getVentAlarmString(alarm) }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="cell">
      <mat-form-field appearance="outline">
        <mat-label>IV Pump Alarms</mat-label>
        <mat-select [(value)]="data.clinical.IVPumpAlarm">
          <mat-option
            *ngFor="let alarm of alarms.getIVPumpAlarmKeys()"
            [value]=" alarms.getIVPumpAlarmNumber(alarm) "
            >{{ alarms.getIVPumpAlarmString(alarm) }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-button class="green-focus" [mat-dialog-close]="data.clinical">
    Apply Vitals
  </button>
</mat-dialog-actions>
