import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DialogInstructorPointComponent } from '../dialogs/dialog-instructor-point/dialog-instructor-point.component';
import {   MatDialog } from '@angular/material/dialog';
import { DialogDeleteInstructorPointComponent } from '../dialogs/dialog-instructor-point/dialog-delete-instructor-point/dialog-delete-instructor-point.component';
import { statedata } from 'src/app/models/statedata.model';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { toolsservice } from '../services/tools.service';

@Component({
  selector: 'app-instructor-note',
  templateUrl: './instructor-note.component.html',
  styleUrls: ['./instructor-note.component.scss'],
})
export class InstructorNoteComponent implements OnInit {
  @Input() instructornote: statedata;
  @Input() symptomIndex: number;

  @Output() instructorNoteEvent = new EventEmitter<{
    action: string;
    data: statedata;
  }>();

  constructor(
    private snackBar: MatSnackBar,
    private toolsservice: toolsservice,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  onEditItem(): void {
    this.onOpenInstructorDialog();
  }

  onOpenInstructorDialog() {

    const dialogRef = this.dialog.open(DialogInstructorPointComponent, {
      height: '375px',
      width: '500px',
      data: {
        name: 'Edit Instructor Note',
        title: this.instructornote.name,
        description: this.instructornote.description,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.phaseItemService.updateStateData(this.instructornote, result);
      if (result) {
        this.instructornote.description = result;
        this.instructorNoteEvent.emit({
          action: 'Update',
          data: this.instructornote,
        });
        toolsservice.openSnackBar(
          this.snackBar,
          `Instructor Note Updated`,
          true
        );
      }
    });
  }

  onDeleteItem(): void {
    const dialogRef = this.dialog.open(DialogDeleteInstructorPointComponent, {
      // height: '320px',
      width: '447px',
      data: { name: this.instructornote.name, description: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        // this.phaseItemService.removeStateData(this.instructornote);
        toolsservice.openSnackBar(
          this.snackBar,
          `Instructor Note Removed`,
          false
        );
        this.instructorNoteEvent.emit({
          action: 'Delete',
          data: this.instructornote,
        });
      }
    });
  }
}
