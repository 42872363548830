import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-dialog-reinstate-account',
  templateUrl: './dialog-reinstate-account.component.html',
  styleUrls: ['./dialog-reinstate-account.component.scss']
})
export class DialogReinstateAccountComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogReinstateAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

}
