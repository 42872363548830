import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import {   MatDialog } from '@angular/material/dialog';
import { phase } from '../../models/phase.model';
import { DialogPhasesComponent } from '../dialogs/dialog-phases/dialog-phases.component';
import { patient } from 'src/app/models/patient.model';
import { toolsservice } from '../services/tools.service';
import {   MatSnackBar } from '@angular/material/snack-bar';

import { Symptom } from 'src/app/models/draggable.model';
import { clinical, statedata } from 'src/app/models/statedata.model';

@Component({
  selector: 'app-simulation-timeline',
  templateUrl: './simulation-timeline.component.html',
  styleUrls: ['./simulation-timeline.component.scss'],
})
export class SimulationTimelineComponent implements OnInit {

  // needs a notification here so that when we do get a new patient loaded, we shouldn't rely on this existing patient....
  @Input() patient: patient;
  @Output() phaseChanged = new EventEmitter<string>();


  // example: string = 'EXAMPLE_1';
  // example2: string = 'EXAMPLE_2';
  // JB - 2020m09d04 - no longer in use. It was breaking the code that I had for checking with checklist.
  // Ultimately, this was not updating the patient model that needs to be kept. The UI should continue to function as long as it uses the property below.
  // phases: phase[] = [];
  temporary: phase[] = [];
  counter: number = 0;
  phaseID: string;

  // JB - 2020m09d04 - Property for getter/setter for patient.phases in terms of replacing the temp variable above.
  get phases() : phase[] {
    return this.patient.phases;
  }

  set phases(value : phase[]) {
    this.patient.phases = value;
  }

  //private phaseService:PhaseService,
  constructor( private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {}

  onOpenDialog(){
    const dialogRef = this.dialog.open(DialogPhasesComponent, {
      // height: '700px',
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {
      //Appends new phase(s) to show the user for the simulation timeline.
      if( result !== undefined && result !== null ){
        this.temporary = result;
        this.temporary.forEach(phase => {
          this.patient.phases.push(phase);
        });
        this.phaseChanged.emit("Add");
        toolsservice.openSnackBar(this.snackBar, "Phases added!", true);
      }
    });
  }

  onPhaseEvent(event : any ){
    if( event === null ) return;
    // event contains action: string and phase : phase.
    switch( event.action ){
      case "Update" : this.updatePhase(event.action); break;
      case "Delete" : this.deletePhase(event.phase); break;
      case "Add" :  this.addPhase(event.action); break;
      default:
    }
  }

  onSymptomEvent(event: any ){
    // data comes in as this { action: string, clinicalData: clinical }
    if( event === null ) return;
    switch( event.action ) {
      case 'Delete' : this.removeTransitionIfMatches(event.clinicalData.statedataId);
    }
  }

  //TODO: find a way to call the save events!

  /**
   * Filter the selected phase Id out of the current array.
   * @param phase
   */
  private deletePhase( phase : phase ){
    //this.phases = this.phases.filter(obj => obj.phaseId != phase.phaseId );
    this.CheckAutomaticTransition(phase);
    this.phases.splice(this.phases.indexOf(phase),1);
    this.reorderPhaseID();
    this.phaseChanged.emit("Delete");
  }

  /**
   * When the phase has been updated (Adding clinical data, etc)
   * @param action
   */
  private updatePhase(action: string){
    this.phaseChanged.emit(action);
  }

  /**
   * When user adds a new phase
   * @param action
   */
  private addPhase(action: string){
    this.reorderPhaseID();
    this.phaseChanged.emit(action);
  }

  /**
   * Reorder the phases index to match the scenario list.
   */
  private reorderPhaseID(): void {
    var i = 0;
    this.phases.forEach(x=>x.index = i++);
  }

  /**
   * pass the object that are being deleted from the timeline (This can be both phase or clinicalstatedata )
   */
  private CheckAutomaticTransition( objectBeingDeleted : phase | clinical ) : void{
    const phaseObject = objectBeingDeleted as phase;
    const clinicalObject = objectBeingDeleted as clinical;
    console.log(phaseObject, clinicalObject);
    if( phaseObject )
    {
      console.log(`Found that the object is a phase!`);
      phaseObject.clinicalStates.forEach( c => {
        this.removeTransitionIfMatches(c.statedataId);
      });
    }
    else if ( clinicalObject )
      this.removeTransitionIfMatches(clinicalObject.statedataId);
  }

  /**
   * Go through and check all other phase to ensure that we remove the transitionState from the timeline if matching exist..
   */
  private removeTransitionIfMatches( statedataId : string ) {
    this.patient.phases.forEach(p => {
      p.clinicalStates.forEach( c => {
        const s = c as clinical;
        if( s.transitionState && s.transitionState.indexOf(`_${statedataId}`) > -1 )
        {
          s.transitionState = '';
          s.transitionTime = 0;
        }
      })
    })
  }
}
