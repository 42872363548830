import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { clinical } from 'src/app/models/statedata.model';

@Component({
  selector: 'app-audio-hover-button',
  templateUrl: './audio-hover-button.component.html',
  styleUrls: ['./audio-hover-button.component.scss']
})
export class AudioHoverButtonComponent implements OnInit {
  @Input() clinical : clinical;
  @Input() age : string;
  @Input() gender : string;
  
  @Output() soundEvent = new EventEmitter<{ action: string, data : clinical}>();

  get audioCount() : number { 
    return  ( ( this.clinical.VoiceSound !== '' && this.clinical.VoiceSound !== undefined && this.clinical.VoiceSound !== null ) ? 1 : 0 ) + 
            ( ( this.clinical.HeartSound !== '' && this.clinical.HeartSound !== undefined && this.clinical.HeartSound !== null ) ? 1 : 0 ) +
            ( ( this.clinical.LungSound  !== '' && this.clinical.LungSound  !== undefined && this.clinical.LungSound  !== null ) ? 1 : 0 );
  }

  showPopupContent: boolean = false;
  showPopup: boolean = false;

  showAudioPopupF(): void {
    this.showPopupContent = true;
  }

  showPopupF(): void {
    // this.showAltPopupContent = true;
    this.showPopupContent = false;
    this.showPopup = true;
  }

  /**
   * Properly display the title name by showing the filename instead
   * @param path : File path
   */
  displayTitle( _path : string  ) : string{
    var _dir : string[] = [];
    // split the path if it's a url path
    if( _path.indexOf("/") >= 0 ) {
      _dir = _path.split("/");
    }
    // split the path if it's a directory path
    else if( _path.indexOf("\\") >= 0 ){
      _dir = _path.split("\\");
    }
    // cannot splice path, push path instead.
    else {     
      _dir.push(_path);
    }
    // get last array element
    // var _filename = _dir[_dir.length - 1];
    var _filename = _dir.pop();
    // split period sign.
    var _file : string[] = _filename.split('.');
    // return the first element array containing file name.
    return _file[0];
  }

  /**
   * Show only the one that is playing, other button should be unaffected if the source does not match to audio source.
   * @param audio : Audio Source
   * @param source : File source of the audio
   */
  isPlaying( _audio : any, _src : string ){
      return _audio.src === _src ? !_audio.paused : false;
  }

  
  onDeleteItem(): void {
    this.soundEvent.emit({action:"Delete", data: this.clinical});
    this.showPopup = false;
  }
  
  onEditSound() {
    this.soundEvent.emit({action:"Update", data:this.clinical});
    this.showPopup = false;
  }
  
  onPlayAudio(url : string, audio : any)
  {
    console.log(url);
    audio.src = null;
    this.http.get(url, { responseType: "arraybuffer"}).subscribe((response : any)=>{
      if(response){
        const blob = new Blob([response], { type: "audio/ogg"});
        const url = window.URL.createObjectURL(blob);
        audio.src = url;
        audio.play(); 
      }
    });
  }

  constructor(private http : HttpClient) { }
  
  ngOnInit() { }
}
