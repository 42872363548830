import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MemberListComponent } from './member-list/member-list.component';
import { DialogEditTeamNameComponent } from './dialogs/dialog-edit-team-name/dialog-edit-team-name.component';
import { DialogInviteTeamMemberComponent } from './dialogs/dialog-invite-team-member/dialog-invite-team-member.component';
import { DialogDeleteNewMemberComponent } from './dialogs/dialog-delete-new-member/dialog-delete-new-member.component';
import { DialogArchiveMemberComponent } from './dialogs/dialog-archive-member/dialog-archive-member.component';
import { DialogTransferPublishedSimulationsComponent } from './dialogs/dialog-transfer-published-simulations/dialog-transfer-published-simulations.component';
import { DialogReinstateAccountComponent } from './dialogs/dialog-reinstate-account/dialog-reinstate-account.component';
import { DialogDeleteArchivedAccountComponent } from './dialogs/dialog-delete-archived-account/dialog-delete-archived-account.component';
import { DialogChangeMemberPasswordComponent } from './dialogs/dialog-change-member-password/dialog-change-member-password.component';
import { DialogResendInvitationComponent } from './dialogs/dialog-resend-invitation/dialog-resend-invitation.component';



@NgModule({
  declarations: [
    AdminComponent, 
    MemberListComponent, 
    DialogEditTeamNameComponent, 
    DialogInviteTeamMemberComponent, 
    DialogDeleteNewMemberComponent, 
    DialogArchiveMemberComponent, 
    DialogTransferPublishedSimulationsComponent, 
    DialogReinstateAccountComponent, DialogDeleteArchivedAccountComponent, DialogChangeMemberPasswordComponent, DialogResendInvitationComponent, 
    
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    AdminComponent,
    DialogChangeMemberPasswordComponent,
  ]
})
export class AdminModule { }
