export class TeamDownloadResult
{
  constructor(
    public result:string,
    public scenarioId:string,
    public hasError:boolean
  )
  {

  }
}
