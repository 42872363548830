import { Component, OnInit, Input } from '@angular/core';
import { patient } from 'src/app/models/patient.model';
import { PatientControllerService } from 'src/app/controllers/patient-controller.service';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss']
})
export class PatientInfoComponent implements OnInit {

  @Input() patientModel : patient;
  @Input() patientControllerService : PatientControllerService;
  
  constructor() { }

  ngOnInit(): void {
    //this.patientControllerService.patient = this.patientModel;
  }


}
