<div class="background">
  <ng-particles id="tsparticles" [options]="particlesOptions" [particlesInit]="particlesInit"
    (particlesLoaded)="particlesLoaded($event)"></ng-particles>

  <div style="text-align: center; margin-top: auto; margin-bottom: auto">
    <div class="mat-elevation-z8" style="
        position: absolute;
        left: 50%;
        top: 50%;
        width: 440px;
        background-color: white;
        transform: translate(-50%, -50%);
      ">
      <div style="
          position: absolute;
          display: flex;
          flex-direction: row;
          transform: translate(100px, -50px);
        ">
        <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 43.26 42.35"
          style="fill: white; margin: 0px 5px; padding-bottom: 8px">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                d="M21.06,22.77A7.87,7.87,0,0,0,20,20.22l1-1L18.77,17l-1,1A7.33,7.33,0,0,0,15.24,17V15.56H12.11V17A7.3,7.3,0,0,0,9.56,18l-1-1L6.36,19.23l1,1a7.46,7.46,0,0,0-1.06,2.55H4.9V25.9H6.29a7.46,7.46,0,0,0,1.06,2.55l-1,1,2.21,2.22,1-1a7.49,7.49,0,0,0,2.55,1.06v1.39h3.13V31.72a7.52,7.52,0,0,0,2.54-1.06l1,1L21,29.43l-1-1a7.87,7.87,0,0,0,1.06-2.55h1.39V22.77Zm-7.39,5.77a4.21,4.21,0,1,1,4.21-4.21A4.2,4.2,0,0,1,13.67,28.54Z" />
              <path
                d="M23.31,12.3a4.91,4.91,0,0,0,0-1.71l.79-.34-.75-1.78-.79.33a4.8,4.8,0,0,0-1.22-1.2l.33-.8-1.79-.73-.33.8a4.68,4.68,0,0,0-1.71,0l-.33-.8-1.79.76.34.79a4.53,4.53,0,0,0-1.21,1.22L14,8.52l-.73,1.79.8.33a4.91,4.91,0,0,0,0,1.71l-.79.33.75,1.79.79-.34a4.68,4.68,0,0,0,1.22,1.21l-.33.79,1.79.74.33-.8a4.91,4.91,0,0,0,1.71,0l.33.79,1.79-.75-.34-.8a4.49,4.49,0,0,0,1.21-1.21l.8.33.73-1.8Zm-5.58,1.58a2.6,2.6,0,1,1,3.39-1.43A2.6,2.6,0,0,1,17.73,13.88Z" />
              <path
                d="M13.2,37.79a4.47,4.47,0,0,0,0-1.71l.8-.34L13.24,34l-.8.33a4.76,4.76,0,0,0-1.21-1.2l.32-.8-1.79-.73-.33.8a4.47,4.47,0,0,0-1.71,0l-.33-.8-1.79.75.34.8a4.46,4.46,0,0,0-1.2,1.21L3.94,34,3.21,35.8l.79.33a4.62,4.62,0,0,0,0,1.7l-.8.34L4,40l.8-.33A4.6,4.6,0,0,0,6,40.82l-.33.8,1.8.73.32-.8a4.47,4.47,0,0,0,1.71,0l.34.8,1.78-.75-.33-.8a4.76,4.76,0,0,0,1.2-1.21l.8.32L14,38.11ZM7.62,39.37a2.6,2.6,0,1,1,2-4.82,2.6,2.6,0,0,1-2,4.82Z" />
              <path
                d="M42.76,25.12C41.15,21.57,39.58,18,40,13.94c.25-2.43-.89-4.53-2.22-6.41C34.09,2.34,28.67.5,22.62.05A20.52,20.52,0,0,0,15,1,20.55,20.55,0,0,0,2.08,12.43a20.86,20.86,0,0,0-2,11A22.41,22.41,0,0,0,3,32.87c.08.13.15.25.21.37,1.44,2.5,2.25,2.22,2.25,2.22l1.72-2.05L6.93,32a2.34,2.34,0,0,1-1-.91,14.51,14.51,0,0,1-1.76-4.16,20.31,20.31,0,0,1,0-9.43A17.31,17.31,0,0,1,11,6.92c5.52-3.89,11.49-4.23,17.69-2,5.34,1.92,8.41,5.66,8.48,11.5a15.54,15.54,0,0,0,1.81,7.08c.92,1.76.83,2-1,2.91a2.34,2.34,0,0,0-1.42,3,15.58,15.58,0,0,1,.36,4.25A3.55,3.55,0,0,1,34,37.11c-2.29.46-4.6.16-6.9.21-2.75.06-5.5,0-8.25,0-1.79,0-2.84,1.49-2,2.82a2.32,2.32,0,0,0,2.16,1h7.77c2.47,0,4.94,0,7.41,0a10.21,10.21,0,0,0,3.38-.57A3.2,3.2,0,0,0,39.78,38a18.92,18.92,0,0,0,.06-6.28c-.25-1.67.22-2.8,1.66-3.39C43.47,27.54,43.63,27.06,42.76,25.12Z" />
            </g>
          </g>
        </svg>




        <h1 style="font-family: OpenSansBold; font-weight: 800; color: white">
          PerSim
        </h1>
        <h1 style="
            font-family: OpenSans;
            margin-left: 6px;
            transform: translateY(1px);
            color: white;
          ">
          Community
        </h1>
      </div>

      <!-- Login window -->
      <div *ngIf="forgotPassword === false; else showForgotPassword" class="card">
        <h1 style="text-align: left; font-family: OpenSansLight">Log In</h1>

        <br />

        <form [formGroup]="form" (submit)="login()">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Username or Email</mat-label>
            <input matInput autocomplete="off" required name="email" formControlName="email" />
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Password</mat-label>
            <input autocomplete="off" matInput name="password" formControlName="password"
              [type]="hide ? 'password' : 'text'" />
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
          </mat-form-field>
          <div *ngIf="isImpersonationenabled">

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Impersonation Username or Email </mat-label>
              <input matInput autocomplete="off" name="impersonateUserInfo" formControlName="impersonateUserInfo" />
            </mat-form-field>
          </div>

          <div *ngIf="this.isErrorDisplayed" class="error-box">
            <mat-icon class="error-icon">error</mat-icon>
            <p>
              <strong>Username</strong> or <strong>Password</strong> is
              incorrect, if these results are unexpected please see your system
              administrator for assistance.
            </p>
          </div>
          <div *ngIf="this.isAccountDeactivated" class="error-box">
            <mat-icon class="error-icon">error</mat-icon>
            <p>
              <strong>This account</strong> has been
              <strong>deactivated</strong>, please see your system administrator
              for assistance.
            </p>
          </div>
          <div *ngIf="this.isAccountLocked" class="error-box">
            <mat-icon class="error-icon">error</mat-icon>
            <p>
              <strong>Too many failed login attempts!</strong>
              Your account has been locked out.
              Please contact your system administrator for assistance.
            </p>
          </div>


          <br />

          <!-- [routerLink]="['/dashboard']" -->
          <div *ngIf="isImpersonationenabled">
            <div *ngIf="!isLoading ; else showLoading">
              <input type="submit" class="green-button-login" style="background-color:#ffae00; margin: 5px;"
                *ngIf="isImpersonationenabled" value="Start Impersonation">
            </div>
          </div>

          <div *ngIf="!isImpersonationenabled">
            <input *ngIf="!isLoading ; else showLoading" type="submit" class="green-button-login" value="Log In"
              [disabled]="isLoading" />
          </div>
          <ng-template #showLoading>
            <div>
              <mat-spinner style="margin-left: auto;margin-right:auto;"></mat-spinner>
            </div>
          </ng-template>
        </form>

        <br />
        <a (click)="forgotPassword = !forgotPassword" class="forgot-password">Forgot password?</a>
        <br />
      </div>
      <!-- Forgot password window -->
      <ng-template #showForgotPassword>
        <!-- style="display: flexbox; flex-direction: column; justify-content: space-between; margin: 16px;" -->
        <div class="card">
          <form [formGroup]="resetform" (submit)="sendResetPassword()">
            <div style="display: flex; flex-direction: row">
              <button mat-icon-button style="transform: translate(-5px, -5px)" (click)="resetForm()">
                <mat-icon style="color: #616161">arrow_back</mat-icon>
              </button>
              <h1 style="font-family: OpenSansLight">Forgot Password</h1>
            </div>

            <br />

            <div *ngIf="!resetPassword">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Email</mat-label>
                <input autocomplete="off" matInput required formControlName="email" />
                <mat-error *ngIf="resetform.controls.email.invalid">{{
                  getErrorMessage()
                  }}</mat-error>
              </mat-form-field>
              <br />
              <input type="submit" mat-raised-button class="green-button" value="Reset Password" />
            </div>

            <div *ngIf="resetPassword">
              <p style="font-family: OpenSans; text-align: left">
                If your email matches the one on file, you will receive password
                reset instructions shortly.
              </p>
              <br />
              <button mat-raised-button class="green-button" (click)="resetForm()">
                Back to Login
              </button>
            </div>
          </form>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<p class="version">Version: {{this.versionNumber}}</p>