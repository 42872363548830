<!-- Header -->
<div class="checklistHeader">
    <div class="dialog-container-header">
        <h2 mat-dialog-title>Task List Menu </h2>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <form>
        <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Tasklist Filename</mat-label>
            <input matInput [formControl]="titleFormControl" [errorStateMatcher]="matcher" [(ngModel)]="newTitleName">
            <mat-error *ngIf="titleFormControl.hasError('required')">
                Title name is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </form>

     <!-- Allow user to add new checklist item here -->
     <div>
        <form #form (submit)="onAddChecklist(item.value); form.reset();" async>
            <span class="container">
                <mat-form-field appearance="outline" style="width:100%">
                    <input matInput autocomplete="off" type="text" #item name="item" placeholder="Create a new task here...">
                </mat-form-field>
            </span>
        </form>
    </div>


    <!-- dialog contents -->
    <mat-dialog-content style="max-height:400px">

        <!-- Task Item list -->
        <div cdkDropList (cdkDropListDropped)="drop($event)" class="checklistContent">
            <!-- Column header -->
            <div class="itemList">
                <span style="width: 32px;">Critical</span>
                <span style="Width: 100%; text-align: center;">Task Description</span>
            </div>


            <!-- List of rows -->
            <div *ngFor="let item of taskList.tasklist; let x = index" class="itemList" cdkDrag>

                <mat-icon style="margin-top:auto; margin-bottom:auto;">drag_handle</mat-icon>

                <mat-checkbox color="primary" [(ngModel)]="item.critical" style="margin-top:auto; margin-bottom:auto;"></mat-checkbox>

                <mat-form-field appearance="outline" class="taskDescriptionItem">
                    <input matInput [(ngModel)]="item.taskname">
                </mat-form-field>

                <button class="checklistActionButton" mat-button (click)="onDeleteChecklist(x)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

        </div>

    </mat-dialog-content>

    <!-- How do I get this to display at the bottom of the screen? -->
    <mat-dialog-actions>

        <div class="buttons">
            <span>
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-button color="primary" (click)="onDiscard()">Revert</button>
                <button mat-button class="green-focus" (click)="onSave();onDialogClose();" [disabled]="disableSave()">Save</button>
                <!-- <button mat-button class="green-focus" (click)="">Ok</button> -->
            </span>
        </div>
    </mat-dialog-actions>
</div>
