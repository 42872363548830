<div class="slidercard">
    <div *ngIf="showShort else default">
    <p [ngStyle]="{'color' : isNormal() ? 'black' : 'red'}">
        <span *ngIf="!isNormal()">*</span>{{shortDisplayName}}: {{value}} {{showUoM ? uom : ''}}</p>
    </div>
    <ng-template #default>
        <div class="regular">
            <p [ngStyle]="{'color' : isNormal() ? 'black' : 'red'}"><span *ngIf="!isNormal()">*</span>{{displayName}} <span *ngIf="showUoM && uom">({{uom}})</span></p>
            <div *ngIf="!canEdit else editMode">
                {{value}} {{uom}}
            </div>
            <ng-template #editMode>
                <div class="container">
                    
                    <input autocomplete="off" type="range" #slider 
                        [ngClass]="['slider', !isNormal() ? 'warning' : '']" 
                        [min]="min" [max]="max" [step]="step" 
                        [(ngModel)]="value">

                        <!-- slider.value -->
                    <mat-form-field appearance="outline" class="textfield">
                        <input autocomplete="off" matInput type="number" #inputField name="inputField" 
                        [formControl]="numberControl" 
                        [min]="min" [max]="max" 
                        [(ngModel)]="value" 
                        (focusout)="onClampValue(inputField)">
                    </mat-form-field>
                    <span class="uom"> {{uom}}</span>
                </div>
            </ng-template>
        </div>
    </ng-template>
</div>