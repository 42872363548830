import { Component, OnInit } from '@angular/core';
import {   MatDialogRef } from '@angular/material/dialog';

// export interface Instruction{
//   firstParagraph:string;
//   secondParagraph:string;
//   thirdParagraph:string;
//   frouthParagraph:string;
// }

@Component({
  selector: 'app-dialog-tutorial',
  templateUrl: './dialog-tutorial.component.html',
  styleUrls: ['./dialog-tutorial.component.scss']
})
export class DialogTutorialComponent implements OnInit {

  // instruction:Instruction;
  // pages:Instruction [];

  loadedImage: string = '';
  loadedParagraph: string = '';

  showNextButton = true;
  showPreviousButton = false;
  showDoneButton = false;
  showCheckMessage = false;

  currentIndex = 0;

  checked = false;

  constructor(public dialogRef: MatDialogRef<DialogTutorialComponent>) { }

  ngOnInit(): void {
    this.showNextButton = true;
    this.showPreviousButton = false;
    this.showDoneButton = false;
    this.showCheckMessage = false;

    // this.checked = false;
    this.currentIndex = 0;
  }

  onPressedNext(): void{
    this.currentIndex++;
    this.showPreviousButton = true;
    if(this.currentIndex === 5){
      this.showDoneButton = true;
      this.showCheckMessage = true;
      this.showNextButton = false;
    }
  }

  onPressedPrevious(): void{
    this.currentIndex--;
    this.showDoneButton = false;
    this.showCheckMessage = false;
    this.showNextButton = true;
    if(this.currentIndex === 0){
      this.showPreviousButton = false;
    }
  }
}
