import { Injectable, Input } from '@angular/core';
import { clinical } from '../models/statedata.model';
import { CardiacRhythm } from '../editor/services/tools.service';
import { RespiratoryWaveform } from '../editor/services/tools.service';

@Injectable({
  providedIn: 'root',
})
export class ClinicalControllerService {
  @Input() clinical: clinical;
  // public hermoglobin : clinicalmetadata;

  /**
   * List of Cardiac Rhythm Description available accordingly to the enumerator for clinical.cardiacRhythm
   */
  private _cardiacRhythmDescription: string[] = [
    'AFIB',
    'ASYSTOTLE',
    'AVB3',
    'MOBITZ1',
    'MOBITZ2',
    'NORMAL',
    'SVT',
    'TORSADES',
    'VENTACHY (nonperfusing)',
    'VENTACHY (perfusing)',
    'VFIB',
  ];

  private _respiratoryWaveformDescription: string[] = [
    'NORMAL_RESP',
    'HYPO',
    'HYPER',
    'CPR',
    'ROSC',
    'SUDDEN_LOSS',
    'DECREASING',
    'SEDATION',
  ];

  //TODO: Discuss with jordan about creating objects for each vital/lab for reference, similar to the following structure
  // public HeartRate = {
  //   min: 0,
  //   max: 200,
  //   step: 1,
  //   value: 65,
  //   default: 65,
  // }

  /**
   * Return the list of Cardiac Rhythm descriptions
   */
  get getCardiacRhythmDescriptions(): string[] {
    return this._cardiacRhythmDescription;
  }

  get getRespiratoryWaveformDescriptionArray(): string[] {
    return this._respiratoryWaveformDescription;
  }

  /**
   * Return the literal translation of cardiacRhythm enumerator
   */
  get getCardiacRhythmDescription(): string {
    if (
      this.clinical !== undefined &&
      this.clinical.cardiacRhythm !== undefined
    )
      return this._cardiacRhythmDescription[this.clinical.cardiacRhythm];
    else return 'undefined';
  }

  get getRespiratoryWaveformDescription(): string {
    if (
      this.clinical !== undefined &&
      this.clinical.respiratoryWaveform !== undefined
    )
      return this._respiratoryWaveformDescription[
        this.clinical.respiratoryWaveform
      ];
    else return 'undefined';
  }

  /*************************************************************************************************
   * Function: Get Cardiac Rhythm Value
   * Purpose: allows for fetching cardiac rhythm data value
   *
   * Parameters:
   *    I: str: string representing the cardiac rhythm
   *
   * Return:
   *    O: returns a number representing the position within the enum list of cardiac rhythm options
   *************************************************************************************************/
  getCardiacRhythmValue(str: string): number {
    if (str === 'VENTACHY (nonperfusing)') {
      str = 'VENTACHY_1';
    } else if (str === 'VENTACHY (perfusing)') {
      str = 'VENTACHY_2';
    }
    return CardiacRhythm[str];
  }

  getRespiratoryWaveformValue(str: string): number {
    return RespiratoryWaveform[str];
  }

  /************************************************************************
   * Function: Get Cardiac Rhythim String
   * Purpose: an input int allows for the retrieval of the associated
   *          string in the list of cardiac rhythms
   *
   * Return: string representing selected cardiac rhythm
   **********************************************************************/
  getCardiacRhythmString(index: number): string {
    return this._cardiacRhythmDescription[index];
  }

  getRespiratoryWaveformString(index: number): string {
    return this._respiratoryWaveformDescription[index];
  }

  /***********************************************************************************************
   * Function: Copy Clinical State
   * Purpose: copies the values of the default state so that symptoms can obtain and set their own data
   *
   * Returns:
   *    O: returns an instance of a clinical state
   ***********************************************************************************************/
  copyClinicalState(): clinical {
    let clinicalCopy: clinical = Object.assign({}, this.clinical);

    return clinicalCopy;
  }
}
