<!-- <div class="dialog-container"> -->
<div class="dialog-container-header">
  <h2 mat-dialog-title>{{ data.name }}</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<!-- <hr> -->
<mat-dialog-content>
  <br />
  <mat-label>Transition Animation</mat-label>
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-select [(value)]="state">
      <!-- TODO: get a list of available symptoms -->
      <mat-option
        *ngFor="let item of animationTransitionService.transitionPrettyList"
        [value]="item"
        (click)="
          getValueForClinicalTransition(
            animationTransitionService.transitionPrettyList.indexOf(item)
          )
        "
        >{{ item }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <br />
  <mat-label>Transition Time</mat-label>
  <div style="width: 100%">
    <mat-form-field
      appearance="outline"
      style="padding-right: 13px; width: 188px"
    >
      <input autocomplete="off" matInput [(ngModel)]="time" />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 188px">
      <mat-select [(ngModel)]="mode">
        <mat-option value="seconds">Seconds</mat-option>
        <mat-option value="minutes">Minutes</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <div class="buttons">
           
            <span> -->
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-button class="green-focus" (click)="onDialogClose()">
    Add Transition
  </button>
  <!-- </span>
        </div> -->
</mat-dialog-actions>
<!-- </div> -->
