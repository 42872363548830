<!-- <app-editor-component></app-editor-component> && !router.url.startsWith('/editor') - It was decided that Sohail wants the persim bar in the editor anyway-->
<app-persim-header *ngIf="setHeaderStatus()"></app-persim-header>
<div [hidden]="ServerOnline" class="bar offlineMsg">
  You're currently offline, please connect to a network to access all features
</div>
<!-- " && !hasShowed && CallToHide() -->
<div *ngIf="ServerOnline" class="bar onlineMsg">
  You're back online!
</div>

<div class="screen-size-warning"  *ngIf="isTooSmall" [@enterAnimation]>
    <div class="screen-size-message">
      <mat-icon class="icon">aspect_ratio</mat-icon>
      <H1>Your window size is too small</H1>
      <H2
        >We recommend widening the window or using full-screen mode</H2
      >
      <button mat-flat-button color="primary" style="background-color: white;" (click)="onClickedClose()">I understand window size may distort some UI elements, continue as is</button><br>
      <br>
      <mat-checkbox (click)="onCheckChange()" [(ngModel)]="permaClose">Do not show this warning again</mat-checkbox>
    </div>
</div>

<router-outlet></router-outlet>
