<div class="mat-elevation-z8" style="height: 78px;">
    <div
        style="font-family: OpenSansLight; font-size: xx-large; padding-left: 25px; padding-top: 27px; padding-bottom: auto;">
        Your Account</div>
</div>
<div class="account-body">
    <div class="account-content">
        <div>
            <p style="font-size: small;">Rank and Name</p>
            <p style="font-size: medium;">{{account.fName}} {{account.lName}}</p>
        </div>
        <div>
            <p style="font-size: small;">PerSim Team</p>
            <p style="font-size: medium;">{{institutionName}}</p>
        </div>
        <div>
            <p style="font-size: small;">Email</p>
            <p style="font-size: medium;">{{account.email}}</p>
        </div>
        <div>
            <p style="font-size: small;">Password</p>
            <p style="font-size: medium;">****************</p>
        </div>
    </div>
    <!-- <button mat-flat-button style="color: #616161;" (click)="onEditInfo()">
        <mat-icon class="dropdown-color">
            create
        </mat-icon>
         Edit Info
    </button> -->
</div>