import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {   MatDialog } from '@angular/material/dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { DialogPromptComponent } from 'src/app/dialogs/dialog-prompt/dialog-prompt.component';
import { toolsservice } from 'src/app/editor/services/tools.service';
import { ServerService } from 'src/app/services/Server.service';
import { UploadService } from 'src/app/services/upload.service';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent implements OnInit {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('file') fileName: string;
  @Output() RequestChangeNotification: EventEmitter<void> = new EventEmitter();

  private specificFileType: string = null;
  public audioIconIndex = 0;
  private supportedFileTypes: string [] = [
    'ogg',
    'mp3',
    'docx',
    'doc',
    'pdf'
  ];

  constructor(
    private uploadService: UploadService,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    const links = this.fileName.split('.');
    this.specificFileType = links[links.length - 1];

    if (this.supportedFileTypes.includes(this.specificFileType)){
      switch (true){
        case this.specificFileType === 'ogg' || this.specificFileType === 'mp3':
          // if audio
          this.audioIconIndex = 0;
          break;
        case this.specificFileType === 'doc' || this.specificFileType === 'docx':
          // if microsoft doc
          this.audioIconIndex = 1;
          break;
        case this.specificFileType === 'pdf':
          // if pdf
          this.audioIconIndex = 2;
          break;
        default:
          break;
      }
    }
  }

  /**
   * Delete selected file from the server.
   */
  onDeleteFile(){
    const dialogRef = this.dialog.open(DialogPromptComponent, {
      width: '600px',
      data: {
        title: 'Warning!',
        message: 'Are you sure you wish to delete files! The simulation will continue to show the data, but no audio will be played after depletion',
        approve: 'I understand the risk - Continue',
       },
    });

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
        // Determine if the file is a doc or music..
        // easy way to find out is to look inside the filename struct for voice or auscultations - default is document.
        // If it's not voice/auscultation, pass only the filename.
        const isVoiceAusc = ( this.fileName.indexOf('Voices/') > -1 || this.fileName.indexOf('Auscultations/') > -1);
        if ( isVoiceAusc )
        {
          // hmm something wrong here then?
          this.uploadService.deleteUploadedAudio(this.fileName).subscribe( () => {
            this.RequestChangeNotification.emit();
          });
        } else {
          const list = this.fileName.split('/');
          const name = list[list.length - 1];
          this.uploadService.deleteFile(name).subscribe(() => {
            this.RequestChangeNotification.emit();
          });
        }
      }
    });
  }

  /**
   * Download file from the server
   * @param $event Event to stop further propagation.
   */
  public onClickDownload($event){
    const correctFileName = this.fileName.startsWith('Documents/') ? this.fileName.replace('Documents/', 'upload/') : this.fileName;
    const filePath = this.fileName.split('/');
    const downloadName = filePath[filePath.length - 1];
    const url = `${ServerService.File_URL}/${correctFileName}`;
    console.log(url);
    console.log(downloadName);
    this.http.get(url, { responseType: 'arraybuffer' })
    .subscribe(( data: any ) => {
      console.log('Received data', data);
      if ( data ) {
        this.download(downloadName, data );
        toolsservice.openSnackBar(this.matSnackBar, `Downloaded ${downloadName}`!, true);
      }
    });
    $event.stopPropagation();
  }

  /**
   *
   * @param fileName Name of the file
   * @param data Buffer
   */
  private download(fileName: string, data: any ){
    const mimeType = this.lookup(fileName);
    if ( mimeType ) {
      const blob = new Blob([data], { type : mimeType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    } else {
      console.error('Unhandle exception type for this file!', fileName);
    }
  }

  /**
   * Convert extention to mime type for blob cast.
   * @param filename file name
   */
  private lookup(filename: string ){
    const list = filename.split('.');
    let ext = '';
    if ( list.length <= 1 ) { ext = filename.toLowerCase(); }
    else { ext = list[list.length - 1].toLowerCase(); }

    switch ( ext ) {
      case 'json': return 'application/json';
      case 'pdf': return 'application/pdf';
      case 'doc': return 'application/msword';
      case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'ogg': return 'audio/ogg';
      case 'mp3': return 'audio/mpeg';
      case 'mp4': return 'video/mp4';
      case 'txt': return 'text/plain';
      case 'wav': return 'audio/wav';
      case 'm4a': return 'audio/m4a';
      default: return undefined;
    }
  }
}
