import { Simulation } from './simulation.model'

export enum Mode {
    CHOOSE,
    LIST,
}

export class MobileDevice {
    public selected = false;
    public deviceID : string;
    public serialNo : string;
    public port: number | string;
    public status : string;
    public IsWIFI = false;
    public files: File[] = [];
    constructor(
        public name: string, 
        public simulationCount: number, 
        public fileCount: number, 
        public simulations: Simulation[]
    ){
        this.deviceID = this.name;
    }
}