import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toolsservice } from '../../services/tools.service';

export interface DialogData {
  title: string;
  name: string;
  description: string;
  action: string;
}

@Component({
  selector: 'app-dialog-symptom-edit',
  templateUrl: './dialog-symptom-edit.component.html',
  styleUrls: ['./dialog-symptom-edit.component.scss']
})
export class DialogSymptomEditComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSymptomEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(){}

  onDisableCheck(): boolean{
    return (!toolsservice.isNullUndefinedEmpty(this.data.name) && !toolsservice.isNullUndefinedEmpty(this.data.description));
    // return !(this.data.name.length > 0 && this.data.description.length > 0);
  }

  onAcceptExecute() {
    this.data.action = "Success";
    this.dialogRef.close(this.data);
  }

  onResetExecute() {
    this.data.action = "Revert";
    this.dialogRef.close(this.data);
  }

}
