import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { clinical } from 'src/app/models/statedata.model';
import { ClinicalControllerService } from 'src/app/controllers/clinical-controller.service';
import { Alarms } from 'src/app/models/alarms.model';

export interface DialogData {
  name: string;
  clinical: clinical;
  gender: string;
}

const HR_MAX: number = 200;
const HR_MIN: number = 0;
const RR_MAX: number = 40;
const RR_MIN: number = 0;
const SAO2_MAX: number = 100;
const SAO2_MIN: number = 0;
const ETCO2_MAX: number = 100;
const ETCO2_MIN: number = 0;
const SBP_MAX: number = 300;
const SBP_MIN: number = 50;
const DBP_MIN: number = 20;
const DBP_MAX: number = 200;
const TEMP_MIN: number = 85;
const TEMP_MAX: number = 109;

@Component({
  selector: 'app-dialog-vitals',
  templateUrl: './dialog-vitals.component.html',
  styleUrls: ['./dialog-vitals.component.scss']
})
export class DialogVitalsComponent implements OnInit {

  public clinicalControllerService: ClinicalControllerService = new ClinicalControllerService();

  public cardiacRhythmOptions: string [] = [];
  public capnographyOptions: string [] = [];

  public cardiacRhythmSelection: string;
  public capnographySeleciton: string;

  public heartRate: number;
  public respRate: number;
  public SaO2Rate: number;
  public EtCO2Rate: number;
  public sBPRate: number;
  public dBpRate: number;
  public temp: number;

  public alarms: Alarms = new Alarms();

  constructor(public dialogRef: MatDialogRef<DialogVitalsComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {

    this.clinicalControllerService.clinical = this.data.clinical;

    this.heartRate = HR_MIN;
    this.respRate = RR_MIN;
    this.SaO2Rate = SAO2_MIN;
    this.EtCO2Rate = ETCO2_MIN;
    this.sBPRate = SBP_MIN;
    this.dBpRate = DBP_MIN;
    this.temp = TEMP_MIN;

    this.cardiacRhythmOptions = [
      'AFIB',
      'ASYSTOLE',
      'ATRIALFLUTTER',
      'AVB3',
      'MOBITZ1',
      'MOBITZ2',
      'NORMAL',
      'SVT',
      'TORSADES',
      'VENTTACHY (nonperfusing)',
      'VENTTACHY (perfusing)',
      'VFIB',
    ]

    this.capnographyOptions = [
      'NORMAL RESP',
      'HYPO',
      'HYPER',
      'CPR',
      'ROSC',
      'SUDDEN LOSS',
      'DECREASING',
      'SEDATION',
    ]

    this.cardiacRhythmSelection = this.cardiacRhythmOptions[6];
    this.capnographySeleciton = this.capnographyOptions[0];

  }

}
