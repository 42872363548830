import { toolsservice } from '../editor/services/tools.service';

export enum DeviceType {
    IVPump = "IV Pump",
    Ventilator = "Ventilator",
}

export class Device {
    public id: string;
    public kind: DeviceType;
    constructor() {
        if( this.id === null || this.id === undefined )
            this.id = toolsservice.makeId();
    }
}