import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
// import { Teammember } from '../../../models/teammember.model'

export interface DialogData{
  name: string;
  teammembers: User[];
}

@Component({
  selector: 'app-dialog-transfer-published-simulations',
  templateUrl: './dialog-transfer-published-simulations.component.html',
  styleUrls: ['./dialog-transfer-published-simulations.component.scss']
})
export class DialogTransferPublishedSimulationsComponent implements OnInit {

  public selectedTeamMember: User = this.data.teammembers[0];

  constructor(
    public dialogRef: MatDialogRef<DialogTransferPublishedSimulationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void { }

}
