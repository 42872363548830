<div class="dialog-container">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h2 mat-dialog-title style="font-family: OpenSansLight;"> Your Account </h2>
        <button mat-icon-button style="margin-left: auto; transform: translate(5px,-5px);" mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    <hr>
    <mat-dialog-content>
        <!-- NAME: required -->
        <!-- <mat-label>Rank and Name *</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
            <input autocomplete="off" matInput placeholder="Lt. Col. Shane Runyon" [(ngModel)]="data.account.name" required>
            <mat-error *ngIf="data.account.name === ''">member name required</mat-error>
        </mat-form-field> -->
        <!-- TEAM -->
        <!-- <mat-label>Team</mat-label> -->
        <!-- <p style="font-size: large; margin-top: 10px;">{{data.account.persimTeam}}</p> -->
        <br>
        <!-- EMAIL: required -->
        <mat-label>Email *</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
            <input autocomplete="off" matInput placeholder="shane.s.runyon.mil@mail.mil" [(ngModel)]="data.account.email" required>
            <mat-error *ngIf="data.account.email === ''">email required</mat-error>
        </mat-form-field>
        <br>
        <button mat-flat-button style="color: #616161" (click)="onChangePassword()">Change Password</button>
    </mat-dialog-content>
    <br>
    <mat-dialog-actions>
        <div class="buttons">
            <span>
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button mat-dialog-close
                    [mat-dialog-close]="data.account" 
                    style="background-color: #1a844f; color: white;" 
                    [ngStyle]="{'background-color':'#1a844f'}" 
                    >Save</button>
            </span>
        </div>
    </mat-dialog-actions>
</div>
