

export enum UnitOfMeasure {
    none = 0,
    mg = 1,
    Gm = 2,
    Un = 3,
    mUn = 4, 
    mEq = 5,
    mMol = 6,
    nG = 7,
    mcg = 8,
    kg = 9,
}

export enum UnitOfMeasureString{
    none = "none",  
    mg = "mg",
    Gm = "Gm",
    Un = "Un",
    mUn = "mUn",
    mEq = "mEq",
    mMol = "mMol",
    nG = "nG",
    mcg = "mcg",
    kg = "kg",
}

export class UOMConverter {
    constructor(){

    }

    public getUOMString(uom: number): string{
        let uomArray = Object.keys(UnitOfMeasureString);
        let uomString: string = uomArray[uom];
        console.log(uomArray);
        console.log(uomString);
        return uomString;
    }

    public getUOMNumber(uom: string): number{
        let uomNumber: number = UnitOfMeasure[uom];
        return uomNumber;
    }
}