import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RolesService } from './services/roles.service';
import { AuthService } from './auth/auth.service';
import {
  Permissions,
  PermissionsService,
} from './services/permissions.service';
import { Role } from './models/role.model';


@Injectable({
  providedIn: 'root',
})
export class AdminGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let evaluation: boolean = (this.authService.getAdminPermission() & Permissions.Read) !== 0;

    //return to the dashboard if the user tries to manually navigate to the admin page without permission
    if(!evaluation){
      this.router.navigate(['/','dashboard']);
    }

    return evaluation;
  }
}
