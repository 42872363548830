import { Injectable, OnDestroy } from '@angular/core';
import { scenario } from '../models/scenario.model';
import { LinkedScenarioData } from '../models/linkedscenarios.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, interval, Subscription } from 'rxjs';
import { ServerService } from '../services/Server.service';
import { JsonPipe } from '@angular/common';
import { FileDataholder } from '../scenario-card/dialogs/dialog-upload-file/dialog-upload-file.component';
// import Config from '../../assets/configs/Servercfg.json';
import { downloadData } from '../models/downloadData.model';
// switch between using RxDB and using Json files.// is it possible to wait and see if he's not loading or anything?
// let offlineMode = false;

@Injectable({
  providedIn: 'root'
})
export class ScenariosService {
  public scenarios: scenario[] = [];

  // Create new
  public createScenario(scenarioObj: scenario): Observable<any> {
    // || !( scenarioObj instanceof scenario )
    if (!(scenarioObj)) {
      return new Observable<any>((observer) => {
        observer.next(null);
        observer.complete();
      });
    }
    this.scenarios.push(scenarioObj);

    if (ServerService.OfflineMode) {
      return new Observable<any>((observer) => {
        observer.next({ id: scenarioObj.ScenarioId });
        observer.complete();
      });
    } else {

      // header information
      const httpHeaders = new HttpHeaders({
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': ServerService.Server_URL
      });

      // post scenario to the server
      return this.http.post<string>(ServerService.Scenario_URL, scenarioObj, { headers: httpHeaders });
    }
  }
  public downloadAudioFile(audioId:string):Observable<Blob>
  {
    const httpHeaders = new HttpHeaders({
      'Content-type': 'application/octet-stream',
      Accept: 'application/octet-stream',
      'Access-Control-Allow-Origin': ServerService.GetCustomAudioFile_URL(audioId)
    });
    return this.http.get(ServerService.GetCustomAudioFile_URL(audioId), { responseType:'blob', headers: httpHeaders });
  }
  public getCustomAudioFiles(): Observable<any[]> {
    const httpHeaders = new HttpHeaders({
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': ServerService.CustomAudio_URL()
    });
    const audiofiles: Observable<any[]> = this.http.get<any[]>(ServerService.CustomAudio_URL(), { headers: httpHeaders });
    return audiofiles;
  }
  public deleteCustomAudioFile(audiofileId: string):Observable<string> {
    const httpHeaders = new HttpHeaders({
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': ServerService.DeleteCustomAudioFile_URL(audiofileId)
    });
    const options = {
      headers: httpHeaders,
    };
    const operationResult: Observable<string> = this.http.delete<string>(ServerService.DeleteCustomAudioFile_URL(audiofileId), options);
    return operationResult;
  }

  public dashboardScenario(): Observable<any[]> {
    if (ServerService.OfflineMode) {
      return new Observable<any[]>(observer => {
        const list: scenario[] = [];
        this.scenarios.sort(c => c.modifiedDate).forEach(s => list.push(s));
        observer.next(list);
        observer.complete();
      });
    }
    else {
      // for some reason this object is being deserialized into a array of string instead of actual object... why???
      return this.http.get<any[]>(ServerService.Scenario_URL);
    }
  }
  public getTeamLinkedScenarios(): Observable<LinkedScenarioData[]> {
    return this.http.get<LinkedScenarioData[]>(ServerService.TeamLinkedScenario_URL);
  }

  public getUserScenarios(): Observable<scenario[]> {
    return this.http.get<scenario[]>(ServerService.Scenario_URL);

  }
  public scenariosDownloadCount():Observable<downloadData[]> {

    const result=  this.http.get<downloadData[]>(ServerService.ScenarioDownloadCount_URL);
    return result;

  }
  public async postDownloadCount(scenario: scenario): Promise<any> {
    const result = await this.http.post<boolean>(ServerService.ScenarioDownloadCount_URL, scenario).subscribe();
    return result;
  }


  public readScenario(scenarioId: string): Observable<scenario> {
    if (ServerService.OfflineMode) {
      return new Observable<scenario>((observer) => {
        let tempScenario: scenario = null;
        this.scenarios.forEach((scenarioObj) => {
          if (scenarioId === scenarioObj.ScenarioId) {
            tempScenario = scenarioObj;
          }
        });
        observer.next(tempScenario);
        observer.complete();
      });
    } else {
      return this.http.get<scenario>(`${ServerService.Scenario_URL}/${scenarioId}`);
      // return this.http.get<scenario>(`${ServerService.Scenario_URL}/scenarioId`);
    }
  }

  // return true if submitted else false if something fails really this should be save?
  public updateScenario(scenarioObj: scenario): Observable<any> {
    if (ServerService.OfflineMode) {
      return new Observable<any>(observer => {
        observer.next(true);
        observer.complete();
      });
    } else {
      return this.http.put<scenario>(ServerService.Scenario_URL, scenarioObj);
    }
  }

  // true if destroyed success, false if fails
  public deleteScenario(scenarioId: string): Observable<any> {
    if (ServerService.OfflineMode) {
      // this._dummyScenario = null;
      for (let index = 0, n = this.scenarios.length; index < n; index++) {
        if (this.scenarios[index].ScenarioId === scenarioId) {
          this.scenarios.splice(index, 1);
          break;
        }
      }
      return new Observable<any>((observer) => {
        observer.next(true);
        observer.complete();
      });
    } else {
      return this.http.delete(`${ServerService.Scenario_URL}/${scenarioId}`);
    }
  }

  public getAllFiles(): Observable<any> {
    return this.http.get(ServerService.File_URL);
  }


  /**
   * Toggle user favorites on simulation
   * 0th index author is the original author
   * @param scenario The simulation user favorites to.
   */
  public toggleFavorites(scenarioObj: scenario): Observable<any> {
    const data = {
      userId: scenarioObj.authors[0]._id,
      scenarioId: scenarioObj.ScenarioId
    };
    return this.http.put(ServerService.Favorite_URL, data);
  }

  /**
   * Update the simuation ratings (Works for both team simulation based and current dashboard list )
   * 0th index author is the original author
   * @param scenario The team simulation you wish to update (This also works for current dashboard simulation if requested)
   * @param rating The points user feed into the rating system (1-5 only!)
   */
  public updateRatings(scenarioObj: scenario, ratingNumber: number): Observable<any> {

// data must be same as UpdateTicket on Rating.controller on API
    const data = {
      ownedUserId: scenarioObj.authors[0]._id,
      scenarioId: scenarioObj.ScenarioId,
      rating: ratingNumber
    };
    return this.http.put(ServerService.Rating_URL, data);
  }

  // download Json file to storage - ask user for permission...
  // public downloadScenario( _scenario : scenario, _path : string ) : string {
  //   // between web and electron build we'll have to mitigate actions.
  //   // For now this service will be intended to use for web development, and electron will have to code differently to utilize FS module.
  //   // we need a _path to know where we're saving the files to. then convert this scenarioId
  //   // hmm not necessary.. we'll just download from the server, and then ask the user to save it to their desktop.
  //   return null;
  // }

  // allow web user to uplaod json files from local storage to the web build.
  // public uploadScenario( _scenario : scenario ) : boolean {
  //   // TODO: Call upload function to NestJS

  //   return false;
  // }

  /**
   * function that recursively searches for a string and returns the searched for string if no string was found. used to build off of duplicate scenario names
   * @param scenarioNames the list of scenario names to check against
   * @param newScenarioName the name of the scenario
   * @param index the index to start at
   */
  checkNameDoesNotExistRecursion(scenarioNames: string[], newScenarioName: string, index: number): string {
    if (scenarioNames.find(element => element.toLowerCase() === newScenarioName.toLowerCase()) === undefined)
      return newScenarioName;
    if (index > 0) {
      newScenarioName = newScenarioName.slice(0, newScenarioName.length - 4);
    }
    index = index + 1;
    newScenarioName = `${newScenarioName} - ${index}`;

    return this.checkNameDoesNotExistRecursion(scenarioNames, newScenarioName, index);
  }

  constructor(private http: HttpClient) { }
}
