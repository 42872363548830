import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    // intercept any Http request before making final call.
    intercept ( req: HttpRequest<any>, next: HttpHandler ) : Observable<HttpEvent<any>>{
        // retrieve the token in local storage
        const idToken = this.authService.getToken();
        // if the token exist.
        if(idToken){
            // clone the header and then modify to include authorization token
            const cloned = req.clone({
                headers : req.headers.set("Authorization", "Bearer " + idToken)
            });

            // return the modified headers
            return next.handle(cloned);
        } else {
            // return normal handle (With no modification)
            return next.handle(req);
        }
    }
}