<h2 mat-dialog-title>
  Create Your Simulation Timeline ({{ currentIndex + 1 }}/6)
</h2>

<mat-dialog-content class="content-row">
  <div style="padding-right: 20px">
    <div *ngIf="currentIndex === 0">
      <h3>
        <strong>First, begin creating your scenario timeline</strong> by
        creating a patient story arc from start to finish by adding phases the
        patient will go through during their treatment.
      </h3>
      <h3>
        During a live PerSim scenario, learners will move to the next phase
        after successfully treating the patient with the treatments you indicate
        inside of a phase.
      </h3>
    </div>
    <div *ngIf="currentIndex === 1">
      <h3>
        <strong>Next, add patient symptoms</strong> to a phase by dragging them
        from the list of available symptoms and dropping them inside the phase
        container.
      </h3>
    </div>
    <div *ngIf="currentIndex === 2">
      <h3>
        Once you have symptoms inside your phase, you can add attributes like
        sounds and injury textures to the patient animation.
      </h3>
      <h3>
        <strong>To add attributes</strong>, first hover over a symptom to reveal
        that symptom's menu icon. Next, click the menu icon to show the menu.
      </h3>
    </div>
    <div *ngIf="currentIndex === 3">
      <h3>
        After you add attributes to your symptoms, you'll see an icon for each
        attribute type.
      </h3>
      <h3>
        <strong>Hovering</strong> over an attribute icon will display
        information about that attribute.
      </h3>
      <h3>
        <strong>Clicking</strong> an attribute icon will allow you to edit or
        delate the applied attribute.
      </h3>
    </div>
    <div *ngIf="currentIndex === 4">
      <h3>
        You can also add treatment points or instructor notes into your phases.
      </h3>
      <div
        style="
          border-left: solid;
          border-width: 5px;
          border-color: orange;
          padding-left: 10px;
        "
      >
        <h3>
          <strong>Treatment points</strong> identify how the patients symptoms
          should be treated by the simulation learner before moving on to the
          next phase.
        </h3>
      </div>
      <div
        style="
          border-left: solid;
          border-width: 5px;
          border-color: palegreen;
          padding-left: 10px;
        "
      >
        <h3>
          <strong>Instructor Notes</strong> are information the instructor
          should know about a particular part of the simulation.
        </h3>
      </div>
    </div>
    <div *ngIf="currentIndex === 5">
      <h3>
        When your simulation is finished, you can watch it in the preview window
        to make sure everything works the way you wanted.
      </h3>
      <h3>
        <strong>Share your finished simulation</strong> with the rest of your
        team by clicking the Share with Team button at the top-right of the
        screen.
      </h3>
    </div>
  </div>
  <div style="height: 510px">
    <!-- ../../../ -->
    <img
      src="assets/TutorialImages/tutorial-image-{{ currentIndex + 1 }}.png"
      alt="tutorial-image"
      width="450"
    />
  </div>
</mat-dialog-content>
<br />
<mat-dialog-actions align="end">
  <h3>
    <mat-checkbox *ngIf="showCheckMessage" [(ngModel)]="checked"
      >Don't show this again.</mat-checkbox
    >
  </h3>
  <span>
    <button
      *ngIf="showPreviousButton"
      mat-button
      color="primary"
      style="
        background-color: white;
        color: #1a844f;
        border: solid;
        border-color: #1a844f;
        box-sizing: border-box;
      "
      (click)="onPressedPrevious()"
    >
      Previous
    </button>
    <button
      *ngIf="showNextButton"
      mat-raised-button
      style="
        background-color: #1a844f;
        color: white;
        border-radius: 0px;
        border: none;
      "
      (click)="onPressedNext()"
    >
      Next
    </button>
    <button
      *ngIf="showDoneButton"
      mat-button
      [mat-dialog-close]="checked"
      class="green-focus"
    >
      Done
    </button>
  </span>
</mat-dialog-actions>
