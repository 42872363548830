import { Component, Inject, OnInit } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

//TODO: do we even need this?
export interface DialogData {
  name: string,
  age: string,
}

@Component({
  selector: 'app-dialog-baby-warning',
  templateUrl: './dialog-baby-warning.component.html',
  styleUrls: ['./dialog-baby-warning.component.scss'],
})
export class DialogBabyWarningComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogBabyWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
