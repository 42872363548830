import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import _ from 'underscore';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ratedUser, scenario } from '../models/scenario.model';
import { UntypedFormControl, Validators } from '@angular/forms';
import {   MatSelectChange } from '@angular/material/select';
import { Author } from '../models/author.model';
import { Observable, of } from 'rxjs';

import {   MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PersimHeaderComponent } from '../persim-header/persim-header.component';
import { FeatureKeys } from '../services/featureflag.service';
import { map, startWith } from 'rxjs/operators';


// can't I just make a datamodel for this so that any data can be accepted here???
export class SearchContainer {
  name: string;
  tags: string[];
  object: scenario;
  downloadCount: number;
  constructor(name: string, tags: string[], object: scenario, downloadcount: number) {
    this.name = name;
    this.tags = tags;
    this.object = object;
    this.downloadCount = downloadcount


  }

}


export enum SortByOptions {
  LastModified = 0,
  ScenarioName = 1,
  ScenarioRating = 2,
  ScenarioDownloadCount = 3
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    // animation triggers go here
    trigger('openClose', [
      state(
        'open',
        style({
          maxWidth: '200px',
          width: '200px',
          opacity: 1,
          backgroundColor: 'transparent',
        })
      ),
      state(
        'closed',
        style({
          maxWidth: '0px',
          width: '0px',
          opacity: 1,
          backgroundColor: 'transparent',
        })
      ),
      transition('open <=> closed', [animate('0.2s')]),
    ]),
  ],
})


export class SearchComponent implements OnInit {

  searchAuthorControl = new UntypedFormControl('');
  @Input() showTag = true;

  @Input() isYourScenarioTab: boolean = true;
  @Input() authors: Author[] = [];
  public authorsObservable: Observable<Author[]>;
  public isDownloadCountEnabled: boolean;
  public isRatingEnabled: boolean;
  selectedTtypes: string[] = [];


  private _searchArray: SearchContainer[] = [];
  // Getter and setters for search array values. We need to find a way to update the tag setup in case we need to load another instances
  @Input() public set searchArray(value: SearchContainer[]) {
    this._searchArray = value;
    this.tagSetup();
    this.showSearch = true;
  }
  public get searchArray(): SearchContainer[] {
    return this._searchArray;
  }

  @Output() searchArrayChange = new EventEmitter<SearchContainer[]>();

  public showSearch: boolean = false;
  public isTagDrawrOpen: boolean = false;
  public selectedOrderText: string;
  public searchWord: string = '';
  public existingTags: string[] = [];
  public selectedTags: string[] = [];
  public isSearchByDescription: boolean = false;
  public currentSortOption: SortByOptions
  public isSortingSelections: boolean[] = [true, false]; // two sorting types first one is selected by default

  private notSharedScenarios: string = 'Not Shared';
  private sharedWithTeam: string = 'Shared With Team';
  private sharedWithCommunity: string = 'Shared With Community';
  selectedItems: string[] = [];
  selectedAuthor: Author;
  scenarioTypeList: string[] = [this.notSharedScenarios, this.sharedWithTeam, this.sharedWithCommunity];
  ngOnInit(): void {
    this.currentSortOption = SortByOptions.LastModified;
    this.onselectSortType(this.currentSortOption);
    this.sortUpdateDateDesc(0);
    this.authorslists(this.authors);
    this.authorsObservable = this.searchAuthorControl.valueChanges.pipe(
      startWith(''),
      map(x => (x ? this._filterAuthors(x) : this.authors.slice())),
    );
  }
  private _filterAuthors(value: string): Author[] {
    const filterValue = value.toLowerCase();
    var result = this.authors.filter(x => x?.fName.toLowerCase().includes(filterValue) || x?.lName.toLowerCase().includes(filterValue));
    return result;
  }
  ngAfterViewInit(): void {
    this.currentSortOption == SortByOptions.LastModified;
    this.onselectSortType(this.currentSortOption);
    this.sortUpdateDateDesc(0);
    this.isDownloadCountEnabled = PersimHeaderComponent.featureList.find(x => x.featureName == FeatureKeys.DownloadCountSystem).isEnabled;
    this.isRatingEnabled = PersimHeaderComponent.featureList.find(x => x.featureName == FeatureKeys.RatingSystem).isEnabled;
  }
  authorslists(value: Author[]) {

    this.authorsObservable = new Observable((observer) => {
      observer.next(value);
      observer.complete();
    });
  }
  onAuthorChange(event: MatAutocompleteSelectedEvent) {

    const selectedAuthor = this.authors.find(
      author => (author.fName + ' ' + author.lName) === event.option.value
    );
    this.filterByAuthorId(selectedAuthor._id);
  }

  onSelectionChange(event: MatSelectChange) {
    this.selectedItems = event.value;
    this.filter(false);
  }
  public isSelectedTag(tag): boolean {
    return this.selectedTags.includes(tag);
  }

  public onselectSortType(sortByOption: SortByOptions) {
    this.currentSortOption = sortByOption;
  }
  private calculateRating(ratedUsers: ratedUser[]): number {
    let totalRating = 0;
    if (ratedUsers != null && ratedUsers.length > 0) {
      for (const user of ratedUsers) {
        totalRating += user.rated;
      }
      let rateCount = ratedUsers.length;
      totalRating = Math.round(
        totalRating / rateCount);
    }
    return totalRating;
  }



  public onSelectTag(tag: string): void {
    const index = this.selectedTags.indexOf(tag);
    if (index > -1) {
      this.selectedTags.splice(index, 1);
    } else {
      this.selectedTags.push(tag);
    }
    this.filter(this.isSearchByDescription);
  }

  public tagSetup(): void {
    // convert the object into scenario[] typecast, then for each scenarios's tags, we'll push the string into the existing tags
    this.existingTags = [];
    this.searchArray.forEach((element: SearchContainer) => {
      element.tags?.forEach((tag: string) => {
        this.existingTags.push(tag);
      })
    });

    // remove duplicate entry.
    this.existingTags = _.union(this.existingTags);
  }

  public onPrintSearchWord($event): void {
    this.filter(this.isSearchByDescription);
  }

  public onCancel() {
    this.searchWord = '';
    this.filter(this.isSearchByDescription);
  }

  //sorts the arrays and presents them in alphabetical order
  public onSortArray(index: number) {
    this.isSortingSelections.fill(false)
    this.isSortingSelections[index] = true;
    this.selectedOrderText = "Scenario name  A-Z";
    //complicated searching algorithm
    this.searchArray.sort(this.compare);
    this.filter(this.isSearchByDescription);
  }
  //sorts the arrays and then presents the data in reverse order
  public reverseSort(index: number) {
    this.isSortingSelections.fill(false)
    this.isSortingSelections[index] = true;
    this.selectedOrderText = "Scenario name  Z-A";
    //complicated searching algorithm
    this.searchArray.sort(this.compare);
    this.searchArray.reverse();
    this.filter(this.isSearchByDescription);
  }

  public sortUpdateDateAsc(index: number) {
    this.selectedOrderText = "Last modified date oldest on top";
    this.isSortingSelections.fill(false);
    this.isSortingSelections[index] = true;
    this.searchArray.sort((a: SearchContainer, b: SearchContainer) => {
      return a.object.modifiedDate - b.object.modifiedDate
    });
    this.filter(this.isSearchByDescription);
  }
  public sortUpdateDateDesc(index: number) {
    this.isSortingSelections.fill(false);
    this.isSortingSelections[index] = true;
    this.selectedOrderText = "Last modified date newest on top";
    this.searchArray.sort((a: SearchContainer, b: SearchContainer) => {
      return a.object.modifiedDate - b.object.modifiedDate
    });
    this.searchArray.reverse();
    this.filter(this.isSearchByDescription);
  }
  public sortByRateOneFive(index: number) {
    this.isSortingSelections.fill(false);
    this.isSortingSelections[index] = true;
    this.selectedOrderText = "Order by rate 1-5 Stars";
    this.searchArray.forEach(x => x.object.rating = this.calculateRating(x.object.ratedUsers));
    this.searchArray.sort((a: SearchContainer, b: SearchContainer) => {
      return a.object.rating - b.object.rating
    });
    this.filter(this.isSearchByDescription);
  }
  public sortByRateFiveOne(index: number) {
    this.isSortingSelections.fill(false);
    this.isSortingSelections[index] = true;
    this.selectedOrderText = "Order by rate 5-1 Stars";
    this.searchArray.forEach(x => x.object.rating = this.calculateRating(x.object.ratedUsers));
    this.searchArray.sort((a: SearchContainer, b: SearchContainer) => {
      return a.object.rating - b.object.rating
    });
    this.searchArray.reverse();
    this.filter(this.isSearchByDescription);
  }
  public sortByDownloadCountAsc(index: number) {

    this.isSortingSelections.fill(false);
    this.isSortingSelections[index] = true;
    this.selectedOrderText = "Order by Downloads Ascending";
    this.searchArray.sort((a: SearchContainer, b: SearchContainer) => {
      return a.downloadCount - b.downloadCount
    });

    this.filter(this.isSearchByDescription);
  }
  public sortByDownloadCountDesc(index: number) {
    this.isSortingSelections.fill(false);
    this.isSortingSelections[index] = true;
    this.selectedOrderText = "Order by Downloads Descending";
    this.searchArray.sort((a: SearchContainer, b: SearchContainer) => {
      return a.downloadCount - b.downloadCount
    });
    this.searchArray.reverse();
    this.filter(this.isSearchByDescription);


  }

  public onSimulationNameSearch() {
    this.isSearchByDescription = false;
    this.filter(this.isSearchByDescription);

  }

  public onSimulationDescriptionSearch() {
    this.isSearchByDescription = true;
    this.filter(this.isSearchByDescription);

  }

  //comparator for scenarios to be sorted by name
  private compare(a: SearchContainer, b: SearchContainer) {
    return a.name.localeCompare(b.name);
  }
  private filterByAuthorId(authorId: string) {
    let tempArray: SearchContainer[] = this.searchArray;

    tempArray = tempArray.filter((container: SearchContainer) =>
      container.object.originalAuthor?._id == authorId);
    this.searchArrayChange.emit(tempArray);
  }

  //filter simulations by a searched keyword and tags if any
  private filter(isDescription: boolean): void {
    let tempArray: SearchContainer[] = this.searchArray;
    // by default system will search for name and description
    if (this.searchWord.length > 0 && !isDescription) {
      tempArray = tempArray.filter((container: SearchContainer) =>
        container.name.toLowerCase().includes(this.searchWord.toLowerCase())
        || (container.object.scenarioDescription != null
          && container.object.scenarioDescription.toLowerCase().includes(this.searchWord.toLowerCase()))
      );
    }
    else if (this.searchWord.length > 0 && isDescription) {
      tempArray = tempArray.filter((container: SearchContainer) => container.object.scenarioDescription != null
        && container.object.scenarioDescription.toLowerCase().includes(this.searchWord.toLowerCase())
      );
    }

    // then filter out the remaining container by tags.
    if (this.showTag && this.selectedTags.length > 0) {
      tempArray = tempArray.filter((container: SearchContainer) => {
        return this.selectedTags.every(x => container.tags.includes(x))
      })
    }
    if (this.isYourScenarioTab) {
      // no need filterfor All option.
      if (this.selectedItems.indexOf(this.notSharedScenarios) > -1) {
        tempArray = tempArray.filter((container: SearchContainer) => container.object && container.object.isShared == false && container.object.isCommunityShared == false);
      }
      if (this.selectedItems.indexOf(this.sharedWithCommunity) > -1) {
        tempArray = tempArray.filter((container: SearchContainer) => container.object && container.object.isCommunityShared == true);
      }
      if (this.selectedItems.indexOf(this.sharedWithTeam) > -1) {
        tempArray = tempArray.filter((container: SearchContainer) => container.object && container.object.isShared == true);
      }


    }
    // return the result
    this.searchArrayChange.emit(tempArray);
  }
}
