import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData{
  name: string;
  description: string;
}

@Component({
  selector: 'app-dialog-delete-phase',
  templateUrl: './dialog-delete-phase.component.html',
  styleUrls: ['./dialog-delete-phase.component.scss']
})
export class DialogDeletePhaseComponent implements OnInit {

  checked:boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogDeletePhaseComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.checked = false;
  }

}
