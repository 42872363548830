
export class FileData {
  filePath: string;
  fileType: FileType;
  fileId: string;
  fileName: string;
  fileHash:string;
}
export enum FileType {
  LUNG_AUDIO = 'Lung Audio',
  HEART_AUDIO = 'Heart Audio',
}

export class customAudioModel extends FileData
{

}
