import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toolsservice } from '../../services/tools.service';

export interface DialogData {
  name: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-dialog-instructor-point',
  templateUrl: './dialog-instructor-point.component.html',
  styleUrls: ['./dialog-instructor-point.component.scss']
})
export class DialogInstructorPointComponent implements OnInit {

  formText:string = '';

  constructor(public dialogRef: MatDialogRef<DialogInstructorPointComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.formText = this.data.description;
  }

  isDisabled(){
    return toolsservice.isNullUndefinedEmpty(this.formText);
  }

  onPressedDone(){

  }

  getErrorMessage() {
    return 'field must not be left blank';
  }

}
