<div class="dialog-container">
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <h2 mat-dialog-title style="font-family: OpenSansLight">
      Change password for {{this.data.username}}:
    </h2>
    <!--TODO: close button using material ui-->
    <button
      mat-icon-button
      style="margin-left: auto; transform: translate(5px, -5px)"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <hr />
  <mat-dialog-content>
    <div *ngIf="this.data.password !== null">
      <mat-label>Old Password *</mat-label>
      <mat-form-field appearance="outline" style="width: 100%">
        <input autocomplete="off" matInput required [(ngModel)]="oldPasswordAttempt" (focusout)="ValidateOldPassword()"/>
        <mat-error *ngIf="oldPasswordAttempt === ''">
          Old password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <p *ngIf="!oldPasswordValid" style="color:red">
        old password does not match your current password!
      </p>
    </div>

    <mat-label>New Password *</mat-label>
    <mat-form-field appearance="outline" style="width: 100%">
      <input autocomplete="off" matInput required [(ngModel)]="newPassword"/>
      <mat-error *ngIf="!onValidateNewPassword()"
        >New passwrod required</mat-error
      >
    </mat-form-field>
    <mat-label>Re-enter New Password *</mat-label>
    <mat-form-field appearance="outline" style="width: 100%">
      <input autocomplete="off" matInput required [(ngModel)]="reTypePassword"/>
      <mat-error *ngIf="!onValidateRetypePassword()"
        >New passwrod required</mat-error
      >
    </mat-form-field>
    <br />
  </mat-dialog-content>
  <br />
  <mat-dialog-actions>
    <div class="buttons">
      <span>
        <button mat-button color="primary" mat-dialog-close>Cancel</button>
        <button
          mat-raised-button
          [disabled]="!canSubmit()"
          [mat-dialog-close]="reTypePassword"
          style="background-color: #1a844f; color: white"
        >
          Change Password
        </button>
      </span>
    </div>
  </mat-dialog-actions>
</div>
