import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import appPackage from 'package.json';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private get baseUrl(): string {
    return appPackage.backendServerPath + '/roles';
  }

  constructor(private http: HttpClient) { }

  /**
   * Get a list of all available roles
   */
  public getRoles(): Observable<any> {
    return this.http.get(this.baseUrl);
  }

  /**
   * Get one role using a known roleID
   * @param roleId: the role id of the role to obtain from the backend
   */
  public getRole( roleId: string ): Observable<any> {
    return this.http.get(`${this.baseUrl}/${roleId}`);
  } 

  public getAssignmentRoles() : Observable<any> {
    return this.http.get(`${this.baseUrl}/assignment`);
  }
}
