import { formatCurrency } from '@angular/common';
import { IfStmt } from '@angular/compiler';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogNewSimulationsComponent } from 'src/app/devices/dialogs/dialog-new-simulations/dialog-new-simulations.component';
import { toolsservice } from 'src/app/editor/services/tools.service';
import {
  Channel,
  ChannelAlarm,
  ChannelMode,
  SetupMode,
} from 'src/app/models/channel.model';
import { Line } from 'src/app/models/line.model';
import {
  UnitOfMeasure,
  UnitOfMeasureString,
  UOMConverter,
} from 'src/app/models/unit-of-measure.enum';
import { UnitOfTime } from 'src/app/models/unit-of-time.enum';
import unit from './units.json';

export class BeginTime {
  hour: number;
  minute: number;
  date: Date;
}

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
})
export class ChannelComponent implements OnInit, AfterViewChecked {
  public concentrationUOMs: string[] = [
    UnitOfMeasureString.mg,
    UnitOfMeasureString.Gm,
    UnitOfMeasureString.Un,
    UnitOfMeasureString.mUn,
    UnitOfMeasureString.mEq,
    UnitOfMeasureString.mMol,
    UnitOfMeasureString.mcg,
  ];
  public doesUOMs: string[] = [
    UnitOfMeasureString.mg,
    UnitOfMeasureString.Gm,
    UnitOfMeasureString.Un,
    UnitOfMeasureString.mUn,
    UnitOfMeasureString.mEq,
    UnitOfMeasureString.mMol,
    UnitOfMeasureString.nG,
    UnitOfMeasureString.mcg,
  ];
  public doseDivideByUOMS: string[] = [
    UnitOfMeasureString.kg,
    UnitOfMeasureString.none,
  ];

  public easyPanelOpenState: boolean = true;
  public advancedPanelOpenState: boolean;

  private _channel: Channel;
  @Input() public set channel(value: Channel) {
    this._channel = value;
    this.SetInfuseSince();
  }
  public get channel(): Channel {
    return this._channel;
  }
  @Output() channelChange: EventEmitter<Channel> = new EventEmitter<Channel>();
  @Output() invalidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _setupLineOption: boolean;
  @Input() public set SetupLineOption(value) {
    this._setupLineOption = value;
    if (!value) {
      // force this mode to VR & Rate if the user unchecks setup line options.
      this.channel.SetupMode = SetupMode[SetupMode['VR & Rate']];
      this.channelChange.emit(this.channel);
    }
  }

  @ViewChild('primarySelection', { read: ElementRef })
  primarySelection: ElementRef;
  @ViewChild('primaryAndSecondary', { read: ElementRef })
  primaryAndSecondary: ElementRef;

  public get SetupLineOption(): boolean {
    return this._setupLineOption;
  }

  public get ChannelState(): string[] {
    return toolsservice.ToArray(ChannelMode);
  }

  public get SetupModes(): string[] {
    return toolsservice.ToArray(SetupMode);
  }

  public get Alarms(): string[] {
    return toolsservice.ToArray(ChannelAlarm);
  }

  public get UOMs(): string[] {
    return toolsservice.ToArray(UnitOfMeasure);
  }

  public get Times(): string[] {
    return toolsservice.ToArray(UnitOfTime);
  }

  private _infuseHour: number;
  public get InfuseHour(): number {
    return this._infuseHour;
  }

  public set InfuseHour(value: number) {
    this._infuseHour = value;
    this.updateInfuseSince();
  }

  private _infuseMinute: number;
  public get InfuseMinute(): number {
    return this._infuseMinute;
  }

  public set InfuseMinute(value: number) {
    this._infuseMinute = value;
    this.updateInfuseSince();
  }

  private _infuseDate: Date;
  public get InfuseDate(): Date {
    return this._infuseDate;
  }

  public set InfuseDate(value: Date) {
    this._infuseDate = value;
    
    this.updateInfuseSince();
  }

  public focusIndex: number;

  public UOMConverter: UOMConverter = new UOMConverter();

  public checkedOnce = false;

  public doseDivTimeOptions: string[] = ['min', 'h', 'day'];

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {}

  ngOnInit(): void {}

  //#region primary and primary & secondary radio button checks
  /**
   * determine what to do for the primary check
   */
  public determineTrueFalsePrimary(): boolean {
    if (this.channel.SecondaryLine) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * determine what to do for secondary check
   */
  public determineTrueFalseSecondary(): boolean {
    if (this.channel.SecondaryLine) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion

  private SetInfuseSince() {
    // only and if only if we actually have data values assigned to this field.
    if (this.channel?.InfusingStart) {
      var data = this.splitInfusingStart(this.channel.InfusingStart);
      this._infuseHour = data.hour;
      this._infuseMinute = data.minute;
      this._infuseDate = data.date;
    }
  }

  IsAlarmSelected(alarm) {
    const index = this.channel.Alarms.indexOf(alarm);
    return index !== -1;
  }

  /**
   * Update the alarm array collection.
   * @param event MatCheckboxChange
   */
  OnAlarmChanged(event) {
    // so the event is MatCheckboxChange notification
    // it contains the following information :
    // { source: MatCheckbox, checked: boolean }
    const value = event.source.value; // the value assigned to this changed checkbox.
    const index = this.channel.Alarms.indexOf(value); // get the index of the value from channel array collection.
    let hasChanged = false; // monitor if we have made any changes to the channel

    // if the entry does not exist, but the user checks, append value to list
    if (index === -1 && event.checked) {
      this.channel.Alarms.push(value);
      hasChanged = true;
      // if the entry DO exist, but the user unchecks, remove the entry.
    } else if (index > -1 && !event.checked) {
      this.channel.Alarms.splice(index, 1);
      hasChanged = true;
    }
    if (hasChanged) this.channelChange.emit(this.channel);
  }

  public onSecLine(): void {
    //TODO: safeguard against multiple clicks (i.e. if the user clicks toggle again it will reset data ??? )
    if (!this.channel.SecondaryLine) {
      this.channel.SecondaryLine = new Line();
    }
  }

  public offSecLine(): void {
    this.channel.SecondaryLine = null;
  }

  ToggleSecLine(isSecondaryLineActive: boolean) {
    
    // if($event.checked){
    //   this.channel.SecondaryLine = new Line();
    // }else{
    //   this.channel.SecondaryLine = null;
    // }
  }

  AddSecondaryLine() {
    this.channel.SecondaryLine = new Line();
    this.focusIndex = 1;
  }

  RemoveSecondaryLine() {
    this.channel.SecondaryLine = null;
    this.focusIndex = 0;
  }

  OnSetDateTimeNow() {
    // get the date time right now and then apply to the date.
    const todayDate = new Date();
    // assemble them all together with the following format
    this._infuseHour = todayDate.getHours();
    this._infuseMinute = todayDate.getMinutes();
    this._infuseDate = todayDate;
    this.updateInfuseSince();
  }

  private updateInfuseSince() {
    const hour = this.ForceTwoDigits(this.InfuseHour);
    const min = this.ForceTwoDigits(this.InfuseMinute);
    if (!this.InfuseDate) {
      this.channel.InfusingStart = '';
      return;
    }
    const day = this.ForceTwoDigits(this.InfuseDate?.getDate());
    const month = this.InfuseDate.toLocaleString('default', { month: 'short' });
    const year = this.InfuseDate.getFullYear().toString().substr(2, 2);
    const postMederterrain = this.InfuseHour >= 12 ? 'p' : 'a';
    // assemble them all together with the following format
    // THE FOLLOWING FORMAT {HH}:{MM}{a/p} {DD} {MMM} {YY}
    if (hour && min && day && month)
      this.channel.InfusingStart = `${hour}:${min}${postMederterrain} ${day} ${month} ${year}`;
    else this.channel.InfusingStart = '';
  }

  private splitInfusingStart(value: string): BeginTime {
    //{HH}:{MM}{a/p} {DD} {MMM} {YY}
    let data: BeginTime = new BeginTime();
    const Months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // should be four array here.
    let raw = value.split(' ');
    if (raw.length !== 4) {
      return data;
    }

    // should be two array.
    let time = raw[0].split(':');
    data.hour = parseInt(time[0]); //+ ( time[1].endsWith('p') ? 12 : 0 );
    data.minute = parseInt(time[1].substr(0, 2));

    // new Date('2011', '04' - 1, '11')
    data.date = new Date(
      parseInt(`20${raw[3]}`),
      Months.indexOf(raw[2]),
      parseInt(raw[1])
    );
    return data;
  }

  private ForceTwoDigits(value: any): string {
    if (value)
      return value.toString().length === 1
        ? '0' + value.toString()
        : value.toString();
    else return null;
  }

  ClearDateTime() {
    this.InfuseHour = null;
    this.InfuseMinute = null;
    this.InfuseDate = null;
  }

  /**
   * this method is used to see if the patient weight needs to be used
   * if not, it returns 1, since it is being multiplied in a calculation
   */
  public getWeight(): number {
    if (this.channel.DoseDivUOM == 9) {
      return this.channel.PatientWeight;
    }
    return 1;
  }

  /**
   * obtain minutes either h or min or day
   * @param time time in the format of "min","h", or "day"
   */
  public getMinutes(time: string): number {
    if (time === 'min') {
      return 60;
    } else if (time === 'h') {
      return 1;
    } else {
      // time is "day" or somehow anything but min or h.
      return 0.0416666;
    }

  }

  public checkIfNumber(numberToCheck: number): boolean {
    this.invalidChange.emit(isNaN(numberToCheck));
    return isNaN(numberToCheck); //for some reason isNaN will not work inline html, have to use this wrapper function
  }

  /**
   * Caclulate the DRC rate of the IVPump
   * @param $event force the data to capture from the custom number field component before applying it to the equation
   * @param weight is the data captured from the customer number field data for weight?
   * @param dose is the data captured from the custom number field data for dose?
   * @param concentration is the data captured from the custom number field data for concentration?
   * @param concDivAmount is the data captured from the custom number field data for concDivAmount?
   */
  public calculateRate(
    $event?: any,
    weight?: boolean,
    dose?: boolean,
    concentration?: boolean,
    concDivAmount?: boolean
  ): void {
    this.cd.detectChanges();

    if (weight) {
      this.getWeight();
    }
    if (dose) {
      this.channel.Dose = $event;
    }
    if (concentration) {
      this.channel.Concentration = $event;
    }
    if (concDivAmount) {
      this.channel.ConcDivAmount = $event;
    }

    let _ConcUOM = this.UOMConverter.getUOMString(this.channel.ConcUOM);
    let _DoseUOM = this.UOMConverter.getUOMString(this.channel.DoseUOM);
    
    this.channel.DRCRate =
      (this.channel.Dose *
        this.getMinutes(this.channel.DoseDivTime) *
        this.getWeight() *
        this.channel.ConcDivAmount) /
      this.convertUnitOfMeasureMathEdition(_ConcUOM, _DoseUOM) /
      this.channel.Concentration;
    
    
    //this.cd.detectChanges();
  }

  /**
   * Caclulate the Dose of the IVPump
   * @param $event force the data to capture from the custom number field component before applying it to the equation
   */
  public calculateDose($event?: any): void {
    this.cd.detectChanges();
    this.channel.DRCRate = $event;

    let _ConcUOM = this.UOMConverter.getUOMString(this.channel.ConcUOM);
    let _DoseUOM = this.UOMConverter.getUOMString(this.channel.DoseUOM);

    this.channel.Dose =
      ((this.channel.DRCRate * this.channel.Concentration) /
        this.channel.ConcDivAmount /
        this.getMinutes(this.channel.DoseDivTime) /
        this.getWeight()) *
      this.convertUnitOfMeasureMathEdition(_ConcUOM, _DoseUOM);
  }

  /**
   * Mathematical solution to mapping of unit conversions needed for calculations of dose and rate
   * helps avoid having ot use tuple "(mg,mcg)"" mapping of hard coded data in a dictionary (the solution used in IVPump on Unity)
   * @param from the unit of measure going from (source)
   * @param to the unit of measure going to (destination)
   */
  private convertUnitOfMeasureMathEdition(from: string, to: string): number {
    return unit[to.toLowerCase()] / unit[from.toLowerCase()];
  }
}
