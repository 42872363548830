<div class="dialog-container-header">
    <h2 mat-dialog-title> {{promptData.title}} </h2>
    <button mat-icon-button [mat-dialog-close]='false'>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <mat-label>{{promptData.message}}</mat-label>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]='false'>{{promptData.reject}}</button>
    <button mat-button class="green-focus" [mat-dialog-close]='true'>{{promptData.approve}}</button>
</mat-dialog-actions>