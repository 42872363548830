import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnityService {

  private _unityInstance : any;

  private _model : string = null;

  private _isLoadingCharacter : boolean = false;

  /**
   * Invoked when the scene has been loaded (void Start() calls);
   */
  public onSceneLoaded : EventEmitter<void> = new EventEmitter();

  /**
   * Invoked when patient model fully loaded into the scene
   */
  public onPatientLoaded : EventEmitter<void> = new EventEmitter();

  /**
   * Invoked when clinical data is received? - Question
   */
  public onClinicalDataLoaded : EventEmitter<void> = new EventEmitter();

  /**
   * Invoked when successfully quitted the web GL application.
   */
  public onQuitCompleted : EventEmitter<void> = new EventEmitter();

  // getter and setter property
  // return unity WebGl instance when needed
  get UnityInstance() : any {
    return this._unityInstance;
  }

  // set the instances of unity webgl to reference to.
  set UnityInstance(_instance: any ){
    if( this._unityInstance !== null && this._unityInstance !== undefined )
    {
      // close this webgl first before creating a new instances...
      this.closeWebGL();
    }
    this._unityInstance = _instance;
  }

  constructor() {
    // once teh patient is fully loaded, check the queue system to see if we have anything loading...
    this.onPatientLoaded.subscribe(()=> this.onPatientFullyLoaded());

    // (window as any).webglExceptionListener = (message: string) => {

    // }
  }

  //#region Methods that you should use:
  /*
  1. Load Character: 
  ​
  gameInstance.SendMessage('UnityHook','LoadCharacter',input);
  ​
  structure of input: [modelEnum-ToInt]+[PatientName]+[clothingOptions]	

  if bodyTextures and eyesOverlay are either null or empty, default the values to normal to reset them. 
  ​
  real input:
  32+James+4-helmet-goggles-Army*/ 

  public loadCharacter( modelType : number, patientName : string, clothingType : string ) : void 
  {
    // combine the model type, patient name, and the clothing type as a parameter to send to unity WebGL.
    var charString = `${modelType}+${patientName}+${clothingType}`; 
    // if unity webGL is still loading, we'll update the queue system and return. The queue system have a subscription to Unity's event on Patient loaded.
    if( this._isLoadingCharacter ) {
      this._model = charString; 
      return;
    }

    // Flag this script that we are now loading the models into Unity.

    if( this._unityInstance !== undefined && this._unityInstance !== null ) {
      this._unityInstance.SendMessage('UnityHook', 'LoadCharacter', charString);
      this._isLoadingCharacter = true;
    }
    else
    { 
      // a micro queue system, in case the fact that unity has not fully initialized yet.
      this._model = charString;
    }
  }

  /*​
  2. Set clinical State:
  ​
  gameInstance.SendMessage('UnityHook','SetPatientStateData',input);
  ​
  structure of input: [animationName]+[BodyOverlay]+[EyesOverlay]	

  Must include two pluses, If there are no body or eyes, include the pluses - Normal.
  ​
  real input:
  SevAbPain+Male_Depressed_Skull_Fracture-Lacerations_On_Torso+Blown_Pupils-Eye_Irritation
  ​*/

  public setClinicalState( symptomsName : string, bodyTexture : string[] = null, eyesOverlay : string[] = null ) : void
  {
    if( bodyTexture === null || bodyTexture === undefined || bodyTexture.length === 0 )
      bodyTexture = ["normal"];
    if( eyesOverlay === null || eyesOverlay === undefined || eyesOverlay.length === 0 )
      eyesOverlay = ["normal"];
    var data : string = symptomsName + "+" + bodyTexture.join('-')+'+'+eyesOverlay.join('-');
    if( this._unityInstance !== undefined && this._unityInstance !== null )
      this._unityInstance.SendMessage('UnityHook', 'SetPatientStateData', data);
  }

  /*
    Reset Patient clinical State
   */
  public resetClinicalState(){
    this.setClinicalState("NormalBreath");
  }

  //#endregion

  //#region Optional Method

  /*
  1. Remove Character:
  ​
  gameInstance.SendMessage('UnityHook','RemoveCharacter');
  */

  public removeCharacter() : void 
  {
    if( this._unityInstance !== undefined && this._unityInstance !== null )
      this._unityInstance.SendMessage('UnityHook', 'RemoveCharacter');
  }

  /*
  2. Set Animation:
  ​
  gameInstance.SendMessage('UnityHook','SetPatientAnimation',input);
  ​
  ​
  structure of input: [animationName]
  ​
  real input:
  SevAbPain

  */
    
  public setAnimation( animationName : string ) : void 
  {
    if( this._unityInstance !== undefined && this._unityInstance !== null )
      this._unityInstance.SendMessage('UnityHook', 'SetPatientAnimation', animationName.toLowerCase());
  }​

  /*
  3. Set Body Overlay:
  ​
  gameInstance.SendMessage('UnityHook','SetPatientBodyOverlay',input);
  ​
  ​
  structure of input: [BodyOverlay]

  If bodyOverlay is null or empty, set the animation back to normal
  ​
  real input:
  Male_Depressed_Skull_Fracture-Lacerations_On_Torso
  
  */

  public setBodyOverlay( bodyOverlay : string[] = null ) : void
  {
    // what happen if we put in blank?
    if( bodyOverlay === undefined || bodyOverlay === null )
      bodyOverlay = ["normal"];
    if( bodyOverlay.length === 0 )
      bodyOverlay.push("normal");
    if( this._unityInstance !== undefined && this._unityInstance !== null )
      this._unityInstance.SendMessage('UnityHook', 'SetPatientBodyoverlay', bodyOverlay.join('-').toLowerCase());
  }

  /*
  ​
  4. Set Eye Overlay:
  ​
  gameInstance.SendMessage('UnityHook','SetPatientEyeOverlay',input);
  ​
  structure of input: [EyeOverlay]

  If no param is provided, push "normal" to return back to default value instead.
  ​
  real input:
  Blown_Pupils-Eye_Irritation

   */

  public setEyeOverlay(eyesOverlay : string[] = null ) : void 
  {
    // What happen if the eyesOverlay is blank?
    if ( eyesOverlay === null || eyesOverlay === undefined )
      eyesOverlay = ["normal"];
    if( eyesOverlay.length === 0 )
      eyesOverlay.push("normal");
    if( this._unityInstance !== undefined && this._unityInstance !== null )
      this._unityInstance.SendMessage('UnityHook', 'SetPatientEyeOverlay', eyesOverlay.join('-').toLowerCase());
  }

  //#endregion

  // try to reload the model...
  public forceRefresh() : void {
    if( this._model !== null && this._model !== undefined ){

      this._unityInstance.SendMessage('UnityHook', 'LoadCharacter', this._model);
      this._model = null;
    }
  }

  // ngOnInit() {
  //   // called in a Start() function in Unity
  //   (window as any).appLoadedListener = () => {
  //     console.log('webgl ready...');  
  //   }
  //   // called after character loaded in Unity
  //   (window as any).patientLoadedListener = () => {
  //     console.log('patient loaded...'); 
  //   }
  //   // called after recieving message in Unity
  //   (window as any).stateChangeListener = () => {
  //     console.log('clinical state loaded...');
  //   }
  // }

  /**
   * Gracefully close the Unity WebGL browser and remove any links to the webGL to free memory collection.
   */
  public closeWebGL(){
    if( this._unityInstance == null || this._unityInstance == undefined ) return;
    this._unityInstance?.Quit( () => this.onQuitCompleted.emit() );
    this._unityInstance = null;
  }

  /**
   * Reset unity web GL
   */
  public resetWebGL(){
    if( this._unityInstance === null || this._unityInstance === undefined ) return;
    this._unityInstance?.SendMessage('UnityHook', 'ResetScene');
  }

  /**
   * Queue system to load the proper model to view and simulate.
   */
  private onPatientFullyLoaded(){
    this._isLoadingCharacter = false;
    if ( this._model !== null || this._model !== undefined ){
      setTimeout( ()=>
      this.forceRefresh()
      , 100 );
    }
  }
}
