import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialog,  MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogChangeMemberPasswordComponent } from '../../../admin/dialogs/dialog-change-member-password/dialog-change-member-password.component';
import { User } from 'src/app/models/user.model';
import { hash } from 'bcryptjs';
import { UserService } from 'src/app/admin/user.service';

export interface DialogData {
  account: User;
}

@Component({
  selector: 'app-dialog-edit-account-information',
  templateUrl: './dialog-edit-account-information.component.html',
  styleUrls: ['./dialog-edit-account-information.component.scss']
})
export class DialogEditAccountInformationComponent implements OnInit {

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogEditAccountInformationComponent>,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  onChangePassword(){
    const dialogRef = this.dialog.open(DialogChangeMemberPasswordComponent, {
      data: {username: this.data.account.userName, password: this.data.account.password},
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        hash(result, 10, function(err, newPass) {
          if( err ) throw err;
          this.data.account.password = newPass;
          this.userService.update(this.data.account);
        });
      }
    })
  }
}
