<div
  *ngIf="selectedDevice === null"
  class="mat-elevation-z8"
  style="height: 78px"
>
  <div class="device-manager-header">
    Device Manager
  </div>
</div>
<div *ngIf="selectedDevice !== null" style="height: 78px">
  <div
    style="
      font-family: OpenSansLight;
      font-size: xx-large;
      padding-left: 25px;
      padding-top: 27px;
      padding-bottom: auto;
    "
  >
    <button
      mat-icon-button
      (click)="onReturnClicked()"
      style="transform: translate(-6px, -6px); color: #1f7abe"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    {{ selectedDevice.name }}
  </div>
  <div
    style="
      position: absolute;
      right: 10px;
      display: flex;
      flex-direction: row;
      z-index: 2;
    "
  >
    <button
      mat-flat-button
      style="color: #1f7abe; background-color: whitesmoke"
      (click)="updateAllSimulations(selectedDevice.simulations)"
    >
      <mat-icon style="color: #1f7abe">cached</mat-icon> Update All Scenarios
    </button>
    <button
      mat-flat-button
      style="color: #1f7abe; background-color: whitesmoke"
      (click)="onUploadNewSimulations()"
    >
      <mat-icon style="color: #1f7abe">add_box</mat-icon> Upload New Scenarios
    </button>
  </div>
  <mat-tab-group style="height: 1000px">
    <mat-tab
      label="Simulations on Device ({{ selectedDevice.simulationCount }})"
    >
      <div
        id="SimulationsTab"
        style="
          display: flex;
          flex-direction: row;
          font-family: OpenSansLight;
          font-size: large;
          padding-left: 25px;
        "
      >
        <mat-form-field appearance="standard">
          <mat-label>Sort By</mat-label>
          <mat-select ="sortBy">
            <mat-option [value]="option.value" *ngFor="let option of sorts">{{
              option.viewValue
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon
          style="
            color: #616161;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 20px;
          "
          >search</mat-icon
        >
        <app-tag-search-box
          style="
            margin-left: 20px;
            margin-top: auto;
            margin-bottom: auto;
            z-index: 5;
          "
        ></app-tag-search-box>
      </div>

      <div
        id="devicesScrollArea"
        style="
          overflow-y: scroll;
          max-height: 700px;
          margin-left: 27px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        "
      >
        <div id="row" *ngFor="let simulation of selectedDevice.simulations">
          <div
            style="
              width: 325px;
              height: 376px;
              background-color: white;
              margin-left: 27px;
              margin-bottom: 37px;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: 138px;
                overflow: hidden;
                background-image: url(../../../assets/Images/helpers-six-month-medical-exam-HERO-2-768x569.jpg);
                background-position: center;
              "
            >
              <!-- <img src="../../../assets/Images/helpers-six-month-medical-exam-HERO-2-768x569.jpg" style="transform: translate(-200px, -400px);"> -->
              <p></p>
              <div
                style="
                  margin-left: auto;
                  margin-right: 4px;
                  margin-top: 4px;
                  height: 32px;
                  width: 32px;
                  background-color: white;
                  opacity: 0.5;
                  border: solid;
                  border-color: transparent;
                  border-width: 0px;
                  border-radius: 100px;
                  z-index: 2;
                  text-align: right;
                "
              >
                <button
                  mat-icon-button
                  style="transform: translate(-4px, -4px)"
                  [matMenuTriggerFor]="menu"
                >
                  <mat-icon style="color: black">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    style="color: #616161"
                    (click)="onDownloadToAccount()"
                  >
                    <mat-icon style="color: #616161">get_app</mat-icon>Download
                    to My Account
                  </button>
                  <button
                    mat-menu-item
                    style="color: #616161"
                    (click)="onRemoveSimulation(simulation)"
                  >
                    <mat-icon style="color: #616161">delete</mat-icon>Remove
                    Simulation
                  </button>
                </mat-menu>
              </div>
            </div>
            <div
              style="
                background-color: white;
                border: solid;
                border-top: none;
                border-color: silver;
                border-width: 1px;
                min-height: 238px;
                max-height: 238px;
                padding: 16px;
                padding-bottom: 0px;
                font-family: OpenSans;
                overflow: hidden;
              "
            >
              <p style="font-family: OpenSansBold; color: #616161">
                {{ simulation.name }}
              </p>
              <p style="font-family: OpenSansLight; font-size: small">
                {{ simulation.creator }}
              </p>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  height: 40px;
                  line-height: 40px;
                "
              >
                <div *ngIf="!simulation.isUpdating">
                  <div
                    id="upToDate"
                    *ngIf="simulation.isUpdated"
                    style="color: #1a844f; font-size: small"
                  >
                    <mat-icon style="color: #1a844f; transform: translateY(6px)"
                      >check</mat-icon
                    >
                    Up To Date
                  </div>
                  <div id="upToDate" *ngIf="!simulation.isUpdated">
                    <button
                      mat-flat-button
                      style="
                        color: orange;
                        border: solid;
                        border-color: orange;
                        border-width: 2px;
                        border-radius: 0px;
                      "
                      (click)="onUpdating(simulation)"
                    >
                      UPDATE NOW
                    </button>
                  </div>
                </div>
                <div *ngIf="simulation.isUpdating">
                  <div id="upToDate" style="color: purple; font-size: small">
                    <mat-icon style="color: purple; transform: translateY(6px)"
                      >cached</mat-icon
                    >
                    Updating
                  </div>
                </div>
                <div style="font-size: small">
                  <i style="margin-top: auto"
                    >Updated: {{ getDate(simulation.date) }}</i
                  >
                </div>
              </div>
              <br />
              <p style="font-size: small">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
          <!-- <div style="cursor: pointer; background-color: white; border: solid; border-top: none; border-color: silver; height: 238px; width: 323px; border-width: 1px; margin-left: 27px; margin-bottom: 27px;">
                    </div> -->
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Files on Device ({{ selectedDevice.fileCount }}) ">
      <!--TODO: files on device -->
    </mat-tab>
  </mat-tab-group>
</div>
<div *ngIf="selectedDevice === null">
  <div
    id="availableDevicesHeader"
    style="
      font-family: OpenSansLight;
      font-size: large;
      padding-left: 25px;
      padding-top: 27px;
      padding-bottom: 27px;
    "
  >
    Available Devices ( {{ devices.length }} )
  </div>
  <div
    id="devicesScrollArea"
    style="
      overflow-y: scroll;
      max-height: 700px;
      margin-left: 27px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    "
  >
    <div id="row" *ngFor="let device of devices">
      <div
        style="
          cursor: pointer;
          background-color: white;
          border: solid;
          border-color: silver;
          height: 296px;
          width: 205px;
          border-width: 1px;
          margin-left: 27px;
          margin-bottom: 27px;
        "
        (click)="onDeviceClicked(device)"
      >
        <mat-icon
          style="
            font-size: 100px;
            transform: translate(52px, 32px);
            color: #616161;
          "
          >tablet_android
        </mat-icon>
        <div style="margin: 16px; margin-top: 64px">
          <p style="font-family: OpenSansBold; color: #616161">
            {{ device.name }}
          </p>
          <p style="font-family: OpenSansLight; font-size: small">
            Simulations on Device: {{ device.simulationCount }}
          </p>
          <p style="font-family: OpenSansLight; font-size: small">
            Files on Device: {{ device.fileCount }}
          </p>
          <a href="#" style="font-family: OpenSansLight; text-decoration: none"
            >Manage Files</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="selectedDevice !== null">
  <p></p>
</div>
