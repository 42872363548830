import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicesComponent } from './devices/devices.component';
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { DialogDeleteSimulationComponent } from './dialogs/dialog-delete-simulation/dialog-delete-simulation.component';
import { DialogNewSimulationsComponent } from './dialogs/dialog-new-simulations/dialog-new-simulations.component';
import { TagSearchModule } from '../tag-search/tag-search.module'

@NgModule({
    declarations: [
        DevicesComponent,
        DialogDeleteSimulationComponent,
        DialogNewSimulationsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TagSearchModule,
    ],
    exports: [
        DevicesComponent
    ]
})
export class DevicesModule { }
