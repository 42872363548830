import { throwError } from 'rxjs';
import { IVPumpAlarmStatesNumber, VentilatorAlarmsStatesNumber } from './alarms.model';
import { toolsservice } from '../editor/services/tools.service';

export class statedata {
  // placeholder to retain original Name struct.
  public originalName: string;
  public originalDescript: string;
  public animation: string;

  constructor(
    public statedataId: string = '',
    public name: string = '', // according to the chat conversation with Sohail, this field can be modified from the Editor.
    public description: string = '',
    public type: number = 0,
    public phaseParentIndex: number = 0,
    public position: number = 0
  ) {
    if (statedataId === null) {
      this.statedataId = toolsservice.makeId();
    }
  }
}

export class clinical extends statedata {
  // indicate the symptoms has changed the lab/vital results. strictly for the icon to display under editor
  public containsVital = false;
  public containsLab = false;

  // Sounds
  public HeartSound = ''; // could be user specified so cannot be enums
  public LungSound = ''; // could be user specified so cannot be enums
  public VoiceSound = '';
  
  // visual Symptoms
  public Textures: string[] = []; // could be a list<string> but cannot be enum due too many types
  public EyesOverlays: string[] = [];
  
  // Vitals
  public cardiacRhythm = 5; // enum
  public respiratoryWaveform = 0; // enum - capnography

  public ecgPrintOut = '';
  // could be enums with current implementation, but not really used right now since
  // this was for the EKG printout works but is hidden since Kevin said to put it on hold,
  // I guess we're waiting on someone to add the 12Leads stuff.
  public spo2Amp = 100;
  public HR = 65; // heart rate
  public RR = 15; // Respiration Rate
  public ETCo2 = 32;
  public SaO2 = 97;
  public dBP = 80;
  public sBP = 120;
  public temp = 98;
  //
  // auto transition data
  public transitionState = '';
  public transitionTime = 0;
  //

  
  // Labs
  // CBC
  public whiteBloodCount = 6.0; //  old: 0.2 - 25 range;; new: 2000 - 25000 range (updated as per new doc standards)
  public Hematocrit = 45; // 20 - 70% range
  public Hemoglobin = 15.0; // 6.3 - 23.3 range
  public platelets = 250; // 10 - 600 range
  // BMP
  public Sodium = 140.5; // 115 - 160 range
  public bloodUreaNitrogen = 8; // 2 - 60 range
  public potassium = 4.0; // 1.5 - 8.0 range
  public creatinine = 0.8; // 0.6 - 10 range
  public chloride = 97; // 75 - 135 range
  public glucose = 85; // 0 - 800 range
  public bicarbonate = 24; // 12 - 40 range
  public calcium = 9.2; // 5.4 - 16.2 range
  // FSG
  public FSG_glucose = 85; // 0 - 600 range
  // VGB / ABG
  public drawType = 0; // Enum               - D: - Default value ABG 0 VBG 1
  public pH = 7.36; // VBG: <6.90 - >8.00 D: 7.36   ABG: <6.90 - >8.00 D: 7.40
  public bicarb = 25; // VBG: 12 - 40 D: 25           ABG: 11 - 39 D: 25
  public pa02 = 40; // VBG: 20 - 60 D: 40           ABG: 60-120 D: 95
  public bE = 0; // Both: -10 - 10 D: 0
  public paCO2 = 44; // VBG: 25 - 60 D: 44           ABG: 25 - 60 D: 40
  public lab_saO2 = 75; // VBG: 40-80 D: 75             ABG: <70% - 100% D:
  public lactate = 0; // TODO: Need information from Sohail.

  // VENTILATOR
  // low priority items
  public lungPressureWaveform = 0; // enum
  public ventAlarm = 0; // enum DEFAULT VENTALARM NONE

  // high - immediately using them, priority items
  public PPLAT = 10;
  public MAP = 10;
  public PIP = 20;
  public Vt = 400;

  // ============ ALARMS ================
  public IVPumpAlarm: number = 0;
  public VentilatorAlarm: number = 0;

  // =========== Custom Auscultation ============
  // Auscultations are a part of a clinical state but they can have their mp3 file names changed by the user
  // here is a list of Auscultation zones available to be changes set to a default audio file type.
  // TODO: update with the appropriate file type name
  public Auscultations: auscultations = {
    PointA: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointB: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointC: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointD: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointE: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointF: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointG: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointH: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointI: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointJ: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointK: "Auscultations/Lung/Normal Breath Sound.mp3",
    PointL: "Auscultations/Lung/Normal Breath Sound.mp3",
  }
}

// interface data structure for a Auscultation data type
export interface auscultations {
  PointA: string,
  PointB: string,
  PointC: string,
  PointD: string,
  PointE: string,
  PointF: string,
  PointG: string,
  PointH: string,
  PointI: string,
  PointJ: string,
  PointK: string,
  PointL: string,
}

/*
=====
ENUMS
=====
StateDataType {
    Clinical,
    TreatmentPoint,
    InstructorPoint
}
CardiacRhythm {
    AFIB,
    ASYSTOTLE,
    AVB3,
    MOBITZ1,
    MOBITZ2,
    NORMAL,
    SVT,
    TORSADES,
    VENTACHY (nonperfusing),
    VENTACHY (perfusing),
    VFIB
}
respiratoryWaverform {
    NORMAL_RESP,
    HYPO,
    HYPER,
    CPR,
    ROSC,
    SUDDEN LOSS,
    DECREASING,
    SEDATION
}
lungPressureWaveform {
    None,
    ...
}
VentAlarm {
    None,
    PEEP_Leak,
    Self_Check_Failure,
    Gas_Intake_Fault
}
FSG_DrawType {
    ABG,
    VBG
}

*/
