import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import {   MatDialog } from '@angular/material/dialog';
import { DialogCreateScenarioComponent } from '../dialogs/dialog-create-scenario/dialog-create-scenario.component';
import { Router } from '@angular/router';
import { ScenariosService } from 'src/app/services/scenarios.service';
import { scenario } from 'src/app/models/scenario.model';
import { UntypedFormControl } from '@angular/forms';
import { TeamsService } from 'src/app/services/teams.service';
import { CommunityService } from 'src/app/services/community.service';
import { UserService } from 'src/app/admin/user.service';
import { SearchComponent, SearchContainer } from 'src/app/search/search.component';
import { ServerService } from 'src/app/services/Server.service';
import {   MatPaginator,   PageEvent } from '@angular/material/paginator';
import { LinkedScenarioData } from 'src/app/models/linkedscenarios.model';
import { Institution } from 'src/app/models/institution.model';
import { InstitutionService } from 'src/app/services/institution.service';
import { Author } from 'src/app/models/author.model';
import { downloadData } from 'src/app/models/downloadData.model';

@Component({
  selector: 'app-list-of-scenarios',
  templateUrl: './list-of-scenarios.component.html',
  styleUrls: ['./list-of-scenarios.component.scss'],
})
export class ListOfScenariosComponent implements OnInit {
  // private date: Date = new Date();

  public sService: ScenariosService;
  public userSimulationLoading = true;
  public remoteSimulationLoading = true;
  public communitySimulationLoading = true;
  public userFilesLoading = true;
  public userScenarioList;
  private firstLoad: boolean = true;
  @ViewChild('displayUserSim') usersimSearch: SearchComponent;
  @ViewChild('paginatoryourscenario') paginatoryourscenario: MatPaginator;
  @ViewChild('paginatorteamscenario') paginatorteamscenario: MatPaginator;
  @ViewChild('paginatorcommunityscenario') paginatorcommunityscenario: MatPaginator;
  public get ServerOnline(): boolean {
    return ServerService.ServerOnline;
  }

  // public updateTagsEvent = new EventEmitter<any>();

  constructor(
    private router: Router,
    private scenarioService: ScenariosService,
    private institutionService: InstitutionService,
    private teamsService: TeamsService,
    public dialog: MatDialog,
    private userService: UserService,
    private communityservice: CommunityService,
    private cd: ChangeDetectorRef
  ) {
    this.sService = this.scenarioService;
  }

  public institutionList: Institution[] = [];
  public teamLinkedScenarios: LinkedScenarioData[] = [];
  public downloadCountData: downloadData[] = [];
  scenariosSearchContainers: SearchContainer[] = [];
  scenarioSearchResult: SearchContainer[] = [];
  public searchResultSlice: SearchContainer[] = []
  public teamsearchResultSlice: SearchContainer[] = [];
  public communitySearchResultSlice: SearchContainer[] = [];
  public communityAuthors: Author[] = []
  public teamAuthors: Author[] = []
  communitySearchResult: SearchContainer[] = [];
  communityScenarioSearchContainers: SearchContainer[] = [];
  remoteSearchContainers: SearchContainer[] = [];
  remoteSearchResult: SearchContainer[] = [];
  fileSearchContainers: SearchContainer[] = [];
  fileSearchResult: SearchContainer[] = [];

  // tools for determining the currently selected tab
  selected = new UntypedFormControl(0);

  public showAddButton = true;

  public onShowButton($event) {
    this.showAddButton = $event.index === 0;
    if ($event.index == 2) // community
    {
      this.updateCommunityList();
    }
    else if ($event.index == 1) //team
    {
      this.updateTeamList();
    }
  }

  public updateTeamList() {
    this.teamScenarioListUpdate();
  }
  public updateSorting() {
    this.usersimSearch.sortUpdateDateDesc(0);
  }
  delayedYourScenariosReorder(paginator: MatPaginator, currentresult: SearchContainer[]): void {
    currentresult?.sort((a: SearchContainer, b: SearchContainer) => { return a.object.modifiedDate - b.object.modifiedDate });
    currentresult?.reverse();
    this.firstLoad = false;
    this.searchResultSlice = this.refreshPageData(paginator, this.scenarioSearchResult);
  }

  teamDelayedReorder(paginator: MatPaginator, currentresult: SearchContainer[]): void {
    currentresult?.sort((a: SearchContainer, b: SearchContainer) => { return a.object.modifiedDate - b.object.modifiedDate });
    currentresult?.reverse();
    this.teamsearchResultSlice = this.refreshPageData(paginator, currentresult);
  }
  communityDelayedReorder(paginator: MatPaginator, currentresult: SearchContainer[]): void {
    currentresult?.sort((a: SearchContainer, b: SearchContainer) => { return a.object.modifiedDate - b.object.modifiedDate });
    currentresult?.reverse();
    this.communitySearchResultSlice = this.refreshPageData(paginator, currentresult);
  }

  onSearchArrayChange(searchArray: SearchContainer[]): void {
    this.scenarioSearchResult = searchArray;
    if (this.paginatoryourscenario != null) {
      this.searchResultSlice = this.refreshPageData(this.paginatoryourscenario, this.scenarioSearchResult);
    }
  }

  onSearchTeamArrayChange(searchArray: SearchContainer[]): void {

    this.remoteSearchResult = searchArray;
    if (this.paginatorteamscenario != null) {
      this.teamsearchResultSlice = this.refreshPageData(this.paginatorteamscenario, this.remoteSearchResult);
    }
  }
  onSearchCommunityArrayChange(searchArray: SearchContainer[]): void {
    this.communitySearchResult = searchArray;
    if (this.paginatorcommunityscenario != null) {
      this.communitySearchResultSlice = this.refreshPageData(this.paginatorcommunityscenario, this.communitySearchResult);
    }
  }

  refreshPageData(paginator: MatPaginator, currentresult: SearchContainer[]): SearchContainer[] {
    const totalPages = Math.ceil(currentresult.length / paginator.pageSize);
    if (paginator.pageIndex >= totalPages) {
      paginator.pageIndex = 0;
    }
    const startIndex = paginator.pageIndex * paginator.pageSize;
    const endIndex = startIndex + paginator.pageSize;
    const currentPageData = currentresult.slice(startIndex, endIndex);
    return currentPageData;
  }

  // Method that triggers the updateSearchArray method in SearchComponent

  public OnPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.scenarioSearchResult.length) {
      endIndex = this.scenarioSearchResult.length;
    }
    this.searchResultSlice = this.scenarioSearchResult.slice(startIndex, endIndex);
  }

  public OnTeamPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.remoteSearchResult.length) {
      endIndex = this.remoteSearchResult.length;
    }
    this.teamsearchResultSlice = this.remoteSearchResult.slice(startIndex, endIndex);
  }
  public OnCommunityPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.communitySearchResult.length) {
      endIndex = this.communitySearchResult.length;
    }
    this.communitySearchResultSlice = this.communitySearchResult.slice(startIndex, endIndex);
  }



  updateCommunityList() {
    this.updateCommunityScenarioList();
  }

  setUserOwnedScenarios(): void {
    this.scenarioService.getUserScenarios().subscribe((list) => {
      this.userScenarioList = list;
    });
  }
  public getLinkedScenarios() {
    this.scenarioService.getTeamLinkedScenarios().subscribe(
      (list) => {
        this.teamLinkedScenarios = list.map(s => new LinkedScenarioData(s.downloadedfrom, s.userId, s.institutionId, s.originalScenarioId, s.copiedScenarioId));
      });
  }
  updateUserScenarioList(): void {
    this.userSimulationLoading = true;
    this.cd.detectChanges();
    this.scenarioService.dashboardScenario().subscribe(
      (list) => {

        this.scenariosSearchContainers = list.map(scenario => new SearchContainer(scenario.name, scenario.tags, scenario, this.getScenarioDownloadCount(scenario.ScenarioId)));
        this.scenarioSearchResult = this.scenariosSearchContainers;
        this.searchResultSlice = this.scenarioSearchResult.slice(0, 10);
        this.userSimulationLoading = false;
        if (this.paginatoryourscenario != undefined) {
          setTimeout(() => this.delayedYourScenariosReorder(this.paginatoryourscenario, this.scenarioSearchResult), 200);
        }

        this.cd.detectChanges();

      },
      (err) => {
        console.log(err);
        this.userSimulationLoading = false;
        this.cd.detectChanges();
      }
    );
  }
  teamScenarioListUpdate() {
    this.getLinkedScenarios();
    this.remoteSimulationLoading = true;
    this.cd.detectChanges();
    this.teamsService.getList().subscribe(
      (list) => {
        if ((list || list.data) && list.data != undefined) {
          this.remoteSearchContainers = list.data.filter(remote => remote != null).map(remote => new SearchContainer(remote.name, remote.tags, remote, this.getScenarioDownloadCount(remote.ScenarioId)));
          for (const searchContainerItem of this.remoteSearchContainers.filter(x => x.object?.authors)) {
            for (const author of searchContainerItem.object.authors) {
              const isAuthorExists = this.teamAuthors.some(a => a._id === author._id);
              if (!isAuthorExists) {
                this.teamAuthors.push(author);
              }
            }
          }
          this.remoteSearchResult = this.remoteSearchContainers;
          this.teamsearchResultSlice = this.remoteSearchResult.slice(0, 10);
          if (this.paginatorteamscenario != undefined) {
            setTimeout(() => this.teamDelayedReorder(this.paginatorteamscenario, this.remoteSearchResult), 200)
          }
        }
        this.remoteSimulationLoading = false;
        this.cd.detectChanges();
      },
      (err) => {
        console.error(err);
        this.remoteSimulationLoading = false;
        this.cd.detectChanges();
      });

  }

  public updateCommunityScenarioList(): void {
    this.communitySimulationLoading = true;
    this.cd.detectChanges();
    this.communityservice.getCommunityScenarios().subscribe(
      (list) => {
        this.communityScenarioSearchContainers = list.map(scenario => scenario && new SearchContainer(scenario.name, scenario.tags, scenario, this.getScenarioDownloadCount(scenario.scenarioId))).filter(Boolean);
        for (const searchContainerItem of this.communityScenarioSearchContainers.filter(x => x.object?.authors)) {
          for (const author of searchContainerItem.object.authors) {
            const isAuthorExists = this.communityAuthors.some(a => a._id === author._id);
            if (!isAuthorExists) {

              this.communityAuthors.push(author);
            }
          }

        }
        this.communitySearchResult = this.communityScenarioSearchContainers;
        this.communitySearchResultSlice = this.communitySearchResult.slice(0, 10);
        if (this.paginatorcommunityscenario != undefined) {
          setTimeout(() => this.communityDelayedReorder(this.paginatorcommunityscenario, this.communitySearchResult), 200)
        }
        this.communitySimulationLoading = false;
        this.cd.detectChanges();
      },
      (err) => {
        console.error(err);
        this.communitySimulationLoading = false;
        this.cd.detectChanges();
      });

  }

  updateScenarioList(): void {
    this.updateUserScenarioList();
    this.teamScenarioListUpdate();

  }
  public async getDownloadDataRefreshAllTabs() {


  }


  public async getDownloadData(): Promise<boolean | any> {
    await this.scenarioService.scenariosDownloadCount().subscribe((list) => {
      if (list) {
        this.downloadCountData = list.map(downloaddata => {
          const data = new downloadData();
          data.date = downloaddata.date;
          data.scenarioId = downloaddata.scenarioId;
          data.ownedUserId = downloaddata.ownedUserId;
          data.downloaduserId = downloaddata.downloaduserId;
          data.institutionId = downloaddata.institutionId;
          return data;
        });
        return true;
      }
    }, (err) => {
      console.error(err);
      return false;
    }
    );

  }

  public async getInstitutionsList() {
    await this.institutionService.getAllList().subscribe(
      (list) => {
        if (list) {
          this.institutionList = list.map(file => {

            const inst = new Institution();
            inst._id = file._id;
            inst.institutionName = file.institutionName;
            inst.created = new Date(file.created);
            return inst;
          });
        }
      },
      (err) => {
        console.error(err);

      });

  }
  public getScenarioDownloadCount(scenarioId: string): number {
    const result = this.downloadCountData.filter((downloadcount) => downloadcount.scenarioId == scenarioId);
    return result.length;
  }
  public filterInstitutionsById(id: string): Institution | null {
    return this.institutionList.find((institution) => institution._id === id) || null;
  }
  public refreshFileList() {
    this.scenarioService.getAllFiles().subscribe(
      (files) => {
        if (files) {
          this.fileSearchContainers = files.map(file => new SearchContainer(file, null, file, 0));
          this.fileSearchResult = this.fileSearchContainers;
          this.userFilesLoading = false;
        }
      },
      (err) => {
        console.error(err);
        this.userFilesLoading = false;
      });
  }

  ngOnInit(): void {
    this.firstLoad = true;
    this.getInstitutionsList();
    this.getDownloadData();
  }
  ngAfterViewInit(): void {

    this.refreshAllTabs();
  }

  public async refreshAllTabs() {
    this.scenarioService.scenariosDownloadCount().subscribe((list) => {
      if (list) {
        this.downloadCountData = list.map(downloaddata => {
          const data = new downloadData();
          data.date = downloaddata.date;
          data.scenarioId = downloaddata.scenarioId;
          data.ownedUserId = downloaddata.ownedUserId;
          data.downloaduserId = downloaddata.downloaduserId;
          data.institutionId = downloaddata.institutionId;
          return data;
        });
        this.firstLoad = true;
        this.setUserOwnedScenarios();
        this.refreshFileList();
        this.userService.refreshUserList();
        this.getLinkedScenarios();
        this.updateScenarioList();
        this.updateCommunityScenarioList();

      }
    }, (err) => {
      console.error(err);
    });
  }

  onCreateScenario() {
    let stringNames: string[] = [];
    this.scenariosSearchContainers?.forEach(scenario => {
      stringNames.push(scenario.name);
    });

    // let simulationName:string = this.scenarioService.checkNameDoesNotExistRecursion(stringNames,"Simulation Name", 0);


    const dialogRef = this.dialog.open(DialogCreateScenarioComponent, {
      width: '910px',
      data: {
        scenarioName: "Simulation Name",
      }
    });

    dialogRef.afterClosed().subscribe((scenarioData) => {
      // create scenario in the server side.
      (<scenario>scenarioData).name = this.scenarioService.checkNameDoesNotExistRecursion(stringNames, (<scenario>scenarioData).name, 0);

      this.scenarioService.createScenario(scenarioData).subscribe(
        (val) => {
          if (scenario !== undefined && scenario !== null && scenarioData !== '') {
            this.router.navigate(['/editor', val.id, true]);
          }
        },
        (err) => { }
      );
      // only route the user to the editor window if the scenario was actually created
    });
  }
}
