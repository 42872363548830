import { Component, OnInit, Input, ɵConsole, Output, EventEmitter } from '@angular/core';
import { clinical } from 'src/app/models/statedata.model';
import { ClinicalControllerService } from '../../../controllers/clinical-controller.service';

@Component({
  selector: 'app-vitals-hover-button',
  templateUrl: './vitals-hover-button.component.html',
  styleUrls: ['./vitals-hover-button.component.scss']
})
export class VitalsHoverButtonComponent implements OnInit {

  @Input() clinical: clinical;
  @Input() gender: string = '';
  @Output() VitalEvent = new EventEmitter<{action:string, data:clinical}>();

  showAudioPopupContent: boolean = false;
  showAltPopupContent: boolean = true;
  showPopup: boolean = false;
  icon:string = 'favorite';

  showAudioPopupF(): void {
    this.showAudioPopupContent = true;
    this.showAltPopupContent = false;
  }

  showPopupF(): void{
    this.showAltPopupContent = true;
    this.showAudioPopupContent = false;
    this.showPopup = true;
  }

  constructor(public _clinicalControllerService: ClinicalControllerService) { }

  ngOnInit(): void { }

  onDeleteItem(): void {
    this.VitalEvent.emit({action:"Delete", data : this.clinical});
  }  

  onEditItem(): void{
    this.VitalEvent.emit({action: "Update", data : this.clinical});
  }
}
