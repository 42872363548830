import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import appPackage from 'package.json';

let offlineMode = false;

@Injectable({
  providedIn: 'root'
})
export class ServerService implements OnDestroy {
  /**
   * Url of the root API server
   */
  public static get Server_URL(): string {
    // Remove the last back slash if exist.
    return appPackage.backendServerPath.replace(/\/$/, '');
  }

  /**
   * Url of file API
   */
  public static get File_URL(): string {
    return `${this.Server_URL}/files`;
  }

  /**
   * Url of Task API
   */
  public static get Task_URL(): string {
    return `${this.Server_URL}/tasklist`;
  }
  public static  CustomAudio_URL(): string {
    return `${this.Server_URL}/files/auscultations/customaudio`;
  }
  public static DeleteCustomAudioFile_URL(audioFileId:string)
  {
    return `${this.Server_URL}/files/auscultations/${audioFileId}`;
  }
  public static GetCustomAudioFile_URL(audioFileId:string)
  {
    return `${this.Server_URL}/files/auscultations/customaudio/${audioFileId}`;
  }
  /**
   * Url of Scenario API
   */
  public static get Scenario_URL(): string {
    return `${this.Server_URL}/scenario`;
  }
  /**
   * URL of linked team scenarios
   */
  public static get TeamLinkedScenario_URL(): string {
    return `${this.Server_URL}/teams/teamdownloaded`;
  }
  public static get ScenarioDownloadCount_URL()
  {
    return `${this.Server_URL}/downloadcounter`;

  }
  /**
   * Url of Public Scenarios(community) API
   */
  public static get PublicScenario_URL(): string {
    return `${this.Server_URL}/community`;
  }

  /**
   * Url of Document API
   */
  public static get Document_URL(): string {
    return `${this.File_URL}/upload`;
  }

  /**
   * Url of Voices API
   */
  public static get Voice_URL(): string {
    return `${this.File_URL}/voices`;
  }

  /**
   * Url of Auscultation API
   */
  public static get Auscultation_URL(): string {
    return `${this.File_URL}/auscultations`;
  }

  /**
   * Url of Favorite API
   */
  public static get Favorite_URL(): string {
    return `${this.Server_URL}/favorites`;
  }

  /**
   * Url of Rating API
   */
  public static get Rating_URL(): string {
    return `${this.Server_URL}/ratings`;
  }

  /**
   * Url of User API
   */
  public static get User_URL(): string {
    return `${this.Server_URL}/users`;
  }

  public static get User_All_URL(): string {
    return `${this.Server_URL}/users/all`;
  }


  public static get User_ReInviteURL(): string {

    return `${this.Server_URL}/users/reinviteuser`;
  }
  public static ResetPass_URL(token: string): string {
    return `${this.Server_URL}/auth/activate/` + token;
  }

  /**
   * Check to see if the server is online and available
   */
  public static get ServerOnline(): boolean {
    return !offlineMode;
  }

  /**
   * Return if we are in offline mode.
   */
  public static get OfflineMode(): boolean {
    return offlineMode;
  }

  private static Instance;
  private subscription: Subscription;
  private readonly source = interval(20000);

  constructor(private http: HttpClient) { }

  onStartUp(): void {
    if (ServerService.Instance) { return; }
    ServerService.Instance = this;
    this.checkOnlineStatus();
    this.subscription = this.source.subscribe(val => this.checkOnlineStatus());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // probably need to find a way to resync with the server, but not for this build just yet.
  private checkOnlineStatus(): void {
    this.http.get(ServerService.Server_URL).subscribe(
      () => offlineMode = false,
      err => offlineMode = err.status !== 200,
      () => { }
    );
  }
}
