export enum audioType{
    NONE = 'None',
    VOICE = 'Voice',
    AUSCULTATION = 'Auscultation',
}

export enum characterType{
    NONE = 'None',
    BABY = 'Baby',
    MALE = 'Male',
    FEMALE = 'Female',
}

export enum auscultationType{
    NONE = 'None',
    HEART = 'Heart',
    LUNG = 'Lung',
}

export class AudioFileSelection {
    private AUDIO_TYPE = audioType;
    private CHARACTER_TYPE = characterType;
    private AUSCULTATION_TYPE = auscultationType;

    private audioKeys: string [] = [];
    private characterKeys: string [] = [];
    private auscultationKeys: string [] = [];

    constructor(){
        this.audioKeys = Object.keys(this.AUDIO_TYPE);
        this.characterKeys = Object.keys(this.CHARACTER_TYPE);
        this.auscultationKeys = Object.keys(this.AUSCULTATION_TYPE);
    }

    public getAudioKeys(): string [] {
        return this.audioKeys;
    }

    public getCharacterKeys(): string [] {
        return this.characterKeys;
    }

    public getAuscultationKeys(): string [] {
        return this.auscultationKeys;
    }

    public getAudioValue(audioKey: string): string{
        return this.AUDIO_TYPE[audioKey];
    }

    public getCharacterValue(characterKey: string): string{
        return this.CHARACTER_TYPE[characterKey];
    }

    public getAuscultationValue(auscultationKey: string): string{
        return this.AUSCULTATION_TYPE[auscultationKey];
    }
}