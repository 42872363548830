<!-- <div class="dialog-container"> -->
    <h2 mat-dialog-title> Delete Simulation?</h2>
    <!-- <hr> -->
    <mat-dialog-content>
        <h3>Are you sure you want to delete this simulation? All progress will be deleted permanently. This action can't be undone.</h3>
        <!-- <br> -->
        <!-- <section> -->
        <div style="height:32px">
            <mat-checkbox [(ngModel)]="checked">I understand this simulation will be deleted forever</mat-checkbox>
        </div>
            <!-- </section> -->
    </mat-dialog-content>
    
    <!-- <br> -->
    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button color="primary" mat-dialog-close cdkFocusInitial>Cancel</button>
                <button mat-raised-button color="warn"
                    [mat-dialog-close]="true" [disabled]="!checked" style="border-radius: 0px; border: none;">Delete Simulation</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
<!-- </div> -->