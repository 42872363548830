import { Component, Inject, OnInit } from '@angular/core';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceMode, IVPump } from 'src/app/models/ivpump.model';
import { toolsservice } from '../../services/tools.service';
import { Channel } from 'src/app/models/channel.model';

export interface DialogData {
  device : IVPump;
  patientName: string;
}

@Component({
  selector: 'app-dialog-edit-iv-pump',
  templateUrl: './dialog-edit-iv-pump.component.html',
  styleUrls: ['./dialog-edit-iv-pump.component.scss']
})
export class DialogEditIVPumpComponent implements OnInit {

  public ivpump : IVPump = null;

  //channels 0,1,2: channels A B and C respectively
  public channels : Channel [] = [];

  public get DeviceSettings(): string[] {
    return toolsservice.ToArray(DeviceMode);
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data : DialogData) { }

  public errorInChannel: boolean [] = [false, false, false];


  ngOnInit(): void {

    this.ivpump = this.data.device;

    this.channels = [
      this.ivpump.ChannelA,
      this.ivpump.ChannelB,
      this.ivpump.ChannelC
    ];

  }
}
