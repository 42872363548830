import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import appPackage from 'package.json';
import { CreateErrorLogDto } from '../models/errorlog.model';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { InstitutionService } from './institution.service';
import { UserService } from '../admin/user.service';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class ErrorLoggerService extends ErrorHandler {

  constructor(private http: HttpClient, private userService: UserService, private authService: AuthService, private institutionService: InstitutionService) {
    super();
  }

  // handleError(error) {
  //   console.log('found error:'+error.message);
  //   try {
  //     this.userService.get(this.authService.getUserID()).subscribe((user) => {
  //       this.institutionService.get().subscribe((institution) => {

  //         const errorLog = new CreateErrorLogDto(error.message, new Date(), institution.institutionName, user.userName, appPackage.version, appPackage.errorLoggerName, 'N/A');
  //         this.postError(errorLog).subscribe(
  //           response => {
  //             console.log('Error logged successfully:', response);
  //           },
  //           error => {
  //             console.error('Error logging failed:', error);
  //           });
  //       });
  //     });
  //   }
  //   catch (ex) {
  //     const errorLog = new CreateErrorLogDto(`Logger System Error ${ex}`, new Date(), `N/A`, `N/A`, appPackage.version, appPackage.errorLoggerName, 'N/A');
  //     this.postError(errorLog).subscribe(
  //       response => {
  //         console.log('Error logged successfully:', response);
  //       },
  //       error => {
  //         console.error('Error logging failed:', error);
  //       });

  //   }



  // }

  get errorLogUrl(): string {
    return appPackage.errorLoggerServerPath + "/errorlog";
  }

  postError(errorlog: CreateErrorLogDto): Observable<any> {

    const httpHeaders = new HttpHeaders({
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': this.errorLogUrl,
      'Authorization':appPackage.errorLoggerHeader
    });

    var data = {
      errorDetails: errorlog.errorDetails,
      timeStamp: errorlog.timeStamp,
      institutionName: errorlog.institutionName,
      userName: errorlog.userName,
      appVersion: errorlog.appVersion,
      appName: errorlog.appName,
      licenses: errorlog.licenses
    };

    return this.http.post(`${this.errorLogUrl}`, data, { headers: httpHeaders });

  }

}




