<!-- <div class="dialog-container"> -->
    <div class="dialog-container-header">
        <h2 mat-dialog-title> Edit Phase Title and Description </h2>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <!-- <hr> -->
    <mat-dialog-content>
        <mat-label>Title</mat-label>
        <!-- <br> -->
        <mat-form-field appearance="outline" style="width: 100%;">
            <input autocomplete="off" matInput style="width: 408px;" [(ngModel)]="editPhase.name">
        </mat-form-field>
        <br>
        <mat-label>Description</mat-label>
        <!-- <br> -->
        <mat-form-field appearance="outline" style="width: 100%;">
            <textarea matInput style="height: 172px; width: 408px; resize: none;" [(ngModel)]="editPhase.description"></textarea>
          </mat-form-field>
        <!-- <br> -->
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-button class="green-focus" [disabled]="onDisableCheck()"(click)="onAcceptClick()">Done</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
<!-- </div> -->