<div class="mat-elevation-z8" style="height: 78px;">
    <div
        style="font-family: OpenSansLight; font-size: xx-large; padding-left: 25px; padding-top: 27px; padding-bottom: auto;">
        Admin Panel</div>
</div>
<div style="padding-left: 25px; padding-right: 25px;"  >
    <div class="admin-body">
        <div class="admin-content">
          <div class="header">
            <mat-form-field *ngIf="!this.hideSearchBox" class="half-width">
            <mat-label>Select Institution:</mat-label>
              <input  type="text" id="autocomplete-institution"
                     placeholder="Search Institution"
                     aria-label="Search Institution"
                     [(ngModel)]="currentInstitutionName"
                     matInput
                     [formControl]="searchInstitutionControl"
                     (blur)="revertIfNoSelection($event)"
                     [matAutocomplete]="auto">
                     <mat-icon matSuffix>search</mat-icon>

              <mat-autocomplete class="half-width" autoActiveFirstOption #auto="matAutocomplete"   (optionSelected)="onInstitutionChange($event)" >

                <mat-option *ngFor="let item of this.institutionObservable | async" [value]="item.institutionName">
                  {{item.institutionName}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <p *ngIf="this.hideSearchBox"> Institution: <b>{{currentInstitutionName}}</b> </p>
          </div>
          <div class="tab-content" *ngIf="this.isLoading;">
            <div class="spinnerHolder">
              <mat-spinner
                [diameter]="500"
                [strokeWidth]="10"
                style="align-self: center; align-content: center"
              ></mat-spinner>
            </div>
          </div>
          <div class="members"  [style.display]="!this.isLoading ? 'block' : 'none'" >
            <app-member-list  #appmemberlist ></app-member-list>
          </div>
        </div>
      </div>
</div>
