<!-- <div class="dialog-container"> -->
    <div class="dialog-container-header">
        <h2 mat-dialog-title> {{data.name}} </h2>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <!-- <hr> -->
    
    <mat-dialog-content>
        <mat-label>Injury Type</mat-label>
        <br>
        <mat-form-field appearance="outline">
            <mat-select [value]="availableKeys[0]">
                <mat-option *ngFor="let key of availableKeys; let i = index;" [value]="key" (click)="setSelection(i)">
                    {{getPrettyName(key)}}</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <div style="display: flex; flex-direction: row; width:100%">
            <mat-label style="width: 50%">Injury Name</mat-label>
        </div>
        <div class="texture-selections">
            <div class="scrollable-area">
                <div *ngIf="selectionIndex === 0">
                    <div class="injury-item" *ngFor="let texture of bodyOverlayTexturesDictionary | keyvalue">
                        <div>
                            <mat-checkbox 
                            style="margin-top: auto; margin-bottom: auto;"
                            [checked]="texture.value" 
                            (click)="checkDisabledMulti(texture.key, bodyOverlayTexturesDictionary, '(Body Overlays)')"
                            [disabled]="activeCheckbox.length === 3 && texture.value === false"></mat-checkbox>
                            <img [src]="ImagePath(textureCategory.bodyOverlayTexture, texture.key)" [alt]="texture.key">
                            <h3>{{texture.key | ReplaceUnderscore | RemoveGenderAndRace}}</h3>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectionIndex === 1">
                    <div class="injury-item" *ngFor="let texture of bodyTreatmentTexturesDictionary | keyvalue">
                        <div>
                            <mat-checkbox 
                            style="margin-top: auto; margin-bottom: auto;"
                            [checked]="texture.value"
                            (click)="checkDisabledMulti(texture.key, bodyTreatmentTexturesDictionary, '(Body Treatments)')"
                            [disabled]="activeCheckbox.length === 3 && texture.value === false"></mat-checkbox>
                            <img [src]="ImagePath(textureCategory.bodyTreatmentTexture, texture.key)" [alt]="texture.key">
                            <h3>{{texture.key | ReplaceUnderscore | RemoveGenderAndRace}}</h3>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectionIndex === 2">
                    <div class="injury-item" *ngFor="let texture of animOverlayTexturesDictionary | keyvalue">
                        <div>
                            <mat-checkbox 
                               style="margin-top: auto; margin-bottom: auto;"
                               [checked]="texture.value"
                               (click)="checkDisabledMulti(texture.key,animOverlayTexturesDictionary, '(Animated Overlays)')"
                               [disabled]="activeCheckbox.length === 3 && texture.value === false"></mat-checkbox>
                            <img [src]="ImagePath(textureCategory.animOverlayTexture, texture.key)" [alt]="texture.key">
                            <h3>{{texture.key | ReplaceUnderscore | RemoveGenderAndRace}}</h3>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectionIndex === 3">
                    <div class="injury-item" *ngFor="let texture of eyeOverlayTexturesDictionary | keyvalue">
                        <div>
                            <mat-checkbox style="margin-top: auto; margin-bottom: auto;"
                            [checked]="texture.value" 
                            (click)="checkDisableRestEyes(texture.key)"
                            [disabled]="activeCheckboxEyes.length === 3 && texture.value === false"></mat-checkbox>
                            <img [src]="ImagePath(textureCategory.eyeOverlayTexture, texture.key)" [alt]="texture.key">
                            <h3>{{texture.key | ReplaceUnderscore | RemoveGenderAndRace}}</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div class="texture-information">
                <h2>{{activeCheckbox.length}}/3 Body Textures:</h2>
                <p *ngFor="let selectedTexture of activeCheckboxWExtraData">{{ selectedTexture | ReplaceUnderscore | RemoveGenderAndRace }}</p>
                <h2>{{activeCheckboxEyes.length}}/3 Eye Textures:</h2>
                <p *ngFor="let eyeTexture of activeCheckboxEyes">{{ eyeTexture | ReplaceUnderscore | RemoveGenderAndRace}}</p>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-button [mat-dialog-close]="this.data.clinical" class="green-focus">Add Injury Textures</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
<!-- </div> -->