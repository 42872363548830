import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { patient } from 'src/app/models/patient.model';
import { scenario } from 'src/app/models/scenario.model';
import { IfStmt } from '@angular/compiler';
import { toolsservice } from '../../services/tools.service';
import {   MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  name: string;
  patient: patient;
  description: string;
  scenario: scenario;
}

@Component({
  selector: 'app-dialog-edit-patient',
  templateUrl: './dialog-edit-patient.component.html',
  styleUrls: ['./dialog-edit-patient.component.scss'],
})
export class DialogEditPatientComponent implements OnInit {
  constructor(
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogEditPatientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void { }

  isSubmitButtonDisabled(): boolean {
    return (toolsservice.isNullUndefinedEmpty(this.data.patient.patientName))
  }
}
