import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewEncapsulation } from '@angular/core';
import { TaskItem, TaskList } from '../../models/taskitem.model';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { UploadService } from 'src/app/services/upload.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ScenariosService } from 'src/app/services/scenarios.service';
import {  MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerService } from 'src/app/services/Server.service';
// import { MatFormFieldModule, MatInputModule } from '@angular/material';

export interface DialogData {
  tasklink: string;
}

export class SpaceErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && ( control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChecklistComponent implements OnInit {

  taskListControl: UntypedFormControl = new UntypedFormControl();

  matcher = new SpaceErrorStateMatcher();

  // url path to each individual checklist items.
  // taskList : string[] = [];
  // @Input()

  taskList: TaskList;
  // @Output()
  // taskItemChange = new EventEmitter<TaskItem[]>();
  // path to save the tasklist.

  selectedTaskList = '';
  newTitleName = '';

  titleFormControl: UntypedFormControl = new UntypedFormControl();

  // private get isOffline(): boolean {
  //   return this.scenarios.offlineMode;
  // }

  constructor( private uploadService: UploadService,
               private http: HttpClient,
               private scenarios: ScenariosService,
               public dialogRef: MatDialogRef<ChecklistComponent>,
               @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    if ( this.data.tasklink !== null && this.data.tasklink !== undefined )
    {
      this.selectedTaskList = this.data.tasklink.replace('.json', '');
      this.onLoad(this.selectedTaskList);
    }
  }

  /**
   * Add new checklist to the current checklist.
   * @param TaskName The name of the task
   */
  onAddChecklist( newTaskName: string ): void{
    // do not add item if the string is blank.
    if ( newTaskName === '' || newTaskName.trim().length === 0 ) { return; }
    // basically if we hvae duplicated entry, avoid that.
    if ( this.taskList.tasklist.filter( x => x.taskName === newTaskName ).length > 0 ) { return; }
    this.taskList.tasklist.splice(0, 0, new TaskItem(newTaskName) );
    // this.taskItemChange.emit(this.taskItem);
  }

  /**
   * Remove checklist item from this patient
   * @param index The index of which the task is going to be deleted
   */
  onDeleteChecklist( index: number ): void{
    //this.taskItem.splice(index, 1);
    // this.taskItemChange.emit(this.taskItem);
  }

  onEditChecklist(index: number, taskName: string ){
    //this.taskItem[index].TaskName = taskName;
    // this.taskItemChange.emit(this.taskItem);
  }

  drop(event: CdkDragDrop<string[]>){
    //moveItemInArray(this.taskItem, event.previousIndex, event.currentIndex);
    // this.taskItemChange.emit(this.taskItem);
  }

  // when we need to save teh checklist back to the file structure.
  onSave(){
    // so um how are we going to do this? Are we going to create our own http.post call here?
    // who knows? Let's find out more with Gene tomorrow!
    // this means that the old file gets deleted and then save as a new file instead.
    if ( this.newTitleName !== this.selectedTaskList ) {

      // destroy old file name
      this.deleteTaskList( this.selectedTaskList ).subscribe(x => console.log(x), err => console.log(err));
      // create new file
      // this.createTaskList( this.newTitleName, this.taskItem ).subscribe( x => {
      //   if ( x !== null ) {
      //     // refresh the list once it has been created.
      //     this.selectedTaskList = this.newTitleName;
      //   }
      // }, err => console.log(err));
    } else {

      // in this case here we're just updating the file.
      // this.updateTaskList( this.selectedTaskList, this.taskItem ).subscribe(
      //   x => {
      //     if ( x !== null ) {
      //       console.log(x);
      //     }
      //   },
      //   err => console.log(err));
    }
  }

  onDiscard() : void {
    // discard changes made (Which basically reloads the onLoad() function anyway?)
    this.onLoad(this.selectedTaskList);
  }

  /**
   * Load the task from the server.
   * @param taskname The Task Name to load
   */
  onLoad( taskname: string ): void{
    // load the checklist from the server.
    this.readTaskList(taskname).subscribe(
      x => this.taskList.tasklist = x ?? [] // assuming the result is TaskItem[]
    );
    this.newTitleName = taskname;
  }

  clearList() {
    this.selectedTaskList = '';
    this.newTitleName = '';
    this.taskList.tasklist= new TaskItem[1];
  }

  disableSave(): boolean {
    return this.taskList.tasklist?.length === 0 || this.newTitleName.trim().length === 0;
  }

  /**
   * Get list of task available from the server.
   */
  // getList() {
  //   // provide a full list of combo box of checklist items.
  //   if( !this.isOffline ) {
  //     this.http.get<string[]>(this.url).subscribe(x => {
  //       this.taskList = x.map(x=>x.replace('.json',''));
  //     });
  //   }
  // }

  // once we close this dialog confirming ok, we'll send back the patient controller exactly what the tasklist the user picked.
  onDialogClose(): void {
    this.dialogRef.close(this.newTitleName);
  }

  // providing the CRUD method...

  // Create new
  createTaskList( taskname: string, taskItems: TaskItem[] ): Observable<any> {

    // TODO: need to change this code for electron build..
    if ( ServerService.OfflineMode )
    {
      return new Observable<any>((observer) => {
        observer.next( null );
        observer.complete();
      })
    } else {
      // header information
      const httpHeaders = new HttpHeaders({
        'Content-type' : 'application/json',
        Accept : 'application/json',
        'Access-Control-Allow-Origin' : ServerService.Task_URL
      });

      // post scenario to the server
      return this.http.post(`${ServerService.Task_URL}/${taskname}`, taskItems, { headers: httpHeaders });
    }
  }

  // need to change the code for this for electron build...
  readTaskList( taskname: string ): Observable<TaskItem[]> {
    // TODO: call read from NestJS - for now we'll use this placeholder.
    if ( ServerService.OfflineMode )
    {
      return new Observable<TaskItem[]>((observer) => {
        observer.next( [ new TaskItem('')]);
        observer.complete();
      });
    } else {
      return this.http.get<TaskItem[]>(`${ServerService.Task_URL}/${taskname}`);
    }
  }

  // return true if submitted else false if something fails really this should be save?
  updateTaskList( taskname: string, taskItems: TaskItem[] ): Observable<any> {
    // TODO: Call update  function to NestJS
    if ( ServerService.OfflineMode )
    {
      return new Observable<any>(observer => {
        observer.next(true);
        observer.complete();
      });
    } else {
      return this.http.put( `${ServerService.Task_URL}/${taskname}`, taskItems );
    }
  }

  // true if destroyed success, false if fails
  deleteTaskList( taskname: string ): Observable<any> {
    // TODO: Call delete function to NestJS
    if ( ServerService.OfflineMode )
    {
      return new Observable<any>((observer) => {
        observer.next(true);
        observer.complete();
      });
    } else {
      return this.http.delete( `${ServerService.Task_URL}/${taskname}` );
    }
  }
}
