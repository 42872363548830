import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData{
  name: string;
  description: string;
}


@Component({
  selector: 'app-dialog-delete-patient',
  templateUrl: './dialog-delete-patient.component.html',
  styleUrls: ['./dialog-delete-patient.component.scss']
})
export class DialogDeletePatientComponent implements OnInit {

  checked:boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogDeletePatientComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.checked = false;
  }
}
