<div class="dialog-container">
    <h2 mat-dialog-title style="font-family: OpenSansLight;"> Delete Account Forever? </h2>
    <hr>
    <mat-dialog-content>
        <p>Are you sure you want to delete {{data.name}}'s account forever?</p>
        <br>
        <p>Any simulations this member created and weren't copied to your account will be deleted permanently.</p>
        <br>
        <section style="display: flex;">
            <mat-checkbox [(ngModel)]="checked" style="font-size: medium; color: black;"></mat-checkbox>
            <p style="margin-left: 8px; font-weight: 500;">I understand this member's account will be deleted permanently.</p>
        </section>
    </mat-dialog-content>
    <br>
    <mat-dialog-actions>
        <div class="buttons">
            <span>
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button mat-dialog-close
                    [mat-dialog-close]="true" 
                    color="warn" [ngStyle]="{'opacity': checked ? '1' : '0.5'}" [disabled]="!checked">Delete Account</button>
            </span>
        </div>
    </mat-dialog-actions>
</div>