<div class="dialog-container">
    <h2 mat-dialog-title> Delete {{data.name}}? </h2>
    <!-- <hr> -->
    <mat-dialog-content>
        <p style="font-size: medium;">Are you sure you want to delete {{ data.name }}? All progress will be deleted
            permanently. This action can't be undone.</p>
        <br>
        <!-- <section> -->
            <div style="height:32px">
                <mat-checkbox [(ngModel)]="checked" style="font-size: medium;">I understand this patient will be deleted
                    forever</mat-checkbox>
            </div>
        <!-- </section> -->
    </mat-dialog-content>
    <!-- <br> -->
    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button color="warn" [mat-dialog-close]="checked" [disabled]="!checked">Delete
                    Patient</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
</div>