import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-advancesearchbox',
  templateUrl: './advancesearchbox.component.html',
  styleUrls: ['./advancesearchbox.component.scss']
})
export class AdvancesearchboxComponent implements OnInit {

  @Input() title = 'item';

  get displayTitle(): string {
    return 'Create or New ' + this.title;
  }

  get selectedItem(): string {
    return this.selectedItems.join(',');
  }

  @Input() set selectedItem( value: string ) {
    // if value is null or empty, assign empty array, otherwise try to use comma split.
    this.selectedItems = ( value == null || value?.trim().length === 0 ) ? [] : value?.split(',') ?? [];
  }
  @Output() selectedItemChange: EventEmitter<string> = new EventEmitter();

  // determine whether we can allow user to add multiple of selection or only allow one selection.
  @Input() canAcceptMultipleValues = false;

  // two way binding setup for collection of available tags. May provide two ways? We'll see?
  @Input() collectionItems: string[] = [];
  @Output() collectionItemsChange: EventEmitter<string[]> = new EventEmitter();

  // Two way binding setup
  @Input() selectedItems: string[] = [];
  @Output() selectedItemsChange: EventEmitter<string[]> = new EventEmitter();

  // Event to handle options
  @Output() EditSelected: EventEmitter<string> = new EventEmitter();
  @Output() DeleteSelected: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  /**
   * Return remaining collection that was not selected.
   */
  unselectedCollection( filter: string ): string[] {
    if ( filter !== null && filter !== undefined && filter.trim().length > 0 ){
      return this.collectionItems.filter(x => this.selectedItems.indexOf(x) === -1 && x.toLowerCase().indexOf(filter.toLowerCase()) > -1 );
    } else {
      return this.collectionItems.filter(x => this.selectedItems.indexOf(x) === -1 );
    }
  }

  /**
   * When the user adds a new item from the search menu.
   * @param value new item
   */

  // so this will be interesting? this only happens when the user hits enter on the search bar.
  // In this case here, I need to ask Sohail if the design changes are intentional or not?
  onAddItem(value: string ): void {
    if ( this.canAcceptMultipleValues ) {
      this.selectedItems.push(value);     // push regardless.
    } else {
      if ( this.selectedItems.length > 0 ) { // if there are more than one entry, go ahead and update the value instead.
        this.selectedItems[0] = value;
      } else {
        this.selectedItems.push(value);   // otherwise push a new value here instead
      }
    }

    this.selectedItemChange.emit(this.selectedItem);
    this.selectedItemsChange.emit(this.selectedItems);

    // check and see about the history collection
    if ( this.collectionItems.indexOf(value) === -1 )
    {
      this.collectionItems.push(value);
      this.collectionItemsChange.emit(this.collectionItems);
    }
  }

  /**
   * When the user selects the history item.
   * At this point we do not care if the collectionItem has changed or not.
   * but will be filtered out.
   */
  onSelectItem(value: string): void {
    if ( this.canAcceptMultipleValues ){
      this.selectedItems.push(value);
    } else {
      if ( this.selectedItems.length > 0 ){
        this.selectedItems[0] = value;
      } else {
        this.selectedItems.push(value);
      }
    }
    this.selectedItemsChange.emit(this.selectedItems);
    this.selectedItemChange.emit(this.selectedItem);
  }

  /**
   * Remove the selected item based on the string value.
   */
  onRemoveItem( value: string ): void {
    const index = this.selectedItems.indexOf(value);
    if ( this.selectedItems.length === 1 && index === 0 ) {
      this.selectedItems = [];
    } else {
      this.selectedItems.splice(index, 1);
    }
    // notify of the changes made to the selected items.
    this.selectedItemsChange.emit(this.selectedItems);
    this.selectedItemChange.emit(this.selectedItem);
  }

  /**
   * Notify the user that they want to delete this item from history.
   */
  onDeleteItem(value: string ): void {
    // Remove from selected items if they exist.
    let index = this.selectedItems.indexOf(value);
    if ( index > -1 ) {
      this.selectedItems.splice(index, 1 );
    }
    // remove from collection items if they exist.
    index = this.collectionItems.indexOf(value);
    if ( index > -1) {
      this.collectionItems.splice(index, 1);
    }
    // notify parent controller to handle this object.
    this.DeleteSelected.emit(value);

    this.selectedItemsChange.emit(this.selectedItems);
    this.selectedItemChange.emit(this.selectedItem);
  }

  /**
   * Notify the user that they want to edit this particular item in history.
   */
  onEditItem( value: string ): void {
    this.EditSelected.emit(value);
  }
}
