import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { scenario } from 'src/app/models/scenario.model';

import {   MAT_DIALOG_DATA,   MatDialog,   MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { toolsservice } from 'src/app/editor/services/tools.service';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from 'src/app/services/Server.service';
import { ScenariosService } from 'src/app/services/scenarios.service';
import { FileType, customAudioModel } from 'src/app/models/CustomFile.model';
import { DialogDeleteConfirmComponent } from '../dialog-delete-confirm/dialog-delete-confirm.component';
import { Md5 } from 'ts-md5';
export class FileDataholder {
  fileBuffer;
  fileName: string;
}

@Component({
  selector: 'app-dialog-upload-file',
  templateUrl: './dialog-upload-file.component.html',
  styleUrls: ['./dialog-upload-file.component.scss']
})
export class DialogUploadFileComponent implements OnInit {
  private currentlyPlayingAudio: HTMLAudioElement | null = null;
  @Input()
  requiredFileType: string;
  fileTypes = Object.values(FileType);
  selectedFileType = this.fileTypes[0];
  fileName = '';
  uploadProgress: number;
  uploadSub: Subscription;
  audioFiles: customAudioModel[];
  isLoading: boolean;
  audioLoading:boolean;
  selectedAudioFile: string;
  @ViewChild('audioSrc', { static: true }) audioElementRef: ElementRef;
  private static MAX_FILE_SIZE_MB = 8;
  private static MAX_FILE_SIZE_BYTES = DialogUploadFileComponent.MAX_FILE_SIZE_MB * 1024 * 1024;
  selectedFile: File;
  constructor(private http: HttpClient,
    public dialogRef: MatDialogRef<DialogUploadFileComponent>,
    private msb: MatSnackBar,
    private scenarioService: ScenariosService,
    public dialog: MatDialog,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.getCurrentAudioFiles();
  }
   getGUID(): string {
    let d = new Date().getTime();
    const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return guid;
}

  isValidExtension(filename): boolean {
    var parts = filename.name.split('.');
    var ext = parts[parts.length - 1];
    switch (ext.toLowerCase()) {
      case 'mp3':
      case 'ogg':
        return true;
    }
    return false;
  }
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.fileName = this.selectedFile.name;
  }
  //list.map(scenario => new SearchContainer(scenario.name, scenario.tags, scenario));

  getCurrentAudioFiles() {
    this.isLoading = true;
    this.scenarioService.getCustomAudioFiles().subscribe(
      (list) => {
        this.audioFiles = list.map(item => {
          const audioModel = new customAudioModel();
          audioModel.filePath = item.filePath;
          audioModel.fileType = item.fileType;
          audioModel.fileId = item.fileId;
          audioModel.fileName = item.fileName;
          audioModel.fileHash = item.fileHash;
          return audioModel;
        });
        this.isLoading = false;
      },
      (err) => {
        console.error('Error on audioFiles' + err);
      });

  }
  public playAudioFile(audio: customAudioModel) {

    this.audioLoading=true;
    this.scenarioService.downloadAudioFile(audio.fileId).subscribe((fileData: Blob) => {
      this.audioLoading=false;
      const audioPlayerContainer = this.elementRef.nativeElement.querySelector('#audioPlayerContainer');
      audioPlayerContainer.innerHTML = ''; // Clear previous content
      const audioBlob = new Blob([fileData], { type: 'audio/wav' }); // Adjust the type based on the actual audio format
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio();
      audioElement.src = audioUrl;
      audioElement.controls = true;
      audioPlayerContainer.appendChild(audioElement);
      audioElement.play();
      this.currentlyPlayingAudio = audioElement;
      this.selectedAudioFile=audio.fileName;
      this.audioLoading=false;

    },
      (error) => {
        console.error('Error downloading audio:', error);
        this.isLoading = false;
      }
    );
  }
  deleteAudioFile(audio: customAudioModel) {
    const dialogRef = this.dialog.open(DialogDeleteConfirmComponent, {
      width: '447px',
      data: {
        audioFile: audio
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.scenarioService.deleteCustomAudioFile(audio.fileId).subscribe(
          (list) => {
            toolsservice.openSnackBar(this.msb, `${audio.fileName} Custom Audio File is Deleted`, false);
            this.getCurrentAudioFiles();
          }
          , (err) => {
            console.error('Error on deleting audioFiles:' + err.message);
            this.getCurrentAudioFiles();
          });

      }
    });

  }
  arrayBufferToString(buffer: Uint8Array): string {
    let result = '';
    const length = buffer.length;
    for (let i = 0; i < length; i++) {
      result += String.fromCharCode(buffer[i]);
    }
    return result;
  }

  async calculateMD5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        const data = new Uint8Array(buffer);
        const dataString = this.arrayBufferToString(data);
        const hash = Md5.hashStr(dataString);
        resolve(hash.toString());
      };
      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  async onUploadClicked() {
    if (!this.isValidExtension(this.selectedFile)) {
      toolsservice.openSnackBar(this.msb, 'File format is not Supported!', false);
      return;
    }
    const fileHash = await this.calculateMD5(this.selectedFile);
    if (this.audioFiles.length != 0 && this.audioFiles.filter(x => x.fileHash == fileHash).length > 0) {
      toolsservice.openSnackBar(this.msb, 'File is already uploaded!', false);
      return;
    }

    //check if file size exceeds limits
    if (this.selectedFile.size > DialogUploadFileComponent.MAX_FILE_SIZE_BYTES) {
      toolsservice.openSnackBar(this.msb, 'File is more than 8MB please select a smaller file!', false);
      return;
    }

    if (this.selectedFile) {
      this.fileName = this.selectedFile.name;
      const formData = new FormData();

      formData.append("file", this.selectedFile);
      formData.append("fileId", this.getGUID());
      formData.append("fileType", this.selectedFileType);
      formData.append("fileHash", fileHash);
      const upload = this.http.post(ServerService.CustomAudio_URL(), formData, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        finalize(() => this.reset())
      );
      this.uploadSub = upload.subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
        else {
          this.getCurrentAudioFiles();
        }
      })
    }
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }
  onDialogClose() {
    this.dialogRef.close();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
}
