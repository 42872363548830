import { Component, Inject, OnInit } from '@angular/core';
import {   MAT_DIALOG_DATA,   MatDialogRef } from '@angular/material/dialog';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import {   MatSnackBar } from '@angular/material/snack-bar';
import { scenario } from 'src/app/models/scenario.model';
import { toolsservice } from 'src/app/editor/services/tools.service';
import { TeamsService } from 'src/app/services/teams.service';
import { CommunityService } from 'src/app/services/community.service';
export interface DialogData {
  scenario: scenario;
  moduleName:string;
}

@Component({
  selector: 'app-rename-scenario',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          backgroundColor: 'green',
          maxWidth: '400px',
          width: '400px',
        })
      ),
      state(
        'closed',
        style({
          backgroundColor: 'blue',
          maxWidth: '0px',
          width: '0px',
          display: 'none',
        })
      ),
      transition('open <=> closed', [animate('0.2s')]),
    ]),
  ],
  templateUrl: './rename-scenario.component.html',
  styleUrls: ['./rename-scenario.component.css']
})

export class RenameScenarioComponent implements OnInit {
  public prettyCreatedDate: string = null;
  public prettyUpdatedDate: string = null;
  public initialName:string;
  constructor(
    public dialogRef: MatDialogRef<RenameScenarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackbar: MatSnackBar,
    private teamService: TeamsService,
    private communityService:CommunityService
  ) {
  this.initialName=  this.data.scenario?.name;
  }

  ngOnInit(): void {
    let tempDate: Date = new Date(this.data.scenario.originalDate);
    this.prettyCreatedDate = tempDate.toLocaleDateString();
    tempDate = new Date(this.data.scenario.modifiedDate);
    this.prettyUpdatedDate = tempDate.toLocaleDateString();
  }

  onDialogClose(){
    this.dialogRef.close();
  }
  onReShare(scenario:scenario)
  {
    if(scenario.name=='' ||scenario.name==undefined || scenario.name==this.initialName)
    {
      toolsservice.openSnackBar(this.snackbar,`Please change name of Scenario ${this.data.scenario.name}` ,false);
      return;
    }
// unlink scenario and share
if(this.data.moduleName=='Team')
{
   this.teamService
        .addToSimulation(this.data.scenario.ScenarioId,this.data.scenario.name)
        .subscribe(() => {
          toolsservice.openSnackBar(this.snackbar,`Scenario ${this.data.scenario.name} is shared with ${this.data.moduleName}`,true);
          this.dialogRef.close();
        },(error)=>
        {
          toolsservice.openSnackBar(this.snackbar,`Error on database ${error}`,false);
          this.dialogRef.close();
        });
}
else
{
  this.communityService.addScenarioToCommunity(this.data.scenario.ScenarioId,this.data.scenario.name)
  .subscribe(() => {
    toolsservice.openSnackBar(this.snackbar,`Scenario ${this.data.scenario.name} is shared with ${this.data.moduleName}`,true);
    this.dialogRef.close();
  },(error)=>
  {
    toolsservice.openSnackBar(this.snackbar,`Error on database ${error}`,false);
    this.dialogRef.close();
  });

}

}
}
