import { ChangeDetectorRef, Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

import { compare, hashSync } from 'bcryptjs';
import { toolsservice } from 'src/app/editor/services/tools.service';

export interface DialogData{
  username: string,
  password: string,
}

@Component({
  selector: 'app-dialog-change-member-password',
  templateUrl: './dialog-change-member-password.component.html',
  styleUrls: ['./dialog-change-member-password.component.css']
})
export class DialogChangeMemberPasswordComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogChangeMemberPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }

  public oldPasswordAttempt: string = '';
  public oldPasswordValid: boolean = false;
  public newPassword: string = '';
  public reTypePassword: string = '';

  async ValidateOldPassword(){
    // DO NOT COMPARE THIS EVERY CHANGE NOTIFICATION! THIS WILL SLOW DOWN YOUR WEBSITE!
    // Instead calling this after the user leaves the control to only fire once.
    await compare( this.oldPasswordAttempt, this.data.password, (err, value)=>{
      if( err ) {
        console.error(err);
      } else {
        // value will be a boolean - true means it matches, false means it does not.
        this.oldPasswordValid = value;
      }
    })
  }

  onValidateOldPassword(): boolean {
    if( !this.oldPasswordAttempt ) return false;
    let temp = hashSync(this.oldPasswordAttempt, 10);
    return temp === this.data.password;
  }

  onValidateNewPassword(): boolean {
    return toolsservice.Password_constraint.test(this.newPassword);
  }

  onValidateRetypePassword(): boolean {
    return this.reTypePassword === this.newPassword
  }

  canSubmit():boolean {
    return this.onValidateNewPassword() && this.onValidateRetypePassword();
  }
}
