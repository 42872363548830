<div class="dialog-container">
    <h2 mat-dialog-title style="font-family: OpenSansLight;"> Delete Audio File? </h2>
    <hr>
    <mat-dialog-content>
        <p>Are you sure you want to delete {{data.audioFile.fileName}}
           <br/> custom audio file ?</p>
        <br>
        <!-- <section>
            <mat-checkbox [(ngModel)]="checked" style="font-size: medium;">I understand this patient will be deleted forever</mat-checkbox>
        </section> -->
    </mat-dialog-content>
    <br>
    <mat-dialog-actions>
        <div class="buttons">
            <span>
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button mat-dialog-close
                    [mat-dialog-close]="true"
                    color="warn">Delete Audio File</button>
            </span>
        </div>
    </mat-dialog-actions>
</div>
