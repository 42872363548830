import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData{
  name: string;
  description: string;
}

@Component({
  selector: 'app-dialog-delete-simulation',
  templateUrl: './dialog-delete-simulation.component.html',
  styleUrls: ['./dialog-delete-simulation.component.scss']
})
export class DialogDeleteSimulationComponent implements OnInit {

  checked:boolean = false;


  constructor(public dialogRef: MatDialogRef<DialogDeleteSimulationComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.checked = false;
  }

}
