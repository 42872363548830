import { phase } from './phase.model';
import { clinical } from './statedata.model';
import { toolsservice } from '../editor/services/tools.service';
import { Device } from './device.model';

export class patient {
  public isHeadgearChecked = false; // THINK OF REFACTOR MAYBE?
  public InitialClinicalData: clinical;
  public genderEthnicity = 'Wht_Male'; // this combines to ModelType...
  public amputation = 'Body'; // this combines to ModelType...
  public age = 'Adult';
  public clothingOption = 'Civ1';
  public clothingState = 'Underwear Only';
  public accessory = 'none';
  public headgear = false;

  public customfileLinks: string[] = [];
  public treatments: string [] = [];
  public devices: Device[] = [];
  
  ///image reference for the scenario card
  public imageUrl: string = "";

  constructor(
    public patientId: string,
    public taskList: string,
    public goalsAndDetails: string,
    public weight: number = 80,
    public patientName: string,
    public modelType: number = 16,
    public clothingType: string = '1-Civ1',
    public phases: phase[],

    // extra detail data for editor
    public backstory: string
  ) {
    if (patientId === null) this.patientId = toolsservice.makeId();
    this.InitialClinicalData = new clinical(null, 'default', 'defaut', 0, 0, 0);
    this.weight = 80;
    this.modelType = 16;
    this.clothingType = '1-Civ1';
  }
}
