import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-auscultation-point',
  templateUrl: './auscultation-point.component.html',
  styleUrls: ['./auscultation-point.component.css']
})
export class AuscultationPointComponent implements OnInit {

  @Input() label: string;
  @Input() options: string [];
  @Input() auscultation: string;
  @Output() auscultationChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    console.log(this.options);
  }

  public emitChange(){
    console.log(this.auscultation);
    this.auscultationChange.emit(this.auscultation);
  }
}
