<div class="listed-items">
  <div style="margin-top: auto; margin-bottom: auto;"><mat-icon>audiotrack</mat-icon>{{ this.fileName }}</div>

  <span class="right">
    <mat-form-field appearance="legacy">
      <mat-label> Source </mat-label>
      <mat-select [(value)]="selectedSource">
        <mat-option
          *ngFor="let source of this.audioFileSelection.getAudioKeys()"
          value="{{ this.audioFileSelection.getAudioValue(source) }}"
          (click)="this.toggleDropdownSelections()"
          >{{ this.audioFileSelection.getAudioValue(source) }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="this.showCharacterType" appearance="legacy">
      <mat-label> Character Type </mat-label>
      <mat-select [(value)]="selectedCharacterType">
        <mat-option
          *ngFor="
            let characterType of this.audioFileSelection.getCharacterKeys()
          "
          value="{{ this.audioFileSelection.getCharacterValue(characterType) }}"
        >
          {{ this.audioFileSelection.getCharacterValue(characterType) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="this.showAuscultationType" appearance="legacy">
      <mat-label> Organ </mat-label>
      <mat-select [(value)]="selectedAuscultation">
        <mat-option
          *ngFor="let organ of this.audioFileSelection.getAuscultationKeys()"
          value="{{ this.audioFileSelection.getAuscultationValue(organ) }}"
        >
          {{ this.audioFileSelection.getAuscultationValue(organ) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-flat-button
      (click)="uploadToServer()"
      [disabled]="this.determineDisabled()"
      [ngClass]="{
        'button-disabled': this.determineDisabled(),
        'button-enabled': !this.determineDisabled()
      }"
      style="margin-left: 20px"
    >
      confirm
    </button>

    <button mat-icon-button style="margin-left: 20px">
      <mat-icon>delete</mat-icon>
    </button>
  </span>
</div>
