import { Component, Inject, OnInit } from '@angular/core';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CharacterParamsManagerService,
  IDENTITY_KEY_STRING,
} from 'src/app/data/character-params-manager.service';

export interface DialogData {
  characterParam: string;
  patientActiveEquipment: string[];
}

export interface EquipmentState {
  equipmentName: string;
  isActive: boolean;
}

@Component({
  selector: 'app-dialog-treatment',
  templateUrl: './dialog-treatment.component.html',
  styleUrls: ['./dialog-treatment.component.scss'],
})
export class DialogTreatmentComponent implements OnInit {
  public identityKeyString: IDENTITY_KEY_STRING;
  public equipmentStates: EquipmentState[] = [];
  public chosenEquipment: string[] = [];

  constructor(
    public characterParams: CharacterParamsManagerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    //collect a list of equipment
    let equipment: string[] = this.characterParams.getEquipmentByEntry(
      this.data.characterParam
    );

    //for each piece of equipment add it to a new collection that monitors active states
    equipment.forEach((item) => {
      this.equipmentStates.push({ equipmentName: item, isActive: false });
    });

    //for each piece of equipment that is already in the collected patientActiveEquipment array set its active state to true
    this.equipmentStates.forEach((state) => {
      this.data.patientActiveEquipment.forEach((activeState) => {
        if (state.equipmentName === activeState) {
          state.isActive = true;
          this.chosenEquipment.push(state.equipmentName);
        }
      });
    });
  }

  /**
   * add/remove items from the chosen equipment list using the dom
   * @param $event the event passed from the dom
   * @param index the position in the array
   */
  public updateActiveState($event: any, index: number) {
    //grab the true or false value from the dom
    this.equipmentStates[index].isActive = $event;

    //if that logged value is true, then add the equipment to the list
    //otherwise if that equipment if false, remove it from the list using
    if (this.equipmentStates[index].isActive === true) {
      this.chosenEquipment.push(this.equipmentStates[index].equipmentName);
    } else {
      this.chosenEquipment.splice(
        this.chosenEquipment.indexOf(this.equipmentStates[index].equipmentName),
        1
      );
    }
  }
}
