import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { clinical } from 'src/app/models/statedata.model';

@Component({
  selector: 'app-labs-hover-button',
  templateUrl: './labs-hover-button.component.html',
  styleUrls: ['./labs-hover-button.component.scss']
})
export class LabsHoverButtonComponent implements OnInit {

  @Input() clinical: clinical;
  @Input() gender : string;

  @Output() labEvent = new EventEmitter<{action:string, data:clinical}>();

  showAudioPopupContent: boolean = false;
  showPopup: boolean = false;

  showAudioPopupF(): void {
    this.showAudioPopupContent = true;
  }

  showPopupF(): void{
    this.showAudioPopupContent = false;
    this.showPopup = true;
  }

  constructor() { }

  ngOnInit(): void { }

  OnEditItem():void {
    this.labEvent.emit({action:"Update", data: this.clinical});
  }

  onDeleteItem(): void {
    this.labEvent.emit({action:"Delete", data: this.clinical});
  }
}
