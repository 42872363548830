import { Component, OnInit, Inject } from '@angular/core';
import { patient } from 'src/app/models/patient.model';
import { scenario } from 'src/app/models/scenario.model';
import { UntypedFormBuilder } from '@angular/forms';
import { toolsservice, ModelType, Age, CardiacRhythm, ABGVBG_DrawType } from '../../../editor/services/tools.service';
import { disableDebugTools } from '@angular/platform-browser';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  scenario: scenario;
}

@Component({
  selector: 'app-dialog-add-patient',
  templateUrl: './dialog-add-patient.component.html',
  styleUrls: ['./dialog-add-patient.component.scss']
})
export class DialogAddPatientComponent implements OnInit {
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      public dialogRef: MatDialogRef<DialogAddPatientComponent>,
      private fb: UntypedFormBuilder,
    ) { }

   public patientNames: string [] = [];
  // @Input() scenario: scenario;

  public presentVitals = false;
  public newPatient: patient = new patient(null, '', '', 50, '', 0, '', [], '');

  public genderEthnicity = '';
  public amputation = '';

  ngOnInit(): void {}

  // returns the enum value for associated string
  setEnumValue(age: string, patientObj: patient, genderEthnicity: string, amputation: string = 'Body'): void {
    let str = '';
    if (amputation === '') {
      str = genderEthnicity;
    } else {
      str = `${genderEthnicity}_${amputation}`;
    }

    // if old person append G_ to obtain proper number from ModelType
    if (Age[age] === 2) {
      str = `G_${str}`;
    } else if (Age[age] === 0) {
      // baby
      patientObj.clothingType = '0';
      str = str.split('_')[0];
      str += '_Baby';
    }

    patientObj.modelType = ModelType[str];
  }

  getCardiacRhythmValue(str: string) {
    if (str === 'VENTACHY (nonperfusing)') {
      str = 'VENTACHY_1';
    } else if (str === 'VENTACHY (perfusing)') {
      str = 'VENTACHY_2';
    }
    return CardiacRhythm[str];
  }

  // sets the datafields to their appropriate numbers
  setABGVBG_DrawType_DrawType_Data(patientObj: patient, drawType: number) {
    switch (drawType) {
      case 0:
        // ABG DEFAULT DATA
        console.log('abg');
        patientObj.InitialClinicalData.pH = 7.40;
        patientObj.InitialClinicalData.pa02 = 95;
        patientObj.InitialClinicalData.paCO2 = 40;
        patientObj.InitialClinicalData.lab_saO2 = 99;
        break;
      case 1:
        // VBG DEFAULT DATA
        console.log('VBG');
        patientObj.InitialClinicalData.pH = 7.36;
        patientObj.InitialClinicalData.pa02 = 40;
        patientObj.InitialClinicalData.paCO2 = 44;
        patientObj.InitialClinicalData.lab_saO2 = 75;
        break;

      default:
        break;
    }


  }

  getFSG_DrawType(str: string) {
    const drawType = ABGVBG_DrawType[str];
    /// this.setFSG_DrawType_Data(patient, drawType);
    return drawType;
  }

  // determines what happens when a new age is selected
  onClickAge(age: string, patientObj: patient) {
    // if the patients gender and ethnicity have not been set then do not update
    patientObj.age = age;
    if (patientObj.genderEthnicity === undefined || patientObj.genderEthnicity === null) {
      return null;
    }

    // otherwise get a new enum value
    this.setEnumValue(patientObj.age, patientObj, patientObj.genderEthnicity, patientObj.amputation);
    return null;

  }

  // if the user selects the gender and ethnicity then that change needs to be reflected in the selected model string
  onClickGenderEthnicity(genderEthnicity: string, patientObj: patient) {
    this.determineStartWeight(genderEthnicity, patientObj);
    // TOOD: update enum for patient
    this.setEnumValue(patientObj.age, patientObj, patientObj.genderEthnicity, patientObj.amputation);
  }

  // if the user updates the amputation then that change also needs to reflect in the selected model string
  onClickAmputation(patientObj: patient, amputation: string) {
    patientObj.amputation = amputation;
    this.setEnumValue(patientObj.age, patientObj, patientObj.genderEthnicity, patientObj.amputation);
  }

  determineStartWeight(genderEthnicity: string, patientObj: patient) {


    if (genderEthnicity.toLowerCase() === 'eth') {
      // PERSON OF COLOR BABY
      patientObj.weight = 0;
      return;
    } else if (genderEthnicity.toLowerCase() === 'wht') {
      // CAUCASIAN BABY
      patientObj.weight = 0;
      return;
    }

    if (genderEthnicity.split('_')[1].toLowerCase() === 'male') {
      patientObj.weight = 80;
      return;
    } else if (genderEthnicity.split('_')[1].toLowerCase() === 'female') {
      patientObj.weight = 60;
      return;
    }
  }

  onClothingStateClick(patientObj: patient, clothingState: string) {
    // dummy string array;
    const strArr: string[] = [];
    patientObj.clothingState = clothingState;
    this.setClothingType(patientObj, toolsservice.getPatientClothesIndex(patientObj.clothingState), patientObj.clothingOption, strArr, '');
  }

  onClothingOptionClick(patientObj: patient, clothingOption: string) {
    const strArr: string[] = [];
    patientObj.clothingOption = clothingOption;
    if (patientObj.clothingState === undefined || patientObj.clothingState === null) {
      patientObj.clothingState = 'Underwear Only';
    }
    this.setClothingType(patientObj, toolsservice.getPatientClothesIndex(patientObj.clothingState), patientObj.clothingOption, strArr, '');

  }

  setClothingType(patientObj: patient, clothingState: number = 0, clothingOutfit: string = '', clothingAccessories: string[] = [], clothingHat: string = ''): void {
    // todo build the string, example "0-goggles-helmet-army"
    let clothingType = '';
    clothingType = `${clothingState}`;
    if (clothingAccessories !== null && clothingAccessories !== undefined) {
      clothingAccessories.forEach(accessory => {
        clothingType += `-${accessory}`;
      });
    }
    if (clothingHat !== null && clothingHat !== undefined && clothingHat !== '') {
      clothingType += `-${clothingHat}`;
    }
    if (patientObj.age === 'Baby') {
      patientObj.clothingType = '0';
      return;
    }

    if (clothingOutfit !== null && clothingOutfit !== undefined && clothingOutfit !== '') {
      clothingType += `-${clothingOutfit}`;
    }

    patientObj.clothingType = clothingType;
    return;
  }


  // check if the patient button should be disabled
  public isAddPatientDisabled(): boolean {
    return (toolsservice.isNullUndefinedEmpty(this.newPatient.patientName));
  }
}
