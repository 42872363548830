import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  // so exactly what does this one do? 
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  files : any[] = [];

  constructor() { }

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt){
    evt.preventDefault();
    // evt.stopPropagation();
    this.fileOver = true;
  }

  // DragLeave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if(files.length > 0 ){
      this.fileDropped.emit(files);
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event){
    for(const item of $event){
      this.files.push(item);
    }
  }
}
