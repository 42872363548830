import { Component, enableProdMode, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScenariosService } from './services/scenarios.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { ServerService } from './services/Server.service';

@Component({
  selector: 'app-root',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('500ms', style({opacity: 1}))
        ]),
        transition(':leave',[
          style({opacity: 1}),
          animate('500ms', style({opacity: 0}))
        ])
      ]
    )
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'PerSim  Community';
  public isTooSmall: boolean;
  public permaClose: boolean;
  public innerWidth: number;
  public isErrorPage:boolean;
  public get ServerOnline(): boolean {
    return ServerService.ServerOnline;
  }

  constructor(
    public scenarioServices: ScenariosService,
    public router: Router,
    private serverService: ServerService

    ){ }

  ngOnInit(): void {
    //enableProdMode(); // ONLY FOR MASTER BRANCH SHOULD YOU USE THIS TO RUN THIS WEBSITE IN PRODUCTION MODE!
    this.innerWidth = window.innerWidth;
    this.isTooSmall = (this.innerWidth < 1440);
  
    this.permaClose = Boolean(localStorage.getItem('permaClose'));
    this.serverService.onStartUp();
    this.isErrorPage=false;
    if(this.permaClose)
    {
this.isTooSmall=false;

    }
  }
  onCheckChange()
  {
    localStorage.setItem('permaClose','true');
  }
  setHeaderStatus():boolean
  {

    if(this.isErrorPage)
    {
return false;
    }
    if(this.router.url !== '/')
    {
return true;
    }
    else
    {
return false;

    }

  }
  onClickedClose()
  {
this.isTooSmall=false;
localStorage.setItem('permaClose', this.permaClose.toString());

  }

  @HostListener('window:resize', ['$event']) onResize(){
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1440 && this.permaClose === false){
      // window.alert('window too small');
      this.isTooSmall = true;
    }else{
      this.isTooSmall = false;
    }
    // window.alert('Host Element Clicked');
  }
  
}
