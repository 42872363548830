import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData{
  name: string;
}

@Component({
  selector: 'app-dialog-resend-invitation',
  templateUrl: './dialog-resend-invitation.component.html',
  styleUrls: ['./dialog-resend-invitation.component.scss']
})
export class DialogResendInvitationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogResendInvitationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:DialogData
  ) { }

  ngOnInit(): void {
  }

}
