<div class="hover-button" (mouseleave)="showPopup = false">
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
        style="transform: translateX(-50%);" (mouseover)="showPopupF()" (click)="showAudioPopupF()">
        <mat-icon style="color: #1f7abe">layers</mat-icon>
        <span style="color: #1f7abe; font-weight: bold;">{{layerCount}}</span>
    </button>
    <div class="popup" *ngIf="showPopup">
        <div class="popup-notch">
        </div>
        <div class="transparent-top-part" (click)="showAudioPopupF()">
        </div>
        <div class="popup-content" *ngIf="!showPopupContent">
            <div style="font-size: small; font-weight: 500;">
                INJURY TEXTURES
                <div style="display: flex; flex-direction: column;">
                    <p *ngFor="let item of this.clinical.Textures">{{item | ReplaceUnderscore}}</p>
                    <p *ngFor="let item of this.clinical.EyesOverlays">{{item | ReplaceUnderscore}}</p>
                </div>
            </div>
        </div>
        <div class="popup-content" *ngIf="showPopupContent">
            <button mat-flat-button style="color:#1f7abe" (click)="onEditItem()">
                <mat-icon>create</mat-icon> Edit
            </button>
            <button mat-flat-button style="color:#1f7abe" (click)="onDeleteItem()">
                <mat-icon>delete</mat-icon> Delete
            </button>
        </div>
    </div>
</div>