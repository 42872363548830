import { Component, Inject, OnInit } from '@angular/core';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  statedata,
  clinical,
  auscultations,
} from '../../../models/statedata.model';
import { UploadService } from '../../../services/upload.service';
import * as auscultationJSON from '../../../../assets/auscultation/auscultationConfig.json';

@Component({
  selector: 'app-dialog-edit-auscultation',
  templateUrl: './dialog-edit-auscultation.component.html',
  styleUrls: ['./dialog-edit-auscultation.component.css'],
})
export class DialogEditAuscultationComponent implements OnInit {
  public tempAuscultationArray: string[] = [];

  public auscultationFiles: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { clinical: clinical },
    private uploadService: UploadService
  ) {}

  ngOnInit(): void {
    this.addLocalAuscultationFiles();
    this.updateAuscultationFiles();
  }

  /**
   * obtains and updates auscultation data from the local machine
   */
  public addLocalAuscultationFiles(): void {
    let regex: RegExp = /[\/|\\](.+[\/|\\])(.+)$/;
    auscultationJSON["hearts"].forEach(heartSound => {
      let match = regex.exec(heartSound);
      this.auscultationFiles.push(`Auscultations/Heart/${match[2]}`);
    });

    auscultationJSON["lungs"].forEach(lungSound => {
      let match = regex.exec(lungSound);
      this.auscultationFiles.push(`Auscultations/Lung/${match[2]}`);
    });
  }

  /**
   * obtains and updates auscultation data from the cloud
   */
  public updateAuscultationFiles(): void {
    this.uploadService.getCustomSounds().subscribe((customSoundFiles) => {
      if (!customSoundFiles) return;
      this.filterAuscultationData(customSoundFiles);
    });
  }

  /**
   * filters the auscultation data and stores it into a list of auscultation file names
   * @param data auscultation data
   */
  public filterAuscultationData(auscultationJSONData: any) {
    const lungAuscultations: string[] = auscultationJSONData['Auscultations']['Lung'];
    const heartAuscultations: string[] = auscultationJSONData['Auscultations']['Heart'];

    lungAuscultations?.forEach((lungFileName) => {
      if(lungFileName)
        this.auscultationFiles.push(lungFileName);
    });

    heartAuscultations?.forEach((heartFileName) => {
      if(heartFileName)
        this.auscultationFiles.push(heartFileName);
    });
  }
}
