import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListOfScenariosComponent } from './list-of-scenarios/list-of-scenarios.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogCreateScenarioComponent } from './dialogs/dialog-create-scenario/dialog-create-scenario.component';
//import { AddPatientComponentComponent } from '../add-patient/add-patient-component/add-patient-component.component';
import { AddPatientModule } from '../add-patient/add-patient.module';
import { ScenarioCardModule } from '../scenario-card/scenario-card.module';
import { SearchModule } from '../search/search.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [ListOfScenariosComponent, DialogCreateScenarioComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AddPatientModule,
    ScenarioCardModule,
    SearchModule,
    SharedModule,
  ],
  exports: [
    ListOfScenariosComponent,
  ]
})
export class ListOfScenariosModule { }
