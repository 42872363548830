import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { clinical } from 'src/app/models/statedata.model';

@Component({
  selector: 'app-transition-hover-button',
  templateUrl: './transition-hover-button.component.html',
  styleUrls: ['./transition-hover-button.component.scss'],
})
export class TransitionHoverButtonComponent implements OnInit {
  @Input() clinical: clinical;
  @Input() prettyState: string;

  @Output() transitionEvent = new EventEmitter<{
    action: string;
    data: clinical;
  }>();

  showPopupContent = false;
  showPopup = false;

  showAudioPopupF(): void {
    this.showPopupContent = true;
  }

  showPopupF(): void {
    this.showPopupContent = false;
    this.showPopup = true;
  }

  constructor() {}

  ngOnInit(): void {}

  public onDeleteItem(): void {
    this.transitionEvent.emit({ action: 'Delete', data: this.clinical });
  }

  public onEditItem(): void {
    this.transitionEvent.emit({ action: 'Update', data: this.clinical });
  }
}
