import { Injectable } from '@angular/core';
import { UnityService } from '../simulation-preview/unity.service';
import { clinical } from '../../models/statedata.model';
import * as animationNames from '../../data/clinical-states.json'

@Injectable({
  providedIn: 'root'
})
export class PreviewAnimationService {

  private _currentSymptoms : clinical = null;

  constructor( private _unityService : UnityService ) { }

  /**
   * Begin previewing animations of the selected symptoms
   * @param _clinicalData Symptoms to play animation
   */
  public onPlayAnimation( clinicalData: clinical ): void {
    if ( clinicalData !== this._currentSymptoms )
    {
      this._currentSymptoms = clinicalData;
      // find a way to notify the other symptoms that they're no longer active?
      this._unityService.setClinicalState(clinicalData.animation, clinicalData.Textures, clinicalData.EyesOverlays );
    }
  }

  /**
   * Compare and return if this is indeed the clinical data we're previewing.
   * @param clinicalCompare Symptoms to compare to
   */
  public IsMatchingSymptoms( clinicalCompare : clinical ) : boolean {
    return clinicalCompare.statedataId === this._currentSymptoms?.statedataId;
  }

  /**
   * Notify the unity web gl to stop the animations
   */
  public onStopAnimation() : void {

    if( this._currentSymptoms !== null ){

      this._unityService.resetClinicalState();
      this._currentSymptoms = null;
    }
  }

  /**
   * Check and see if there any animation playing
   */
  public isPlaying() : boolean {
    return this._currentSymptoms !== null;
  }
}
