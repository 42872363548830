<style>

</style>

<div class="dialog-container-header">
  <div class="header content">
    <h2 mat-dialog-title class="header-margin">
      {{ this.data.scenario.name }}
    </h2>
    <div class="header-flex-box">
      <div  *ngIf="this.data.scenario?.originalAuthor">{{ this.data.scenario.originalAuthor?.fName }} {{ this.data.scenario.originalAuthor?.lName }}</div>
      <em>Created: {{ this.prettyCreatedDate }}</em>
      <em style="margin-left: 20px">Updated: {{ this.prettyUpdatedDate }}</em>
    </div>
  </div>
  <div class="info-box">

    <button mat-icon-button [matMenuTriggerFor]="checklistMenu">
      <mat-icon style="color: #777">assignment</mat-icon>
    </button>
    ({{ this.taskList.tasklist?.length }})
    <mat-menu #checklistMenu="matMenu" xPosition="before"
      ><h2 style="padding-left: 10px; padding-right: 10px">Checklist</h2>
      <h3 style="padding-left: 10px; padding-right: 10px">
        {{ this.data.scenario.checklistLink }}
      </h3>
      <ul
        style="list-style-type: none; padding-left: 10px; padding-right: 10px"
        *ngIf="this.taskList.tasklist?.length !== 0"
      >
        <li *ngFor="let task of this.taskList.tasklist | slice:0:5;">
          <p *ngIf="!task.critical">{{ task.taskName }}</p>
          <p *ngIf="task.critical">
            <strong>{{ task.taskName }}</strong
            ><EM> (critical)</EM>
          </p>
        </li>
      </ul>

      <p *ngIf="this.taskList.tasklist?.length > 5" style="text-align: center; padding-left: 10px; padding-right: 10px;">
        more tasks listed in scenario editor view, download to dashboard to see more.
      </p>
        <p
        *ngIf="this.taskList.tasklist?.length === 0"
        style="padding-left: 10px; padding-right: 10px"
      >
        no checklist data available
      </p>
    </mat-menu>
    <button id="btn_close" style="margin-left: 2px;"  mat-icon-button (click)="onDialogClose()">
      <mat-icon  style=" transform: scale(2);"
        >close</mat-icon
      >
    </button>
  </div>

</div>


<mat-dialog-content style="max-height: 513px">
  <!-- paragraph description -->
  <br />
  <p>
    {{ this.data.scenario.simulationGoal }}
  </p>
  <!-- runtime? -->
  <p class="runtime">
    <mat-icon class="runtime-clock">schedule</mat-icon>Run Time:
    {{ this.data.scenario.runtime }}m
  </p>
  <!-- mat-tab-group -->
  <mat-tab-group mat-stretch-tabs="false"
    animationDuration="0ms"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="changePatient($event)"
  >

    <mat-tab
      *ngFor="let patient of patientArray"
      label="{{ patient.patientName }}">
      <hr  style="margin-top: 0px;"/>
      <div class="tab-content">
        <div class="viewer-and-data">
          <img
          class="scenario-patient-image "
          *ngIf="patient?.imageUrl"
          loading="lazy"
          [src]="patient?.imageUrl"
          alt="{{ patient?.imageUrl }}"
        />
        <div style="width: 100%;" [innerHTML]="formatDescription(this.data.scenario.scenarioDescription,patient.patientName)"></div>
          <div class="patient-info">
            <!-- <h3 class="label-tag">Patient Info</h3>
            <H3>Patient Goal or Details</H3>
            <p>{{ patient.goalsAndDetails }}</p> -->
          </div>
        </div>

        <hr style="margin-right: 4px">
        <div class="phases-and-symptoms">
          <app-phase-card-c
            *ngFor="let phase of patient.phases"
            [phase]="phase"
          >
          </app-phase-card-c>
          <div *ngIf="this.patient.phases.length === 0" class="no-phase-data">
            no phase data
          </div>
        </div>

      </div>

    </mat-tab>
  </mat-tab-group>


</mat-dialog-content>

<mat-dialog-actions class="flex-box-ify" align="end">
  <!-- left aligned rating system-->

  <p *ngIf="this.isRatingEnabled"  class="rate-simulation-label">Rate Simulation:</p>
  <div *ngIf="this.isRatingEnabled"  class="rate-simulation">
    <mat-icon
      [ngStyle]="{ color: this.userRating >= 1 ? '#ffc300' : '#DDDDDD' }"
      (click)="onRateSimulation(1)"
      >grade</mat-icon
    >
    <mat-icon
      [ngStyle]="{ color: this.userRating >= 2 ? '#ffc300' : '#DDDDDD' }"
      (click)="onRateSimulation(2)"
      >grade</mat-icon
    >
    <mat-icon
      [ngStyle]="{ color: this.userRating >= 3 ? '#ffc300' : '#DDDDDD' }"
      (click)="onRateSimulation(3)"
      >grade</mat-icon
    >
    <mat-icon
      [ngStyle]="{ color: this.userRating >= 4 ? '#ffc300' : '#DDDDDD' }"
      (click)="onRateSimulation(4)"
      >grade</mat-icon
    >
    <mat-icon
      [ngStyle]="{ color: this.userRating >= 5 ? '#ffc300' : '#DDDDDD' }"
      (click)="onRateSimulation(5)"
      >grade</mat-icon
    >
  </div>

  <!-- favorite button-->

  <!-- <button
    mat-button
    class="green-normal"
    *ngIf="this.favorited === false"
    (click)="this.onFavoriteSim(this.data.scenario)"
  >
    <mat-icon style="padding: 0px 10px 0px 0px">favorite_border</mat-icon
    >Favorite
  </button>

  <button
    mat-button
    class="green-normal"
    *ngIf="this.favorited"
    (click)="onUnFavorite()"
    style="color: orangered"
  >
    <mat-icon style="padding: 0px 7px 0px 0px; color: orangered"
      >favorite</mat-icon
    >
    Favorite
  </button> -->


  <div  *ngIf="(this.data.scenario.isDownloaded&& this.data.onYourScenarioTab)" style="width: 100%;position:relative;text-align: center;">
    <br/>
    <hr/>
    <button   mat-button       class="btn-green"
  (click)="onDialogClose()" [routerLink]="['/scenario-editor']" [queryParams]="{ scenarioId: this.data.scenario.ScenarioId}">
  <mat-icon class="icon-size" >edit</mat-icon>Edit Scenario</button>
  </div>
  <div  *ngIf="(!this.data.scenario.isDownloaded)" style="width: 100%;position:relative;text-align: center;"> <!--*ngIf="(!isOwnedScenario)" style="width: 100%;position:relative;"-->
    <br/>
    <hr/>
    <button
      mat-button
      class="btn-green"
      (click)="onTeamDownload(this.data.scenario)"
    >
      <mat-icon class="icon-size">get_app</mat-icon> Get Scenario
    </button>
  </div>

</mat-dialog-actions>
