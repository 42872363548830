


<div class="wrapper" >

  <div style="display: inline;text-align: left;">

  <button style="width: 250px;font-size: 12px;" mat-icon-button [matMenuTriggerFor]="sortminimenu" aria-label="open sort menu" id="btn-open-sorting">
    <mat-icon>sort</mat-icon>
      <mat-label style="vertical-align: middle;">{{this.selectedOrderText}}</mat-label>
    </button>
    <mat-menu #sortminimenu="matMenu" style="width: 250px">
      <ul class="sort-menu-item">
        <li>
          <p><mat-icon>sort</mat-icon> Sort By:</p>
          <hr>

          <p mat-menu-item  matMenuTrigger (click)="$event.stopPropagation();onselectSortType(0);sortUpdateDateDesc(0);">
            <mat-icon *ngIf="currentSortOption===0">check</mat-icon> <mat-icon *ngIf="currentSortOption!==0"></mat-icon> <span> Last Modified</span>
          </p>
          <p mat-menu-item  matMenuTrigger (click)="$event.stopPropagation();onselectSortType(1);onSortArray(0)">
            <mat-icon *ngIf="currentSortOption===1">check</mat-icon> <mat-icon *ngIf="currentSortOption!==1"></mat-icon> <span> Scenario Name</span>
        </p>
        <p *ngIf="this.isRatingEnabled" mat-menu-item  matMenuTrigger (click)="$event.stopPropagation();onselectSortType(2);onSortArray(0)">
          <mat-icon *ngIf="currentSortOption===2">check</mat-icon> <mat-icon *ngIf="currentSortOption!==2"></mat-icon> <span> Scenario Rating</span>
      </p>
      <p *ngIf="this.isDownloadCountEnabled" mat-menu-item  matMenuTrigger (click)="$event.stopPropagation();onselectSortType(3);onSortArray(0)">
        <mat-icon *ngIf="currentSortOption===3">check</mat-icon> <mat-icon *ngIf="currentSortOption!==3"></mat-icon> <span> Scenario Download Count</span>
    </p>
        </li>
      </ul>
      <!--Options for Last Modified-->
      <ul *ngIf="currentSortOption===0" class="sort-menu-item">
        <li>
          <mat-icon>filter_list</mat-icon>Sort Order
          <hr>
          <button id="btn-newest-on-top" mat-menu-item (click)="sortUpdateDateDesc(0)" >
            <mat-icon  *ngIf="this.isSortingSelections[0]">check</mat-icon> <mat-icon  *ngIf="!this.isSortingSelections[0]"></mat-icon>   <span> Newest on top</span>
          </button>
          <button  id="btn-oldest-on-top" mat-menu-item (click)="sortUpdateDateAsc(1)">
            <span> <mat-icon class="sort-menu-item" *ngIf="this.isSortingSelections[1]">check</mat-icon>  <mat-icon  *ngIf="!this.isSortingSelections[1]"></mat-icon>Oldest on top</span>
          </button>

        </li>
      </ul>
      <!--Options for Scenario Name-->
      <ul *ngIf="currentSortOption===1" class="sort-menu-item">
        <li>
          <mat-icon>filter_list</mat-icon> Sort Order
          <hr>
          <button  id="btn-a-z" mat-menu-item (click)="onSortArray(0)">
            <span> <mat-icon *ngIf="this.isSortingSelections[0]">check</mat-icon><mat-icon *ngIf="!this.isSortingSelections[0]"></mat-icon> A-Z</span>
          </button>
          <button id="btn-z-a" mat-menu-item (click)="reverseSort(1)">
            <span> <mat-icon *ngIf="this.isSortingSelections[1]">check</mat-icon> <mat-icon *ngIf="!this.isSortingSelections[1]"></mat-icon> Z-A</span>
          </button>
        </li>
      </ul>

      <!--Options for Scenario Rating-->
      <ul *ngIf="!isYourScenarioTab && currentSortOption===2" class="sort-menu-item">
        <li>
          <mat-icon>filter_list</mat-icon> Sort Order
          <hr>
          <button  id="btn-one-five" mat-menu-item (click)="sortByRateOneFive(0)">
            <span> <mat-icon *ngIf="this.isSortingSelections[0]">check</mat-icon><mat-icon *ngIf="!this.isSortingSelections[0]"></mat-icon> 1-5 Stars</span>
          </button>
          <button id="btn-five-one" mat-menu-item (click)="sortByRateFiveOne(1)">
            <span> <mat-icon *ngIf="this.isSortingSelections[1]">check</mat-icon> <mat-icon *ngIf="!this.isSortingSelections[1]"></mat-icon> 5-1 Stars</span>
          </button>
        </li>
      </ul>
      <!--Options for Scenario Download Count-->
      <ul *ngIf="!isYourScenarioTab && currentSortOption===3" class="sort-menu-item">
        <li>
          <mat-icon>filter_list</mat-icon> Sort Order
          <hr>
          <button  id="btn-a-z" mat-menu-item (click)="sortByDownloadCountAsc(0)">
            <span> <mat-icon *ngIf="this.isSortingSelections[0]">check</mat-icon><mat-icon *ngIf="!this.isSortingSelections[0]"></mat-icon>Ascending order  </span>
          </button>
          <button id="btn-z-a" mat-menu-item (click)="sortByDownloadCountDesc(1)">
            <span> <mat-icon *ngIf="this.isSortingSelections[1]">check</mat-icon> <mat-icon *ngIf="!this.isSortingSelections[1]"></mat-icon>Descending order</span>
          </button>
        </li>
      </ul>


    </mat-menu>
  </div>
  <mat-form-field  [style.width.px]=350>
    <mat-label>Search</mat-label>
    <input
    id="search-txt"
      autocomplete="off"
      matInput
      type="text"
      [(ngModel)]="searchWord"
      (input)="onPrintSearchWord($event.target.value)"
    />

  </mat-form-field>

   <button mat-icon-button>
    <mat-icon style="color: gray">search</mat-icon>
  </button>
  <mat-form-field style="width: 200px; background-color: transparent !important">
    <mat-label>Search By</mat-label>
    <mat-select [value]="'option'">
      <mat-option value="option" (click)="onSimulationNameSearch()"> Name/Description</mat-option>
      <mat-option value="option2" (click)="onSimulationDescriptionSearch()">Description</mat-option>
    </mat-select>
  </mat-form-field>

  <span *ngIf="isYourScenarioTab">
  <mat-form-field>
    <mat-label>Scenario Type:</mat-label>
    <mat-select [(value)]="this.selectedItems" multiple (selectionChange)="onSelectionChange($event)">
      <mat-select-trigger>

        {{ selectedItems[0]  || '' }}
        <span *ngIf="(selectedItems?.length || 0) > 1" class="example-additional-selection">
          (+{{ (selectedItems?.length || 0) - 1 }} {{ selectedItems?.length === 2 ? 'other' : 'others' }})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let item of scenarioTypeList" [value]="item">{{item}}</mat-option>
    </mat-select>
  </mat-form-field>
</span>
<span *ngIf="!isYourScenarioTab">
  <mat-form-field class="example-full-width">
    <mat-label>Author:</mat-label>
    <input type="text" id="autocomplete-author"
           placeholder="Search Author"
           aria-label="Search Author"
           matInput
           [formControl]="searchAuthorControl"
           [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  (optionSelected)="onAuthorChange($event)" >
      <mat-option *ngFor="let item of this.authorsObservable | async" [value]="item.fName+' '+item.lName">
        {{item.fName}} {{item.lName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

</span>


  <div *ngIf="isTagDrawrOpen" class="tag-drawr-wrapper">
    <div class="tag-drawr">
      <div class="tag-drawr-header"><p>Filter by Tags</p><button mat-icon-button (click)="isTagDrawrOpen = !isTagDrawrOpen"><mat-icon>close</mat-icon></button></div>
      <div class="tag-container">
        <div *ngFor="let tag of existingTags" (click)="onSelectTag(tag)" class="{{isSelectedTag(tag) ? 'tag-selected':'tag'}}">{{tag}}</div>
      </div>
    </div>
  </div>

</div>
