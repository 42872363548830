import { Injectable } from '@angular/core';
import appPackage from 'package.json';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export class Feature {
  featureName: string;
  isEnabled: boolean;
}
export enum FeatureKeys {
  CustomAudioUpload = '.appconfig.featureflag/CustomAudioUpload',
  DownloadCountSystem = '.appconfig.featureflag/DownloadCountSystem',
  RatingSystem = '.appconfig.featureflag/RatingSystem',
}
@Injectable({
  providedIn: 'root'
})
export class FeatureflagService {

  constructor(private http: HttpClient) { }
  private get featureUrl(): string {
    return appPackage.backendServerPath + '/featurekeys';
  }
  public  getFeatureFlags(): Observable<Feature[]> {
    return this.http.get<Feature[]>(this.featureUrl).pipe(
      map((result) =>
        result.map((item) => {
          const feature = new Feature();
          feature.featureName = item.featureName;
          feature.isEnabled = item.isEnabled;
          return feature;
        })));
  }
}
