import { Component, OnInit, Inject } from '@angular/core';
import { scenario } from '../../../models/scenario.model';
import { patient } from '../../../models/patient.model';
import { toolsservice } from '../../../editor/services/tools.service';
import { ScenarioControllerService } from 'src/app/controllers/scenario-controller.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/admin/user.service';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-create-scenario',
  templateUrl: './dialog-create-scenario.component.html',
  styleUrls: ['./dialog-create-scenario.component.scss'],
  providers: [toolsservice],
})
export class DialogCreateScenarioComponent implements OnInit {
  scenarioControllerService: ScenarioControllerService = new ScenarioControllerService();

  public patientNames: string[] = [];
  public selectedTabsIndex: number = 0;
  public patientCount: number = 0;

  public scenario: scenario;
  public scenarioNamesCleanedUp: string [] = [];



  get patients(): patient[] {
    return this.scenarioControllerService.scenario.patients;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: { scenarioName: string }
  ) {}

  ngOnInit(): void {
    this.userService.get(this.authService.getUserID()).subscribe((user) => {
      this.scenario = new scenario(
        this.data.scenarioName,
        null,
        [],
        '',
        user,
        [],
        Number(new Date()),
        Number(new Date()),
        Number(new Date()),
        '',
        0,false
      );
      this.scenarioControllerService.scenario = this.scenario;
      this.scenarioControllerService.onAddPatient();
    });
  }

  /**
   * Trigger events that happen when the user adds a patient to a new scenario
   */
  onAddPatient() {
    this.scenarioControllerService.onAddPatient();
    this.selectedTabsIndex = this.patients.length - 1;
  }

  /**
   * trigger events that happen when the user removes a patient to a new scenario
   * @param _patient the patient to be removed
   */
  onRemovePatient(_patient: patient) {
    this.scenarioControllerService.onRemovePatient(_patient);
    this.selectedTabsIndex--;
  }

  public genderEthnicity: string = '';
  public amputation: string = '';

  /**
   * determines if the submit button should be disabled based on required fields
   */
  isSubmitButtonDisabled(): boolean {
    // must have scenario name defined
    if (toolsservice.isNullUndefinedEmpty(this.scenarioControllerService.scenario.name)){
      return true;
    }

    // must have patient name defined
    for (let p of this.patients) {
      if (toolsservice.isNullUndefinedEmpty(p.patientName)) {
        return true;
      }
    }

    return false;
  }
}
