import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData{
  name: string;
}

@Component({
  selector: 'app-dialog-delete-archived-account',
  templateUrl: './dialog-delete-archived-account.component.html',
  styleUrls: ['./dialog-delete-archived-account.component.scss']
})
export class DialogDeleteArchivedAccountComponent implements OnInit {

  public checked: Boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogDeleteArchivedAccountComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

}
