import { patient } from './patient.model';
import { toolsservice } from '../editor/services/tools.service';
import { User } from './user.model';
import { Author } from './author.model';

export interface ratedUser {
  UID: string;
  rated: number;
}

export class scenario {

  public runtime: number = 0;
  public tags: string[] = [];
  // after a conversation with Sohail, this checklist cannot be part of this json file
  // it needs ot be a link to another json files to maintain and keep with.
  // this concerns me
  public checklistLink = '';
  public fileLinks: string[] = []; // will provide pdf and mp3
  public isShared = false;
  public isCommunityShared=false;
  
  public ratedUsers: ratedUser[] = []; // does this scenario have any ratings by users?
  public favoriteUsers: string[] = []; // string of UIDs to check against for favorite users.
  public authors: Author[] = [];
  public originalAuthor:Author; // first creator of scenario.
  public scenarioDescription:string;

  constructor(
    public name: string,
    public ScenarioId: string,
    
    public patients: patient[],
    public simulationGoal: string,
    private originalUser: Author,
    public treatmentList: string[],
    public originalDate: number,
    public teamShareDate: number,
    public modifiedDate: number,
    public hash: string,
    public rating: number = 0,
    public isDownloaded:boolean=false,// if scenario is downloaded from community or team
  ) {
    if (ScenarioId === null) {
      this.ScenarioId = toolsservice.makeId();
    }
  }

}
