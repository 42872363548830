import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialog,   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { clinical } from 'src/app/models/statedata.model';

export interface DialogData {
  name: string;
  clinical: clinical;
  gender : string;
}

@Component({
  selector: 'app-dialog-lab-values',
  templateUrl: './dialog-lab-values.component.html',
  styleUrls: ['./dialog-lab-values.component.scss']
})
export class DialogLabValuesComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogLabValuesComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {}

  onDialogClose() : void {
    this.dialogRef.close(this.data.clinical);
  }
}
