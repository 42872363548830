export class User {
    public _id              : string;           // UUID
    public institutionId    : string;
    public userName         : string = '';
    public fName            : string = '';      // first name
    public lName            : string = '';      // last name
    public emailvertified   : boolean = false;
    public email            : string = '';
    public roleId           : string = '';
    public password         : string;
    public created          : string;           // date format
    public modified         : string;           // date format
    public isActive         : boolean;          // if false - archived
    public canImpersonate   : boolean; // this is a special auth for medcognition users.
    public isUserAdmin      : boolean; // this field is for
}
