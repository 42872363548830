import { Component, Input, OnInit } from '@angular/core';
import { UnityService } from './unity.service';

declare var UnityLoader : any;



@Component({
  selector: 'app-simulation-preview',
  templateUrl: './simulation-preview.component.html',
  styleUrls: ['./simulation-preview.component.scss']
})
// implements OnInit 
export class SimulationPreviewComponent implements OnInit{
  
  @Input('width') width: number = 0;

  constructor( private unityService : UnityService ) {}

  private instance : any;

  ngOnInit() {
      //   // called in a Start() function in Unity
    (window as any).appLoadedListener = () => {

      this.unityService.onSceneLoaded.emit();  
    }
    // called after character loaded in Unity
    (window as any).patientLoadedListener = () => {

      this.unityService.onPatientLoaded.emit();
    }
    // called after recieving message in Unity
    (window as any).stateChangeListener = () => {

      this.unityService.onClinicalDataLoaded.emit();
    }
    this.createUnityInstances();

    this.unityService.onSceneLoaded.subscribe(() => {

      this.unityService.forceRefresh()
    })
  }

  // When the component is closed or hidden away, go ahead and free the unity object.
  ngOnDestroy() {
    this.instance = null;
    this.unityService.closeWebGL();
  }

  private createUnityInstances() {
    this.instance = UnityLoader.instantiate("gameContainer", "assets/unity/PerSim_WebGL.json");
    this.unityService.UnityInstance = this.instance;
  }
}
