import { Component, OnInit } from '@angular/core';
import { MobileDevice } from '../../models/mobile-device.model';
import { Simulation } from '../../models/simulation.model';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { DialogDeleteSimulationComponent } from '../../devices/dialogs/dialog-delete-simulation/dialog-delete-simulation.component'
import { DialogNewSimulationsComponent } from '../../devices/dialogs/dialog-new-simulations/dialog-new-simulations.component'
import {   MatDialog } from '@angular/material/dialog';
import { toolsservice } from 'src/app/editor/services/tools.service';

interface Categories {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  devices:MobileDevice [];
  numberOfDevices: number;
  counter: number;
  selectedDevice: MobileDevice;
  showTagFilter: boolean;

  sorts: Categories[] = [
    {value: 'a-z-0', viewValue: 'A-Z'},
    {value: 'z-a-1', viewValue: 'Z-A'},
    {value: 'popular-2', viewValue: 'Popular'},
    {value: 'last-updated-3', viewValue: 'Most Recent'}
  ];

  public sortBy: string = this.sorts[0].value;

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {
    let simulations: Simulation [] = [];
    let simulationCount: number = 0;
    this.numberOfDevices = 100;
    this.counter = 0;
    this.selectedDevice = null;
    this.showTagFilter = false;
    this.devices = [];

    for(this.counter = 0; this.counter < this.numberOfDevices; this.counter++){
      simulations = []
      for(simulationCount = 0; simulationCount < this.counter; simulationCount++){
        let tureorfalse: boolean = (Math.random() >= 0.5);
        simulations.push(new Simulation(`Simulation_${simulationCount}`,`Gene`, new Date(), tureorfalse, `PICTURE_${simulationCount}`, 'lorem ipsum text', false));
      }
      this.devices.push(new MobileDevice(`device_${this.counter}`,this.counter,this.counter,simulations));
    }
  }

  onDeviceClicked(device:MobileDevice){
    //do something with the device

    this.selectedDevice = device;
  }

  onReturnClicked(){
    this.selectedDevice = null;
  }

  getDate(date: Date): string{
    let day: number = date.getDate();
    let month: number = date.getMonth();
    let year: number = date.getFullYear() % 100;

    return `${day}/${month}/${year}`;
  }

  onUpdating(simulation: Simulation){
    setTimeout(function () {simulation.isUpdating = true}, 0);
    // setTimeout(() => {simulation.isUpdating = false; simulation.isUpdated = true; this.snackBar.open('Simulation Updated','X', { duration: 2000, });}, 2000);
    setTimeout(() => {
      simulation.isUpdating = false;
      simulation.isUpdated = true;
      this.openSnackBar('Simulation Updated', true); } , 500);
  }

  updateAllSimulations(simulations: Simulation[]){
    simulations.forEach(simulation => {
      if(!simulation.isUpdated){
        this.onUpdating(simulation);
      }
    });
  }

  openSnackBar(message: string, goodbar : boolean){
    toolsservice.openSnackBar( this.snackBar, message, goodbar);
    // let config = new MatSnackBarConfig();
    // config.panelClass = ['custom-class'];
    // this.snackBar.open(message, action, {
    //   duration: 2000,
    //   panelClass: ['custom-class'],
    // });
    // this.snackBar.openFromComponent(SnackbarComponent, )
  }

  onDownloadToAccount(): void{
    this.openSnackBar('Simulation Downloaded to Your Account', true);
  }

  onRemoveSimulation(simulation: Simulation): void{
    const dialogRef = this.dialog.open(DialogDeleteSimulationComponent, {
      // height: '232px',
      width: '447px',
      data: {name: simulation.name, description: ''},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.openSnackBar(`${simulation.name} Removed`, false);
    });
  }

  onUploadNewSimulations(): void{
    const dialogRef = this.dialog.open(DialogNewSimulationsComponent, {
      // height: '802px',
      width: '1380px',
      data: {
        name: 'Upload New Simulations',
        description: 'Select the simulations you want to upload tot he tablet, then click the Upload Simulations button below.'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.openSnackBar(`# Simulations Uploaded`, true);
    });
  }
}
