<!-- <div class="container"> -->
  <div class="dropWindow" #dndWin appDnd (fileDropped)="onFileDropped($event)">
    <!-- Change this to a loading bar if the user drops content in here... -->
    <!-- <div *ngIf="!Uploading; else uploadWindow"> -->
      <div class="displayInfo">
        <input
          autocomplete="off"
          type="file"
          #fileDropRef
          id="fileDropRef"
          style="display:none"
          [accept]="acceptedExtension.join(',')"
          multiple
          (change)="fileBrowseHandler($event.target.files)"
        />
        <p class="fileHeader">Drag and Drop {{ Title }} Here</p>
        <p>
        <mat-icon
          *ngIf="!minimized"
          class="fileIcon"
          >{{ icon }}</mat-icon
        >
      </p>
        <label for="fileDropRef" class="fileLink">or Browse</label>
      </div>
    <!-- </div> -->

    <!-- Loading bar -->
    
    <!-- <ng-template #uploadWindow>
      <div class="loadingContent">
        <p class="loadingHeader">Uploading</p>
        <mat-progress-bar
          mode="determinate"
          [value]="barProgress"
        ></mat-progress-bar>
      </div>
    </ng-template>  -->
  </div>
<!-- </div> -->