import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogTreatmentOptionComponent } from '../dialogs/dialog-treatment-option/dialog-treatment-option.component';
import { DialogDeleteTreatmentOptionComponent } from '../dialogs/dialog-treatment-option/dialog-delete-treatment-option/dialog-delete-treatment-option.component';
import {   MatDialog } from '@angular/material/dialog';
import { statedata } from '../../models/statedata.model';

@Component({
  selector: 'app-treatment-point',
  templateUrl: './treatment-point.component.html',
  styleUrls: ['./treatment-point.component.scss'],
})
export class TreatmentPointComponent implements OnInit {
  @Input() treatmentpoint: statedata;
  @Input() symptomIndex: number;

  @Output() treatmentPointEvent = new EventEmitter<{
    action: string;
    data: statedata;
  }>();
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  onEditItem(): void {
    this.onOpenTreatmentDialog();
  }

  onOpenTreatmentDialog() {

    const dialogRef = this.dialog.open(DialogTreatmentOptionComponent, {
      // height: '375px',
      width: '500px',
      data: {
        name: 'Edit Treatment Point',
        title: this.treatmentpoint.name,
        description: this.treatmentpoint.description,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.treatmentpoint.description = result;
        this.treatmentPointEvent.emit({
          action: 'Update',
          data: this.treatmentpoint,
        });
      }
    });
  }

  onDeleteItem(): void {
    const dialogRef = this.dialog.open(DialogDeleteTreatmentOptionComponent, {
      // height: '320px',
      width: '447px',
      data: { name: this.treatmentpoint.name, description: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.treatmentPointEvent.emit({
          action: 'Delete',
          data: this.treatmentpoint,
        });
      }
    });
  }
}
