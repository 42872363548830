<mat-tab-group class="scenario-header" animationDuration="0ms" style="transform: translateY(-10px);">
  <mat-tab label="Patient Info">
    <div class="border-tab">
      <div style="padding-left: 10px; height: calc(100% - 54px)">
        <app-patient-info
          [patientControllerService]="patientControllerService"
          style="height: 100%"
        ></app-patient-info>
      </div>

      <div class="bottom-docker">
        <button
          mat-button
          (click)="onDeletePatient()"
          [disabled]="isDeleteButtonDisabled"
          class="patient-footer-buttons"
        >
          <mat-icon>delete</mat-icon> Delete Patient
        </button>
        <button
          style="margin-top: auto; margin-bottom: auto; color: #616161"
          mat-button
          (click)="onEditPatient()"
        >
          <mat-icon style="color: #616161">create</mat-icon> Edit Info
        </button>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Vitals/Labs">
    <div class="border-tab">
      <div style="height: calc(100% - 54px); overflow: hidden auto; padding-left: 16px">
        <div style="display: flex; flex-direction: row; text-align: center">
          <p class="section-header">
            Baseline Vitals
          </p>
        </div>

        <div *ngIf="currentMode !== EditModeType.Vitals">
          <div class="labRow">
            <div class="cell">
              <p class="label">Cardiac Rhythm</p>
              <p class="content">
                {{ clinicalControllerService.getCardiacRhythmDescription | FirstLetterUppercase }}
              </p>
            </div>
            <div>
              <p class="label">Capnography</p>
              <p class="content">
                {{
                  clinicalControllerService.getRespiratoryWaveformDescription | ReplaceUnderscore | FirstLetterUppercase
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Heart Rate</p>
              <p class="content">
                {{ patientModel.InitialClinicalData.HR }}
              </p>
          </div>

          <div>
            <p class="label">SBP</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.sBP }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Respiratory Rate</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.RR }}
            </p>
          </div>

          <div>
            <p class="label">DBP</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.dBP }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">SaO2</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.SaO2 }}
            </p>
          </div>

          <div>
            <p class="label">Temperature (°F)</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.temp }}°F
            </p>
          </div>
        </div>
        <div class="labRow">
          <div class="cell">
            <p class="label">ETCO2</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.ETCo2 }}
            </p>
          </div>
        </div>

        <hr class="labRow endBlock" />

        <div style="text-align: center" class="labRow">
          <p class="section-header">
            Baseline Ventilator Vitals
          </p>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">PIP</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.PIP }}
            </p>
          </div>
          <div class="cell">
            <p class="label">VT</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.Vt }}
            </p>
          </div>
        </div>
        <div class="labRow">
          <div class="cell">
            <p class="label">PPLAT</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.PPLAT }}
            </p>
          </div>
          <div class="cell">
            <p class="label">MAP</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.MAP }}
            </p>
          </div>
        </div>

        <hr class="labRow endBlock" />

        <div style="text-align: center" class="labRow">
          <p class="section-header">
            Baseline CBC
          </p>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">White Blood Count</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.whiteBloodCount }}
            </p>
          </div>
          <div class="cell">
            <p class="label">Hematocrit</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.Hematocrit }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Hemoglobin</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.Hemoglobin }}
            </p>
          </div>
          <div class="cell">
            <p class="label">Platelets</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.platelets }}
            </p>
          </div>
        </div>

        <hr class="labRow endBlock" />

        <div style="text-align: center" class="labRow">
          <p class="section-header">
            Baseline BMP
          </p>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Sodium</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.Sodium }}
            </p>
          </div>
          <div class="cell">
            <p class="label">Blood Urea Nitrogen</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.bloodUreaNitrogen }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Potassium</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.potassium }}
            </p>
          </div>
          <div class="cell">
            <p class="label">Creatinine</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.creatinine }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Chloride</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.chloride }}
            </p>
          </div>
          <div class="cell">
            <p class="label">Glucose</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.glucose }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Bicarbonate</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.bicarbonate }}
            </p>
          </div>
          <div class="cell">
            <p class="label">Calcium</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.calcium }}
            </p>
          </div>
        </div>

        <hr class="labRow endBlock" />

        <div style="text-align: center" class="labRow">
          <p class="section-header">
            Baseline FSG
          </p>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Glucose</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.FSG_glucose }}
            </p>
          </div>
        </div>

        <hr class="labRow endBlock" />

        <div style="text-align: center" class="labRow">
          <p class="section-header">
            Baseline ABG/VBG
          </p>
        </div>

        <div class="labRow">
          <div class="cell">
          <p class="label">Draw Type</p>
          <p *ngIf="patientModel.InitialClinicalData.drawType === 0" class="content">
            ABG
          </p>
          <p *ngIf="patientModel.InitialClinicalData.drawType === 1" class="content">
            VBG
          </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">pH</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.pH }}
            </p>
          </div>
          <div class="cell">
            <p class="label">Bicarb</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.bicarb }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">PaO2</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.pa02 }}
            </p>
          </div>
          <div class="cell">
            <p class="label">bE</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.bE }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">PACO2</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.paCO2 }}
            </p>
          </div>
          <div class="cell">
            <p class="label">SAO2</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.lab_saO2 }}
            </p>
          </div>
        </div>

        <div class="labRow">
          <div class="cell">
            <p class="label">Lactate</p>
            <p class="content">
              {{ patientModel.InitialClinicalData.lactate }}
            </p>
          </div>
        </div>

        <div
          class="labRow"
          style="border-bottom: solid; border-width: 1px"
        ></div>
      </div>
      <div class="bottom-docker-vitals">
        <button
          mat-button
          (click)="onDeletePatient()"
          [disabled]="isDeleteButtonDisabled"
          class="patient-footer-buttons"
        >
          <mat-icon>refresh</mat-icon> Reset All
        </button>
        <button
          style="margin-top: auto; margin-bottom: auto; color: #616161"
          mat-button
          (click)="onEditDefaultVitals()"
        >
          <mat-icon style="color: #616161">favorite</mat-icon> Edit Vitals
        </button>
        <button mat-button (click)="onEditDefaultLabs()" class="patient-footer-buttons">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            style="fill: #616161;"
          >
            <g><rect fill="none" height="24" width="24" /></g>
            <g>
              <path
                d="M19.8,18.4L14,10.67V6.5l1.35-1.69C15.61,4.48,15.38,4,14.96,4H9.04C8.62,4,8.39,4.48,8.65,4.81L10,6.5v4.17L4.2,18.4 C3.71,19.06,4.18,20,5,20h14C19.82,20,20.29,19.06,19.8,18.4z"
              />
            </g>
          </svg>
          Edit Labs
        </button>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Treatments">
    <div class="border-tab">
      <div class="treatment-devices-wrapper">
        <div class="treatment-devices-header-wrapper">
          <h3 class="treatment-header">
            Active treatments for patient from start of scenario:
          </h3>
          <p
            class="no-treatment-message"
            *ngIf="this.patientModel.treatments?.length === 0"
          >
            Currently no equipment has been set,
            <a (click)="onEditTreatment()">click here to add equipment</a>
          </p>
          <p
            class="treatment-item"
            *ngFor="let treatment of this.patientModel.treatments"
          >
            {{ treatment | ReplaceUnderscore | RemoveGenderAndRace }}
          </p>
        </div>
      </div>

      <div class="bottom-docker">
        <button
          style="
            margin-top: auto;
            margin-bottom: auto;
            color: #616161;
            margin-left: auto;
          "
          mat-button
          (click)="onEditTreatment()"
        >
          <mat-icon style="color: #616161">create</mat-icon> Edit Treatment
          Options
        </button>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Devices">
    <!-- In here, we will list out all of the devices object type -->
    <div class="border-tab">
      <div class="treatment-devices-wrapper">
        <div class="treatment-devices-header-wrapper">
          <h3 class="treatment-header">
            List of devices to monitor patient health
          </h3>
          <p
            class="no-treatment-message"
            *ngIf="this.patientModel.devices?.length === 0"
          >
            Currently no devices has been set,
            <a (click)="onAddRemoveDevices()">click here to add device</a>
          </p>
          <div
            class="devices-item"
            *ngFor="let device of this.patientModel?.devices"
          >
            <p>{{ device.kind }}</p>
            <button
              mat-flat-button
              style="background-color: transparent"
              (click)="onEditDeviceValues(device)"
            >
              <mat-icon>create</mat-icon> Edit Values
            </button>
          </div>
        </div>
      </div>

      <div class="bottom-docker">
        <button
          style="
            margin-top: auto;
            margin-bottom: auto;
            color: #616161;
            margin-left: auto;
          "
          mat-button
          (click)="onAddRemoveDevices()"
        >
          <mat-icon style="color: #616161">create</mat-icon>Add/Remove Devices
        </button>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Procedures">
    <div class="border-tab">
      <div class="treatment-devices-wrapper">
        <button mat-raised-button style="color:#616161" (click)="this.onEditAuscultation()">Auscultation</button>
      </div>
      <div class="bottom-docker">
      
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
