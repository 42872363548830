<div class="hover-button" (mouseleave)="showPopup = false">
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
        style="transform: translateX(-50%);" (mouseover)="showPopupF()" (click)="showAudioPopupF()">
        <mat-icon style="color: #1f7abe">
            {{icon}}
        </mat-icon>
    </button>

    <div class="popup" *ngIf="showPopup">
        <div class="popup-notch">
        </div>
        <div class="transparent-top-part" (click)="showAudioPopupF()">
        </div>
        <div class="popup-content" *ngIf="showAltPopupContent">
            <div style="font-size: small; font-weight: 500;">
                VITAL SIGNS
                <p style="margin-top: 12px;">Cardiac Rhythm: {{_clinicalControllerService.getCardiacRhythmString(clinical.cardiacRhythm)}}</p>
                <p>Capnography: {{_clinicalControllerService.getRespiratoryWaveformString(clinical.respiratoryWaveform)}}</p>
                <div style="display: flex;">
                    <div style="min-width: 140px; margin-right: 10px;">
                        
                        <app-slider [gender]="this.gender.toLowerCase()" canEdit="false" [showShort]="true" [showUoM]="false"
                            [(data)]="clinical.HR" name="HR">
                        </app-slider>
                        <app-slider [gender]="this.gender.toLowerCase()" canEdit="false" [showShort]="true" [showUoM]="false"
                            [(data)]="clinical.RR" name="RR">
                        </app-slider>
                        <app-slider [gender]="this.gender.toLowerCase()" canEdit="false" [showShort]="true" [showUoM]="true"
                            [(data)]="clinical.SaO2" name="SaO2">
                        </app-slider>
                        <app-slider [gender]="this.gender.toLowerCase()" canEdit="false" [showShort]="true" [showUoM]="true"
                            [(data)]="clinical.ETCo2" name="ETCo2">
                        </app-slider>
                        <app-slider [gender]="this.gender.toLowerCase()" canEdit="false" [showShort]="true" [showUoM]="true"
                            [(data)]="clinical.sBP" name="sBP">
                        </app-slider>
                        <app-slider [gender]="this.gender.toLowerCase()" canEdit="false" [showShort]="true" [showUoM]="true"
                            [(data)]="clinical.dBP" name="dBP">
                        </app-slider>
                        <app-slider [gender]="this.gender.toLowerCase()" canEdit="false" [showShort]="true" [showUoM]="true"
                            [(data)]="clinical.temp" name="temp">
                        </app-slider>
                    </div>
                </div>
                <div style="display: flex;">
                    <div style="min-width: 140px; margin-right: 10px;">
                        <p><b>VENTILATOR VITALS</b></p>
                        <p>PIP: {{clinical.PIP}}</p>
                        <p>Vt: {{clinical.Vt}}</p>
                        <p>PPLAT: {{clinical.PPLAT}}</p>
                        <p>MAP: {{clinical.MAP}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup-content" *ngIf="showAudioPopupContent">
            <button mat-flat-button style="color:#1f7abe" (click)="onEditItem()"><mat-icon>create</mat-icon> Edit</button>
            <button mat-flat-button style="color:#1f7abe" (click)="onDeleteItem()"><mat-icon>delete</mat-icon> Delete</button>
        </div>
    </div>
</div>