<div class="dialog-container-header">
  <div mat-dialog-title>
    <h2>Ventilator Settings</h2>
    <h3>
      Adjust the settings for the ventilator connected to patient
      {{ data.patientName }}
    </h3>
  </div>

  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="grid-container">
  <div class="item1">
    <div class="group">
      <h3>Peak Inspiratory Pressure</h3>
      <div class="groupContent">
        <div>
          <h4>PIP</h4>
          <app-custom-number-field
            [(value)]="ventilator.PIP"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>

        <div>
          <h4>PIP High Alarm</h4>
          <app-custom-number-field
            [(value)]="ventilator.PIPHighAlarm"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>
      </div>
      <div class="groupContent">
        <div>
          <h4>PEEP</h4>
          <app-custom-number-field
            [(value)]="ventilator.PEEP"
            [steps]="1"
            [min]="0"
            [max]="999"
          ></app-custom-number-field>
        </div>
        <div>
          <h4>PIP Low Alarm</h4>
          <app-custom-number-field
            [(value)]="ventilator.PIPLowAlarm"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>
      </div>
    </div>

    <div class="group">
      <h3>Breaths Per Minute</h3>
      <div class="groupContent">
        <div>
          <h4>BPM</h4>
          <app-custom-number-field
            [(value)]="ventilator.BPM"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>

        <div>
          <h4>BPM High Alarm</h4>
          <app-custom-number-field
            [(value)]="ventilator.BPMHighAlarm"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>
      </div>
      <div class="groupContent">

        <div style="margin-left: auto;">
          <h4>BPM Low Alarm</h4>
          <app-custom-number-field
            [(value)]="ventilator.BPMLowAlarm"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>
      </div>
    </div>

    <div class="group">
      <h3>Inhalation/Exhalation Ratio</h3>
      <div class="groupContent">
        <div>
          <h4>IT Ratio</h4>
          <app-custom-number-field
            [(value)]="ventilator.IERatio"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>
      </div>
    </div>
  </div>

  <div class="item2">
    <div class="group">
      <h3>Fraction of Inspired Oxygen</h3>
      <div class="groupContent">
        <div class="groupContentItem">
          <h4>FIO2</h4>
          <app-custom-number-field
            [(value)]="ventilator.FIO2"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>
        <div class="groupContentItem">
          <h4>FIO2 Usage</h4>
          <app-custom-number-field
            [(value)]="ventilator.FIO2Usage"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>
      </div>
    </div>

    <div class="group">
      <h3>Tidal Volume</h3>
      <div class="groupContent">
        <div>
          <h4>Vt</h4>
          <app-custom-number-field
            [(value)]="ventilator.Vt"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>

        <div>
          <h4>Vt High</h4>
          <app-custom-number-field
            [(value)]="ventilator.VtHighAlarm"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>

    </div>
    <div class="groupContent">

        <div style="margin-left: auto;">
          <h4>Vt Low</h4>
          <app-custom-number-field
            [(value)]="ventilator.VtLowAlarm"
            [steps]="1"
            [min]="0"
            [max]="999"
          >
          </app-custom-number-field>
        </div>
      </div>
    </div>

    <div class="group">
      <h3>MISC</h3>
      <div class="groupContent">
        <div>
          <h4>Mode</h4>
          <mat-form-field appearance="outline" style="width: 60%;">
            <mat-select [(value)]="ventilator.Mode">
              <mat-option
                *ngFor="let mode of VentilatorMode; let i = index"
                [value]="i + 1"
                >{{ mode }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div style="margin-left: 5px">
          <h4>Adjunct Mode (P/V)</h4>
          <mat-form-field appearance="outline" style="width: 50%;">
            <mat-select [(value)]="ventilator.AdjunctMode">
              <mat-option
                *ngFor="let mode of VentilatorAdjunctMode; let i = index"
                [value]="i + 1"
                >{{ mode }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="item3">
    <div class="alarm-group">
      <h3>Alarms</h3>
      <section style="padding: 16px 0">
        <div *ngFor="let alarm of VentilatorAlarms">
            <mat-checkbox
            style="margin-right: 32px"
            [checked]="IsActiveAlarm(alarm)"
            (change)="OnAlarmChange($event, alarm)"
            >{{ alarm }}
          </mat-checkbox>
        </div>
      </section>
    </div>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-button class="green-focus" [mat-dialog-close]="this.ventilator">
    Submit Changes
  </button>
</mat-dialog-actions>
