import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DialogTreatmentOptionComponent } from '../dialogs/dialog-treatment-option/dialog-treatment-option.component';
import {   MatDialog } from '@angular/material/dialog';
import { DialogInstructorPointComponent } from '../dialogs/dialog-instructor-point/dialog-instructor-point.component';
import { phase } from '../../models/phase.model';
import { DialogDeletePhaseComponent } from '../dialogs/dialog-phases/dialog-delete-phase/dialog-delete-phase.component';
import { DialogEditPhaseComponent } from '../dialogs/dialog-phases/dialog-edit-phase/dialog-edit-phase.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { clinical, statedata } from 'src/app/models/statedata.model';
import { toolsservice } from '../services/tools.service';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { patient } from 'src/app/models/patient.model';
import { Symptom } from 'src/app/models/draggable.model';
import { PreviewAnimationService } from '../services/preview-animation.service';
import animationNames from '../../data/clinical-states.json';

@Component({
  selector: 'app-phase',
  templateUrl: './phase.component.html',
  styleUrls: ['./phase.component.scss'],
  providers: [toolsservice]
})
export class PhaseComponent implements OnInit {

  isOpen = false;
  private wasOpen = false;

  @Input() phase: phase;
  @Input() patient: patient;

  @Output() phaseEvent = new EventEmitter<{action: string, phase: phase}>();
  @Output() symptomEvent = new EventEmitter<{action: string, clinicalData: clinical }>();
  @Output() resetPreviewAnimation = new EventEmitter();

  constructor(
    private previewAnimationService: PreviewAnimationService,
    private cdRef : ChangeDetectorRef,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if( this.patient.phases[0] === this.phase )
      this.onPhaseClicked();
  }

  // opens a dialog box to edit the phase title and description
  onEditPhase() {
    const dialogRef = this.dialog.open(DialogEditPhaseComponent, {
      width : '447px',
      data: {
        name : this.phase.name,
        description : this.phase.description }
    });

    dialogRef.afterClosed().subscribe(
      val => {

        if ( val === undefined || val === null || val === '' ) { return; }
        this.phase.name = val.name;
        this.phase.description = val.description;
        this.phaseEvent.emit({action: 'Update', phase: this.phase});
          // toolsservice.openSnackBar( this.snackBar, `Phase ${this.phase.index} Updated`, true);
      }
    );
  }

  // removes a phase from the list of phases
  onDeletePhase() {

    const dialogRef = this.dialog.open(DialogDeletePhaseComponent, {
      // height: '320px',
      width: '447px',
      data: { name: this.phase.name, description: `` },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        // check and see if any of the symptoms have the animation playing, then stop if true.
        if ( this.phase.clinicalStates.some( x => this.previewAnimationService.IsMatchingSymptoms(x as clinical))) {
          this.previewAnimationService.onStopAnimation();
        }
        this.phaseEvent.emit({action: 'Delete', phase: this.phase});
        toolsservice.openSnackBar( this.snackBar, `Phase "${this.phase.name}" Deleted`, false);
      }
    });
  }

  // handles the toggle on and off for opening an existing phase, also closes an existing phase
  onPhaseClicked() {
    this.isOpen = !this.isOpen;
    this.wasOpen = this.isOpen;
  }

  // opens the instructor note dialog box from the instructor notes button
  onOpenInstructorDialog() {

    const dialogRef = this.dialog.open(DialogInstructorPointComponent, {
      // height: '375px',
      width: '500px',
      data: { name: 'Add Instructor Note', title: '', description: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!(result === undefined || result === '')) {
        let instructorNote = new statedata(toolsservice.makeId(10), 'Instructor Note', result, 2, 0, 0);
        this.phase.clinicalStates.push(instructorNote);
        this.phaseEvent.emit({action: 'Add', phase: this.phase});
        toolsservice.openSnackBar( this.snackBar, `Instructor Note Added`, true);
      }
    });
  }

  onNoteEvent(data){
    // contains the following data { action: string data: statedata }
    if ( data === undefined || data === null ) { return; }
    switch ( data.action ){
      // case "Add" : break;
      case 'Update' : this.onUpdateSymptoms(data.data); break;
      case 'Delete' : this.onDeleteSymptoms(data.data); break;
      default :

    }
    this.phaseEvent.emit({action : 'Update', phase: this.phase});
    if ( data.data.type === 1 ) {
      toolsservice.openSnackBar( this.snackBar, `Treatment Point ${data.action}`, data.action == "Delete" ? false: true);
    }
    else {
      toolsservice.openSnackBar( this.snackBar, `Instructor Note ${data.action}`, data.action == "Delete" ? false: true);
    }
  }

  // opens the treatment dialog box using the add treatment point button
  onOpenTreatmentDialog() {
    const dialogRef = this.dialog.open(DialogTreatmentOptionComponent, {
      // height: '375px',
      width: '500px',
      data: { name: 'Add Treatment Point', title: '', description: '' },
    });

    dialogRef.afterClosed().subscribe(result => {

      // TODO: check if result is valid data
      if (!(result === undefined || result === '')) {
        const treatmentPoint = new statedata(toolsservice.makeId(10), 'Treatment Point', result, 1, 0, 0);
        this.phase.clinicalStates.push(treatmentPoint);
        this.phaseEvent.emit({action: 'Add', phase: this.phase});
        toolsservice.openSnackBar( this.snackBar, `Treatment Point Added`, true);
      }
    });
  }

  onSymptomsEvent(data){
    if ( data === undefined || data === null ) { return; }
    // data should come out with the following datatype format
    // { action : string, clinical : clinical }

    switch ( data.action ){
      case 'Delete' : this.onDeleteSymptoms(data.clinical); break;
      case 'Update' : this.onUpdateSymptoms(data.clinical); break;
      case 'Add' : break;
      default : console.log(`Unhandle exception raised: ${data.action}`, data.clinical);
    }
    // this needs to be called first before we save the data back to the server. This is to prevent potential non-matching data updates.
    this.symptomEvent.emit({action:data.action, clinicalData: data.clinical});
    // then we'll update the symptoms here so that we can properly save the data back to the master.
    this.phaseEvent.emit({action: 'Update', phase: this.phase});
  }

  onDeleteSymptoms( symptoms: statedata ) {
    this.phase.clinicalStates = this.phase.clinicalStates.filter(x => x.statedataId !== symptoms.statedataId);
    this.reorderStateData();
  }

  onUpdateSymptoms(symptoms: clinical | statedata ){
    const index = this.phase.clinicalStates.findIndex(x => x.statedataId === symptoms.statedataId);
    if ( index > -1 ) {
      this.phase.clinicalStates[index] = symptoms;
    }
  }

  reorderStateData() {
    this.phase.clinicalStates.forEach(listedStateData => {
        listedStateData.position === this.phase.clinicalStates.indexOf(listedStateData) + 1;
    });
    this.phaseEvent.emit({action:'Update', phase: this.phase});
  }

  private getAnimationNameState( animName: string ){
    for ( const key in animationNames.CategoryLabels ) {
      for ( const set_key in animationNames.Categories[key] ) {
        if ( animationNames.Categories[key][set_key] === animName ) {
          return set_key;
        }
      }
    }
    return null;
  }

  /**
   * Handles the event to detect Symptoms entering the phase.
   * @param event item that is being dragged?
   */
  dragEnter(event:any)
  {
    // store away to notify this script this phase was intentionally left open by the user.
    // do not collapse the phase if the user changes their mind about symptoms.
    this.wasOpen = this.isOpen;
    if( !this.isOpen )
    {
      this.isOpen = true;
      this.cdRef.detectChanges();
    }
  }

  /**
   * Check and see if the user wishes to move the symptoms elsewhere.
   * @param event Item has left the phase list.
   */
  dragLeave(event:any){
    if( this.isOpen && !this.wasOpen ){
      this.isOpen = false;
      this.cdRef.detectChanges();
    }
  }

  // Handles the drag and drop of phase items from the symptoms search list to the phase
  drop(event: CdkDragDrop<any[]>) {

    if ( event.isPointerOverContainer )
    {
      if (event.previousContainer.id === event.container.id) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
      // get a copy of the array element we dropped?
        const iS: Symptom =  ( event.previousContainer.data[ event.previousIndex ] ) as Symptom; // the symptom being dropped in
        // copy the initial data from patient initialclinicalstate
        const newStateData: clinical = Object.assign({}, this.patient.InitialClinicalData);
        // update the new symptom card
        newStateData.statedataId = toolsservice.makeId(10);
        newStateData.name = iS.name,
        newStateData.description = iS.category;
        newStateData.animation = this.getAnimationNameState(iS.name);

        // append to the current array in specific position
        this.phase.clinicalStates.splice(event.currentIndex, 0, newStateData);
      }
    }
    else {
     // in this case it basicallymeans we're going to pop this guy out.
     if ( this.previewAnimationService.IsMatchingSymptoms(this.phase.clinicalStates[event.currentIndex] as clinical)){
        this.previewAnimationService.onStopAnimation();
     }
     const data = this.phase.clinicalStates[event.currentIndex] as clinical;
     this.symptomEvent.emit({action:"Delete", clinicalData: data});
     this.phase.clinicalStates.splice(event.currentIndex, 1);
    }
    this.reorderStateData();
  }
}
