import { Device, DeviceType } from './device.model';

export enum VentilatorMode {
    AC = 1,
    SIMV = 2,
    CPAP = 3
}

export enum VentilatorAdjunctMode {
    V = 1,
    P = 2
}

export enum VentilatorAlarms {
    "Tubing Compliance Fault", 
    "Gas Intake Fault", 
    "Self Check Failure", 
    "PEEP Leak", 
    "Patient Disconnect"
}

export class Ventilator extends Device {
    public FIO2 = 0;
    public FIO2Usage = 0;
    public PIP = 20;
    public PIPHighAlarm = 35;
    public PIPLowAlarm = 15;
    public PEEP = 5;
    public Vt = 450;
    public VtHighAlarm = 800;
    public VtLowAlarm = 200;
    public BPM = 12;
    public BPMHighAlarm = 20;
    public BPMLowAlarm = 8;
    public IERatio = 2;
    public Mode = VentilatorMode.AC;
    public AdjunctMode = VentilatorAdjunctMode.V;
    // public MAP = 10;
    // public PPLAT = 10;
    public Alarms: string[] = [];
    constructor(){
        super();
        this.kind = DeviceType.Ventilator;
    }
}