<div 
    class="container" 
    cdkDropList [id]="phase.phaseId"
    cdkDropListConnectedTo="search-list" 
    (cdkDropListDropped)="drop($event)"
    (cdkDropListExited)="dragLeave($event)"
    (cdkDropListEntered)="dragEnter($event)"
    [cdkDropListData]="this.phase.clinicalStates"
>
    <div class="container-column">

        <!-- Left dock -->
        <div class="arrow-alignment">
            <button mat-icon-button class="arrowButton" (click)="onPhaseClicked()">
                <mat-icon class="icon-override" *ngIf="!this.isOpen">keyboard_arrow_down</mat-icon>
                <mat-icon class="icon-override" *ngIf="this.isOpen">keyboard_arrow_up</mat-icon>
            </button>
        </div>
        
        <!-- Content -->
        <div class="main-container">
            <!-- Header -->
            <div class="header-info" (click)="onPhaseClicked()">

                <!-- Title and Descript -->
                <div class="body-container">
                    <h1>{{phase.name}}
                        <span *ngIf="this.phase.clinicalStates.length > 0" 
                            class="symptomCountDisplay">
                            ({{this.phase.clinicalStates.length}})
                        </span>
                    </h1>
                    <p>{{phase.description}}</p>
                </div>

                <!-- Menu content -->
                <div class="right-container">
                    <button mat-icon-button aria-label="more" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onEditPhase()" style="color: #616161;">
                            <mat-icon style="color: #616161;">create</mat-icon> Edit
                        </button>
                        <button mat-menu-item (click)="onDeletePhase()" style="color: #616161;">
                            <mat-icon style="color: #616161;">delete</mat-icon> Delete
                        </button>
                    </mat-menu>
                </div>

            </div>
            
            <!-- Body -->
            <div [ngClass]="{'hide' : !isOpen }">

                <!-- Drag and drop container -->
                <div class="inner-container">

                    <!-- Draggable List : this is a list of type any holding symptoms, treatment points, and instructor notes-->
                    <div class="symptomList">
                        <div cdkDrag 
                            class="symptomItem" 
                            *ngFor="let draggable of this.phase.clinicalStates; let i=index"
                        >
                            <app-symptoms 
                                *ngIf="draggable.type === 0" 
                                [clinical]="draggable"
                                [symptomIndex]="i+1"
                                [(patient)]="patient"
                                [currentPhaseId]="phase.phaseId"
                                (symptomsEvent)="onSymptomsEvent($event)">
                            </app-symptoms>

                            <app-treatment-point
                                *ngIf="draggable.type === 1"
                                [treatmentpoint]="draggable"
                                [symptomIndex]="i+1"
                                (treatmentPointEvent)="onNoteEvent($event)">
                            </app-treatment-point>

                            <app-instructor-note 
                                *ngIf="draggable.type === 2"
                                [instructornote]="draggable"
                                [symptomIndex]="i+1"
                                (instructorNoteEvent)="onNoteEvent($event)">
                            </app-instructor-note>
                        </div>
                    </div>    
                </div>

                <!-- buttons -->
                <div class="actionGroup">
                    <!-- Instructor notes -->
                    <button mat-button
                        class="actionButton"
                        (click)="onOpenInstructorDialog()">Add Inst. Note</button>

                    <!-- Treatment Point -->
                    <button mat-button
                        class="actionButton"
                        (click)="onOpenTreatmentDialog()">Add Treatment Point</button>
                </div>

            </div>
        </div>
    </div>
</div>