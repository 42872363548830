import { Component, OnInit, Inject } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { patient } from 'src/app/models/patient.model';
import { clinical } from 'src/app/models/statedata.model';
import { toolsservice } from '../../services/tools.service';
import { CharacterParamsManagerService } from '../../../data/character-params-manager.service';

export interface DialogData {
  name: string;
  patient: patient;
  clinical: clinical;
}

export enum TextureCategory{
  bodyOverlayTexture = "_bodyOverlayTextures",
  bodyTreatmentTexture = "_bodyTreatmentTextures",
  animOverlayTexture = "_animOverlayTextures",
  eyeOverlayTexture = "_eyeOverlayTextures",
  clothingOverlayTexture = "_clothingOverlayTextures"
}

@Component({
  selector: 'app-dialog-textures',
  templateUrl: './dialog-textures.component.html',
  styleUrls: ['./dialog-textures.component.scss'],
})
export class DialogTexturesComponent implements OnInit {

  public bodyOverlayTextures: string[];
  public bodyTreatmentTextures: string[];
  public animOverlayTextures: string[];
  public eyeOverlayTextures: string[];
  public clothingOverlayTextures: string[];

  public bodyOverlayTexturesDictionary: {} = {};
  public bodyTreatmentTexturesDictionary: {} = {};
  public animOverlayTexturesDictionary: {} = {};
  public eyeOverlayTexturesDictionary: {} = {};
  public clothingOverlayTexturesDictionary: {} = {};

  public availableKeys: string[] = [];
  public characterParamReference: string;
  public characterParameter: any;
  public selectionIndex: number = 0;

  constructor(public dialogRef: MatDialogRef<DialogTexturesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              // private _toolsService: toolsservice,
              public characterParamsManagerService: CharacterParamsManagerService) { }

  public ImagePath(category: TextureCategory, key : string ): string{
    const array = ['Ethnic','Eth','Wht','White']
    const name = key
      .split('_')
      .filter(x=> array.indexOf(x) === -1 )
      .join('_');
    return `./assets/Images/Textures/${category as string}/${name}.PNG`;
  }

  public get textureCategory(): typeof TextureCategory{
    return TextureCategory;
  }

  ngOnInit(): void {

    //check if the patient is a baby
    if (this.data.patient.age === "Baby") {
      this.characterParamReference = `${this.data.patient.genderEthnicity}_${this.data.patient.age}`;
    } else {
      this.characterParamReference = `${this.data.patient.genderEthnicity}_${this.data.patient.amputation}`;
    }

    this.characterParameter = this.characterParamsManagerService.getTexturesByEntry(this.characterParamReference);
    for (let key in this.characterParameter) {

      //WE DO NOT want to log the _clothingOverlayTexture just yet
      if(key !== "_clothingOverlayTextures"){
        this.availableKeys.push(key);
      }

      switch (key) {
        case TextureCategory.bodyOverlayTexture:
          this.bodyOverlayTextures = Object.assign({}, this.characterParameter[key]);
          this.createDictionary(this.bodyOverlayTextures, this.bodyOverlayTexturesDictionary);
          break;
        case TextureCategory.bodyTreatmentTexture:
          this.bodyTreatmentTextures = Object.assign({}, this.characterParameter[key]);
          this.createDictionary(this.bodyTreatmentTextures, this.bodyTreatmentTexturesDictionary);
          break;
        case TextureCategory.animOverlayTexture:
          this.animOverlayTextures = Object.assign({}, this.characterParameter[key]);
          this.createDictionary(this.animOverlayTextures, this.animOverlayTexturesDictionary);
          break;
        case TextureCategory.eyeOverlayTexture:
          this.eyeOverlayTextures = Object.assign({}, this.characterParameter[key]);
          this.createDictionary(this.eyeOverlayTextures, this.eyeOverlayTexturesDictionary);
          break;
          case TextureCategory.clothingOverlayTexture:
          // TODO: in the future maybe add this back once we have clothing overlays as an option in the editor.
          // this.clothingOverlayTextures = Object.assign({}, this.characterParameter[key]);
          // this.createDictionary(this.clothingOverlayTextures, this.clothingOverlayTexturesDictionary);
          break;
        default:

          break;
      }
    }
    //critical for allowing pre-selected textures to autofill.
    this.checkTexturesSelected();
  }
  //function used to check if textures have already been selected for this model and toggles them.
  checkTexturesSelected() {
    if(this.data.clinical.Textures !== null ){
      for(let texture of this.data.clinical.Textures){
        this.checkDisabledMulti(texture, this.bodyOverlayTexturesDictionary, '(Body Overlays)');
        this.checkDisabledMulti(texture, this.bodyTreatmentTexturesDictionary, '(Body Treatments)');
        this.checkDisabledMulti(texture, this.animOverlayTexturesDictionary, '(Animated Overlays)');
        //this.checkDisabledMulti(texture, this.bodyTreatmentTexturesDictionary, '()')
      }
    }

    if(this.data.clinical.EyesOverlays !== null ){
      for(let texture of this.data.clinical.EyesOverlays){
        this.checkDisableRestEyes(texture)
      }
    }
  }


  //#region EYE TEXTURE COLLECTION
  //TODO: the collection methods between the eyes and the below remaining texture collection are extreamly similar
  //      may need be able to refactor into just one function, however for now this will remain separate
  public activeCheckboxEyes: string[] = [];

  /*****************************************************************************************
   * function: checkDisableRestEyes
   * purpose: controlls the toggled state of each dictionary entry between true and false
   *          to determine toggled state of chceckbox and to log away selected items for use
   *          in determining disabled state for remaining buttons
   *
   * Parameters:
   *  I: iKey: string   - the key from the dictionary that provides the stored boolean value
   ******************************************************************************************/
  public checkDisableRestEyes(iKey: string) {
    if (this.activeCheckboxEyes.length < 3 && this.eyeOverlayTexturesDictionary[iKey] === false) {
      this.eyeOverlayTexturesDictionary[iKey] = !this.eyeOverlayTexturesDictionary[iKey];
      if (this.eyeOverlayTexturesDictionary[iKey] === true) {
        this.activeCheckboxEyes.push(iKey);
      }

    } else if (this.eyeOverlayTexturesDictionary[iKey] === true) {

      this.eyeOverlayTexturesDictionary[iKey] = false;
      this.activeCheckboxEyes.splice(this.activeCheckboxEyes.indexOf(iKey), 1);

    }
    this.data.clinical.EyesOverlays = this.activeCheckboxEyes;
  }
  //#endregion

  //#region OVERLAY, TREATMENTS, AND ANIMATED OVERLAY COLLECTION
  //TODO: as stated above in EYE TEXTURE COLLECTION may be able to merge these two functions into one master
  //      function somehow as for now it works.
  public activeCheckbox: string[] = [];
  public activeCheckboxWExtraData: string [] = [];

  /*****************************************************************************************
   * function: checkDisabledMulti
   * purpose: controlls the toggled state of each dictionary entry between true and false
   *          to determine toggled state of chceckbox and to log away selected items for use
   *          in determining disabled state for remaining buttons
   *
   *          this function is more dynamic than the one above and allows for the manipulation
   *          of any passed in dictionary, it also logs away the associated category for each
   *          texture in its own array
   *
   * Parameters:
   *  I: iKey: string   - the key from the dictionary that provides the stored boolean value
   *  I: testDictionary: {} - the dictionary that is to be read from
   *  I: category: string - the associated category that should be logged away with the collected
   *                        data
   ******************************************************************************************/
  public checkDisabledMulti(iKey: string, testDictionary: {}, category: string = "") {

    if (this.activeCheckbox.length < 3 && testDictionary[iKey] === false) {
      testDictionary[iKey] = !testDictionary[iKey];
      if (testDictionary[iKey] === true) {
        this.activeCheckbox.push(iKey);
        this.activeCheckboxWExtraData.push(`${iKey}_${category}`)
      }

    } else if (testDictionary[iKey] === true) {

      testDictionary[iKey] = false;
      this.activeCheckbox.splice(this.activeCheckbox.indexOf(iKey), 1);
      this.activeCheckboxWExtraData.splice(this.activeCheckboxWExtraData.indexOf(`${iKey}_${category}`), 1);


    }
    this.data.clinical.Textures = this.activeCheckbox;
  }

  //#endregion

  /************************************************************************************
  * function: createDictionary
  * purpose: fills in a previously instantiated dictionary with appropriate keys and
  *          expected default starting values
  *
  * parameters:
  *   I: dictionary : { }   - the dictionary to copy from
  *   I/O: dictOutput : { } - the generatedDictionary with default boolean values
  *************************************************************************************/
  private createDictionary(dictionary, dictOutput: {}) {
    for (let key in dictionary) {
      dictOutput[dictionary[key]] = false;
    }
  }

  public setSelection(index: number) {
    this.selectionIndex = index;
  }

  /************************************************************************************
   * function: getPrettyName
   * purpose: for each subcategory provided by the character parameters texture options
   *          there should be options for
   ************************************************************************************/
  public getPrettyName(key: string): string {
    switch(key){
      case "_bodyOverlayTextures":
        return "Body Overlays";
      case "_bodyTreatmentTextures":
        return "Body Treatements";
      case "_animOverlayTextures":
        return "Animated Overlays";
      case "_eyeOverlayTextures":
        return "Eye Overlays";
      default:
        break;
    }
    return undefined;
  }
}
