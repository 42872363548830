<!-- <div class="dialog-container"> -->
    <h2 mat-dialog-title style="font-family: OpenSansLight;"> Remove Scenario? </h2>
    <!-- <hr> -->
    <mat-dialog-content>
        <p style="font-size: medium; font-family: OpenSans;">Are you sure you want to remove {{ data.name }} from this table?</p>
        <br>
        <!-- <section>
            <mat-checkbox [(ngModel)]="checked" style="font-size: medium;">I understand this patient will be deleted forever</mat-checkbox>
        </section> -->
    </mat-dialog-content>
    <!-- <br> -->
    <mat-dialog-actions align="end">
        <!-- <div class="buttons">
            <span> -->
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button color="warn" mat-dialog-close
                    [mat-dialog-close]="true">Remove Scenario</button>
            <!-- </span>
        </div> -->
    </mat-dialog-actions>
<!-- </div> -->