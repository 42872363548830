<mat-toolbar color="primary">
  <mat-toolbar-row
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <button
      mat-icon-button
      class="example-icon"
      aria-label="Example icon-button with menu icon"
      (click)="showNavDropdown = !showNavDropdown"
    >
      <mat-icon style="color: white">menu</mat-icon>
    </button>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35px"
      height="35px"
      viewBox="0 0 43.26 42.35"
      style="cursor: pointer; fill: white; margin-top: 0px; margin-left: 5px"
      (click)="reloadDashBoard()"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M21.06,22.77A7.87,7.87,0,0,0,20,20.22l1-1L18.77,17l-1,1A7.33,7.33,0,0,0,15.24,17V15.56H12.11V17A7.3,7.3,0,0,0,9.56,18l-1-1L6.36,19.23l1,1a7.46,7.46,0,0,0-1.06,2.55H4.9V25.9H6.29a7.46,7.46,0,0,0,1.06,2.55l-1,1,2.21,2.22,1-1a7.49,7.49,0,0,0,2.55,1.06v1.39h3.13V31.72a7.52,7.52,0,0,0,2.54-1.06l1,1L21,29.43l-1-1a7.87,7.87,0,0,0,1.06-2.55h1.39V22.77Zm-7.39,5.77a4.21,4.21,0,1,1,4.21-4.21A4.2,4.2,0,0,1,13.67,28.54Z"
          />
          <path
            d="M23.31,12.3a4.91,4.91,0,0,0,0-1.71l.79-.34-.75-1.78-.79.33a4.8,4.8,0,0,0-1.22-1.2l.33-.8-1.79-.73-.33.8a4.68,4.68,0,0,0-1.71,0l-.33-.8-1.79.76.34.79a4.53,4.53,0,0,0-1.21,1.22L14,8.52l-.73,1.79.8.33a4.91,4.91,0,0,0,0,1.71l-.79.33.75,1.79.79-.34a4.68,4.68,0,0,0,1.22,1.21l-.33.79,1.79.74.33-.8a4.91,4.91,0,0,0,1.71,0l.33.79,1.79-.75-.34-.8a4.49,4.49,0,0,0,1.21-1.21l.8.33.73-1.8Zm-5.58,1.58a2.6,2.6,0,1,1,3.39-1.43A2.6,2.6,0,0,1,17.73,13.88Z"
          />
          <path
            d="M13.2,37.79a4.47,4.47,0,0,0,0-1.71l.8-.34L13.24,34l-.8.33a4.76,4.76,0,0,0-1.21-1.2l.32-.8-1.79-.73-.33.8a4.47,4.47,0,0,0-1.71,0l-.33-.8-1.79.75.34.8a4.46,4.46,0,0,0-1.2,1.21L3.94,34,3.21,35.8l.79.33a4.62,4.62,0,0,0,0,1.7l-.8.34L4,40l.8-.33A4.6,4.6,0,0,0,6,40.82l-.33.8,1.8.73.32-.8a4.47,4.47,0,0,0,1.71,0l.34.8,1.78-.75-.33-.8a4.76,4.76,0,0,0,1.2-1.21l.8.32L14,38.11ZM7.62,39.37a2.6,2.6,0,1,1,2-4.82,2.6,2.6,0,0,1-2,4.82Z"
          />
          <path
            d="M42.76,25.12C41.15,21.57,39.58,18,40,13.94c.25-2.43-.89-4.53-2.22-6.41C34.09,2.34,28.67.5,22.62.05A20.52,20.52,0,0,0,15,1,20.55,20.55,0,0,0,2.08,12.43a20.86,20.86,0,0,0-2,11A22.41,22.41,0,0,0,3,32.87c.08.13.15.25.21.37,1.44,2.5,2.25,2.22,2.25,2.22l1.72-2.05L6.93,32a2.34,2.34,0,0,1-1-.91,14.51,14.51,0,0,1-1.76-4.16,20.31,20.31,0,0,1,0-9.43A17.31,17.31,0,0,1,11,6.92c5.52-3.89,11.49-4.23,17.69-2,5.34,1.92,8.41,5.66,8.48,11.5a15.54,15.54,0,0,0,1.81,7.08c.92,1.76.83,2-1,2.91a2.34,2.34,0,0,0-1.42,3,15.58,15.58,0,0,1,.36,4.25A3.55,3.55,0,0,1,34,37.11c-2.29.46-4.6.16-6.9.21-2.75.06-5.5,0-8.25,0-1.79,0-2.84,1.49-2,2.82a2.32,2.32,0,0,0,2.16,1h7.77c2.47,0,4.94,0,7.41,0a10.21,10.21,0,0,0,3.38-.57A3.2,3.2,0,0,0,39.78,38a18.92,18.92,0,0,0,.06-6.28c-.25-1.67.22-2.8,1.66-3.39C43.47,27.54,43.63,27.06,42.76,25.12Z"
          />
        </g>
      </g>
    </svg>
    <span
      (click)="reloadDashBoard()"
      style="
        cursor: pointer;
        font-family: OpenSansBold;
        padding-left: 10px;
        color: white;
      "
      >PerSim</span
    >
    <span
      (click)="reloadDashBoard()"
      style="
        cursor: pointer;
        font-family: OpenSansLight;
        padding-left: 6px;
        color: white;
      "
      >Community</span
    >
    <span class="example-spacer"></span>
    <div class="account-button" [routerLink]="['/account']">
      <mat-icon>account_circle</mat-icon>
      <div style="font-family: OpenSansLight">{{ getLoginName }}</div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!-- TODO:l make this its own component -->
<div
  *ngIf="showNavDropdown"
  id="navDropdown"
  style="
    width: 353px;
    height: 413;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    background-color: white;
  "
  class="mat-elevation-z8"
>
  <div
    class="dropdown"
    style="display: flex; flex-direction: column; font-family: OpenSans"
  >
    <span
      style="
        cursor: pointer;
        height: 64px;
        border-bottom: solid;
        border-color: silver;
        border-width: 1px;
      "
      class="childheader"
      (click)="showNavDropdown = !showNavDropdown"
    >
      <mat-icon class="listed-icons-header">clear</mat-icon>
      <div style="margin-top: 15px; margin-left: 15px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35px"
          height="35px"
          viewBox="0 0 43.26 42.35"
          style="fill: #616161; margin-top: 0px; margin-left: 5px"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                d="M21.06,22.77A7.87,7.87,0,0,0,20,20.22l1-1L18.77,17l-1,1A7.33,7.33,0,0,0,15.24,17V15.56H12.11V17A7.3,7.3,0,0,0,9.56,18l-1-1L6.36,19.23l1,1a7.46,7.46,0,0,0-1.06,2.55H4.9V25.9H6.29a7.46,7.46,0,0,0,1.06,2.55l-1,1,2.21,2.22,1-1a7.49,7.49,0,0,0,2.55,1.06v1.39h3.13V31.72a7.52,7.52,0,0,0,2.54-1.06l1,1L21,29.43l-1-1a7.87,7.87,0,0,0,1.06-2.55h1.39V22.77Zm-7.39,5.77a4.21,4.21,0,1,1,4.21-4.21A4.2,4.2,0,0,1,13.67,28.54Z"
              />
              <path
                d="M23.31,12.3a4.91,4.91,0,0,0,0-1.71l.79-.34-.75-1.78-.79.33a4.8,4.8,0,0,0-1.22-1.2l.33-.8-1.79-.73-.33.8a4.68,4.68,0,0,0-1.71,0l-.33-.8-1.79.76.34.79a4.53,4.53,0,0,0-1.21,1.22L14,8.52l-.73,1.79.8.33a4.91,4.91,0,0,0,0,1.71l-.79.33.75,1.79.79-.34a4.68,4.68,0,0,0,1.22,1.21l-.33.79,1.79.74.33-.8a4.91,4.91,0,0,0,1.71,0l.33.79,1.79-.75-.34-.8a4.49,4.49,0,0,0,1.21-1.21l.8.33.73-1.8Zm-5.58,1.58a2.6,2.6,0,1,1,3.39-1.43A2.6,2.6,0,0,1,17.73,13.88Z"
              />
              <path
                d="M13.2,37.79a4.47,4.47,0,0,0,0-1.71l.8-.34L13.24,34l-.8.33a4.76,4.76,0,0,0-1.21-1.2l.32-.8-1.79-.73-.33.8a4.47,4.47,0,0,0-1.71,0l-.33-.8-1.79.75.34.8a4.46,4.46,0,0,0-1.2,1.21L3.94,34,3.21,35.8l.79.33a4.62,4.62,0,0,0,0,1.7l-.8.34L4,40l.8-.33A4.6,4.6,0,0,0,6,40.82l-.33.8,1.8.73.32-.8a4.47,4.47,0,0,0,1.71,0l.34.8,1.78-.75-.33-.8a4.76,4.76,0,0,0,1.2-1.21l.8.32L14,38.11ZM7.62,39.37a2.6,2.6,0,1,1,2-4.82,2.6,2.6,0,0,1-2,4.82Z"
              />
              <path
                d="M42.76,25.12C41.15,21.57,39.58,18,40,13.94c.25-2.43-.89-4.53-2.22-6.41C34.09,2.34,28.67.5,22.62.05A20.52,20.52,0,0,0,15,1,20.55,20.55,0,0,0,2.08,12.43a20.86,20.86,0,0,0-2,11A22.41,22.41,0,0,0,3,32.87c.08.13.15.25.21.37,1.44,2.5,2.25,2.22,2.25,2.22l1.72-2.05L6.93,32a2.34,2.34,0,0,1-1-.91,14.51,14.51,0,0,1-1.76-4.16,20.31,20.31,0,0,1,0-9.43A17.31,17.31,0,0,1,11,6.92c5.52-3.89,11.49-4.23,17.69-2,5.34,1.92,8.41,5.66,8.48,11.5a15.54,15.54,0,0,0,1.81,7.08c.92,1.76.83,2-1,2.91a2.34,2.34,0,0,0-1.42,3,15.58,15.58,0,0,1,.36,4.25A3.55,3.55,0,0,1,34,37.11c-2.29.46-4.6.16-6.9.21-2.75.06-5.5,0-8.25,0-1.79,0-2.84,1.49-2,2.82a2.32,2.32,0,0,0,2.16,1h7.77c2.47,0,4.94,0,7.41,0a10.21,10.21,0,0,0,3.38-.57A3.2,3.2,0,0,0,39.78,38a18.92,18.92,0,0,0,.06-6.28c-.25-1.67.22-2.8,1.66-3.39C43.47,27.54,43.63,27.06,42.76,25.12Z"
              />
            </g>
          </g>
        </svg>
      </div>
      <h2 class="listed-label">PerSim Community</h2>
    </span>
    <span
      class="child"
      [routerLink]="['/dashboard']"
      (click)="showNavDropdown = !showNavDropdown"
    >
      <mat-icon class="listed-icons">dashboard</mat-icon>
      <h2 class="listed-label">Dashboard</h2>
    </span>

    <span
      *ngIf="!isInstructor"
      class="child"
      [routerLink]="['/admin']"
      (click)="showNavDropdown = !showNavDropdown"
    >
      <mat-icon class="listed-icons">people_alt</mat-icon>
      <h2 class="listed-label">Admin Panel</h2>
    </span>

    <div
      style="
        background-color: silver;
        height: 1px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    ></div>
    <span class="child" (click)="logout()">
      <mat-icon class="listed-icons">exit_to_app</mat-icon>
      <h2 class="listed-label">Log Out</h2>
    </span>
  </div>
</div>
<style>
  #unity-canvas {
    width: 100%;
    height: 900px;
  }

  #unity-loading-bar {
    position: absolute;
    left: 30%;
    top: 50%;
    width: 40%;
    height: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  #unity-progress-bar {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 1%;
    height: 100%;
    background-color: #ccc;
    border-radius: 10px;
    transition: 400ms linear;
  }
</style>

<div
  id="unity-container"
  style="
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    max-height: 900px;
    visibility: hidden;
    position: relative;
  "
>
  <div
    *ngIf="this.isCustomAudioEnabled"
    class="header-flex-box"
    style="text-align: right"
  >
    <button mat-button class="btn-green" (click)="uploadCustomAudio()">
      <mat-icon class="icon-size">edit</mat-icon>Upload Custom Audio File
    </button>
  </div>
  <canvas
    id="unity-canvas"
    style="
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
      max-height: 900px;
      cursor: default;
    "
  ></canvas>

  <div id="unity-loading-bar">
    <div id="unity-progress-bar">
      <br />
      <h2>Loading...</h2>
    </div>

    <div id="unity-logo"></div>
    <div id="unity-progress-bar-empty">
      <div id="unity-progress-bar-full"></div>
    </div>
  </div>

  <div id="unity-mobile-warning"></div>
  <div id="unity-footer">
    <div id="unity-webgl-logo"></div>
    <div id="unity-fullscreen-button"></div>
  </div>
</div>
