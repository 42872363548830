import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { scenario } from 'src/app/models/scenario.model';
import { Router } from '@angular/router';
import {   MatDialog } from '@angular/material/dialog';
import { DialogPreviewWindowComponent } from '../dialogs/dialog-preview-window/dialog-preview-window.component';
import { AuthService } from 'src/app/auth/auth.service';
import { toolsservice } from 'src/app/editor/services/tools.service';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { ScenariosService } from 'src/app/services/scenarios.service';
import { UserService } from 'src/app/admin/user.service';
import { TeamsService } from 'src/app/services/teams.service';
import { ServerService } from 'src/app/services/Server.service';
import { CommunityService } from 'src/app/services/community.service';

import { DialogPromptComponent } from 'src/app/dialogs/dialog-prompt/dialog-prompt.component';
import { ListOfScenariosComponent } from 'src/app/list-of-scenarios/list-of-scenarios/list-of-scenarios.component';
import { TeamDownloadResult } from 'src/app/models/teamdownloadresult.model';
import { RenameScenarioComponent } from 'src/app/scenario-card/dialogs/rename-scenario/rename-scenario.component';
import { environment } from 'src/environments/environment';
import { PersimHeaderComponent } from 'src/app/persim-header/persim-header.component';
import { FeatureKeys } from 'src/app/services/featureflag.service';


@Component({
  selector: 'app-scenario-card',
  templateUrl: './scenario-card.component.html',
  styleUrls: ['./scenario-card.component.scss'],
})
export class ScenarioCardComponent implements OnInit {
  @Input() scenario: scenario;
  @Input() shared = false;
  @Input() iscommunity = false;
  @Input() teamSimulation = false;
  @Output() itemRemoved = new EventEmitter<any>();
  @Output() teamSimulationAdded = new EventEmitter<any>();
  @Output() communitySimulationAdded = new EventEmitter<any>();

  public prettyDate: string;
  public sService: ScenariosService;
  public aService: AuthService;
  public institutionName: string;
  private totalRating = 0;
  public isOwnedScenario: boolean;
  public rateCount: number;
  public downloadCount: number;
  public isYourScenariosTab: boolean;
  public isRatingEnabled: boolean = false;
  public isDownloadCountEnabled: boolean = false;
  public get ServerOnline(): boolean {
    return ServerService.ServerOnline;
  }

  public get ServerOffline(): boolean {
    return ServerService.OfflineMode;
  }


  constructor(
    private scenarioService: ScenariosService,
    private communityService: CommunityService,
    private teamService: TeamsService,
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private msb: MatSnackBar,
    private userService: UserService,
    private listOfScenariosComponent: ListOfScenariosComponent,
    private cd: ChangeDetectorRef

  ) {
    this.sService = this.scenarioService;
    this.aService = this.authService;
    this.listOfScenariosComponent = listOfScenariosComponent;
  }

  ngOnInit(): void {
    this.institutionName = "Unknown";
    this.calculateRating();
    this.setDownloadCount();
    const modifiedDate: Date = new Date(this.scenario?.modifiedDate);
    this.prettyDate = modifiedDate.toLocaleDateString();
    this.isOwnedScenario = this.scenario.isDownloaded
    this.isYourScenariosTab = this.listOfScenariosComponent.showAddButton
  }

  public deterimineNewTag(): boolean {
    const todayDate: number = Number(Date.now());
    const cardDate: number = this.scenario?.teamShareDate ?? 0; // might need to be based off modified date?

    const oneDay = 24 * 60 * 60 * 1000;

    const diffDays: number = Math.floor(
      Math.abs((todayDate - cardDate) / oneDay)
    );
    return diffDays < 7;
  }

  public onUnShareSimulation($event: Event) {
    const dialogRef = this.dialog.open(DialogPromptComponent, {
      width: '600px',
      data: {
        title: 'Warning!',
        message: 'Are you sure sure you want to stop sharing this scenario with the community ?',
        approve: 'Yes',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.communityService.removeScenarioFromCommunity(this.scenario.ScenarioId).subscribe(
          response => {
            toolsservice.openSnackBar(this.msb, `Scenario ${this.scenario.name} has been removed from share`, false);
            this.listOfScenariosComponent.updateCommunityScenarioList();
          },
          (err) => {
            console.log(err);
          });
      }
    });
  }

  public toggleFavorite(): boolean {
    this.scenarioService.toggleFavorites(this.scenario).subscribe();
    if (this.isFavorite()) {
      this.scenario?.favoriteUsers.splice(
        this.scenario?.favoriteUsers.indexOf(this.authService.getUserID()),
        1
      );
      return false;
    } else {
      this.scenario?.favoriteUsers.push(this.authService.getUserID());
      return true;
    }
  }
  public onShareWithCommunity(isCommunityShared: boolean) {
    let dialogRef = null;
    if (!this.scenario?.isCommunityShared && this.listOfScenariosComponent.teamLinkedScenarios.find(x => x.copiedScenarioId == this.scenario.ScenarioId)) {
      // this scenario has been downlodad from another team mate user has to change label of scenario
      dialogRef = this.dialog.open(RenameScenarioComponent, {
        width: '910px',
        data: { scenario: this.scenario, moduleName: 'Community' }
      });
      return;
    }

    if (!this.scenario?.isCommunityShared) {
      dialogRef = this.dialog.open(DialogPromptComponent, {
        width: '600px',
        data: {
          title: 'Confirm',
          message: 'Are you sure sure you want to share this scenario with the community ?',
          approve: 'Yes',
        },
      });
    }
    else // community shared already
    {
      dialogRef = this.dialog.open(DialogPromptComponent, {
        width: '600px',
        data: {
          title: 'Warning!',
          message: 'Are you sure sure you want to stop sharing this scenario with the community ?',
          approve: 'Yes',
        },
      });

    }

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.scenario.isCommunityShared) {
        this.teamService
          .removeFromSimulation(this.scenario.ScenarioId)
          .subscribe(() => {
            this.communityService.removeScenarioFromCommunity(this.scenario.ScenarioId).subscribe(response => {
              toolsservice.openSnackBar(this.msb, `Scenario ${this.scenario.name} has been removed from community`, false);
            });
            console.log(`should remove from community`);
            this.scenario.isCommunityShared = isCommunityShared;
          });
      }
      else if (result && !this.scenario.isCommunityShared) {
        this.communityService.addScenarioToCommunity(this.scenario.ScenarioId, this.scenario.name).subscribe(response => {
          this.communitySimulationAdded.emit();
          toolsservice.openSnackBar(this.msb, 'Scenario ' + this.scenario.name + ' shared with community', true);
          this.listOfScenariosComponent.refreshAllTabs();
        });
        this.scenario.isCommunityShared = isCommunityShared;
      }
    });



  }

  /**
   * Duplicate the selected simulation
   */
  public onDuplicateSimulation() {
    // in this case we need to find a way to duplicate the current selected simulation.
    // then notify the dashboard to refresh
    const newScenario = this.scenario;
    // create a new ID to keep this scenario different
    newScenario.ScenarioId = toolsservice.makeId();
    // add a copy at the end of scenario name to help identify the duplication
    newScenario.name = `${newScenario.name} - copy`;
    // send to the server as a new createscenario object.
    this.scenarioService.updateScenario(newScenario).subscribe(() => {
      this.itemRemoved.emit();
      toolsservice.openSnackBar(this.msb, 'simulation duplicated', true);
    });
  }

  public onRemoveSimulation() {
    this.scenarioService
      .deleteScenario(this.scenario.ScenarioId)
      .subscribe(() => {
        // Once completed, need to refresh the home page.
        this.itemRemoved.emit();
        toolsservice.openSnackBar(this.msb, 'simulation deleted', false);
      });
    // TODO: Needs a notification to tell the list-of-scenario to refresh the list.
  }
  /**
   * Function to share scenario with team
   * @param isShared true or false depending on if the scenario is shared with the team.
   */
  public onShareWithTeam(isShared: boolean) {
    this.listOfScenariosComponent.getLinkedScenarios();
    if (this.listOfScenariosComponent.teamLinkedScenarios.find(x => x.copiedScenarioId == this.scenario.ScenarioId)) {
      // this scenario has been downlodad from another team mate user has to change label of scenario
      const dialogRef = this.dialog.open(RenameScenarioComponent, {
        width: '910px',
        data: { scenario: this.scenario, moduleName: 'Team' }
      });
      return;
    }
    let dialogRef = null;
    if (!this.scenario.isShared) {
      dialogRef = this.dialog.open(DialogPromptComponent, {
        width: '600px',
        data: {
          title: 'Confirm',
          message: 'Are you sure sure you want to share this scenario with the team ?',
          approve: 'Yes',
        },
      });
    }
    else // shared already
    {
      dialogRef = this.dialog.open(DialogPromptComponent, {
        width: '600px',
        data: {
          title: 'Warning!',
          message: 'Are you sure sure you want to stop sharing this scenario with the team ?',
          approve: 'Yes',
        },
      });

    }

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.scenario.isShared) {
        this.teamService
          .removeFromSimulation(this.scenario.ScenarioId)
          .subscribe(() => {

            toolsservice.openSnackBar(this.msb, 'Scenario ' + this.scenario.name + ' has been removed from the team share', false);
            this.scenario.isShared = isShared;


          });
      }
      else if (result && !this.scenario.isShared) {

        this.teamService
          .addToSimulation(this.scenario.ScenarioId, this.scenario.name)
          .subscribe(() => {
            toolsservice.openSnackBar(this.msb, 'Scenario ' + this.scenario.name + ' has been shared with the team', true);
            this.scenario.isShared = isShared;
          });
      }
    });
  }

  public async onDownloadEvent($event: Event): Promise<void> {
    $event.stopPropagation();
    await this.downloadScenarioProcess();

  }
  public async downloadScenarioProcess() {
    this.listOfScenariosComponent.remoteSimulationLoading = true;
    if (this.isOwnedScenario) {
      toolsservice.openSnackBar(
        this.msb,
        'Scenario is already in your list!',
        false);
      this.listOfScenariosComponent.remoteSimulationLoading = false;
      return;
    }
    else {
      if (this.scenario.authors == null) {
        console.log("Scenario has no author");
        return;
      }
      var originalUser = this.scenario.originalAuthor;

      this.teamService.dowloadTeamSimulationToYourSimulation(originalUser._id, this.scenario.ScenarioId)
        .subscribe(
          async (result) => {
            const resultObj = new TeamDownloadResult(
              result.result,
              result.scenarioId,
              result.hasError
            );
            toolsservice.openSnackBar(
              this.msb,
              resultObj.result,
              !resultObj.hasError);
            if (this.isDownloadCountEnabled) {
              await this.scenarioService.postDownloadCount(this.scenario);
            }
            this.listOfScenariosComponent.refreshAllTabs();

          },
          (error) => {
            toolsservice.openSnackBar(this.msb, 'UnExpected Error' + error, false);
            console.error('API call error:', error);
          },
          () => {



          }
        );
    }

  }

  public onMoreEvent($event: Event): void {
    $event.stopPropagation();
  }

  public onEvent($event: Event): void {
    $event.stopPropagation();
    this.toggleFavorite();
  }

  public isFavorite(): boolean {
    if (this.scenario?.favoriteUsers) {
      for (const user of this.scenario.favoriteUsers) {
        if (user === this.authService.getUserID()) {
          return true;
        }
      }
    }
    return false;
  }
  private setDownloadCount() {
    this.downloadCount = this.listOfScenariosComponent.getScenarioDownloadCount(this.scenario.ScenarioId);
  }
  // Function to calculate showcased star rating on card.
  private calculateRating() {
    this.totalRating = 0;
    this.rateCount = 0;
    if (this.scenario?.ratedUsers) {
      for (const user of this.scenario.ratedUsers) {
        this.totalRating += user.rated;
      }
      this.rateCount = this.scenario.ratedUsers.length;
      this.scenario.rating = Math.round(
        this.totalRating / this.scenario?.ratedUsers?.length ?? 1
      );
    }
    else {
      this.scenario.rating = 0;
    }
  }

  stateSwitch(iScenario: scenario) {
    let onUserScenarioTab = !(this.iscommunity || this.teamSimulation); // edit scenario button will be hidden on tabs other than 'Your Scenarios'
    this.openPreviewDialog(onUserScenarioTab, iScenario, this);


    // else {
    //   // TODO: opena normaly
    //   this.openScenario(iScenario);
    // }
  }

  public getInstitutionName(): string {
    if (this.scenario.originalAuthor != null || this.scenario.originalAuthor != undefined) {
      const institutionInfo = this.listOfScenariosComponent.filterInstitutionsById(this.scenario.originalAuthor.institutionId);
      if (institutionInfo != null && institutionInfo != undefined) {
        return institutionInfo.institutionName;
      }
    }
    return "Unknown";
  }
  public prettyName(authorID: string): string {

    if (this.scenario.originalAuthor != undefined) {
      return this.scenario.originalAuthor?.fName + ' ' + this.scenario.originalAuthor.lName;
    }
    else {
      return 'unknown';
    }
  }
  public isLongName(): boolean {
    if (this.scenario == undefined) {
      return false;
    }

    return this.scenario.name?.length > 20;
  }
  public prettyScenarioName(scenarioName: string, isShort: boolean) {
    if (scenarioName == '' || scenarioName == undefined) {
      return ''
    }
    const words = scenarioName.split(' ');
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word;
    });

    var prettyScenarioName = capitalizedWords.join(' ');
    if (prettyScenarioName.length > 20 && isShort) {
      return prettyScenarioName.substring(0, 20) + "..."; // to avoid very long scenario names
    }
    else {
      return prettyScenarioName;

    }


  }
  openPreviewDialog(onUserScenarioTab: boolean, iScenario: scenario, iScenarioCard: ScenarioCardComponent): void {
    const dialogRef = this.dialog.open(DialogPreviewWindowComponent, {
      width: '1200px',
      data: { scenario: iScenario, onYourScenarioTab: onUserScenarioTab, scenarioCard: iScenarioCard }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.calculateRating();
      if (result) {
        // TODO: Download Triggered?
        if (result.Download) {
          this.itemRemoved.emit();
        }
      }
    });
  }

  openScenario(iScenario: scenario) {
    this.router.navigate(['/editor', iScenario.ScenarioId]);
  }

  showThisScenario(iScenario: scenario, event: any) {
    event.stopPropagation();
    console.log(iScenario);
  }
  ngAfterViewInit(): void {
    this.isRatingEnabled = PersimHeaderComponent.featureList.find(x => x.featureName == FeatureKeys.RatingSystem).isEnabled;
    this.cd.detectChanges();
    this.isDownloadCountEnabled = PersimHeaderComponent.featureList.find(x => x.featureName == FeatureKeys.DownloadCountSystem).isEnabled;
    this.cd.detectChanges();
  }
}
