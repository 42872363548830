<div class="dialog-container-header">
  <h2 mat-dialog-title>Patient #</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content style="max-height: 513px">
  <app-add-patient
    [patient]="newPatient"
    [(namesArray)]="patientNames"
    [scenario]="this.data.scenario"
  ></app-add-patient>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button
    mat-button
    class="green-focus"
    [mat-dialog-close]="newPatient"
    [disabled]="isAddPatientDisabled()"
  >
    Add Patient
  </button>
</mat-dialog-actions>
