import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as voiceConfig from '../../../../assets/Responses/VoiceConfig.json';
import * as auscultationConfig from '../../../../assets/auscultation/auscultationConfig.json';
import { UploadService, fileType } from 'src/app/services/upload.service';
import { HttpClient } from '@angular/common/http';
import { CleanUpFileName } from 'src/app/custom-pipes/filename-obtainer.pipe';
import { ServerService } from 'src/app/services/Server.service';

// not sure if we need this?
export interface DialogData {
  name: string;
  genderAndAge: string;
  initialVoice: string;
  initialLung: string;
  initialHeart: string;
}

interface CustomRecordings {
  audioPath: string;
  category: string;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-dialog-sounds',
  templateUrl: './dialog-sounds.component.html',
  styleUrls: ['./dialog-sounds.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogSoundsComponent implements OnInit {
  // public acceptExtension = ['.mp3', '.m4a', '.ogg', '.wav'];
  // TODO: when the file converter works again, uncomment the above line
  public acceptExtension = ['.ogg'];
    // wasn't sure where this is coming from? Find out on the next episode of Dragonzballs P!
  // categories: string[] = ['Breathing']
  // selectedCategory: string = '';
  // List of recorded custom sounds
  customList: CustomRecordings[] = [];

  // List of voice statements provided to the user interface
  statements: string[] = [];

  // List of heart Auscultation provided to the user interface - may have to change this to be flexible?
  heartOptions: string[] = [];

  // list of lung Auscultation provided to the user interface - may have to change this to be flexible?
  lungOptions: string[] = [];

  isUploading = false;
  uploadingProgress = 0;

  // what user actually picked
  selectedVoice = 'none';
  selectedLungAuscultation = 'none';
  selectedHeartAuscultation = 'none';
  selectedAuscultationPath: string;

  testSelect = null;
  //#region Recording
  /***************** Initial Recording *****************/
  recordSrc = new Audio();

  @ViewChild('recordSrc') recordElement: ElementRef;

  soundTypes: string[] = ['Voice', 'Heart', 'Lung'];
  newSoundName = '';
  selectedSoundType = '';
  uploadProgress = -1;
  // recordStream : any;
  isRecording = false;
  matcher = new MyErrorStateMatcher();
  soundNameControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^[0-9a-zA-Z_\\-.\\(\\) ]+$'),
  ]);

  // Record Obj
  record: any;

  // URL of Blob
  recordUrl: any;

  // stream data
  stream: any;

  // temp audio
  _audio: HTMLAudioElement;

  constructor(
    private uploadService: UploadService,
    private http: HttpClient,
    public dialogRef: MatDialogRef<DialogSoundsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {

    this.heartOptions = auscultationConfig.hearts;
    this.lungOptions = auscultationConfig.lungs;
    this.loadConfigurationBasedOnGenderAndAge();
    this.setDefaultDropdownOptions();
    const gender =
      this.data.genderAndAge === 'Baby'
        ? 'Baby'
        : this.data.genderAndAge.indexOf('Female') > -1
        ? 'Female'
        : 'Male';

    this.setUpCustomSound(gender);
  }

  /**
   * Start recording
   */
  // recordAudio() {
  //   this.isRecording = true;
  //   const mediaConstraints = { video: false, audio: true };
  //   // begin recording
  //   navigator.mediaDevices
  //     .getUserMedia(mediaConstraints)
  //     .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  // }

  /**
   * Process recording input
   */
  // successCallback(stream) {
  //   // media content
  //   const options = {
  //     type: 'audio',
  //     mimeType: 'audio/ogg',
  //     numberOfAudioChannels: 1,
  //     sampleRate: 96000,
  //   };

  //   // begin recording using RecordRTC handler
  //   const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
  //   this.record = new StereoAudioRecorder(stream, options);
  //   this.record.record();
  //   // save the stream.
  //   this.stream = stream;
  // }

  /**
   * Error on recording
   */
  // errorCallback(err) {
  //   console.log(err);
  // }

  /**
   * Stop recording
   */
  // stopRecording() {
  //   this.isRecording = false;
  //   // this is to process the recording
  //   this.record?.stop(this.processRecording.bind(this));
  //   // stop recording.
  //   this.stream.getTracks().forEach((track) => track.stop());
  // }

  // processRecording(blob) {
  //   this.recordUrl = URL.createObjectURL(blob);
  //   this.recordSrc.src = this.recordUrl;

  //   // so I'm assuming at this point here we're going just upload the files to the server regardless?
  //   this.uploadToServer(this.newSoundName, this.selectedSoundType, blob);
  // }

  onUpdateVoiceSelection(data: any, source: any) {
    this.onStatementChanged(data, source);
    this.selectedVoice = data.value;
  }

  /**
   * @param evt Event from the control. Contains value information
   * @param organ What organs to target (Lung or Heart)
   */
  onAuscultationSelectionChanged( data: any, organ: string, audioSource: any ){
    console.log(data.value);
    if ( ( this.selectedAuscultationPath === this.selectedLungAuscultation && organ === 'Lung' ) ||
        ( this.selectedAuscultationPath === this.selectedHeartAuscultation && organ === 'Heart' ) )
    {
      this.onStatementChanged(data, audioSource);
    }
    if ( organ === 'Lung') {
      this.selectedLungAuscultation = data.value;
    } else {
      this.selectedHeartAuscultation = data.value;
    }
  }

  // uploadToServer(fileName: string, soundType: string, blob: Blob) {
  //   const file = new File([blob], fileName + '.ogg', { type: 'audio/ogg' });

  //   // then this is how we send it off to the server...
  //   // interesting... if we choose voice, we need to pass either Baby, Female, or Male,
  //   // However, if it's auscultation, auscultation should have the soundtype already as a type....
  //   const fileKind: number =
  //     soundType === 'Voice' ? fileType.Voice : fileType.Auscultation;
  //   const gender: string =
  //     this.data.genderAndAge === 'Baby'
  //       ? 'Baby'
  //       : this.data.genderAndAge.indexOf('Female') > -1
  //       ? 'Female'
  //       : 'Male';

  //   let soundPath = '';
  //   switch (fileKind) {
  //     case fileType.Voice:
  //       soundPath = gender;
  //       break; // hmm I'm kinda worry about this.
  //     case fileType.Auscultation:
  //       soundPath = soundType;
  //       break;
  //     default:
  //       break; // should never happen but we'll find out?
  //   }

  //   this.isUploading = true;
  //   this.uploadingProgress = 0;
  //   // try uploading to the server. we need to handle the offline mode too hmm...
  //   this.uploadService.upload(file, fileKind, soundPath).subscribe((data) => {
  //     if (data !== null && data !== undefined) {
  //       // so at this point here, if it's in progress we know it's uploading...
  //       if (data.status === 'progress') {
  //         this.uploadingProgress = data.message;
  //       } else {
  //         // show them the progress dialog?
  //         this.isUploading = false;
  //         const url = ServerService.File_URL;
  //         this.uploadProgress = data.progress;
  //         // find a way to get the url path and then pass it down here.
  //         let newPath = '';

  //         switch (soundType) {
  //           case 'Voice': {
  //             newPath = url + '/voices/' + gender + '/' + fileName + '.ogg';
  //             this.statements.push(newPath);
  //             break;
  //           }
  //           case 'Heart': {
  //             newPath = url + '/auscultations/Heart/' + fileName + '.ogg';
  //             this.heartOptions.push(newPath);
  //             break;
  //           }
  //           case 'Lung': {
  //             newPath = url + '/auscultations/Lung/' + fileName + '.ogg';
  //             this.lungOptions.push(newPath);
  //             break;
  //           }
  //           default:
  //             break;
  //         }

  //         this.customList.push({ audioPath: newPath, category: soundType });
  //       }
  //     }
  //   });

  //   // create a new list and add it to the list.
  //   // now for the url path.... hmm..
  // }

  // May not needed this function at all.
  OnAddSound() {
    // clear the previous url blob
    this.recordUrl = '';

    // add the audio to the array collection
    this.customList.push({
      audioPath: this.recordUrl,
      category: this.selectedSoundType,
    });

    // clear the items. uhh.
    this.newSoundName = '';
    this.selectedSoundType = '';
  }

  /**
   * Delete the file from the server and remove from the array list.
   * If the selection have this file assigned, clear it as well.
   *
   * TODO: remove the custom sounds from the list.
   */
  deleteSound(item: CustomRecordings) {
    this.uploadService.deleteFile(item.audioPath).subscribe(
      (data) => {
        if (data != null) {
          console.log(data);
        }
      },
      (err) => console.error(err)
    );
    // so this didn't splice at all?
    const index = this.customList.indexOf(item);
    this.customList.splice(index, 1);

    // clear out from selected items if they are currently selected
    switch (item.category) {
      case 'Voice':
        if (this.selectedVoice === item.audioPath) {
          this.selectedVoice = 'none';
        }
        this.statements.splice(this.statements.indexOf(item.audioPath), 1);
        break;
      case 'Heart':
        if (this.selectedHeartAuscultation === item.audioPath) {
          this.selectedHeartAuscultation = 'none';
        }
        this.heartOptions.splice(this.heartOptions.indexOf(item.audioPath), 1);
        break;
      case 'Lung':
        if (this.selectedLungAuscultation === item.audioPath) {
          this.selectedLungAuscultation = 'none';
        }
        this.lungOptions.splice(this.lungOptions.indexOf(item.audioPath), 1);
        break;
    }
  }

  // sanitize( url : string ){
  //   return this.domSanitizer.bypassSecurityTrustUrl(url);
  // }

  /*************** End of recording *****************/
  //#endregion

  //#region Files Dropped

  /**
   * Function: On files dropped handler
   * Purpose: Handle how we deal with files being dropped into the drag and drop component
   */
  // onFilesDropped(files: Array<File>) {
  //   // in this case we'll upload them to the server. We'll default this to Voice,
  //   // and then later let the user change to other category if needed.
  //   files.forEach((x) => this.uploadToServer(x.name, 'Voice', x));
  //   // this.uploadToServer(this.newSoundName, this.selectedSoundType, blob);
  // }

  RenameFile(item: CustomRecordings, newName: string): void {
    // call the server to make a change ticket request.
    this.uploadService.renameFile(item.audioPath, newName).subscribe(
      (data) => {
        if (data != null) {
          console.log(data);
        }
      },
      (err) => console.log(err)
    );

    const online = item.audioPath.indexOf('/');
    const files = online ? item.audioPath.split('/') : item.audioPath.split('\\');
    const ext = files[files.length - 1].split('.');
    files[files.length - 1] = newName + '.' + ext[ext.length - 1];
    const newFile = files.join(online ? '/' : '\\');

    item.audioPath = newFile;
  }

  updateCategory(item: CustomRecordings, newCategory: string): void {
    if (item.category === newCategory) {
      return;
    } // do absolutely nothing at all.
    const gender =
      this.data.genderAndAge === 'Baby'
        ? 'Baby'
        : this.data.genderAndAge.indexOf('Female') > -1
        ? 'Female'
        : 'Male';

    let newKind = '';
    let newType = '';
    switch (newCategory) {
      case 'Voice':
        newKind = gender;
        newType = 'Voice';
        break;
      case 'Heart':
        newKind = 'Heart';
        newType = 'Auscultation';
        break;
      case 'Lung':
        newKind = 'Lung';
        newType = 'Auscultation';
        break;
    }

    this.uploadService
      .updateCategory(item.audioPath, newCategory, newKind)
      .subscribe(
        (data) => {
          if (data != null) {
            console.log(data);
          }
        },
        (err) => console.log(err)
      );

    // finally update the user interface.
    item.category = newCategory;
  }


  async playAudio(url: string) {
    if (this._audio === undefined || this._audio === null) {
      this._audio = new Audio();
    } else {
      this._audio.src = null;
    }
    this.onStatementChanged({ value: url }, this._audio, true);
  }

  onStatementChanged(data: any, audio: any, startPlaying: boolean = false) {
    audio.src = null;
    this.selectedAuscultationPath = data.value;
    this.http
      .get(data.value, { responseType: 'arraybuffer' })
      .subscribe((response: any) => {
        if (response) {
          const blob = new Blob([response], { type: 'audio/ogg' }); // this worries me a bit... ouch..
          let url = window.URL.createObjectURL(blob);
          audio.src = url;
          if (startPlaying) {
            audio.play();
          }
        }
      });
  }

  async pauseAudio() {
    await this._audio?.pause();
  }

  /**
   * //TODO: @jordan please comment here
   */
  private setUpCustomSound(gender: string) {
    this.uploadService.getCustomSounds().subscribe( x => {
      if (x === null || x === undefined) {
        return;
      }
      // supposedly there's a hierarchy system for this...
      // map voice collection
      const url = `${ServerService.File_URL}/`;
      if (x.Voices !== null && x.Voices !== undefined) {
        let voiceData: string[] = [];
        switch (gender) {
          case 'Baby':
            voiceData = x.Voices.Baby;
            break;
          case 'Female':
            voiceData = x.Voices.Female;
            break;
          case 'Male':
            voiceData = x.Voices.Male;
            break;
          default:
        }

        voiceData = voiceData.map( y => url + y);
        console.log(voiceData);
        this.statements = this.statements.concat(voiceData);
        voiceData.map( y =>
          this.customList.push({ audioPath: y, category: 'Voice' })
        );
      }

      // map auscultation collection
      if (x.Auscultations !== null && x.Auscultations !== undefined) {
        let data: string[] = [];

        // lung auscultation parsing
        data = x.Auscultations?.Lung?.map( y => url + y);
        if (data !== undefined && data !== null) {
          this.lungOptions = this.lungOptions.concat(data);
          data.map( y =>
            this.customList.push({ audioPath: y, category: 'Lung' })
          );
        }

        // heart auscultation parsing
        data = x.Auscultations?.Heart?.map( y => url + y);
        if (data !== undefined && data !== null) {
          this.heartOptions = this.heartOptions.concat(data);
          data.map( y =>
            this.customList.push({ audioPath: y, category: 'Heart' })
          );
        }
      }
    });
  }

  /**
   * Load file configuration from JSON based on gender type.
   */
  private loadConfigurationBasedOnGenderAndAge() {
    switch (this.data.genderAndAge.trim()) {
      case 'Baby':
        this.statements = voiceConfig.Baby;
        break;
      case 'Adult_Female':
        this.statements = voiceConfig.Adult_Female;
        break;
      case 'Adult_Male':
        this.statements = voiceConfig.Adult_Male;
        break;
      case 'Geriatric_Male':
        this.statements = voiceConfig.Geriatric_Male;
        break;
      case 'Geriatric_Female':
        this.statements = voiceConfig.Geriatric_Female;
        break;
      default:
    }
  }

  /**
   * set the default drop down options for voice and auscultations
   */
  private setDefaultDropdownOptions() {
    console.log(this.data);
    this.selectedVoice = this.setDropDown(this.data.initialVoice, this.selectedVoice);
    this.selectedLungAuscultation = this.setDropDown(this.data.initialLung, this.selectedLungAuscultation);
    this.selectedHeartAuscultation = this.setDropDown(this.data.initialHeart, this.selectedHeartAuscultation);
  }

  /**
   * generic function that is used help set up the default values for the drop down menus
   * @param compareData external data to compare against
   * @param setData the string you wish to have be set
   */
  private setDropDown(compareData: string, setData: string) {
    if (!compareData || compareData === '') {
      return 'none';
    } else {
      return compareData;
    }
  }

  onDialogClose(): void {
    this.dialogRef.close({
      voice: this.selectedVoice === 'none' ? null : this.selectedVoice,
      lungAuscultation: this.selectedLungAuscultation === 'none' ? null : this.selectedLungAuscultation,
      heartAuscultation: this.selectedHeartAuscultation === 'none' ? null : this.selectedHeartAuscultation,
    });
  }
}
