


<div class="dialog-container-header">
    <div class="header content">
      <h2 mat-dialog-title class="header-margin">{{ this.initialName }} </h2>
      <div class="header-flex-box">
        <div class="author-margin">{{ this.data.scenario.originalAuthor.fName }} {{ this.data.scenario.originalAuthor.lName }}</div>
        <em>Created: {{ this.prettyCreatedDate }}</em>
        <em style="margin-left: 20px">Updated: {{ this.prettyUpdatedDate }}</em>
      </div>
    </div>
    <button style="height: 40px; width: 40px;" mat-icon-button (click)="onDialogClose()">
      <mat-icon style="height: 36px; width: 36px; font-size: 36px; transform: translate(0px,6px);">close</mat-icon>
    </button>
  </div>
    <mat-dialog-content style="height: auto;width: 80%;">
        <br />
        <h3>
          This Scenario has been downloaded from  team or community tab to share this scenario you need to rename scenario.
        </h3>
        <br/>
        <h3>Current Scenario name:  {{ this.initialName }} </h3>
        <br/>
       <h2>New Scenario Name:
        <mat-form-field appearance="outline" class="left">
          <mat-label>Scenario Name</mat-label>
          <input
            autocomplete="off"
            matInput
            placeholder="Placeholder"
            [(ngModel)]="this.data.scenario.name"
            required
          />
        </mat-form-field>
       </h2>
    </mat-dialog-content>
    <div   style="width: 100%;position:relative;text-align: center;"> <!--*ngIf="(!isOwnedScenario)" style="width: 100%;position:relative;"-->
      <br/>
      <hr/>
      <button
        mat-button
        class="btn-green"
        (click)="onReShare(this.data.scenario)"
      >
        <mat-icon class="icon-size">get_app</mat-icon> Share with {{this.data.moduleName}}
      </button>
    </div>