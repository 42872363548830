<div class="dialog-container-header">
  <h2 mat-dialog-title>Select Patient Image</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="selected-image-wrapper">
    <mat-label>Selected Image:</mat-label>
    <img
        loading="lazy"
        [src]="this.selectedImage"
        alt="{{ this.selectedImage }}"
    />
  </div>
  <div class="content-scroll">
    <!-- <app-ngui-in-view *ngFor="let imageUrl of urlImages"> -->
      <img class="selectable"
        #image
        *ngFor="let imageUrl of urlImages"
        loading="lazy"
        [src]="imageUrl"
        alt="{{ imageUrl }}"
        (click)="onSelectImage(imageUrl)"
        (error)="image.hidden = true"
        [hidden]="image.hidden"
      />
    <!-- </app-ngui-in-view> -->
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary">cancel</button>
  <button mat-button class="green-focus" [mat-dialog-close]="this.selectedImage">submit</button>
</mat-dialog-actions>
