import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { NgxAudioPlayerModule } from 'ngx-audio-player';

import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EditorComponentComponent } from './editor-component/editor-component.component';
import { ScenariosHeaderComponent } from './scenarios-header/scenarios-header.component';
import { ScenariosBodyComponent } from './scenarios-body/scenarios-body.component';
import { SimulationPreviewComponent } from './simulation-preview/simulation-preview.component';
import { ScenariosInformationComponent } from './scenarios-information/scenarios-information.component';
import { SimulationTimelineComponent } from './simulation-timeline/simulation-timeline.component';
import { SymptomsListComponent } from './symptoms-list/symptoms-list.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { SymptomsComponent } from './symptoms/symptoms.component';
import { PhaseComponent } from './phase/phase.component';
import { DialogPhasesComponent } from './dialogs/dialog-phases/dialog-phases.component';
import { DialogTreatmentOptionComponent } from './dialogs/dialog-treatment-option/dialog-treatment-option.component';
import { DialogInstructorPointComponent } from './dialogs/dialog-instructor-point/dialog-instructor-point.component';
import { InstructorNoteComponent } from './instructor-note/instructor-note.component';
import { TreatmentPointComponent } from './treatment-point/treatment-point.component';
import { DialogDeletePhaseComponent } from './dialogs/dialog-phases/dialog-delete-phase/dialog-delete-phase.component';
import { DialogSoundsComponent } from './dialogs/dialog-sounds/dialog-sounds.component'
import { DialogVitalsComponent } from './dialogs/dialog-vitals/dialog-vitals.component';
import { DialogDeleteSimulationComponent } from './dialogs/dialog-simulation/dialog-delete-simulation/dialog-delete-simulation.component';
import { DialogTutorialComponent } from './dialogs/dialog-tutorial/dialog-tutorial.component';
import { DialogTexturesComponent } from './dialogs/dialog-textures/dialog-textures.component';
import { DialogSymptomEditComponent } from './dialogs/dialog-symptom-edit/dialog-symptom-edit.component';
import { DialogLabValuesComponent } from './dialogs/dialog-lab-values/dialog-lab-values.component';
import { DialogAutomaticTransitionComponent } from './dialogs/dialog-automatic-transition/dialog-automatic-transition.component';
import { DialogAddPatientComponent } from './dialogs/dialog-add-patient/dialog-add-patient.component';
import { DialogDeleteSymptomComponent } from './dialogs/dialog-symptom/dialog-delete-symptom/dialog-delete-symptom.component';
import { DialogDeleteInstructorPointComponent } from './dialogs/dialog-instructor-point/dialog-delete-instructor-point/dialog-delete-instructor-point.component';
import { DialogDeleteTreatmentOptionComponent } from './dialogs/dialog-treatment-option/dialog-delete-treatment-option/dialog-delete-treatment-option.component';
import { DialogDeletePatientComponent } from './dialogs/dialog-delete-patient/dialog-delete-patient.component';
import { DialogEditPhaseComponent } from './dialogs/dialog-phases/dialog-edit-phase/dialog-edit-phase.component';

import { ScenariosService } from '../services/scenarios.service'

import { DragDropModule } from '@angular/cdk/drag-drop';

import { AudioHoverButtonComponent } from './symptoms/audio-hover-button/audio-hover-button.component';
import { LayerHoverButtonComponent } from './symptoms/layer-hover-button/layer-hover-button.component';
import { VitalsHoverButtonComponent } from './symptoms/vitals-hover-button/vitals-hover-button.component';
import { LabsHoverButtonComponent } from './symptoms/labs-hover-button/labs-hover-button.component';
import { TransitionHoverButtonComponent } from './symptoms/transition-hover-button/transition-hover-button.component';

import { RouterModule } from '@angular/router';
import { AddPatientModule } from '../add-patient/add-patient.module';
import { ScenarioFilesComponent } from './scenario-files/scenario-files.component';
import { DndDirective } from './scenario-files/dnd.directive';
import { DialogEditPatientComponent } from './dialogs/dialog-edit-patient/dialog-edit-patient.component';
import { DialogScenarioEditComponent } from './dialogs/dialog-scenario-edit/dialog-scenario-edit.component';

import { TagSearchModule } from '../tag-search/tag-search.module';
import { ChecklistComponent } from './checklist/checklist.component'
import { toolsservice } from './services/tools.service';

import { ReplaceUnderscore } from '../custom-pipes/remove-underscores.pipe';
import { DialogBabyWarningComponent } from './dialogs/dialog-baby-warning/dialog-baby-warning.component';
import { AdvancesearchboxComponent } from './advancesearchbox/advancesearchbox.component';
import { DialogCreditsComponent } from './dialogs/dialog-credits/dialog-credits.component';

import { ApplicationPipesModuleModule } from 'src/app/custom-pipes/application-pipes-module/application-pipes-module.module';
import { DialogUploadFilesComponent } from './dialogs/dialog-upload-files/dialog-upload-files.component';

import { AudioModule } from 'src/app/audio/audio.module';
import { DialogTreatmentComponent } from './dialogs/dialog-treatment/dialog-treatment.component';
import { EquipmentComponent } from './dialogs/dialog-treatment/equipment/equipment.component';
import { NguiInViewComponent } from './ngui-in-view/ngui-in-view.component';
import { DialogListDeviceComponent } from './dialogs/dialog-list-device/dialog-list-device.component';
import { DialogEditVentilatorComponent } from './dialogs/dialog-edit-ventilator/dialog-edit-ventilator.component';
import { DialogEditIVPumpComponent } from './dialogs/dialog-edit-iv-pump/dialog-edit-iv-pump.component';
import { SharedModule } from '../shared/shared.module';
import { ChannelComponent } from './devices/ivpump/channel/channel.component';
import { LineComponent } from './devices/ivpump/line/line.component';
import { DialogEditAuscultationComponent } from './dialogs/dialog-edit-auscultation/dialog-edit-auscultation.component';
import { AuscultationPointComponent } from './dialogs/dialog-edit-auscultation/auscultation-point/auscultation-point.component';
import { ProceduresHoverButtonComponent } from './symptoms/procedures-hover-button/procedures-hover-button.component';

@NgModule({
    declarations: [
        EditorComponentComponent,
        ScenariosHeaderComponent,
        ScenariosBodyComponent,
        SimulationPreviewComponent,
        ScenariosInformationComponent,
        SimulationTimelineComponent,
        SymptomsListComponent,
        PatientInfoComponent,
        SymptomsComponent,
        PhaseComponent,
        DialogPhasesComponent,
        DialogTreatmentOptionComponent,
        DialogInstructorPointComponent,
        InstructorNoteComponent,
        TreatmentPointComponent,
        DialogDeletePhaseComponent,
        DialogSoundsComponent,
        DialogVitalsComponent,
        DialogDeleteSimulationComponent,
        DialogTutorialComponent,
        DialogTexturesComponent,
        DialogSymptomEditComponent,
        DialogLabValuesComponent,
        DialogAutomaticTransitionComponent,
        DialogAddPatientComponent,
        DialogDeleteSymptomComponent,
        DialogDeleteInstructorPointComponent,
        DialogDeleteTreatmentOptionComponent,
        DialogDeletePatientComponent,
        DialogEditPhaseComponent,
        AudioHoverButtonComponent,
        LayerHoverButtonComponent,
        VitalsHoverButtonComponent,
        LabsHoverButtonComponent,
        TransitionHoverButtonComponent,
        ScenarioFilesComponent,
        DndDirective,
        DialogEditPatientComponent,
        DialogScenarioEditComponent,
        ChecklistComponent,
        DialogBabyWarningComponent,
        AdvancesearchboxComponent,
        DialogCreditsComponent,
        DialogUploadFilesComponent,
        DialogTreatmentComponent,
        EquipmentComponent,
        NguiInViewComponent,
        DialogListDeviceComponent,
        DialogEditVentilatorComponent,
        DialogEditIVPumpComponent,
        ChannelComponent,
        LineComponent,
        DialogEditAuscultationComponent,
        AuscultationPointComponent,
        ProceduresHoverButtonComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        // NgxAudioPlayerModule,
        DragDropModule,
        RouterModule,
        AddPatientModule,
        TagSearchModule,
        ApplicationPipesModuleModule,
        AudioModule,
        SharedModule,
    ],
    providers: [
        ScenariosService,
        toolsservice,
    ],
    exports: [
        EditorComponentComponent,
        SimulationPreviewComponent,
        NguiInViewComponent,
    ]
})

export class EditorModule { }
