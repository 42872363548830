import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device } from 'src/app/models/device.model';
import { IVPump } from 'src/app/models/ivpump.model';
import { Ventilator } from 'src/app/models/ventilator.model';

export interface DialogData {
  activeDevices: Device[];
}

export interface DeviceState {
  deviceObject: Device;
  isActive: boolean;
}

@Component({
  selector: 'app-dialog-list-device',
  templateUrl: './dialog-list-device.component.html',
  styleUrls: ['./dialog-list-device.component.scss'],
})
export class DialogListDeviceComponent implements OnInit {

  public selectedDevices: Device[] = []; //devices the user selects to use
  public availableDevices: Device[] = [new IVPump(), new Ventilator()]; //devices available to be selected
  public deviceStates: DeviceState[] = []; //the states of all the listed items (true/false) collections for check boxes / ticks

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    this.availableDevices.forEach((device) => {
      this.deviceStates.push({ deviceObject: device, isActive: false });
    });

    //for each collected device state, if the device is already in the collected
    // selectedDevices array set its active state to true
    this.deviceStates.forEach((state) => {
      this.data.activeDevices.forEach((activeState) => {
        if (state.deviceObject.kind === activeState.kind) {
          state.isActive = true;
          this.selectedDevices.push(state.deviceObject);
        }
      });
    });
  }

  /**
   * add/remove items from the chosen equipment list using the dom
   * @param $event the event passed from the dom (in this case true/false)
   * @param index the position in the array
   */
  public updateActiveState($event: any, index: number) {
    //grab the true or false value from the dom
    this.deviceStates[index].isActive = $event;

    //if that logged value is true, then add the equipment to the list
    //otherwise if that equipment if false, remove it from the list using
    if (this.deviceStates[index].isActive === true) {
      this.selectedDevices.push(this.deviceStates[index].deviceObject);
    } else {
      this.selectedDevices.splice(
        this.selectedDevices.indexOf(this.deviceStates[index].deviceObject),
        1
      );
    }
  }
}
