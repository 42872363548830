<div class="hover-button" (mouseleave)="showPopup = false">
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
        style="transform: translateX(-50%);" (mouseover)="showPopupF()" (click)="showProceduresPopup()">
        <mat-icon style="color: #1f7abe"><img style="width: 24px; height: 24px;" loading="lazy" alt="medical_services-black-18dp" src="../../../../assets/SVGIcons/medical_services-blue-18dp.svg"></mat-icon>
    </button>
    <div class="popup" *ngIf="showPopup">
        <div class="popup-notch">
        </div>
        <div class="transparent-top-part" (click)="showProceduresPopup()">
        </div>
        <div class="popup-content" *ngIf="showPopupContent">
            <button mat-flat-button style="color:#1f7abe" (click)="onEditItem()">
                <mat-icon>create</mat-icon> Edit
            </button>
            <button mat-flat-button style="color:#1f7abe" (click)="onDeleteItem()">
                <mat-icon>delete</mat-icon> Delete
            </button>
        </div>
    </div>
</div>