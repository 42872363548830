import { Component, OnInit, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { patient } from 'src/app/models/patient.model';
import { AnimationTransitionService } from 'src/app/editor/services/animation-transition.service';

export interface DialogData {
  name: string;
  state: string;
  time: number;
  patient: patient;
  currentPhaseId: string;
  currentStateId: string;
}

@Component({
  selector: 'app-dialog-automatic-transition',
  templateUrl: './dialog-automatic-transition.component.html',
  styleUrls: ['./dialog-automatic-transition.component.scss'],
})
export class DialogAutomaticTransitionComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogAutomaticTransitionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public animationTransitionService: AnimationTransitionService,
  ) {}

  state = '';
  mode = 'seconds';
  time = 0;
  actualState = '';


  ngOnInit(): void {
    this.time = this.data.time;

    // Get all symptoms name from every other phase
    this.animationTransitionService.getSymptoms(this.data.patient, this.data.currentStateId);
    this.getValueForClinicalTransition(
      this.animationTransitionService.transitionData.indexOf(this.data.state)
    );
  }

  /**
   * use the index from the selected transition pretty list selected by the user to select the data that will be sent to the controller
   */
  public getValueForClinicalTransition(index: number): void {
    this.state = this.animationTransitionService.transitionPrettyList[index];
    this.actualState = this.animationTransitionService.transitionData[index];
  }

  onDialogClose(): void {
    const seconds =
      this.mode.toLowerCase() === 'seconds' ? this.time : this.time * 60;
    this.dialogRef.close({ prettyState: this.state, state: this.actualState, time: seconds });
  }
}
