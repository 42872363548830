<div class="dialog-container">
    <h2 mat-dialog-title style="font-family: OpenSansLight;"> Archive Member? </h2>
    <hr>
    <mat-dialog-content>
        <p>Are you sure you want to archive {{data.name}} from your team?</p>
        <br>
        <p>Any simulations this member created can be accessed through their archived account</p>
        <br>
        <section>
            <mat-checkbox [(ngModel)]="checked" style="font-size: medium; color: black;">I understand this member will be archived</mat-checkbox>
        </section>
    </mat-dialog-content>
    <br>
    <mat-dialog-actions>
        <div class="buttons">
            <span>
                <button mat-button color="primary" mat-dialog-close>Cancel</button>
                <button mat-raised-button mat-dialog-close
                    [mat-dialog-close]="true" 
                    color="warn" [ngStyle]="{'opacity': checked ? '1' : '0.5'}" [disabled]="!checked">Archive Member</button>
            </span>
        </div>
    </mat-dialog-actions>
</div>