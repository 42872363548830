<!-- <div class="dialog-container"> -->
    <div class="dialog-container-header">
      <h2 mat-dialog-title>WARNING </h2><mat-icon color="error">warning</mat-icon>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <!-- <hr /> -->
    <mat-dialog-content> 
        <p> Changing to {{this.data.age}} will result in reset of scenario timeline </p>
        <div style="height: 32px;">
            <mat-checkbox class="example-margin">I understand</mat-checkbox>
        </div>

    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
      <!-- <div class="buttons">
        
        <span> -->
          <button mat-button color="primary" mat-dialog-close>Cancel</button>
          <button mat-button class="green-focus" [mat-dialog-close]="true">Make Change</button>
        <!-- </span>
      </div> -->
    </mat-dialog-actions>
  <!-- </div> -->
  