export class TaskItem {
    public critical = false;
    public categoryId:number;
    public taskName:string;
    
    constructor( taskName : string ,categoryId:number=0,critical:boolean=false) { 
        this.taskName = taskName;
        this.critical=critical;
        this.categoryId=categoryId;
    } 
}

export class TaskList
{
    public  tasklist :TaskItem[];
    public Categories:number[];
    constructor(taskList:TaskItem[]=[new TaskItem('')],categories:number[]=[0]) {
        this.tasklist=taskList;
        this.Categories=categories;
    }
}