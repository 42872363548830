//#region imports
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Desktop Editor Modules
import { EditorModule } from './editor/editor.module';
import { AdminModule } from './admin/admin.module';
import { DevicesModule } from './devices/devices.module';
import { ListOfScenariosModule } from './list-of-scenarios/list-of-scenarios.module';
import { LoginModule } from './login/login.module';
import { AccountModule } from './account/account.module';
import { SettingsModule } from './settings/settings.module';
import { AddPatientModule } from './add-patient/add-patient.module';
import { ScenarioCardModule } from './scenario-card/scenario-card.module';

// routing module and internet specific functionality
import { AppRoutingModule } from './app-routing.module';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
// import { CookieService } from 'ngx-cookie-service';

// custom modules
import { TagSearchModule } from './tag-search/tag-search.module';
import { PhaseCardModule } from 'src/app/phase-card/phase-card.module';
import { SearchModule } from './search/search.module';
import { AudioModule } from 'src/app/audio/audio.module';

// custom components

import { PersimHeaderComponent } from './persim-header/persim-header.component';

import { SnackbarComponent } from './services/snackbar/snackbar.component';
import { DialogPromptComponent } from './dialogs/dialog-prompt/dialog-prompt.component';
import { DialogSelectImageComponent } from './dialogs/dialog-select-image/dialog-select-image.component';

// shared module - for modules that are shared between components
import { SharedModule } from 'src/app/shared/shared.module';

// providers
import { PermissionsService } from './services/permissions.service';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ScenarioEditorComponent } from './scenario-editor/scenario-editor.component';

import { ErrorLoggerService } from 'src/app/services/errorlogger.service';
import { CookieService } from 'ngx-cookie-service';

//#region Class and Decorators
@NgModule({
    declarations: [
        PagenotfoundComponent,
        AppComponent,
        PersimHeaderComponent,
        // JqueryTestingComponent,
        SnackbarComponent,
        DialogPromptComponent,
        DialogSelectImageComponent,
        ResetPasswordComponent,
        ScenarioEditorComponent,
    ],
    exports: [PersimHeaderComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        DragDropModule,
        EditorModule,
        AdminModule,
        DevicesModule,
        ListOfScenariosModule,
        LoginModule,
        TagSearchModule,
        AccountModule,
        SettingsModule,
        HttpClientModule,
        AddPatientModule,
        ScenarioCardModule,
        PhaseCardModule,
        SearchModule,
        AudioModule,
        SharedModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: ErrorLoggerService },
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        PermissionsService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
//#endregion