import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {
  constructor(
    private appComponent:AppComponent,
    private router:Router
  ) { 

  }
  ngOnInit(): void {
    this.appComponent.isErrorPage=true;
  }
  
  redirectToDashBoard()
  {
    this.appComponent.isErrorPage=false;
    this.router.navigateByUrl('/dashboard');

  }

}
