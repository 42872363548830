<div class="searchWindow">
    <!-- List of selected items -->
    <div #selectedView (click)="$event.stopPropagation();">
        <!-- Selected Items View -->
        <mat-chip-list *ngIf="selectedItems?.length > 0; else noSelection">
            <mat-chip *ngFor="let item of selectedItems" (click)="onRemoveItem(item);$event.stopPropagation();">
                <!-- <span class="roundBorder">  -->
                    {{item}}

                    <button mat-icon-button [matMenuTriggerFor]="selectedMenu" (click)="$event.stopPropagation();" class="selectedMenu">
                        <mat-icon >more_vert</mat-icon>
                    </button>

                    <mat-menu #selectedMenu="matMenu" (click)="$event.stopPropagation();">
                        <button mat-menu-item (click)="onEditItem(item)">Edit 
                            <mat-icon>create</mat-icon>
                        </button>
                        <button mat-menu-item (click)="onDeleteItem(item);$event.stopPropagation()">Delete
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-menu>
                <!-- </span> -->
            </mat-chip>
        </mat-chip-list>

        <!-- Alt selected view -->
        <ng-template #noSelection>
            <div>
                <p><i>You do not have any selected {{title}}!</i></p>
            </div>
        </ng-template>
    </div>

    <!-- Search view -->
    <div #searchView>
        <form #form (submit)="onAddItem($event.target.searchItem.value);form.reset();" (click)="$event.stopPropagation();">
            <mat-form-field (click)="$event.stopPropagation();" class="autoWidth">
                <input matInput type="text" name="searchItem" #searchItem [placeholder]="displayTitle" (click)="$event.stopPropagation();" required>
            </mat-form-field>
        </form>
    </div>

    <!-- History collection -->
    <div #collectionWindow *ngIf="unselectedCollection(searchItem.value).length > 0; else showCreateNew">
        <div *ngFor="let item of unselectedCollection(searchItem.value)" class="historyItem">
            <button class="historyButton" mat-button (click)="onSelectItem(item);$event.stopPropagation();">
                <span class="itemDisplay">{{item}}</span>

                <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();" class="itemActionButton">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu" (click)="$event.stopPropagation();">
                    <button mat-menu-item (click)="onEditItem(item)">Edit 
                        <mat-icon>create</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onDeleteItem(item);$event.stopPropagation()">Delete
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-menu>
            </button>
        </div>
    </div>

    <!-- Alt history view -->
    <ng-template #showCreateNew>
        <div style="text-align: center;" (click)="$event.stopPropagation();">
            <p><i>You do not have any {{title}} history! Create a new {{title}}!</i></p>
        </div>
    </ng-template>
</div>