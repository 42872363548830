
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA,   MatDialog } from '@angular/material/dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { toolsservice } from 'src/app/editor/services/tools.service';
import { patient } from 'src/app/models/patient.model';
import { scenario, ratedUser } from 'src/app/models/scenario.model';
import { phase } from 'src/app/models/phase.model';
import { TeamsService } from 'src/app/services/teams.service';
import { ScenariosService } from 'src/app/services/scenarios.service';
import { UnityService } from 'src/app/editor/simulation-preview/unity.service';
import { UntypedFormControl } from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Observable } from 'rxjs';
import { TaskItem, TaskList } from 'src/app/models/taskitem.model';
import { UploadService } from 'src/app/services/upload.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerService } from 'src/app/services/Server.service';
import { ScenarioCardComponent } from '../../scenario-card/scenario-card.component';
import { DialogUploadFileComponent } from '../dialog-upload-file/dialog-upload-file.component';
import { PersimHeaderComponent } from 'src/app/persim-header/persim-header.component';
import { FeatureKeys } from 'src/app/services/featureflag.service';
export interface DialogData {
  scenario: scenario;
  onYourScenarioTab: boolean;
  scenarioCard: ScenarioCardComponent;
}

export interface PhaseToggler {
  phase: phase;
  toggled: boolean;
}

@Component({
  selector: 'app-dialog-preview-window',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          backgroundColor: 'green',
          maxWidth: '400px',
          width: '400px',
        })
      ),
      state(
        'closed',
        style({
          backgroundColor: 'blue',
          maxWidth: '0px',
          width: '0px',
          display: 'none',
        })
      ),
      transition('open <=> closed', [animate('0.2s')]),
    ]),
  ],
  templateUrl: './dialog-preview-window.component.html',
  styleUrls: ['./dialog-preview-window.component.scss'],
})
export class DialogPreviewWindowComponent implements OnInit {
  public patientArray: patient[] = [];

  public phaseToggleCollection: PhaseToggler[] = [];

  public userRating = 0;

  public prettyCreatedDate: string = null;
  public prettyUpdatedDate: string = null;

  public isOwnedScenario = false;
  public favorited = false;

  public selected: UntypedFormControl = new UntypedFormControl(0);

  public showMoreInfo = false;

  public taskList: TaskList;
  public isRatingEnabled: boolean = false;

  private simulationDownloaded = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogPreviewWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private teamService: TeamsService,
    private snackbar: MatSnackBar,
    private scenarioService: ScenariosService,
    private unityService: UnityService,
    private uploadService: UploadService,
    private http: HttpClient,
    private cd:ChangeDetectorRef

  ) {


  }

  ngOnInit(): void {
    let test = this.data.scenario.name;
    this.taskList = new TaskList();
    this.patientArray = this.data.scenario.patients;
    let UID = this.authService.getUserID();
    for (const initRatedUser of this.data.scenario.ratedUsers) {
      if (initRatedUser.UID === UID) {
        // THE USER HAS ALREADY RATED, LOG THE RATING
        this.userRating = initRatedUser.rated;
      }
    }

    let tempDate: Date = new Date(this.data.scenario.originalDate);
    this.prettyCreatedDate = tempDate.toLocaleDateString();
    tempDate = new Date(this.data.scenario.modifiedDate);
    this.prettyUpdatedDate = tempDate.toLocaleDateString();
    this.phasePrep();
    this.favorited = this.isFavorited();
    // assuming I will always be on index 0 when I open the DIALOG box
    this.newCharacter(0);

    this.getTaskList(this.data.scenario.checklistLink);
  }

  ngAfterViewInit(): void {

    this.isRatingEnabled = PersimHeaderComponent.featureList.find(x => x.featureName == FeatureKeys.RatingSystem).isEnabled;
    this.cd.detectChanges();

  }

  public newCharacter(index: number) {
    this.unityService.loadCharacter(
      this.patientArray[index].modelType,
      this.patientArray[index].patientName,
      this.patientArray[index].clothingType
    );
  }

  formatDescription(description: string, patientName: string) {
    let patientNames: string[] = [];
    this.patientArray = this.data.scenario.patients;

    if (description != null) {
      let createdDescription = description.replace(/\n/g, '<br>').replace(/\*/g, '<hr>');
      this.patientArray.forEach(element => {
        patientNames.push(element.patientName);
        createdDescription = createdDescription.replace("Patient:" + element.patientName, "^");
      });
      let slicedDescription = createdDescription.split('^');
      let patientIndex = patientNames.indexOf(patientName);
      let result = `<b>Description</b> <hr> Patient:${patientNames[patientIndex]}  ${slicedDescription[patientIndex + 1]}`;
      return result;


    }
    else {
      return description;

    }
  }
  public getTaskList(checklistLink: string) {
    if (checklistLink == null || checklistLink == '') {
      this.taskList.tasklist.splice(0);
      return;

    }
    this.readTaskList(checklistLink).subscribe(result => {
      if (!result) {
        return;
      }
      let taskItemArray = result.tasklist;

      for (const taskItem of taskItemArray) {
        this.taskList.tasklist.push(taskItem);
      }

      return result;
    });
  }

  public uploadCustomAudio(iScenario: scenario) {
    const dialogRef = this.dialog.open(DialogUploadFileComponent, {
      width: '1200px',
      data: { scenario: iScenario }
    });


  }

  readTaskList(taskname: string): Observable<TaskList> {


    return this.http.get<TaskList>(`${ServerService.Task_URL}/${taskname}`);;
  }

  // TODO: event to listen for tab changes. then use that index to change the index of the patients in the unityService.loadCharacter
  public changePatient($event): void {
    this.selected.setValue($event);
    this.newCharacter(this.selected.value);
  }



  // check to see if the scenario has already been favorited by the user.
  public isFavorited() {
    for (const favoriteUser of this.data.scenario.favoriteUsers) {
      if (favoriteUser === this.authService.getUserID()) {
        return true;
      }
    }
    return false;
  }

  // un-favorite the simulation
  public onUnFavorite() {
    // search for the userID of the currently active user, remove that user from the favorite users list
    this.data.scenario.favoriteUsers.splice(
      this.data.scenario.favoriteUsers.indexOf(this.authService.getUserID()),
      1
    );
    this.scenarioService.toggleFavorites(this.data.scenario).subscribe();
    this.favorited = false;
  }

  // Sets the data inside the array of phasetogglers
  public phasePrep(): void {
    for (const tempPatient of this.patientArray) {
      for (const tempPhase of tempPatient.phases) {
        const tempPhaseToggle: PhaseToggler = {
          phase: tempPhase,
          toggled: false,
        };
        this.phaseToggleCollection.push(tempPhaseToggle);
      }
    }

    // print test
  }

  public toggleOpen(phaseObject: phase) {
    for (const phaseToggle of this.phaseToggleCollection) {
      if (phaseToggle.phase === phaseObject) {
        phaseToggle.toggled = !phaseToggle.toggled;
      }
    }
  }

  // CONTROLS RATING SYSTEM, sends in associated rating to be stored on a user by user basis
  // updates if the user already rated.
  public onRateSimulation(rating: number) {

    const ratedUserData: ratedUser = {
      UID: '0',
      rated: 0,
    };
    this.scenarioService.updateRatings(this.data.scenario, rating).subscribe();
    const userId = this.authService.getUserID();
    for (const ratedUserObject of this.data.scenario.ratedUsers) {

      if (ratedUserObject.UID === userId) {
        ratedUserObject.rated = rating;
        this.userRating = rating;
        return;
      }
    }

    ratedUserData.UID = userId;
    ratedUserData.rated = rating;
    this.userRating = rating;
    this.data.scenario.ratedUsers.push(ratedUserData);

    // server update

    return;
  }

  onFavoriteSim(associatedScenario: scenario) {
    associatedScenario.favoriteUsers.push(this.authService.getUserID());
    this.favorited = true;

    // server update
    this.scenarioService.toggleFavorites(this.data.scenario).subscribe();
  }

  onTeamDownload(selectedScenario: scenario): void {
    this.data.scenarioCard.downloadScenarioProcess();
    this.dialogRef.close({ Download: this.simulationDownloaded });
  }

  onDialogClose() {
    this.dialogRef.close({ Download: this.simulationDownloaded });
  }
}
